import { AES, enc, lib, mode, pad } from 'crypto-js';

const REACT_APP_ENCRYPT_DECRYPT_KEY  = 'vdz6eJqHZexB87KHKe2aUg==';

export const generateHash = plainText => {
    const randomString = lib.WordArray.random(16).toString();
    const usedIV = randomString.substr(0, 16);
    const key = enc.Latin1.parse(REACT_APP_ENCRYPT_DECRYPT_KEY);
    const iv = enc.Latin1.parse(usedIV);
    const encrypted = AES.encrypt(plainText, key, {
        iv,
        mode: mode.CBC,
        padding: pad.ZeroPadding,
    });
    return `${encrypted}_${usedIV}`;
};

export const decryptHash = input => {
    const splitedInput = input.split('_');
    const data = splitedInput[0];
    const iv = splitedInput[1];
    const decodedData = enc.Base64.parse(data);
    const decryptedData = AES.decrypt(
        {
            ciphertext: decodedData,
        },
        enc.Utf8.parse(REACT_APP_ENCRYPT_DECRYPT_KEY),
        {
            iv: enc.Utf8.parse(iv),
            mode: mode.CBC,
            padding: pad.ZeroPadding,
        },
    );

    return decryptedData.toString(enc.Utf8).trim();
};
