// Icons
import ExploreIcon from '@material-ui/icons/Explore';
import FaceIcon from '@material-ui/icons/Face';
import PersonIcon from '@material-ui/icons/Person';
// import './services/authorization/auth-intercept-service';

// Pages
import {
  advaitHome,
  Alarms,
  AlarmActive,
  GeoMap,
  Graphics,
  Events,
  Trends,
  ManualEntry,
  GroupDisplay,
  Lockscreen,
  PasswordReset,
  Signin,
  Signup,
  NotFound,
  BackendError,
  TestGLG,
  Report,
  assetHome,
  ReportDownload
} from './pages';

export default {
  items: [
    {
      path: '/home',
      name: 'Advait Home',
      type: 'link',
      icon: ExploreIcon,
      component: assetHome,
    },
    {
      path: '/assetHome',
      name: 'Home',
      type: 'link',
      icon: ExploreIcon,
      component: assetHome,
    },
    {
      path: '/alarms',
      name: 'Alarms',
      type: 'link',
      icon: ExploreIcon,
      component: Alarms,
    },
    {
      path: '/alarmActive',
      name: 'Alarm Active',
      type: 'link',
      icon: ExploreIcon,
      component: AlarmActive,
    },
    {
      path: '/geomap',
      name: 'GeoMap',
      type: 'link',
      icon: ExploreIcon,
      component: GeoMap,
    },
    {
      path: '/graphics/:filename',
      // path: '/graphics?filename=',
      name: 'Graphics',
      type: 'link',
      icon: ExploreIcon,
      component: Graphics,
    },
    {
      path: '/events',
      name: 'User Events',
      type: 'link',
      icon: ExploreIcon,
      component: Events,
    },
    {
      path: '/trends',
      name: 'Trends',
      type: 'link',
      icon: ExploreIcon,
      component: Trends,
    },
    {
      path: '/maualEntry',
      name: 'Manual-Entry',
      type: 'link',
      icon: ExploreIcon,
      component: ManualEntry,
    },
    {
      path: '/groupDisplay',
      name: 'Group-Display',
      type: 'link',
      icon: ExploreIcon,
      component: GroupDisplay,
    },
    {
      path: '/testGLG',
      name: 'Test',
      type: 'link',
      icon: ExploreIcon,
      component: TestGLG,
    },
    {
      path: '/oneTimeReport',
      name: 'Report',
      type: 'link',
      icon: ExploreIcon,
      component: Report,
    },
    
    {
      path: '/downloadReport',
      name: 'Report Download',
      type: 'link',
      icon: ExploreIcon,
      component: ReportDownload,
    },
    {
      name: 'Authentication',
      type: 'submenu',
      icon: PersonIcon,
      children: [
        {
          path: '/signin',
          name: 'Signin',
          component: Signin,
        },
        {
          path: '/signup',
          name: 'Signup',
          component: Signup,
        },
        {
          path: '/forgot',
          name: 'Forgot',
          component: PasswordReset,
        },
        {
          path: '/lockscreen',
          name: 'Lockscreen',
          component: Lockscreen,
        },
      ],
    },
    {
      name: 'Error',
      type: 'submenu',
      icon: FaceIcon,
      children: [
        {
          path: '/404',
          name: 'SignIn',
          component: Signin,
        },
        {
          path: '/500',
          name: 'Error',
          component: BackendError,
        },
      ],
    },
  ],
};
