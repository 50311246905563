import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import { Redirect } from 'react-router-dom';
import SessionStyles from '../../styles/Session';
import AuthService from '../../services/authorization/auth-service';
import { CustomizedSnackbars } from '../../shared/snackbar-service';
import DynamicNavService from '../../services/GLG/dynamicNav-service';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon  from "@material-ui/icons/Refresh";
import { generateHash } from '../../shared/encryptDecrypt';

// const Signin = (props) => {
class Signin extends Component {
  constructor(props) {
    super(props);
    this.authService = new AuthService();
    this.navService = '';

    this.onEmailChange = this.onEmailChange.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      email: '',
      password: '',
      pageRedirect: false,
      msgStatus: false,
      msgType: '',
      msgContent: '',
      defaultPage: '/home',
      backImage: ' ',
      custLogo: ' ',
      captchaDetails: '',
      enteredCaptcha: '',
      error: true,
    };
  }

  componentWillMount() {
    this.getCookies();
    this.getCaptchaDetails();
    // if (
    //   sessionStorage.getItem('cust_logo') != undefined &&
    //   sessionStorage.getItem('cust_logo') != ''
    // ) {
    //   this.state.custLogo = sessionStorage.getItem('cust_logo');
    // } else {
    //   this.state.custLogo = '/static/images/advait_logologin.png';
    // }
  }

  getCookies() {
    let decodedCookie = decodeURIComponent(document.cookie);
    // alert(decodedCookie);

    let custLogo = decodedCookie.split(';')[1].split('=')[1];

    if (custLogo != undefined && custLogo != '') {
      this.state.custLogo = custLogo;
    } else {
      this.state.custLogo = '/static/images/advait_logologin.png';
    }
    this.state.custLogo = '/static/images/advait_logologin.png';
  }

  getCaptchaDetails = () => {
    this.authService.getCaptcha().then((res) => {
      this.setState({captchaDetails : res.data});
    });
  }

  getCustomImages = () => {
    this.navService.getImages().then((res) => {
      var resLength = Object.keys(res).length;
      if (resLength == 0) {
        // sessionStorage.removeItem('back_image');
        // sessionStorage.removeItem('cust_logo');
        document.cookie =
          'back_image=; expires=Thu, 18 Dec 2100 12:00:00 UTC; path=/';
        document.cookie =
          'cust_logo=; expires=Thu, 18 Dec 2100 12:00:00 UTC; path=/';
      } else {
        if (res.cust_login_bg_img != null) {
          document.cookie =
            'back_image=' +
            res.cust_login_bg_img.split('/').pop() +
            '; expires=Thu, 18 Dec 2100 12:00:00 UTC; path=/';
        }
        if (res.cust_logo != null) {
          document.cookie =
            'cust_logo=' +
            res.cust_logo.split('/').pop() +
            '; expires=Thu, 18 Dec 2100 12:00:00 UTC; path=/';
        }
        // sessionStorage.setItem('cust_logo', res.cust_logo.split('/').pop());
        // sessionStorage.setItem(
        //   'back_image',
        //   res.cust_login_bg_img.split('/').pop()
        // );
      }
    });
  };

  getTopMenuSettings = () => {
    this.navService.getTopMenuSettings().then((res) => {
      // alert(JSON.stringify(res.defaultPage));
      if (res.defaultPage == undefined) {
        this.state.defaultPage = '/home';
        this.setState({
          msgStatus: true,
          msgType: 'success',
          msgContent: 'Logged in!',
          pageRedirect: true,
        });
      } else {
        this.state.defaultPage = '/' + res.defaultPage;
        this.setState({
          msgStatus: true,
          msgType: 'success',
          msgContent: 'Logged in!',
          pageRedirect: true,
        });
      }
    });
  };

  onSubmit = (event) => {
    event.preventDefault();

    if(this.state.enteredCaptcha.length) {
      let reqParams = {
        email: this.state.email,
        password: generateHash(this.state.password),
        captcha: this.state.enteredCaptcha,
        captchaId: this.state.captchaDetails.captchaId,
      };
      this.authService.captchaVerify(reqParams).then(
        (res) => {
          if (res.status === 'success') {
              this.loginToApp();
          } else {
            this.setState({
              msgStatus: true,
              msgType: 'error',
              msgContent: res.response,
            });
            
            this.getCaptchaDetails();
          }
        })
    }
  }

  loginToApp = () => {
     
    let reqParams = {
      email: this.state.email,
      password: generateHash(this.state.password),
      logged_by_service: 'ADVAIT_HMI',
      otp: ''
    };
    this.authService.login(reqParams).then(
      (res) => {
        if (res.status === 'success') {
          if (res.app_type && res.app_type.includes('ADVAIT_HMI')) {
            sessionStorage.setItem("user", 'login'); 
            this.authService.setuserData(res);

            this.navService = new DynamicNavService();
            this.getTopMenuSettings();
            this.getCustomImages();
          } else {
            this.setState({
              msgStatus: true,
              msgType: 'error',
              msgContent: 'Invalid app type!',
            });
          }
        } else if (res.status == undefined) {
          this.setState({
            msgStatus: true,
            msgType: 'error',
            msgContent: 'Server Connection Failure',
          });
        } else {
          this.setState({
            msgStatus: true,
            msgType: 'error',
            msgContent: res.response,
          });
        }
      },
      (error) => {
        // this.showLoader = false;
        this.setState({
          msgStatus: true,
          msgType: 'error',
          msgContent: 'Server Connection Failure',
        });
        // this.toastr.error('', "Server Connection Failure", {
        //   enableHtml: true
        // })
      }
    );
  }

  onEmailChange = (event) => {
    this.setState({ email: event.target.value });
  };

  onPasswordChange = (event) => {
    this.setState({ password: event.target.value });
  };

  closeSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ msgStatus: false });
  };

  handleCaptchaRefresh = () => {
    this.setState({ enteredCaptcha: '', error: true });
    this.getCaptchaDetails();
  };

  changeEneterCaptcha = (event) => {
    if (event.target.value) {
      this.setState({ enteredCaptcha: event.target.value, error: false });
    } else {
      this.setState({ enteredCaptcha: event.target.value, error: true });
    }
  };

  render() {
    const { classes } = this.props;
    const { pageRedirect, defaultPage, custLogo, captchaDetails, error } = this.state;
    if (pageRedirect) {
      // return(<Redirect to='/home' />)
      return <Redirect to={defaultPage} />;
    } else {
      return (
        <div className={classNames(classes.session, classes.background)}>
          <div className={classes.content}>
            {this.state.msgStatus ? (
              <CustomizedSnackbars
                opened={this.state.msgStatus}
                snackType={this.state.msgType}
                snackMessage={this.state.msgContent}
                closed={this.closeSnackBar}
              ></CustomizedSnackbars>
            ) : null}
            <div className={classes.wrapper} style={{ marginLeft: '15%' }}>
              <Card style={{ backgroundColor: 'rgba(255,255,255,1)' }}>
                {/*style={{ backgroundColor: '#05193C' }} rgba(1,16,67,0.85) style={{ backgroundColor: '#02264A' }} #213146 10,21,100,0.7 */}
                <CardContent>
                  <form onSubmit={this.onSubmit}>
                    <div
                      className="text-xs-center pb-xs"
                      style={{ width: '346px', height: '120px' }}
                    >
                      <img
                        src={custLogo}
                        style={{ width: '90%' }}
                        alt="Advait Web-client"
                      />
                      {/* <Typography style={{ fontSize: 'x-large' }}>
                        Welcome To Advait
                      </Typography> */}
                    </div>

                    <TextField
                      id="username"
                      label="Username"
                      className={classes.textField}
                      fullWidth
                      margin="normal"
                      value={this.state.email}
                      onChange={this.onEmailChange}
                      required={true}
                    />
                    <TextField
                      id="password"
                      label="Password"
                      type="password"
                      fullWidth
                      margin="normal"
                      value={this.state.password}
                      onChange={this.onPasswordChange}
                      required={true}
                      // InputProps={{
                      //   className: classes.input
                      // }}
                    />
                    
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid
                                container
                                alignItems="center"
                                spacing={1}
                                sx={{
                                    backgroundColor: '#f3f3f3',
                                    padding: '4px',
                                    borderRadius: '4px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <TextField
                                    margin="normal"
                                    required
                                    value={captchaDetails.captcha}
                                    sx={{
                                        letterSpacing: '0.05em',
                                        fontWeight: 'bolder',
                                        fontSize: '30px',
                                        color: 'black',
                                        fontFamily: 'Courier New',
                                        userSelect: 'none', // Disable text selection in most modern browsers
                                        WebkitUserSelect: 'none', // Disable text selection in Safari
                                        MozUserSelect: 'none', // Disable text selection in Firefox
                                        msUserSelect: 'none', // Disable text selection in Internet Explorer/Edge
                                        width: 'auto',
                                        '& input': {
                                            height: '80px',
                                            padding: '0',
                                        },
                                    }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />

                                <IconButton
                                    aria-label="refresh captcha"
                                    sx={{ outline: 'none !important' }}
                                    onClick={this.handleCaptchaRefresh}
                                >
                                    <RefreshIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                margin="normal"
                                className={classes.textField}
                                required={true}
                                fullWidth
                                id="captcha"
                                label="Enter Captcha"
                                name="captcha"
                                autoFocus
                                onChange={this.changeEneterCaptcha}
                            />
                        </Grid>
                    </Grid>



                    <Button
                      variant="raised"
                      fullWidth
                      type="submit"
                      color="primary"
                      style={{
                        marginTop: '25px',
                        backgroundColor: 'rgb(0, 153, 204)',
                      }}
                    >
                      Login
                    </Button>

                    <div style={{ marginTop: '5%' }}>
                      <Typography style={{ fontSize: '70%' }}>
                        Powered by{' '}
                        <img
                          src="static/images/brand_logo.png"
                          style={{
                            width: '20%',
                            height: '50%',
                            marginLeft: '0%',
                          }}
                        ></img>
                      </Typography>
                    </div>
                    {/* <div className="pt-1 text-md-center">
                        <Link to="/forgot">
                          <Button>Forgot password?</Button>
                        </Link>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Link to="/signup">
                          <Button>Create new account.</Button>
                        </Link>
                      </div> */}
                  </form>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
      );
    }
  }
}

Signin.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(SessionStyles)(Signin);
