// import { drawerWidth } from './variables';

const TopbarStyles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 2,
    overflowX: 'auto',
  },
  tr: {
    height: '4px',
    lineHeight: '1.0',
  },
  td: {
    color: '#fff',
    fontSize: '0.68em',
  },
  table: {
    width: '100%',
    textAlign: 'center',
  },
  th: {
    backgroundColor: '#505050',
    color: '#FFF',
  },

  // tr:nth-child(even): {
  //   /*background-color: #f2f2f2;*/
  //   /*background-color: #dac8c8;*/
  //   backgroundColor:  rgb(74, 98, 116);
  // },

  // tr:nth-child(odd): {
  //   /*background-color: #f2f2f2;*/
  //   /*background-color: #dac8c8;*/
  //   backgroundColor:  rgb(54, 69, 79);
  // }

  // blink:{
  //   color: '#fff',
  //   animation: 'blinker 1s linear infinite',
  //   '@keyframes blinker': {
  //     '30%': {
  //       opacity: 0
  //     }
  //   }
  // }
});

export default TopbarStyles;
