import {
  axiosInstance1,
  axiosInstance2,
} from '../authorization/auth-intercept-service';
import axios from 'axios';
class AlarmService {
  user = localStorage.getItem('User');
  userEmail = JSON.parse(this.user)['email'];
  // token = JSON.parse(this.user)['token'];
  // getOptions = { method: 'GET', headers: { "Content-Type": "application/png", "Authorization":'Bearer '+this.token }, responseType: 'arraybuffer'};

  url1 = process.env.REACT_APP_API_URL1;

  async last5alarms(reqParams) {
    return axiosInstance2
      .post('alarm/latest', reqParams)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async last5activealarms(reqParams) {
    return axiosInstance2
      .post('activeAlarms/latest', reqParams)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async getActiveAlarm(reqParams) {
    return axiosInstance2
      .post('activeAlarms/get', reqParams)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async ackActiveAlarms(reqParams) {
    var userEmail = localStorage.getItem('User');
    userEmail = JSON.parse(userEmail)['email'];
    return axiosInstance2
      .put('activeAlarms/acknowledge?email=' + userEmail, reqParams)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async getActiveAlarmCount(reqParams) {
    return axiosInstance2
      .post('activeAlarms/getCount', reqParams)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async getActiveAlarmsByPage(reqParams) {
    return axiosInstance2
      .post('activeAlarms/getByPage', reqParams)
      .then((res) => {
        // console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async topLeftLogo() {
    return axiosInstance1
      .get('web_logos/download/LEFT?email=' + this.userEmail, {
        responseType: 'blob',
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async topRightLogo() {
    return axiosInstance1
      .get('web_logos/download/RIGHT?email=' + this.userEmail, {
        responseType: 'blob',
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async getAlarmHistory(reqParams) {
    return axiosInstance2
      .post('alarm/historyAlarms', reqParams)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async getAlarmCount(reqParams) {
    return axiosInstance2
      .post('alarm/getCount', reqParams)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async getAlarmsByPage(reqParams) {
    return axiosInstance2
      .post('alarm/get', reqParams)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async getEquipmentsByUnit(units) {
    return axiosInstance1
      .post('tag/getAllEquipIdsByUnit', units)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async getEquipments() {
    return axiosInstance1
      .get('tag/getAllEquipIds')
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
    // return fetch(this.url1+'tag/getAllEquipIds', this.getOptions)
    //     .then( (response) => {
    //         if(!response.ok){
    //             this.handleResponseError(response);
    //         }
    //         return response.json();
    //     }).catch((error) => {
    //         console.log(error)
    //         return error;
    //     });
  }

  async ackAlarms(reqParams) {
    var userEmail = localStorage.getItem('User');
    userEmail = JSON.parse(userEmail)['email'];
    return axiosInstance2
      .put('alarm/acknowledge?email=' + userEmail, reqParams)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });

    // const userEmail = this.cookie.get('User')['email'];
    // this.putOptions.body = JSON.stringify(reqParams);
    // return fetch(this.url2+'alarm/acknowledge?email='+userEmail, this.putOptions)
    //     .then( (response) => {
    //         if(!response.ok){
    //             this.handleResponseError(response);
    //         }
    //         return response.json();
    //     }).catch((error) => {
    //         console.log(error)
    //         return error;
    //     });
  }

  handleResponseError(response) {
    throw new Error('HTTP error, status = ' + response.status);
  }
  handleError(error) {
    console.log(error.message);
  }
}
export default AlarmService;
