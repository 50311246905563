import {
  axiosInstance1,
  axiosInstance2,
} from '../authorization/auth-intercept-service';

class HomeService {
  user = localStorage.getItem('User');
  userEmail = JSON.parse(this.user)['email'];

  async getHomeDesc() {
    return axiosInstance1
      .get('general_setting')
      .then((res) => {
        console.log(res);
        return res.data.description;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async getLogo() {
    return axiosInstance1
      .get('general_setting/download/ICON?email=' + this.userEmail, {
        responseType: 'blob',
      })
      .then((res) => {
        console.log(res);
        return res;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }
}
export default HomeService;
