import {
  axiosInstance1,
  axiosInstance2,
} from '../authorization/auth-intercept-service';

class TrendService {
  userEmail = localStorage.getItem('User');
  userEmail = JSON.parse(this.userEmail)['email'];
  async getAllTags() {
    return axiosInstance1
      .get('tag/getAllTagNames')
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async getUnitTags(id) {
    return axiosInstance1
      .post('tag/getAllLoggingTagNamesByUnitIds', id)
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async getTrends(reqParams) {
    return axiosInstance2
      .post('log_tags_data/getTagsMultipleCollections', reqParams)
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async getAllTrendView() {
    return axiosInstance2
      .get('trend_view/getAll')
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async addTrendView(reqParams) {
    return axiosInstance2
      .post('trend_view?email=' + this.userEmail, reqParams)
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async searchTag(reqParams) {
    return axiosInstance1
      .post('tag/getBySearch', reqParams)
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  // async searchTagUnit(reqParams) {
  //   return axiosInstance1
  //     .post('tag/getBySearchUnit', reqParams)
  //     .then((res) => {
  //       console.log(res);
  //       return res.data;
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       return err;
  //     });
  // }

  async searchTagUnit(reqParams) {
    console.log("Tag Req "+JSON.stringify(reqParams))
    return axiosInstance1
      .post('tag/getLoggingTagsBySearchAndUnit', reqParams)
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async updateTrendView(reqParams) {
    return axiosInstance2
      .put('trend_view?email=' + this.userEmail, reqParams)
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async getTrendView(id) {
    return axiosInstance2
      .get('trend_view/' + id)
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async deleteTrendView(id) {
    return axiosInstance2
      .delete('trend_view/' + id + '?email=' + this.userEmail)
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async deleteAllTrendViews() {
    return axiosInstance2
      .delete('trend_view/deleteAll?email=' + this.userEmail)
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async getAllAnalogTagNamesByUnit(reqParams) {
    return axiosInstance1
      .post('tag/getAllAnalogTagNamesByUnit', reqParams)
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async getAllDigitalTagNamesByUnit(reqParams) {
    return axiosInstance1
      .post('tag/getAllDigitalTagNamesByUnit', reqParams)
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }
}
export default TrendService;
