import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Wrapper } from '../../components';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Card } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import EventService from '../../services/events/event-service';
import { CustomizedSnackbars } from '../../shared/snackbar-service';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Icon from '@material-ui/core/Icon';
import Toolbar from '@material-ui/core/Toolbar';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import VirtualizedSelect from 'react-virtualized-select';
import 'react-select/dist/react-select.css';
import 'react-virtualized/styles.css';
import 'react-virtualized-select/styles.css';

//*** Toolbar-starts here */
const toolbarStyles = (theme) => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
});

let EnhancedTableToolbar = (props) => {
  const { classes, value, handleSearch } = props;

  return (
    <Toolbar className={classes.root}>
      <div className={classes.title}>
        <Typography variant="title" id="tableTitle">
          Events
        </Typography>
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        <TextField
          placeholder="Search"
          onChange={handleSearch}
          value={value}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon>search</Icon>
              </InputAdornment>
            ),
          }}
          style={{ float: 'right' }}
        />
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);
//*** Toolbar-ends here */

const actionsStyles = (theme) => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 2.5,
  },
});

class TablePaginationActions extends React.Component {
  handleFirstPageButtonClick = (event) => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = (event) => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1)
    );
  };

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
};

const TablePaginationActionsWrapped = withStyles(actionsStyles, {
  withTheme: true,
})(TablePaginationActions);

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 1,
  },
  table: {
    minWidth: 500,
    width: '100%',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  progress: {
    marginLeft: '50%',
    position: 'absolute',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingBottom: theme.spacing.unit * 1,
  },
  textField: {
    marginLeft: theme.spacing.unit * 1,
    marginRight: theme.spacing.unit * 1,
    width: 250,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class Events extends Component {
  constructor(props) {
    super(props);
    this.eventService = new EventService();
    this.intervalId = 0;
    let dt = new Date();
    let tm =
      ('0' + dt.getHours()).slice(-2) + ':' + ('0' + dt.getMinutes()).slice(-2);
    dt = dt.toISOString().split('T')[0];
    this.state = {
      rows: [],
      sRows: [],
      msgStatus: false,
      msgType: '',
      msgContent: '',
      uEmails: [],
      page: 0,
      rowsPerPage: 25,
      isSpinner: false,
      selectedEmail: 'ALL',
      selectedFrom: dt + 'T00:00',
      selectedTo: dt + 'T' + tm,
      order: 'desc',
      orderBy: 'date_time',
      searchValue: '',
      hasError1: false,
      hasError2: false,
      hasError3: false,
      errorStyle: { color: 'red' },
    };
  }

  handleChangePage = (event, page) => {
    this.setState({ page: page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  componentDidMount() {
    this.getEmails();
    this.getLatestEvents();
    // this.intervalId = setInterval(this.getLatestAlarms, 20000); // 5sec = 5000
  }

  componentWillUnmount() {
    console.log('interval end: ' + this.intervalId);
    clearInterval(this.intervalId);
  }

  getEmails() {
    this.eventService.getEmails().then((res) => {
      // alert(JSON.stringify(res));
      let emails = [{ label: 'All', value: 'ALL' }];
      if (res && res.length > 0) {
        res.map((r) => {
          emails.push({ label: r.email, value: r.email });
        });
      }
      // alert(JSON.stringify(emails))
      this.setState({ uEmails: emails });
    });
  }

  getFilteredEvents = () => {
    if (
      !this.state.hasError1 &&
      !this.state.hasError2 &&
      !this.state.hasError3
    ) {
      this.setState({ isSpinner: true });
      let fDT1 = this.state.selectedFrom.split('T');
      let fDT = fDT1[0] + ' ' + fDT1[1] + ':00';
      let tDT1 = this.state.selectedTo.split('T');
      let tDT = tDT1[0] + ' ' + tDT1[1] + ':00';

      //*** Getting difference between two dates */
      const d1 = new Date(fDT1[0]);
      const d2 = new Date(tDT1[0]);
      let diffInTime = d2.getTime() - d1.getTime();
      let diffInDays = diffInTime / (1000 * 3600 * 24);
      if (diffInDays > 30) {
        this.setState({
          msgStatus: true,
          msgType: 'error',
          msgContent:
            'Difference between two dates should not more than 30 days',
          rows: [],
          isSpinner: false,
        });
      } else {
        let reqParams = {
          email: this.state.selectedEmail,
          app_type: JSON.parse(localStorage.getItem('User'))['app_type'],
          from: fDT,
          to: tDT,
        };
        // alert(JSON.stringify(reqParams));
        this.eventService.getFilteredEvents(reqParams).then((res) => {
          console.log('history events: ' + JSON.stringify(res));
          if (res.length > 0) {
            this.setState({ rows: res, sRows: res, isSpinner: false });
          } else {
            this.setState({
              msgStatus: true,
              msgType: 'error',
              msgContent: 'No data found!',
              rows: [],
              isSpinner: false,
            });
          }
        });
      }
    } else {
      this.setState({
        msgStatus: true,
        msgType: 'warning',
        msgContent: 'Form is invalid!',
        rows: [],
        isSpinner: false,
      });
    }
  };

  getLatestEvents = () => {
    this.setState({ isSpinner: true });
    this.eventService.getLatestEvents().then((res) => {
      console.log('latest events: ' + JSON.stringify(res));
      if (res.length > 0) {
        this.setState({ rows: res, sRows: res, isSpinner: false });
      } else {
        // this.setState({ msgStatus: true, msgType:'error', msgContent:'No data found!', rows: [], isSpinner:false });
        this.setState({ rows: [], isSpinner: false });
      }
    });
  };

  changeFromDate = (event) => {
    if (event.target.value > this.state.selectedTo) {
      this.setState({ selectedFrom: event.target.value, hasError1: true });
    } else {
      this.setState({ selectedFrom: event.target.value, hasError1: false });
    }
  };

  changeToDate = (event) => {
    if (event.target.value < this.state.selectedFrom) {
      this.setState({ selectedTo: event.target.value, hasError2: true });
    } else {
      this.setState({ selectedTo: event.target.value, hasError2: false });
    }
  };

  changeEmail = (newEmail) => {
    if (newEmail) {
      this.setState({ selectedEmail: newEmail, hasError3: false });
    } else {
      this.setState({ selectedEmail: newEmail, hasError3: true });
    }
  };

  closeSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ msgStatus: false });
  };

  handleSearch = (event) => {
    const searchInput = event.target.value;
    const { sRows } = this.state;
    let filteredDatas = [];
    filteredDatas = sRows.filter((e) => {
      return (
        e.date_time.toString().includes(searchInput.toLowerCase()) ||
        e.user_email.toLowerCase().includes(searchInput.toLowerCase()) ||
        e.event.toLowerCase().includes(searchInput.toLowerCase()) ||
        e.ip_address.toLowerCase().includes(searchInput.toLowerCase()) ||
        e.port.toString().includes(searchInput.toLowerCase())
      );
    });
    this.setState({ rows: filteredDatas, searchValue: event.target.value });
  };

  render() {
    console.log('.. in render..');
    const { classes } = this.props;
    const {
      uEmails,
      rowsPerPage,
      page,
      rows,
      isSpinner,
      selectedEmail,
      selectedFrom,
      selectedTo,
      searchValue,
      hasError1,
      hasError2,
      hasError3,
      errorStyle,
    } = this.state;
    // const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
    return (
      <Wrapper>
        {/* <Card>
          <CardContent style={{ padding:'10px' }}> */}
        <Paper className={classes.root}>
          {this.state.msgStatus ? (
            <CustomizedSnackbars
              opened={this.state.msgStatus}
              snackType={this.state.msgType}
              snackMessage={this.state.msgContent}
              closed={this.closeSnackBar}
            ></CustomizedSnackbars>
          ) : null}
          <form className={classes.container}>
            <FormControl
              className={classes.textField}
              style={{ marginTop: '15px' }}
            >
              <Typography>&nbsp;&nbsp;From</Typography>
              <TextField
                id="date1"
                label=""
                type="datetime-local"
                defaultValue={selectedFrom}
                className={classes.textField}
                error={hasError1}
                onChange={(event) => this.changeFromDate(event)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {hasError1 && (
                <FormHelperText style={errorStyle}>
                  This can not be greater than to date!
                </FormHelperText>
              )}
            </FormControl>
            <FormControl
              className={classes.textField}
              style={{ marginTop: '15px' }}
            >
              <Typography>&nbsp;&nbsp;To</Typography>
              <TextField
                id="date2"
                label=""
                type="datetime-local"
                defaultValue={selectedTo}
                value={selectedTo}
                error={hasError2}
                className={classes.textField}
                onChange={(event) => this.changeToDate(event)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {hasError2 && (
                <FormHelperText style={errorStyle}>
                  This can not be less than from date!
                </FormHelperText>
              )}
            </FormControl>
            <FormControl
              className={classes.textField}
              style={{ marginTop: '10px' }}
            >
              <Typography>
                Email <sup>*</sup>
              </Typography>
              <VirtualizedSelect
                ref="uEmails"
                options={uEmails}
                simpleValue
                clearable={false}
                name="select-uEmails"
                value={selectedEmail}
                onChange={this.changeEmail}
                searchable
                isRequired={true}
              />
              {hasError3 && (
                <FormHelperText style={errorStyle}>
                  Email is required!
                </FormHelperText>
              )}
              {/* <InputLabel id="email">Email</InputLabel>
                <Select
                  id="email-select"
                  value={selectedEmail}
                  onChange={this.changeEmail}
                >
                  {uEmails.map( (e, i) => {
                      return <MenuItem key={i} value={e}>{e}</MenuItem>
                  })}
                </Select> */}
            </FormControl>
            <FormControl style={{ marginTop: '10px' }}>
              <Typography>&nbsp;</Typography>
              <Button
                color="primary"
                variant="contained"
                size="small"
                title="Get Events"
                onClick={this.getFilteredEvents}
                style={{ backgroundColor: 'rgb(0, 153, 204' }}
              >
                Get <Icon className={classes.rightIcon}>description</Icon>
              </Button>
            </FormControl>
          </form>
          {/* </CardContent>
        </Card> */}
        </Paper>
        <Paper className={classes.root}>
          <EnhancedTableToolbar
            handleSearch={(event) => this.handleSearch(event)}
            value={searchValue}
          />
          <div className={classes.tableWrapper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>S/N</TableCell>
                  <TableCell>Date Time</TableCell>
                  <TableCell>User Email</TableCell>
                  <TableCell>Event</TableCell>
                  <TableCell>IP Address</TableCell>
                  <TableCell>Port Number</TableCell>
                </TableRow>
              </TableHead>
              {isSpinner ? (
                <CircularProgress
                  className={classes.progress}
                  color="primary"
                  size={50}
                  thickness={3}
                />
              ) : (
                <TableBody>
                  {/* slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage). */}
                  {rows.length > 0 ? (
                    rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, i) => {
                        return (
                          <TableRow key={row._id} style={{ fontSize: '16px' }}>
                            <TableCell>{i + 1}</TableCell>
                            <TableCell>{row.date_time}</TableCell>
                            <TableCell>{row.user_email}</TableCell>
                            <TableCell>{row.event}</TableCell>
                            <TableCell>{row.ip_address}</TableCell>
                            <TableCell>{row.port}</TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                    <p>
                      <center>No data found!</center>
                    </p>
                  )}
                  {/* {emptyRows > 0 && (
                  <TableRow style={{ height: 48 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
                </TableBody>
              )}
              <TableFooter>
                <TableRow>
                  <TablePagination
                    colSpan={11}
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActionsWrapped}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </div>
        </Paper>
      </Wrapper>
    );
  }
}

Events.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Events);
