import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Wrapper } from '../../components';
import Paper from '@material-ui/core/Paper';
import ReportService from '../../services/report/report-service';
import { CustomizedSnackbars } from '../../shared/snackbar-service';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import VirtualizedSelect from 'react-virtualized-select';
import { Card } from '@material-ui/core';
import 'react-select/dist/react-select.css';
import 'react-virtualized/styles.css';
import 'react-virtualized-select/styles.css';
import * as FileSaver from 'file-saver';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { OutTable, ExcelRenderer } from 'react-excel-renderer';

import Highcharts from 'highcharts';
require('highcharts/modules/exporting')(Highcharts);

const styles = (theme) => ({
  root: {
    width: '100%',
    height: '100%',
    marginTop: theme.spacing.unit * 1,
  },
  table: {
    minWidth: 500,
    width: '100%',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  progress: {
    marginLeft: '50%',
    position: 'absolute',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingBottom: '5px',
  },
  textField: {
    marginLeft: theme.spacing.unit * 1,
    marginRight: theme.spacing.unit * 1,
    width: 218,
  },
  textField2: {
    marginLeft: theme.spacing.unit * 1,
    marginRight: theme.spacing.unit * 1,
    width: 350,
  },
  textField3: {
    marginLeft: theme.spacing.unit * 1,
    marginRight: theme.spacing.unit * 1,
    width: 150,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  title: {
    flex: '0 0 auto',
    padding: '10px',
  },
});

var divStyle = {
  paddingLeft: '22px',
  paddingTop: '10px',
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class Report extends Component {
  constructor(props) {
    super(props);
    this.reportService = new ReportService();
    let dt = new Date();
    let tm =
      ('0' + dt.getHours()).slice(-2) + ':' + ('0' + dt.getMinutes()).slice(-2);
    dt = dt.toISOString().split('T')[0];
    console.log(dt + '   ' + tm);
    this.units = localStorage.getItem('User');
    this.units = JSON.parse(JSON.parse(this.units)['unit_id']);
    const unitIds = this.units.map((u) => {
      return Object.assign(
        {},
        {
          value: u,
          label: u,
        }
      );
    });
    this.state = {
      tempList: [],
      selectedTemplate: '',
      reportName: '',
      mainTempList: [],
      selectedFrom: dt + 'T00:00',
      selectedTo: dt + 'T' + tm,
      showSheet: false,
      dataLoaded: false,
      rows: null,
      cols: null,
      reportType: [
        { label: 'SCADA-PARA', value: 'SCADA-PARA' }
      ],
      selectedType: 'SCADA-PARA',
      unitList: unitIds,
      selectedUnit: this.units[0],

      intervals : [
        { label: '5 min', value: 300 },
        { label: '10 min', value: 600 },
        { label: '15 min', value: 900 },
        { label: '30 min', value: 1800 },
        { label: '1 hour', value: 3600 },
        { label: '2 hours', value: 7200 },
        { label: '4 hours', value: 14400 },
        { label: '8 hours', value: 28800 },
        { label: '12 hours', value: 43200 },
        { label: '16 hours', value: 57600 },
        { label: '24 hours', value: 86400 },
      ],
      selectedInterval: 0,
      assetList: [],
      selectedAsset: '',
      buttonDisable: false
    };
    // this.renderFile = this.renderFile.bind(this);
  }

  componentDidMount() {
    // this.getTemplate();
    // this.getUnitList();
  }

  componentWillUnmount() {}

  closeSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ msgStatus: false });
  };

  getUnitList() {
    this.state.unitList = [];
    if (JSON.parse(localStorage.getItem('User')).user_role == 'TAS_ADMIN') {
      this.reportService.getUnit().then(
        (result) => {
          // this.state.unitList = JSON.parse(result.unit_id);
          JSON.parse(result.unit_id).map((r) => {
            this.state.unitList.push({
              label: r,
              value: r,
            });
          });
          this.state.selectedUnit = this.state.unitList[0].label;
          // this.getTemplateByUnit();
        },
        (error) => {}
      );
    } else {
      var units = JSON.parse(
        JSON.parse(localStorage.getItem('User'))['unit_id']
      );
      units.map((u) => {
        this.state.unitList.push({ label: u, value: u });
      });
      this.state.selectedUnit = units[0];
      // this.getAssetByUnit();
      // alert(JSON.stringify(this.state.unitList));
    }
  }


  getTemplateByUnit = () => {
    // let getReq = { "org_id": this.orgID }
    // alert('Change Type');
    this.state.tempList = [];
    let req = {
      unit_id: this.state.selectedUnit,
      type: this.state.selectedType,
    };

    // alert(this.state.selectedUnit + ' -- ' + this.state.selectedType);
    console.log('Req ' + JSON.stringify(req));
    this.reportService.getTemplateByUnit(req).then((res) => {
      // alert('Template Data: ' + res.length);
      if (res.length != 0) {
        // this.state.tempList = res;
        if (res && res.length > 0) {
          this.state.mainTempList = res;
          var tempPatch =
            res[0].template_type + '*' + res[0]._id + '*' + res[0].output_type;

          this.state.selectedTemplate = tempPatch;

          this.onChangeTemplate(tempPatch);
          res.map((r) => {
            this.state.tempList.push({
              label: r.template_name,
              value: r.template_type + '*' + r._id + '*' + r.output_type,
            });
          });
        }
      }
    });
  };

  getAssetByUnit = () => {
    // let getReq = { "org_id": this.orgID }
    // alert('Change Type');
    this.state.assetList = [];
    let req = {
      unit_id: this.state.selectedUnit, type: "COMP"
    };

    // alert(this.state.selectedUnit + ' -- ' + this.state.selectedType);
    console.log('Req ' + JSON.stringify(req));
    this.reportService.getAssetByUnit(req).then((res) => {
      // alert('Template Data: ' + res.length);
      if (res.length != 0) {
        // this.state.tempList = res;
        if (res && res.length > 0) {
          // this.state.assetList = res;


          res.map((r) => {
            this.state.assetList.push({
              label: r,
              value: r,
            });
          });
          
          this.state.selectedAsset = res[0];
          this.setState({selectedAsset: res[0]})
        }
      }
    });
  };

  onChangeType = (event) => {
    // alert(JSON.stringify(event));
    this.state.selectedType = event;
    this.setState({ selectedType: event });
  };

  onChangeUnit = (event) => {
    this.state.selectedUnit = event;
    this.setState({ selectedUnit: event });
    // this.getAssetByUnit();
  };

  onChangeAsset = (event) => {
    this.state.selectedAsset = event;
    this.setState({ selectedAsset: event });
  };
  
  changeFromDate = (event) => {
    this.setState({ selectedFrom: event.target.value });
  };

  changeToDate = (event) => {
    this.setState({ selectedTo: event.target.value });
  };

  onSubmit = () => {
    // console.log(JSON.stringify(eventData));
    // debugger
    var fromdateM = this.state.selectedFrom + ':' + '00';
    // alert(fromdateM);
    // this.submitted = true;
    if (this.state.selectedUnit == '') {
      this.setState({
        msgStatus: true,
        msgType: 'warning',
        msgContent: 'Unit ID can not empty !!!',
        isSpinner: false,
      });
    } else {
     
      let fromDate1 =
        this.state.selectedFrom.split('T')[0] +
        ' ' +
        this.state.selectedFrom.split('T')[1] +
        ':00';
      let toDate1 =
        this.state.selectedTo.split('T')[0] +
        ' ' +
        this.state.selectedTo.split('T')[1] +
        ':59';
      var fromdateM = fromDate1;
      var todateM = toDate1;

      // var fromdateM = this.state.selectedFrom + ':' + '00';
      // var todateM = this.state.selectedTo + ':' + '00';

      var date = new Date(fromdateM);
      var milliseconds = date.getTime();
      var tempDate = new Date(milliseconds);

      var date1 = new Date(todateM);
      var milliseconds1 = date1.getTime();
      var tempDate = new Date(milliseconds1);

      // //console.log(new Date(fromdateM).toISOString() + "           "+ new Date(todateM).toISOString())
      // var saveData = {
      //   "org_id": this.orgID, "_redirector_action_id": eventData.rAction, "from": new Date(fromdateM).toISOString(), "to": new Date(todateM).toISOString(), "_template_id": this.tempName,
      //   "report_name": eventData.ReportName
      // };

      var saveData = {
            from: fromdateM,
            to: todateM,
            data_interval: this.state.selectedInterval,
            type: this.state.selectedType,
            unit_id: this.state.selectedUnit,
            // asset_id: this.state.selectedAsset
          }
      

      console.log('Data pass to API:' + JSON.stringify(saveData));

      var frNewDate = new Date(fromdateM).getTime();
      var toNewDate = new Date(todateM).getTime();
      //console.log("success"+frNewDate+"     "+toNewDate);
      console.log(
        'months ' + this.monthDiff(new Date(fromdateM), new Date(todateM))
      );
      let daysDiff = Math.floor((toNewDate - frNewDate) / 1000 / 60 / 60 / 24);

      // let daysDiff = this.monthDiff(new Date(fromdateM), new Date(todateM));


      // let daysDiff = Math.abs(toNewDate - frNewDate) / 36e5;

      if (frNewDate > toNewDate) {
        this.setState({
          msgStatus: true,
          msgType: 'warning',
          msgContent: 'From date should be less then to date !!!',
          isSpinner: false,
        });
      } else if (daysDiff > 32) {
        this.setState({
          msgStatus: true,
          msgType: 'warning',
          msgContent:
            'From date and to date difference should be less then 1 month !!!',
          isSpinner: false,
        });
      } else {
        this.state.isSpinner = true;
        this.state.buttonDisable = true;
        this.setState({buttonDisable: true})
          this.reportService.getCPReport(saveData).then((res) => {
            console.log('Response from server: ' + JSON.stringify(res));

            var data;
              data = new Blob([res], {
                type:
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              });
            var objectUrl = URL.createObjectURL(data);
            this.state.isSpinner = false;
            FileSaver.saveAs(data, this.state.selectedUnit+"_"+this.state.selectedType);
            this.state.buttonDisable = false;
            this.setState({buttonDisable: false})
            // var blob = new Blob([response.data], {type: "application/pdf;charset=utf-8"});

            // this.resetOneTimeReport();
          });
      }
    }
  };
   monthDiff(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  }

  changeInterval = (newInterval) => {
    if (newInterval === null) {
      this.setState({
        selectedInterval: newInterval,
        hasError4: true,
        errMsg: 'Interval is required!',
      });
    } else {
      this.setState({ selectedInterval: newInterval, hasError4: false });
    }
  };

  render() {
    console.log('.. in render..');
    const { classes, defaultOptions, placeholder, inputId } = this.props;
    const {
      isSpinner,
      errorStyle,
      errorStyleU,
      hasError,
      selectedFrom,
      selectedTo,
      dataLoaded,
      rows,
      cols,
      unitList,
      selectedUnit,
      intervals,
      selectedInterval, assetList, selectedAsset, reportType, selectedType,
      buttonDisable
    } = this.state;

    // alert(dataLoaded + ' -- ' + JSON.stringify(unitList));
    return (
      <Wrapper>
        {/* <Card>
          <CardContent style={{ padding:'10px' }}> */}


<Paper className={classes.root}>
          {this.state.msgStatus ? (
            <CustomizedSnackbars
              opened={this.state.msgStatus}
              snackType={this.state.msgType}
              snackMessage={this.state.msgContent}
              closed={this.closeSnackBar}
            ></CustomizedSnackbars>
          ) : null}
          <div className={classes.title}>
            <Typography variant="title" id="tableTitle">
              Report
            </Typography>
          </div>
          <form className={classes.container}>
            <div className="row" style={divStyle}>
              <FormControl className={classes.textField}>
                <Typography>&nbsp;&nbsp;From</Typography>
                <TextField
                  // disabled={!this.state.dateDisable}
                  id="date1"
                  label=""
                  type="datetime-local"
                  // error={hasError2}
                  defaultValue={selectedFrom}
                  className={classes.textField}
                  isRequired={true}
                  onChange={(event) => this.changeFromDate(event)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
              <FormControl className={classes.textField}>
                <Typography>&nbsp;&nbsp;To</Typography>
                <TextField
                  // disabled={!this.state.dateDisable}
                  id="date2"
                  label=""
                  type="datetime-local"
                  defaultValue={selectedTo}
                  value={selectedTo}
                  className={classes.textField}
                  isRequired={true}
                  onChange={(event) => this.changeToDate(event)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
              
              
              {/* <FormControl className={classes.textField}>
                <Typography>
                  Interval <sup>*</sup>
                </Typography>
                <VirtualizedSelect
                  ref="interval"
                  options={intervals}
                  simpleValue
                  clearable={false}
                  name="select-interval"
                  value={selectedInterval}
                  onChange={this.changeInterval}
                  searchable
                />
                </FormControl> */}

                <FormControl className={classes.textField}>
                <Typography style={errorStyleU}>
                  Unit ID <sup>*</sup>
                </Typography>

                <VirtualizedSelect
                  ref="unitList"
                  options={unitList}
                  simpleValue
                  clearable={false}
                  name="select-unitList"
                  value={selectedUnit}
                  onChange={(event) => this.onChangeUnit(event)}
                  searchable
                />
                {hasError && (
                  <FormHelperText style={errorStyleU}>
                    Unit is required!
                  </FormHelperText>
                )}
              </FormControl>

              {/* <FormControl className={classes.textField2}>
                <Typography style={errorStyleU}>
                  Asset ID <sup>*</sup>
                </Typography>

                <VirtualizedSelect
                  ref="assetList"
                  options={assetList}
                  simpleValue
                  clearable={false}
                  name="select-assetList"
                  value={selectedAsset}
                  onChange={(event) => this.onChangeAsset(event)}
                  searchable
                />
                {hasError && (
                  <FormHelperText style={errorStyleU}>
                    Asset is required!
                  </FormHelperText>
                )}
              </FormControl> */}

              <FormControl className={classes.textField}>
                <Typography style={errorStyleU}>
                  Report Type <sup>*</sup>
                </Typography>

                <VirtualizedSelect
                  ref="reportType"
                  options={reportType}
                  simpleValue
                  clearable={false}
                  name="select-reportType"
                  value={selectedType}
                  onChange={(event) => this.onChangeType(event)}
                  searchable
                />
              </FormControl>
              <FormControl>
                <Typography>&nbsp;</Typography>
                <Button
                disabled = {buttonDisable}
                  color="primary"
                  variant="contained"
                  size="small"
                  title="Generate Report"
                  onClick={this.onSubmit}
                  style={{ backgroundColor: 'rgb(0, 153, 204' }}
                >
                  Generate Report
                </Button>
              </FormControl>
            </div>
          </form>
        </Paper>

        
        <Paper className={classes.root}>
          {isSpinner ? (
            <CircularProgress
              className={classes.progress}
              color="primary"
              size={50}
              thickness={3}
            />
          ) : null}
        </Paper>

        {/* <Paper className={classes.root}>
          {this.state.msgStatus ? (
            <CustomizedSnackbars
              opened={this.state.msgStatus}
              snackType={this.state.msgType}
              snackMessage={this.state.msgContent}
              closed={this.closeSnackBar}
            ></CustomizedSnackbars>
          ) : null}
          <div className={classes.title}>
            <Typography variant="title" id="tableTitle">
              One Time Report
            </Typography>
          </div>
          <form className={classes.container}>
            <div className="row" style={divStyle}>
              <FormControl className={classes.textField}>
                <Typography></Typography>
                <TextField
                  id="reportName"
                  label="Report Name"
                  type="text"
                  required={true}
                  value={reportName}
                  style={errorStyle}
                  onChange={(event) => this.onChangeReportName(event)}
                />
              </FormControl>

              <FormControl className={classes.textField2}>
                <Typography>
                  Template Type <sup>*</sup>
                </Typography>

                <VirtualizedSelect
                  ref="tempType"
                  options={tempType}
                  simpleValue
                  clearable={false}
                  name="select-tempType"
                  value={selectedType}
                  onChange={(event) => this.onChangeType(event)}
                  searchable
                />
              </FormControl>

              <FormControl className={classes.textField2}>
                <Typography style={errorStyleU}>
                  Unit ID <sup>*</sup>
                </Typography>

                <VirtualizedSelect
                  ref="unitList"
                  options={unitList}
                  simpleValue
                  clearable={false}
                  name="select-unitList"
                  value={selectedUnit}
                  onChange={(event) => this.onChangeUnit(event)}
                  searchable
                />
                {hasError && (
                  <FormHelperText style={errorStyleU}>
                    Template is required!
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl className={classes.textField2}>
                <Typography style={errorStyleU}>
                  Template <sup>*</sup>
                </Typography>

                <VirtualizedSelect
                  ref="tempList"
                  options={tempList}
                  simpleValue
                  clearable={false}
                  name="select-tempList"
                  value={selectedTemplate}
                  onChange={this.onChangeTemplate}
                  searchable
                />
                {hasError && (
                  <FormHelperText style={errorStyleU}>
                    Template is required!
                  </FormHelperText>
                )}
              </FormControl>

              {showSheet ? (
                <FormControl className={classes.textField2}>
                  <Typography style={errorStyleU}>
                    Sheet <sup>*</sup>
                  </Typography>
                  <VirtualizedSelect
                    ref="sheetList"
                    options={sheetList}
                    clearable={true}
                    name="select-sheetList"
                    value={selectedSheet}
                    onChange={this.onChangeSheet}
                    searchable
                    required={true}
                  />
                  {hasError && (
                    <FormHelperText style={errorStyleU}>
                      Sheet is required!
                    </FormHelperText>
                  )}
                </FormControl>
              ) : null}
            </div>
            <br />
            <div className="row" style={divStyle}>
              <FormControl className={classes.textField}>
                <Typography>&nbsp;&nbsp;From</Typography>
                <TextField
                  disabled={!this.state.dateDisable}
                  id="date1"
                  label=""
                  type="datetime-local"
                  // error={hasError2}
                  defaultValue={selectedFrom}
                  className={classes.textField}
                  isRequired={true}
                  onChange={(event) => this.changeFromDate(event)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
              <FormControl className={classes.textField}>
                <Typography>&nbsp;&nbsp;To</Typography>
                <TextField
                  disabled={!this.state.dateDisable}
                  id="date2"
                  label=""
                  type="datetime-local"
                  defaultValue={selectedTo}
                  value={selectedTo}
                  className={classes.textField}
                  isRequired={true}
                  onChange={(event) => this.changeToDate(event)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>

              <FormControl>
                <Typography>&nbsp;</Typography>
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  title="Generate Report"
                  onClick={this.onSubmit}
                  style={{ backgroundColor: 'rgb(0, 153, 204' }}
                >
                  Generate Report
                </Button>
              </FormControl>
            </div>
          </form>
        </Paper> */}

        {dataLoaded ? (
          <div>
            <Card body outline color="secondary" className="restrict-card">
              <OutTable
                data={rows}
                columns={cols}
                tableClassName="ExcelTable2007"
                tableHeaderRowClass="heading"
              />
            </Card>
          </div>
        ) : null}
      </Wrapper>
    );
  }
}

Report.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Report);

/******** Pagination for Table */
