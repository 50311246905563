import { axiosInstance1 } from '../authorization/auth-intercept-service';
class ManualEntryService {
  user = localStorage.getItem('User');
  userEmail = JSON.parse(this.user)['email'];

  async getAllForms() {
    return axiosInstance1
      .get('manual_form_entry/getAll')
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async getForm(id) {
    return axiosInstance1
      .get('manual_form_entry/' + id)
      .then((res) => {
        console.log(res);
        console.log(res.data.form_data);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async getData(id) {
    return axiosInstance1
      .get('manual_data_entry/' + id)
      .then((res) => {
        console.log(res);
        console.log(res.data.form_data);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async getAllData(reqParams) {
    return axiosInstance1
      .post('manual_data_entry/getAll', reqParams)
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async saveData(reqParams) {
    return axiosInstance1
      .post('manual_data_entry?email=' + this.userEmail, reqParams)
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async updateData(reqParams) {
    return axiosInstance1
      .put('manual_data_entry?email=' + this.userEmail, reqParams)
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async deleteData(id) {
    return axiosInstance1
      .delete('manual_data_entry/' + id + '?email=' + this.userEmail)
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }
}
export default ManualEntryService;
