import { axiosInstance3 } from '../authorization/auth-intercept-service';
class AuthService {
  postOptions = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
    },
  };
  // url = process.env.REACT_APP_API_URL1;
  // url = window.location.protocol+'//'+window.location.hostname+':8100/';

  async login(reqParams) {
    return axiosInstance3
      .post('user/loginByOTP', reqParams)
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
    // console.log(url);
    // this.postOptions.body = JSON.stringify(reqParams);
    // return fetch(this.url+'user/login', this.postOptions)
    //     .then( (response) => {
    //         if(!response.ok){
    //             this.handleResponseError(response);
    //         }
    //         return response.json();
    //     }).catch((error) => {
    //         console.log(error)
    //         return error;
    //     });
  }

  async setuserData(data) {
    localStorage.clear();
    // localStorage.clear();
    localStorage.setItem('User', JSON.stringify(data));
  }

  async getUserData() {
    var User = localStorage.getItem('User');
    if (User) {
      return User;
    }
    return;
  }

  async getCaptcha(){
    return axiosInstance3.get('/captcha/get').then(res => {
        return res;
    }).catch(err => {
        console.log(err);
        return err;
    });
  }

  async captchaVerify(reqParams){
    return axiosInstance3.post('/captcha/verify', reqParams).then(res => {
        return res.data;
    }).catch(err => {
        console.log(err);
        return err;
    });
  }

  handleResponseError(response) {
    throw new Error('HTTP error, status = ' + response.status);
  }
  handleError(error) {
    console.log(error.message);
  }
}
export default AuthService;
