import {
  axiosInstance1,
  axiosInstance2,
  axiosInstance7,
} from '../authorization/auth-intercept-service';
class MapService {
  userEmail = localStorage.getItem('User');
  userEmail = JSON.parse(this.userEmail)['email'];
  async allMapPoints() {
    return axiosInstance1
      .get('mapPoints/getAll')
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async getMapSettings() {
    return axiosInstance1
      .get('geo_map_settings')
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async getMapURL() {
    return axiosInstance1
      .get('general_setting')
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async getMapAsset(req) {
    return axiosInstance1
      .post('assets/getAssetNavigation', req)
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async getEngineerAsset(req) {
    return axiosInstance1
      .post('assets/getByTemplateId', req)
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async getAssetFields(req) {
    return axiosInstance1
      .post('assets/getFieldData', req)
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async addMapSettings(reqParams) {
    return axiosInstance1
      .post('geo_map_settings?email=' + this.userEmail, reqParams)
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async updateMapSettings(reqParams) {
    return axiosInstance1
      .put('geo_map_settings?email=' + this.userEmail, reqParams)
      .then((res) => {
        // console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async liveData(tagList) {
    return axiosInstance2
      .post('log_tags_data/getLive', tagList)
      .then((res) => {
        // console.log('Yo ' + JSON.stringify(res));
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async tcpRequest(reqParams) {
    return axiosInstance1
      .post('tcp_request', reqParams)
      .then((res) => {
        console.log("TCP Req"+JSON.stringify(res));
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async getAssetsLiveData(reqParams) {
    return axiosInstance7
      .post('/assets/get_assets_live_value', reqParams)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async downloadMarkerIcon(id) {
    return axiosInstance1
      .get('mapPoints/download/' + id + '?email=' + this.userEmail, {
        responseType: 'blob',
      })
      .then((res) => {
        // console.log(res);
        return res;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }
}
export default MapService;
