import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Wrapper } from '../../components';
import Paper from '@material-ui/core/Paper';
import { Card } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import GroupService from '../../services/groupDisplay/group-service';
import { CustomizedSnackbars } from '../../shared/snackbar-service';
import CircularProgress from '@material-ui/core/CircularProgress';
import { NavLink } from 'react-router-dom';
import VirtualizedSelect from 'react-virtualized-select';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import 'react-virtualized/styles.css';
import 'react-virtualized-select/styles.css';
import './GroupDisplay.css';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
// import InputLabel from '@material-ui/core/InputLabel';
// import MenuItem from '@material-ui/core/MenuItem';
// import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import CancelIcon from '@material-ui/icons/Cancel';
import NearMeIcon from '@material-ui/icons/NearMe';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import TrendService from '../../services/trends/trends-service';
import Highcharts from 'highcharts';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 1,
  },
  table: {
    minWidth: 500,
    width: '100%',
  },
  progress: {
    marginLeft: '50%',
    position: 'absolute',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '10px',
  },
  textField: {
    marginLeft: theme.spacing.unit * 1,
    marginRight: theme.spacing.unit * 1,
    width: 180,
  },
  textField2: {
    marginLeft: theme.spacing.unit * 1,
    marginRight: theme.spacing.unit * 1,
    width: 300,
  },
  textField3: {
    marginLeft: theme.spacing.unit * 1,
    marginRight: theme.spacing.unit * 1,
    width: 200,
  },
  textField4: {
    // marginLeft: theme.spacing.unit * 1,
    // marginRight: theme.spacing.unit * 1,
    marginBottom: theme.spacing.unit * 1,
    width: 95,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
});

class GroupDisplay extends Component {
  constructor(props) {
    super(props);
    this.groupService = new GroupService();
    this.trendService = new TrendService();
    this.intervalId1 = 0;
    this.intervalId2 = 0;
    let dt = new Date();
    let hourago = new Date(dt.getTime() - 1000 * 60 * 60);
    let tm =
      ('0' + dt.getHours()).slice(-2) + ':' + ('0' + dt.getMinutes()).slice(-2);
    dt = dt.toISOString().split('T')[0];
    let tm1 =
      ('0' + hourago.getHours()).slice(-2) +
      ':' +
      ('0' + hourago.getMinutes()).slice(-2);
    hourago = hourago.toISOString().split('T')[0];
    // alert(dt+' '+tm+'  '+hourago+' '+tm1);
    this.allowChartUpdate = true;
    this.user = localStorage.getItem('User');
    this.userEmail = JSON.parse(this.user)['email'];
    this.state = {
      analogTagList: [],
      msgStatus: false,
      msgType: '',
      msgContent: '',
      isSpinner: false,
      isC1Spinner: false,
      isC2Spinner: false,
      selectedPeriod: 3600,
      selectedInterval: 300,
      errMsg: '',
      series1: [],
      series2: [],
      yAxis1: [],
      yAxis2: [],
      groups: [],
      selectedGroup: 0,
      groupDescription: '',
      digitalTagList: [],
      tagList: [],
      tagLiveValue: 650,
      tagDetails: [],
      chart1Tags: [],
      chart2Tags: [],
      isChart2: false,
      openModal: false,
      newTvalue: 0,
      newTname: '',
      newTprop: '',
      newTidx: 0,
      selectedFrom: hourago + ' ' + tm1 + ':00',
      selectedTo: dt + ' ' + tm + ':00',
      LL0: 0,
      LL1: 0,
      LL2: 0,
      LL3: 0,
      LL4: 0,
      LL5: 0,
      L0: 0,
      L1: 0,
      L2: 0,
      L3: 0,
      L5: 0,
      H1: 0,
      H2: 0,
      H3: 0,
      H4: 0,
      H5: 0,
      HH0: 0,
      HH1: 0,
      HH2: 0,
      HH3: 0,
      HH4: 0,
      HH5: 0,
      selectValue: '',
    };
  }

  componentDidMount() {
    if (Highcharts.chart) {
      Highcharts.chart = null;
    }
    this.getGroupNumbers();
  }

  componentWillUnmount() {
    clearInterval(this.intervalId1);
    clearInterval(this.intervalId2);
    if (Highcharts.chart) {
      Highcharts.chart = null;
    }
  }

  highChartsRender1 = () => {
    var chart = new Highcharts.Chart({
      chart: {
        zoomType: 'xy',
        renderTo: 'advaitTrends1',
      },
      title: {
        align: 'center',
        text: 'Chart1',
      },
      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
          hour: '%H:%M',
          month: '%e. %b',
          year: '%b',
        },
        crosshair: true,
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [
              'printChart',
              'separator',
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadSVG',
            ],
          },
        },
      },
      yAxis: this.state.yAxis1,
      tooltip: {
        shared: true,
      },
      legend: {
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'bottom',
        floating: false,
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || // theme
          'rgba(255,255,255,0.25)',
      },
      series: this.state.series1,
    });
  };

  highChartsRender2 = () => {
    var chart = new Highcharts.Chart({
      chart: {
        zoomType: 'xy',
        renderTo: 'advaitTrends2',
      },
      title: {
        align: 'center',
        text: 'Chart2',
      },
      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
          hour: '%H:%M',
          month: '%e. %b',
          year: '%b',
        },
        crosshair: true,
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [
              'printChart',
              'separator',
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadSVG',
            ],
          },
        },
      },
      yAxis: this.state.yAxis2,
      tooltip: {
        shared: true,
      },
      legend: {
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'bottom',
        floating: false,
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || // theme
          'rgba(255,255,255,0.25)',
      },
      series: this.state.series2,
    });
  };

  highChartRender_tag = (
    tagName,
    tagValue,
    pBands,
    tPostions,
    minVal,
    maxVal,
    engUnit
  ) => {
    // alert('in chart: ' + tagValue + ' -- ' + minVal + ' -- ' + maxVal)
    // if(tagValue < minVal) tagValue = parseFloat(minVal).toFixed(2);
    // alert(tagValue);
    // else if (tagValue > maxVal) tagValue = parseFloat(maxVal).toFixed(2);
    var chart = new Highcharts.Chart({
      chart: {
        // plotBackgroundColor: null,
        // plotBackgroundImage: null,
        // plotBorderWidth: 0,
        // plotShadow: false,
        type: 'gauge',
        renderTo: tagName,
      },
      title: null,
      tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '{series.name}: {point.y} ',
      },
      pane: {
        startAngle: -90,
        endAngle: 90,
        background: {
          backgroundColor: '#ccc',
          borderWidth: 0,
          shape: 'arc',
          innerRadius: '60%',
          outerRadius: '100%',
        },
      },
      yAxis: {
        plotBands: pBands,
        min: minVal,
        max: maxVal,
        minorTickLength: 0,
        lineWidth: 0,
        tickWidth: 0,
        tickLength: 0,
        tickPositions: tPostions,
        labels: {
          distance: 15,
        },

        // // min: 0,
        // // max: 10,
        // lineWidth: 0,
        // tickWidth: 0,
        // tickAmount: 0,
        // labels: {
        //     distance: 15,
        //     align: 'auto',
        //     // style: {
        //     //     fontSize: "20px"
        //     // }
        // }
      },
      exporting: {
        enabled: false,
      },
      series: [
        {
          // type: 'gauge',
          name: tagName,
          data: [tagValue],
          pivot: {
            backgroundColor: '#fff',
            borderColor: '#666',
            borderWidth: 5,
            radius: 6,
          },
          dataLabels: {
            format:
              '<div style="text-align:center">' +
              '<span>{y}</span><br/>' +
              '<span style="opacity:0.4">' +
              engUnit +
              '</span>' +
              '</div>',
          },
          tooltip: {
            valueSuffix: engUnit,
          },
          dial: {
            radius: '105%',
            backgroundColor: '#666',
            borderWidth: 0,
            baseWidth: 5,
            topWidth: 5,
            baseLength: '100%', // of radius
            rearLength: '0%',
          },
        },
      ],
    });
  };

  getGroupNumbers = () => {
    this.groupService.getAllGroupNumbers().then((res) => {
      console.log(res);
      if (res && res.length > 0) {
        let resArr = [];
        res.map((r) => {
          let temp = {};
          temp['label'] = r;
          temp['value'] = r;
          resArr.push(temp);
        });
        // alert(JSON.stringify(resArr));
        this.setState({ groups: resArr });
      } else {
        this.setState({ groups: [] });
      }
    });
  };

  getTrend = (tagsRaw, chartNum) => {
    let tags = [],
      analogTags = [],
      digitalTags = [];
    tagsRaw.map((t1, tIndx) => {
      tags.push(t1.tagName);
      if (t1.tagType == 'Analog') analogTags.push(t1.tagName);
      if (t1.tagType == 'Digital') digitalTags.push(t1.tagName);
    });

    // alert("Analog " + JSON.stringify(analogTags) + " Digital " + JSON.stringify(digitalTags));

    if (this.state.selectedGroup) {
      if (chartNum === 'chart1') {
        this.setState({ isC1Spinner: true, yAxis1: [], series1: [] });
      }
      if (chartNum === 'chart2') {
        this.setState({ isC2Spinner: true, yAxis2: [], series2: [] });
      }

      // if (analogTags.length > 0) {
      let reqParams = {
        tags: analogTags,
        interval: this.state.selectedInterval,
        from: this.state.selectedFrom,
        to: this.state.selectedTo,
        sorting: 1,
      };
      // alert(JSON.stringify(reqParams)+'  '+chartNum+'  '+this.state.isC1Spinner+'  '+this.state.isC2Spinner);
      this.trendService.getTrends(reqParams).then((res) => {
        console.log(JSON.stringify(res));
        var finalRes = [];
        // if (res && res.length > 0) {
        finalRes = res;

        let reqParams = {
          tags: digitalTags,
          interval: 0,
          from: this.state.selectedFrom,
          to: this.state.selectedTo,
          sorting: 1,
        };
        // alert(JSON.stringify(reqParams)+'  '+chartNum+'  '+this.state.isC1Spinner+'  '+this.state.isC2Spinner);
        this.trendService.getTrends(reqParams).then((res1) => {
          console.log('Digital ' + JSON.stringify(res1));
          finalRes = finalRes.concat(res1);

          console.log('final res ' + JSON.stringify(finalRes));
          if (chartNum === 'chart1') {
            this.setState({ isC1Spinner: false });
          }
          if (chartNum === 'chart2') {
            this.setState({ isC2Spinner: false });
          }
          // alert(JSON.stringify(res)+'  '+this.state.isC1Spinner+' '+this.state.isC2Spinner);
          if (finalRes && finalRes.length > 0) {
            let dynSeries = [];
            let dynAxes = [];
            let isNullArr = [];
            tags.map((t, tIndx) => {
              //*** creating yAxis */
              let tAxis = {};
              tAxis['labels'] = {
                format: '{value}',
                style: { color: Highcharts.getOptions().colors[tIndx] },
              };
              tAxis['title'] = {
                text: t,
                style: { color: Highcharts.getOptions().colors[tIndx] },
              };
              tAxis['showEmpty'] = false;
              if (tIndx % 2 === 0) {
                tAxis['opposite'] = false;
              } else {
                tAxis['opposite'] = true;
              }
              dynAxes.push(tAxis);
              //*** creating series */
              let tagSeries = {};
              tagSeries['name'] = t;
              tagSeries['type'] = 'spline';
              tagSeries['yAxis'] = tIndx;
              tagSeries['data'] = [];
              tagSeries['color'] = Highcharts.getOptions().colors[tIndx];
              tagSeries['tooltip'] = { valueSuffix: '' }; //eng unit canbe append here
              finalRes.map((r) => {
                // let cTime = new Date(r.date_time).getTime();
                let cVal = r[t];
                if (cVal !== null && cVal !== undefined) {
                  const TD = r.date_time.split(' ');
                  const dt = TD[0].split('-');
                  const tm = TD[1].split(':');
                  const year = dt[0];
                  const month = parseInt(dt[1]) - 1;
                  const day = dt[2];
                  const hour = tm[0];
                  const minute = tm[1];
                  const seconds = tm[2];
                  tagSeries['data'].push([
                    Date.UTC(year, month, day, hour, minute, seconds),
                    cVal,
                  ]);
                }
                // if(cVal === null){
                //   isNullArr.push(0);
                // }else{
                //   isNullArr.push(1);
                // }
              });
              dynSeries.push(tagSeries);
            });
            console.log(JSON.stringify(dynSeries));
            if (chartNum === 'chart1') {
              this.setState({ series1: dynSeries, yAxis1: dynAxes });
              // if(isNullArr.includes(1)){
              this.highChartsRender1();
              // }else{
              //   console.log('no data..')
              //   document.getElementById('advaitTrends1').innerHTML = '<br/><p>&nbsp;&nbsp;&nbsp; No data found!</p><br/>';
              // }
            } else {
              this.setState({ series2: dynSeries, yAxis2: dynAxes });
              console.log(dynSeries);
              // if(isNullArr.includes(1)){
              this.highChartsRender2();
              // }else{
              //   console.log('no data..')
              //   document.getElementById('advaitTrends2').innerHTML = '<br/><p>&nbsp;&nbsp;&nbsp; No data found!</p><br/>';
              // }
            }
          } else {
            if (chartNum === 'chart1') {
              // document.getElementById('advaitTrends1').innerHTML = '<br/><p>&nbsp;&nbsp;&nbsp; No data found!</p><br/>';
              this.highChartsRender1();
            }
            if (chartNum === 'chart2') {
              // document.getElementById('advaitTrends2').innerHTML = '<br/><p>&nbsp;&nbsp;&nbsp; No data found!</p><br/>';
              this.highChartsRender2();
            }
          }
        });
      });
      // }
    } else {
      this.setState({
        msgStatus: true,
        msgType: 'error',
        msgContent: 'Please Select Group Number!',
      });
    }
  };

  changeGroup = (newValue) => {
    this.setState({ selectedGroup: newValue });
    setTimeout(() => {
      this.getGroupData();
    }, 3);
  };

  getLiveData = () => {
    this.groupService.liveData(this.state.tagList).then((res1) => {
      console.log('live data response: ' + JSON.stringify(res1));
      if (res1) {
        let td = this.state.tagDetails;
        td.map((r, i) => {
          // alert(JSON.stringify(r))
          if (r['tag'] === 'Analog') {
            let gSettings = JSON.parse(r.general_setting);
            if (gSettings.ENGLRV == undefined) gSettings.ENGLRV = 0;
            if (res1[r.tag_name] < gSettings.ENGLRV)
              r['tag_value'] = parseFloat(gSettings.ENGLRV).toFixed(3);
            else r['tag_value'] = parseFloat(res1[r.tag_name]).toFixed(3);
          } else {
            r['tag_value'] = res1[r.tag_name];
          }
          r['changeLimit'] = false;
        });
        this.setState({ tagDetails: td, isSpinner: false });
        // this.getLatestAlarms();
        this.setGroupData();
      }
    });
  };

  getLiveDataOnInterval = () => {
    this.groupService.liveData(this.state.tagList).then((res1) => {
      console.log('live data response on interval: ' + JSON.stringify(res1));
      if (res1) {
        let td = this.state.tagDetails;
        td.map((r, i) => {
          if (r['tag'] === 'Analog') {
            // parseFloat(res1[r.tag_name]).toFixed(3);
            let tVal = parseFloat(res1[r.tag_name]).toFixed(3);
            let gSettings = JSON.parse(r.general_setting);

            if (gSettings.ENGLRV == undefined) gSettings.ENGLRV = 0;
            if (tVal < +gSettings.ENGLRV)
              tVal = parseFloat(gSettings.ENGLRV).toFixed(3);
            else tVal = parseFloat(res1[r.tag_name]).toFixed(3);

            let unit = gSettings.ENGUNIT;
            if (gSettings.ENGLRV == undefined) gSettings.ENGLRV = 0;
            if (gSettings.ENGURV == 0) gSettings.ENGURV = 100;
            if (gSettings.ENGURV == undefined) gSettings.ENGURV = 100;
            const minVal = +gSettings.ENGLRV;
            const maxVal = +gSettings.ENGURV;
            r['tag_value'] = tVal;
            // alert(r.tag_name+'  '+tVal+' '+JSON.stringify(r.gaugeBands)+' '+r.gaugeTicks+' '+minVal+' '+maxVal+' '+unit)
            // alert(r['changeLimit']);
            if (r['changeLimit'] === false) {
              let tagVal = +tVal;

              if (tagVal < minVal) tagVal = parseFloat(minVal).toFixed(2);
              this.highChartRender_tag(
                r.tag_name,
                tagVal,
                r.gaugeBands,
                r.gaugeTicks,
                minVal,
                maxVal,
                unit
              );
            }
          } else {
            r['tag_value'] = res1[r.tag_name];
          }
        });
        this.setState({ tagDetails: td, isSpinner: false });
      }
    });
  };

  getLiveAlarms = () => {
    let tD = this.state.tagDetails;
    tD.map((t) => {
      // alert(t);
      const aParams = {
        unit_id: [t.unitid],
        tags: [t.tag_name],
        no_of_records: 1,
      };
      t['alarmType'] = '';
      t['alarmDescription'] = '';
      t['isAck'] = '';
      t['alarmSTS'] = '';
      t['aPrio'] = '';
      this.groupService.tagAlarm(aParams).then((res2) => {
        // alert(res2[0].alarm_desc)
        // t['alarmDescription'] = "";
        // t['aPrio'] = "";
        if (res2 && res2.length > 0) {
          if (t.tag_name === res2[0].tag_name) {
            t['alarmType'] = res2[0].priority;
            t['alarmDescription'] = res2[0].alarm_desc;
            t['isAck'] = res2[0].is_ack;
            t['alarmSTS'] = res2[0].status;
            t['aPrio'] = res2[0].priority;
          }
        } else {
          t['alarmSTS'] = '';
        }
      });
    }, this.setState({ tagDetails: tD }));
  };

  getLatestAlarms = () => {
    let tD = this.state.tagDetails;
    tD.map((t) => {
      // alert(t);
      const aParams = {
        unit_id: [t.unitid],
        tags: [t.tag_name],
        no_of_records: 1,
      };
      t['alarmType'] = '';
      t['alarmDescription'] = '';
      t['isAck'] = '';
      t['alarmSTS'] = '';
      t['aPrio'] = '';
      this.groupService.tagAlarm(aParams).then((res2) => {
        // t['alarmDescription'] = "";
        // t['aPrio'] = "";
        if (res2 && res2.length > 0) {
          console.log(
            'TEST 222 ' +
              res2[0].tag_name +
              ' -- ' +
              res2[0].date_time +
              ' -- ' +
              res2[0].alarm_desc
          );
          if (t.tag_name === res2[0].tag_name) {
            t['alarmType'] = res2[0].priority;
            t['alarmDescription'] = res2[0].alarm_desc;
            t['isAck'] = res2[0].is_ack;
            t['alarmSTS'] = res2[0].status;
            t['aPrio'] = res2[0].priority;
          }
        } else {
          t['alarmSTS'] = '';
        }
      });
    }, this.setState({ tagDetails: tD }));

    setTimeout(() => {
      this.groupService.liveData(this.state.tagList).then((res1) => {
        console.log('live data response on interval: ' + JSON.stringify(res1));

        console.log('TEST 222: ' + JSON.stringify(res1));
        if (res1) {
          let td = this.state.tagDetails;
          td.map((r, i) => {
            if (r['tag'] === 'Analog') {
              // parseFloat(res1[r.tag_name]).toFixed(3);
              let tVal = parseFloat(res1[r.tag_name]).toFixed(3);
              let gSettings = JSON.parse(r.general_setting);

              if (gSettings.ENGLRV == undefined) gSettings.ENGLRV = 0;
              if (tVal < +gSettings.ENGLRV)
                tVal = parseFloat(gSettings.ENGLRV).toFixed(3);
              else tVal = parseFloat(res1[r.tag_name]).toFixed(3);

              let unit = gSettings.ENGUNIT;
              if (gSettings.ENGLRV == undefined) gSettings.ENGLRV = 0;
              if (gSettings.ENGURV == 0) gSettings.ENGURV = 100;
              if (gSettings.ENGURV == undefined) gSettings.ENGURV = 100;
              const minVal = +gSettings.ENGLRV;
              const maxVal = +gSettings.ENGURV;
              r['tag_value'] = tVal;
              // alert(r.tag_name+'  '+tVal+' '+JSON.stringify(r.gaugeBands)+' '+r.gaugeTicks+' '+minVal+' '+maxVal+' '+unit)
              // alert(r['changeLimit']);
              if (r['changeLimit'] === false) {
                let tagVal = +tVal;

                if (tagVal < minVal) tagVal = parseFloat(minVal).toFixed(2);
                this.highChartRender_tag(
                  r.tag_name,
                  tagVal,
                  r.gaugeBands,
                  r.gaugeTicks,
                  minVal,
                  maxVal,
                  unit
                );
              }
            } else {
              r['tag_value'] = res1[r.tag_name];
            }
          });
          this.setState({ tagDetails: td, isSpinner: false });
        }
      });
    }, 25);
  };

  setGroupData = () => {
    let analogTagArr = [],
      digitalTagArr = [],
      c1Tags = [],
      c2Tags = [];
    let isC2 = false;
    let tagDetails = this.state.tagDetails;
    console.log(JSON.stringify(tagDetails));
    tagDetails.map((r, idx) => {
      if (r.tag === 'Analog') {
        analogTagArr.push(r.tag_name);
        let gSettings = JSON.parse(r.general_setting);
        let eUnit = gSettings.ENGUNIT;
        if (gSettings.ENGLRV == undefined) gSettings.ENGLRV = 0;
        if (gSettings.ENGURV == 0) gSettings.ENGURV = 100;
        if (gSettings.ENGURV == undefined) gSettings.ENGURV = 100;

        const minValue = +gSettings.ENGLRV;
        const maxValue = +gSettings.ENGURV;

        let plotBands = [],
          tickPos = [minValue, maxValue];
        let LLval, Lval, Hval, HHval;
        if (r.is_alarm === 'Enable' && r.alarm_config) {
          let alarmConfig = JSON.parse(r.alarm_config);
          console.log('alarmConfig: ' + JSON.stringify(alarmConfig));
          for (let a in alarmConfig) {
            if (a === 'LL') {
              LLval = alarmConfig[a].PVLLLMT;
              console.log(a + '=' + LLval);
            }
            if (a === 'L') {
              Lval = alarmConfig[a].PVLLMT;
              console.log(a + '=' + Lval);
            }
            if (a === 'H') {
              Hval = alarmConfig[a].PVHILMT;
              console.log(a + '=' + Hval);
            }
            if (a === 'HH') {
              HHval = alarmConfig[a].PVHHLMT;
              console.log(a + '=' + HHval);
            }
          }

          if (LLval) {
            tickPos.push(+LLval);
            plotBands.push({
              from: minValue,
              to: +LLval,
              color: '#F45B5B',
              thickness: '40%',
            });
            if (Lval) {
              tickPos.push(+Lval);
              plotBands.push({
                from: +LLval,
                to: +Lval,
                color: '#FFA500',
                thickness: '40%',
              });

              if (Hval) {
                tickPos.push(+Hval);
                plotBands.push({
                  from: +Lval,
                  to: +Hval,
                  color: '#5DBA4A',
                  thickness: '40%',
                });
                if (HHval) {
                  tickPos.push(+HHval);
                  plotBands.push({
                    from: +Hval,
                    to: +HHval,
                    color: '#FFA500',
                    thickness: '40%',
                  });
                  plotBands.push({
                    from: +HHval,
                    to: maxValue,
                    color: '#F45B5B',
                    thickness: '40%',
                  });
                } else {
                  plotBands.push({
                    from: +Hval,
                    to: maxValue,
                    color: '#FFA500',
                    thickness: '40%',
                  });
                }
              } else {
                if (HHval) {
                  tickPos.push(+HHval);
                  plotBands.push({
                    from: +Lval,
                    to: +HHval,
                    color: '#5DBA4A',
                    thickness: '40%',
                  });
                } else {
                  plotBands.push({
                    from: +Lval,
                    to: maxValue,
                    color: '#5DBA4A',
                    thickness: '40%',
                  });
                }
              }
            } else {
              //LLval, !Lval
              if (Hval) {
                // LLval, Hval
                tickPos.push(+Hval);
                plotBands.push({
                  from: +LLval,
                  to: +Hval,
                  color: '#5DBA4A',
                  thickness: '40%',
                });
                if (HHval) {
                  tickPos.push(+HHval);
                  plotBands.push({
                    from: +Hval,
                    to: +HHval,
                    color: '#FFA500',
                    thickness: '40%',
                  });
                } else {
                  plotBands.push({
                    from: +Hval,
                    to: maxValue,
                    color: '#FFA500',
                    thickness: '40%',
                  });
                }
              } else {
                //LLval, !Hval
                if (HHval) {
                  //LLVal, HHval
                  tickPos.push(+HHval);
                  plotBands.push({
                    from: +LLval,
                    to: +HHval,
                    color: '#5DBA4A',
                    thickness: '40%',
                  });
                } else {
                  //LLval, !Lval, !Hval, !HHval
                  plotBands.push({
                    from: +LLval,
                    to: maxValue,
                    color: '#5DBA4A',
                    thickness: '40%',
                  });
                }
              }
            }
          } else {
            if (Lval) {
              //!LLval, Lval
              tickPos.push(+Lval);
              plotBands.push({
                from: minValue,
                to: +Lval,
                color: '#FFA500',
                thickness: '40%',
              });
              if (Hval) {
                tickPos.push(+Hval);
                plotBands.push({
                  from: +Lval,
                  to: +Hval,
                  color: '#5DBA4A',
                  thickness: '40%',
                });
                if (HHval) {
                  tickPos.push(+HHval);
                  plotBands.push({
                    from: +Hval,
                    to: +HHval,
                    color: '#FFA500',
                    thickness: '40%',
                  });
                } else {
                  plotBands.push({
                    from: +Hval,
                    to: maxValue,
                    color: '#FFA500',
                    thickness: '40%',
                  });
                }
              } else {
                if (HHval) {
                  tickPos.push(+HHval);
                  plotBands.push({
                    from: +Lval,
                    to: +HHval,
                    color: '#5DBA4A',
                    thickness: '40%',
                  });
                } else {
                  plotBands.push({
                    from: +Lval,
                    to: maxValue,
                    color: '#5DBA4A',
                    thickness: '40%',
                  });
                }
              }
            } else {
              //!LLval, !Lval
              if (Hval) {
                //!LLval, !Lval, Hval
                tickPos.push(+Hval);
                plotBands.push({
                  from: minValue,
                  to: +Hval,
                  color: '#5DBA4A',
                  thickness: '40%',
                });
                if (HHval) {
                  tickPos.push(+HHval);
                  plotBands.push({
                    from: +Hval,
                    to: +HHval,
                    color: '#FFA500',
                    thickness: '40%',
                  });
                } else {
                  plotBands.push({
                    from: +Hval,
                    to: maxValue,
                    color: '#FFA500',
                    thickness: '40%',
                  });
                }
              } else {
                // !LLval, !Lval, !Hval
                if (HHval) {
                  // !LLval, !Lval, !Hval, HHval
                  tickPos.push(+HHval);
                  plotBands.push({
                    from: minValue,
                    to: +HHval,
                    color: '#5DBA4A',
                    thickness: '40%',
                  });
                } else {
                  //!LLval, !Lval, !Hval, !HHval
                  plotBands.push({
                    from: minValue,
                    to: maxValue,
                    color: '#5DBA4A',
                    thickness: '40%',
                  });
                }
              }
            }
          }

          // if (LLval) {
          //   tickPos.push(+LLval);
          //   plotBands.push({ 'from': minValue, 'to': +LLval, 'color': '#6D6D1F', 'thickness': '40%' });
          //   if (Lval) {
          //     tickPos.push(+Lval);
          //     plotBands.push({ 'from': +LLval, 'to': +Lval, 'color': '#6D6D1F', 'thickness': '40%' });

          //     if (Hval) {
          //       tickPos.push(+Hval);
          //       plotBands.push({ 'from': +Lval, 'to': +Hval, 'color': '#5DBA4A', 'thickness': '40%' })
          //       if (HHval) {
          //         tickPos.push(+HHval);
          //         plotBands.push({ 'from': +Hval, 'to': +HHval, 'color': '#FFA500', 'thickness': '40%' })
          //         plotBands.push({ 'from': +HHval, 'to': maxValue, 'color': '#F45B5B', 'thickness': '40%' })
          //       } else {
          //         plotBands.push({ 'from': +Hval, 'to': maxValue, 'color': '#FFA500', 'thickness': '40%' })
          //       }
          //     } else {
          //       if (HHval) {
          //         tickPos.push(+HHval);
          //         plotBands.push({ 'from': +Lval, 'to': +HHval, 'color': '#5DBA4A', 'thickness': '40%' })
          //         plotBands.push({ 'from': +HHval, 'to': maxValue, 'color': '#F45B5B', 'thickness': '40%' })
          //       } else {
          //         plotBands.push({ 'from': +Lval, 'to': maxValue, 'color': '#5DBA4A', 'thickness': '40%' })
          //       }
          //     }

          //   } else {  //LLval, !Lval
          //     if (Hval) {  // LLval, Hval
          //       tickPos.push(+Hval);
          //       plotBands.push({ 'from': +LLval, 'to': +Hval, 'color': '#5DBA4A', 'thickness': '40%' })
          //       if (HHval) {
          //         tickPos.push(+HHval);
          //         plotBands.push({ 'from': +Hval, 'to': +HHval, 'color': '#FFA500', 'thickness': '40%' })
          //         plotBands.push({ 'from': +HHval, 'to': maxValue, 'color': '#F45B5B', 'thickness': '40%' })
          //       } else {
          //         plotBands.push({ 'from': +Hval, 'to': maxValue, 'color': '#FFA500', 'thickness': '40%' })
          //       }
          //     } else {  //LLval, !Hval
          //       if (HHval) { //LLVal, HHval
          //         tickPos.push(+HHval);
          //         plotBands.push({ 'from': +LLval, 'to': +HHval, 'color': '#5DBA4A', 'thickness': '40%' })
          //       } else {  //LLval, !Lval, !Hval, !HHval
          //         plotBands.push({ 'from': +LLval, 'to': maxValue, 'color': '#5DBA4A', 'thickness': '40%' })
          //       }
          //     }
          //   }
          // } else {
          //   if (Lval) {   //!LLval, Lval
          //     tickPos.push(+Lval);
          //     plotBands.push({ 'from': minValue, 'to': +Lval, 'color': '#6D6D1F', 'thickness': '40%' })
          //     if (Hval) {
          //       tickPos.push(+Hval);
          //       plotBands.push({ 'from': +Lval, 'to': +Hval, 'color': '#5DBA4A', 'thickness': '40%' })
          //       if (HHval) {
          //         tickPos.push(+HHval);
          //         plotBands.push({ 'from': +Hval, 'to': +HHval, 'color': '#FFA500', 'thickness': '40%' })
          //         plotBands.push({ 'from': +HHval, 'to': maxValue, 'color': '#F45B5B', 'thickness': '40%' })
          //       } else {
          //         plotBands.push({ 'from': +Hval, 'to': maxValue, 'color': '#FFA500', 'thickness': '40%' })
          //       }
          //     } else {
          //       if (HHval) {
          //         tickPos.push(+HHval);
          //         plotBands.push({ 'from': +Lval, 'to': +HHval, 'color': '#5DBA4A', 'thickness': '40%' })
          //         plotBands.push({ 'from': +HHval, 'to': maxValue, 'color': '#F45B5B', 'thickness': '40%' })
          //       } else {
          //         plotBands.push({ 'from': +Lval, 'to': maxValue, 'color': '#5DBA4A', 'thickness': '40%' })
          //       }
          //     }
          //   } else {  //!LLval, !Lval
          //     if (Hval) {   //!LLval, !Lval, Hval
          //       tickPos.push(+Hval);
          //       plotBands.push({ 'from': minValue, 'to': +Hval, 'color': '#5DBA4A', 'thickness': '40%' })
          //       if (HHval) {
          //         tickPos.push(+HHval);
          //         plotBands.push({ 'from': +Hval, 'to': +HHval, 'color': '#E4D354', 'thickness': '40%' })
          //         plotBands.push({ 'from': +HHval, 'to': maxValue, 'color': '#F45B5B', 'thickness': '40%' })
          //       } else {
          //         plotBands.push({ 'from': +Hval, 'to': maxValue, 'color': '#FFA500', 'thickness': '40%' })
          //       }
          //     } else {   // !LLval, !Lval, !Hval
          //       if (HHval) {  // !LLval, !Lval, !Hval, HHval
          //         tickPos.push(+HHval);
          //         plotBands.push({ 'from': minValue, 'to': +HHval, 'color': '#5DBA4A', 'thickness': '40%' })
          //         plotBands.push({ 'from': +HHval, 'to': maxValue, 'color': '#F45B5B', 'thickness': '40%' })
          //       } else {   //!LLval, !Lval, !Hval, !HHval
          //         plotBands.push({ 'from': minValue, 'to': maxValue, 'color': '#5DBA4A', 'thickness': '40%' })
          //       }
          //     }
          //   }
          // }

          console.log(r.tag_name + '  ' + JSON.stringify(plotBands));
        } else {
          tickPos = [minValue, maxValue];
          plotBands = [
            {
              from: minValue,
              to: maxValue,
              color: '#5DBA4A',
              thickness: '40%',
            },
          ];
        }
        r['gaugeBands'] = plotBands;
        r['gaugeTicks'] = tickPos;
        // alert(r.tag_value);
        let tagVal = +r.tag_value;

        if (tagVal < minValue) tagVal = parseFloat(minValue).toFixed(2);

        this.highChartRender_tag(
          r.tag_name,
          tagVal,
          plotBands,
          tickPos,
          minValue,
          maxValue,
          eUnit
        );
      } else {
        digitalTagArr.push(r.tag_name);
      }

      if (idx <= 2) {
        c1Tags.push({ tagName: r.tag_name, tagType: r.tag });
      } else {
        c2Tags.push({ tagName: r.tag_name, tagType: r.tag });
      }
    });
    this.getTrend(c1Tags, 'chart1');
    if (this.state.tagList.length > 3) {
      isC2 = true;
      this.getTrend(c2Tags, 'chart2');
    }
    this.setState({
      analogTagList: analogTagArr,
      digitalTagList: digitalTagArr,
      chart1Tags: c1Tags,
      chart2Tags: c2Tags,
      isChart2: isC2,
    });
  };

  getGroupData = () => {
    this.setState({ isSpinner: true, analogTagList: [], digitalTagList: [] });
    this.groupService
      .getTagsByGroupNumber(this.state.selectedGroup)
      .then((res) => {
        console.log(res);
        if (res && res.length > 0) {
          let tagArr = [];
          res.map((t, i) => {
            tagArr.push(t.tag_name);
            if (t.tag === 'Analog') {
              let ctlConfig = JSON.parse(t.control_config);
              if (ctlConfig.SPSRC) {
                if (ctlConfig.SPSRC.includes('$')) {
                  const req1 = {
                    nodeid: ctlConfig.SPSRC,
                    opcname: 'advait',
                    value: {},
                    dataType: 'string',
                  };
                  this.groupService.OPC(req1).then((res1) => {
                    // alert('SP read res: '+JSON.stringify(res1));
                    if (res1) {
                      t['SP_value'] = parseFloat(res1.value).toFixed(3);
                    }
                  });
                } else {
                  t['SP_value'] = parseFloat(ctlConfig.SPSRC).toFixed(3);
                }
              }
              if (ctlConfig.OPSRC) {
                console.log(ctlConfig.OPSRC);
                if (ctlConfig.OPSRC.includes('$')) {
                  const req2 = {
                    nodeid: ctlConfig.OPSRC,
                    opcname: 'advait',
                    value: {},
                    dataType: 'string',
                  };
                  this.groupService.OPC(req2).then((res2) => {
                    if (res2) {
                      t['OP_value'] = parseFloat(res2.value).toFixed(3);
                    }
                  });
                } else {
                  t['OP_value'] = parseFloat(ctlConfig.OPSRC).toFixed(3);
                }
              }
            }
          });
          setTimeout(() => {
            console.log(
              'sorting the res after adding values: ' + JSON.stringify(res)
            );
            const sortResp = this.sortFunction(res, 'tag_name');
            this.setState({
              tagDetails: sortResp,
              tagList: tagArr,
              groupDescription: JSON.parse(res[0].group_setting).GRPDESC,
            });
            this.getLiveAlarms();
            this.getLiveData();

            this.intervalId2 = setInterval(this.getLatestAlarms, 10000);
            // this.intervalId1 = setInterval(this.getLiveDataOnInterval, 11500); // 5sec = 5000, 30sec = 30000
          }, 100);
        } else {
          this.setState({
            msgStatus: true,
            msgType: 'error',
            msgContent: 'No data found!',
            isSpinner: false,
          });
        }
      });
  };

  changePeriod = (newPeriod) => {
    let DT = new Date();
    let DT1 = DT.toISOString().split('T')[0];
    const TM1 =
      ('0' + DT.getHours()).slice(-2) + ':' + ('0' + DT.getMinutes()).slice(-2);
    // alert('current: '+DT1+'  '+TM1);
    let DT2, TM2;
    switch (newPeriod) {
      case 3600:
        DT2 = new Date(DT.getTime() - 1000 * 60 * 60);
        TM2 =
          ('0' + DT2.getHours()).slice(-2) +
          ':' +
          ('0' + DT2.getMinutes()).slice(-2);
        DT2 = DT2.toISOString().split('T')[0];
        // alert('1 hour: '+DT2+' '+TM2)
        break;
      case 7200:
        DT2 = new Date(DT.getTime() - 1000 * 60 * 60 * 2);
        TM2 =
          ('0' + DT2.getHours()).slice(-2) +
          ':' +
          ('0' + DT2.getMinutes()).slice(-2);
        DT2 = DT2.toISOString().split('T')[0];
        // alert('2 hour: '+DT2+' '+TM2)
        break;
      case 14400:
        DT2 = new Date(DT.getTime() - 1000 * 60 * 60 * 4);
        TM2 =
          ('0' + DT2.getHours()).slice(-2) +
          ':' +
          ('0' + DT2.getMinutes()).slice(-2);
        DT2 = DT2.toISOString().split('T')[0];
        // alert('4 hour: '+DT2+' '+TM2)
        break;
      case 28800:
        DT2 = new Date(DT.getTime() - 1000 * 60 * 60 * 8);
        TM2 =
          ('0' + DT2.getHours()).slice(-2) +
          ':' +
          ('0' + DT2.getMinutes()).slice(-2);
        DT2 = DT2.toISOString().split('T')[0];
        // alert('8 hour: '+DT2+' '+TM2)
        break;
      case 43200:
        DT2 = new Date(DT.getTime() - 1000 * 60 * 60 * 12);
        TM2 =
          ('0' + DT2.getHours()).slice(-2) +
          ':' +
          ('0' + DT2.getMinutes()).slice(-2);
        DT2 = DT2.toISOString().split('T')[0];
        // alert('12 hour: '+DT2+' '+TM2)
        break;
      case 57600:
        DT2 = new Date(DT.getTime() - 1000 * 60 * 60 * 16);
        TM2 =
          ('0' + DT2.getHours()).slice(-2) +
          ':' +
          ('0' + DT2.getMinutes()).slice(-2);
        DT2 = DT2.toISOString().split('T')[0];
        // alert('16 hour: '+DT2+' '+TM2)
        break;
      case 86400:
        DT2 = new Date(DT.getTime() - 1000 * 60 * 60 * 24);
        TM2 =
          ('0' + DT2.getHours()).slice(-2) +
          ':' +
          ('0' + DT2.getMinutes()).slice(-2);
        DT2 = DT2.toISOString().split('T')[0];
        // alert('24 hour: '+DT2+' '+TM2)
        break;
    }
    this.setState({
      selectedPeriod: newPeriod,
      selectedFrom: DT2 + ' ' + TM2 + ':00',
      selectedTo: DT1 + ' ' + TM1 + ':00',
    });
    setTimeout(() => {
      this.getTrend(this.state.chart1Tags, 'chart1');
      if (this.state.isChart2) {
        this.getTrend(this.state.chart2Tags, 'chart2');
      }
    }, 5);
  };

  changeInterval = (newInterval) => {
    this.setState({ selectedInterval: newInterval });
    setTimeout(() => {
      this.getTrend(this.state.chart1Tags, 'chart1');
      if (this.state.isChart2) {
        this.getTrend(this.state.chart2Tags, 'chart2');
      }
    }, 5);
  };

  closeSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ msgStatus: false });
  };

  openDialog = (tIdx, tName, tVal, tProp) => {
    this.setState({
      openModal: true,
      newTidx: tIdx,
      newTname: tName,
      newTprop: tProp,
      newTvalue: tVal,
    });
  };

  closeDialog = () => {
    this.setState({ openModal: false });
  };

  vChange = (event) => {
    this.setState({ newTvalue: event.target.value });
  };

  writeValue = () => {
    let tProp = '',
      pType = '';
    if (this.state.newTprop === 'SP') {
      tProp = 'SPDEST';
      pType = 'control';
    } else if (this.state.newTprop === 'OP') {
      tProp = 'OPDEST';
      pType = 'control';
    } else {
      tProp = this.state.newTprop;
      pType = this.state.newTprop;
    }
    const reqData = {
      operation: 'write_property',
      tag_name: this.state.newTname,
      tag_prop: tProp,
      prop_type: pType,
      value: this.state.newTvalue,
      app_type: 'ADVAIT_HMI',
      email: this.userEmail,
    };
    // alert("reqData: "+JSON.stringify(reqData));
    this.groupService.WriteValue(reqData).then((res) => {
      // alert(JSON.stringify(res));
      this.setState({
        msgStatus: true,
        msgType: 'success',
        msgContent: res.message,
        openModal: false,
      });

      if (this.state.newTprop === 'PV') {
        setTimeout(() => {
          this.getLiveAlarms();
          setTimeout(() => {
            let TD = this.state.tagDetails;

            this.groupService.liveData([this.state.newTname]).then((res1) => {
              // alert('live data response: '+JSON.stringify(res1)+'  '+res1[this.state.newTname]);
              if (res1) {
                let GS = JSON.parse(TD[this.state.newTidx].general_setting);
                let pvTAG = res1[this.state.newTname];
                if (TD[this.state.newTidx].tag === 'Analog') {
                  pvTAG = parseFloat(res1[this.state.newTname]).toFixed(3);
                }
                // TD[this.state.newTidx].tag_value = res1[this.state.newTname];
                TD[this.state.newTidx].tag_value = pvTAG;
                this.setState({ tagDetails: TD });
                // alert(this.state.newTname+' '+res1[this.state.newTname]+' '+TD[this.state.newTidx].gaugeBands+' '+TD[this.state.newTidx].gaugeTicks+' '+GS.ENGLRV+' '+GS.ENGURV+' '+GS.ENGUNIT)
                if (
                  TD[this.state.newTidx].tag === 'Analog' &&
                  TD[this.state.newTidx].changeLimit === false
                ) {
                  const t = parseFloat(res1[this.state.newTname]).toFixed(3);
                  let tagVal = +t;

                  if (tagVal < +GS.ENGLRV)
                    tagVal = parseFloat(+GS.ENGLRV).toFixed(2);
                  this.highChartRender_tag(
                    this.state.newTname,
                    tagVal,
                    TD[this.state.newTidx].gaugeBands,
                    TD[this.state.newTidx].gaugeTicks,
                    +GS.ENGLRV,
                    +GS.ENGURV,
                    GS.ENGUNIT
                  );
                }
              }
            });
          }, 250);
        }, 250);
      } else {
        this.getGroupData();
      }
    });
  };

  openSetLimit = (e, idx) => {
    e.preventDefault();
    if (e.type === 'click') {
      this.props.history.push('/alarms');
    } else if (
      e.type === 'contextmenu' &&
      this.state.tagDetails[idx].tag === 'Analog'
    ) {
      let tDetails = this.state.tagDetails;
      tDetails[idx].changeLimit = !tDetails[idx].changeLimit;
      this.setState({ tagDetails: tDetails });
      // alert(idx+'  '+tDetails[idx].changeLimit);
      setTimeout(() => {
        // alert(JSON.stringify(tDetails[idx]));
        const gSetting = JSON.parse(tDetails[idx].general_setting);
        if (tDetails[idx].changeLimit) {
          let LL = 0,
            L = 0,
            H = 0,
            HH = 0;
          if (
            tDetails[idx].is_alarm === 'Enable' &&
            tDetails[idx].alarm_config
          ) {
            let aConfig = JSON.parse(tDetails[idx].alarm_config);
            for (let a in aConfig) {
              // console.log(alarmConfig[a])
              if (a === 'LL') {
                LL = aConfig[a].PVLLLMT;
              }
              if (a === 'L') {
                L = aConfig[a].PVLLMT;
              }
              if (a === 'H') {
                H = aConfig[a].PVHILMT;
              }
              if (a === 'HH') {
                HH = aConfig[a].PVHHLMT;
              }
            }
          }
          this.setState({
            ['LL' + idx]: LL,
            ['L' + idx]: L,
            ['H' + idx]: H,
            ['HH' + idx]: HH,
          });
        } else {
          const tg = parseFloat(tDetails[idx].tag_value).toFixed(3);
          let tagVal = +tg;

          if (tagVal < +gSetting.ENGLRV)
            tagVal = parseFloat(+gSetting.ENGLRV).toFixed(2);
          this.highChartRender_tag(
            tDetails[idx].tag_name,
            tagVal,
            tDetails[idx].gaugeBands,
            tDetails[idx].gaugeTicks,
            +gSetting.ENGLRV,
            +gSetting.ENGURV,
            gSetting.ENGUNIT
          );
        }
      }, 5);
    }
  };

  updateLimit = (tName, lType, tProp, idx) => {
    const reqData = {
      operation: 'write_property',
      tag_name: tName,
      tag_prop: tProp,
      prop_type: 'alarm',
      value: this.state[lType + idx],
      app_type: 'ADVAIT_HMI',
      email: this.userEmail,
    };
    // alert("reqData: "+JSON.stringify(reqData));
    this.groupService.WriteValue(reqData).then((res) => {
      // alert(JSON.stringify(res));
      this.setState({
        msgStatus: true,
        msgType: 'success',
        msgContent: res.message,
      });
      this.getGroupData();
    });
  };

  resetLimit = (lType, idx) => {
    if (
      this.state.tagDetails[idx].is_alarm === 'Enable' &&
      this.state.tagDetails[idx].alarm_config
    ) {
      let aConf = JSON.parse(this.state.tagDetails[idx].alarm_config);
      for (let a in aConf) {
        if (a === lType) {
          switch (lType) {
            case 'LL':
              this.setState({ [lType + idx]: aConf[a].PVLLLMT });
              break;
            case 'L':
              this.setState({ [lType + idx]: aConf[a].PVLLMT });
              break;
            case 'H':
              this.setState({ [lType + idx]: aConf[a].PVHILMT });
              break;
            case 'HH':
              this.setState({ [lType + idx]: aConf[a].PVHHLMT });
              break;
          }
        }
      }
    }
  };

  changeLL = (event, i) => {
    this.setState({ ['LL' + i]: event.target.value });
  };

  changeL = (event, i) => {
    this.setState({ ['L' + i]: event.target.value });
  };

  changeH = (event, i) => {
    this.setState({ ['H' + i]: event.target.value });
  };

  changeHH = (event, i) => {
    this.setState({ ['HH' + i]: event.target.value });
  };

  sortFunction = (array, key) => {
    return array.sort(function(a, b) {
      var x = a[key];
      var y = b[key];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  };

  render() {
    console.log('.. in render..');
    const { classes } = this.props;
    const {
      isSpinnerTrend,
      isSpinner,
      groups,
      selectedGroup,
      selectedPeriod,
      selectedInterval,
      analogTagList,
      digitalTagList,
      tagDetails,
      groupDescription,
      openModal,
      newTvalue,
      isC1Spinner,
      isC2Spinner,
      newTname,
      newTprop,
      value,
    } = this.state;
    const periods = [
      { label: '1 hour', value: 3600 },
      { label: '2 hours', value: 7200 },
      { label: '4 hours', value: 14400 },
      { label: '8 hours', value: 28800 },
      { label: '12 hours', value: 43200 },
      { label: '16 hours', value: 57600 },
      { label: '24 hours', value: 86400 },
    ];
    const intervals = [
      { label: 'No Interval', value: 0 },
      { label: '1 min', value: 60 },
      { label: '5 min', value: 300 },
      { label: '10 min', value: 600 },
      { label: '15 min', value: 900 },
      { label: '30 min', value: 1800 },
      { label: '1 hour', value: 3600 },
      { label: '2 hours', value: 7200 },
      { label: '4 hours', value: 14400 },
      { label: '8 hours', value: 28800 },
      { label: '12 hours', value: 43200 },
      { label: '16 hours', value: 57600 },
      { label: '24 hours', value: 86400 },
    ];

    console.log('tagDetails from render ' + JSON.stringify(tagDetails));
    const leftPanel = tagDetails.map((tag, idx) => {
      let alarmColor = 'RtnBlink',
        aColor = 'green';
      let gSettings = JSON.parse(tag.general_setting);
      let ctlConfig = JSON.parse(tag.control_config);
      let aConfig = JSON.parse(tag.alarm_config);

      let groupSettings = JSON.parse(tag.group_setting);
      let dTagVal = '',
        dTagColor = '';
      if (tag.tag === 'Digital') {
        if (parseInt(tag.tag_value) === 0) {
          dTagVal = gSettings.STATE0;
          dTagColor = '#5DBA4A';
        } else {
          dTagVal = gSettings.STATE1;
          dTagColor = '#F45B5B';
        }
      } else {
        let LL = 0,
          L = 0,
          H = 0,
          HH = 0;
        if (tag.is_alarm === 'Enable' && tag.alarm_config) {
          console.log('alarmConfig: ' + JSON.stringify(aConfig));
          for (let a in aConfig) {
            if (a === 'LL') {
              LL = aConfig[a].PVLLLMT;
            }
            if (a === 'L') {
              L = aConfig[a].PVLLMT;
            }
            if (a === 'H') {
              H = aConfig[a].PVHILMT;
            }
            if (a === 'HH') {
              HH = aConfig[a].PVHHLMT;
            }
          }
        }
        console.log(
          tag.tag + ' RTN ' + JSON.stringify(tag) + ' ==== ' + tag.alarmSTS
        );

        // if(tag.tag_value == 100.3) {alert(tag.tag_value +" --= "+ HH); alert(parseInt(tag.tag_value) > parseInt(HH))}

        // alert(tag.tag_value)
        if (parseInt(tag.tag_value) <= parseInt(LL)) {
          alarmColor = 'AlmEmg';
          aColor = 'red';
        } else if (
          parseInt(tag.tag_value) > parseInt(LL) &&
          parseInt(tag.tag_value) < parseInt(L)
        ) {
          alarmColor = 'AlmHighBlink';
          aColor = 'orange';
        } else if (
          parseInt(tag.tag_value) >= parseInt(L) &&
          parseInt(tag.tag_value) < parseInt(H)
        ) {
          alarmColor = 'RtnBlink';
          aColor = 'green';
        } else if (
          parseInt(tag.tag_value) >= parseInt(H) &&
          parseInt(tag.tag_value) < parseInt(HH)
        ) {
          alarmColor = 'AlmHighBlink';
          aColor = 'orange';
        } else if (parseInt(tag.tag_value) >= parseInt(HH)) {
          alarmColor = 'AlmEmg';
          aColor = 'red';
        } else {
          alarmColor = 'AlmEmg';
          aColor = 'red';
        }

        // else if(parseInt(tag.tag_value) >= parseInt(HH)){
        //   // alert(tag.tag_value +" < "+ HH)
        //   // alert(tag.tag_value < LL)
        //   alarmColor = "AlmEmg";
        //   aColor = "red";
        // }
      }
      return (
        <Grid item xs={4} key={idx}>
          <Card style={{ height: '415px', overflowY: 'auto' }}>
            <CardContent>
              <Typography title={tag.tag_name}>
                {tag.tag_name}
                <NavLink
                  to={`/graphics/${groupSettings.ASODISP}`}
                  style={{ float: 'right' }}
                  title="Ass. Disp"
                >
                  <NearMeIcon style={{ color: 'blue' }}></NearMeIcon>
                </NavLink>
                {/* <IconButton title='Ass. Disp.' color='primary' style={{ width: '30px', height: '30px' }} onClick={(event) => this.resetLimit('LL', idx)} ><NearMeIcon /></IconButton> */}
              </Typography>
              {gSettings.DESC ? (
                <Typography title={gSettings.DESC}>
                  {gSettings.DESC}{' '}
                </Typography>
              ) : (
                <Typography style={{ color: 'white' }} title={gSettings.DESC}>
                  .{' '}
                </Typography>
              )}
              <br></br>
              {tag.tag === 'Analog' && tag.changeLimit === false ? (
                <div
                  id={tag.tag_name}
                  key={idx}
                  style={{ height: '160px' }}
                ></div>
              ) : tag.tag === 'Analog' && tag.changeLimit === true ? (
                <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                  <form className={classes.container}>
                    <div className="row">
                      <FormControl className={classes.textField4}>
                        <TextField
                          id="LL"
                          type="number"
                          label="PV LL"
                          value={this.state['LL' + idx]}
                          onChange={(event) => this.changeLL(event, idx)}
                        />
                      </FormControl>
                      <IconButton
                        title="Submit"
                        color="primary"
                        onClick={(event) =>
                          this.updateLimit(tag.tag_name, 'LL', 'PVLLLMT', idx)
                        }
                        style={{ width: '30px', height: '30px' }}
                      >
                        <CheckCircleIcon />
                      </IconButton>
                      <IconButton
                        title="Cancel"
                        color="secondary"
                        style={{ width: '30px', height: '30px' }}
                        onClick={(event) => this.resetLimit('LL', idx)}
                      >
                        <CancelIcon />
                      </IconButton>
                    </div>
                    <div className="row">
                      <FormControl className={classes.textField4}>
                        <TextField
                          id="L"
                          type="number"
                          label="PV L"
                          value={this.state['L' + idx]}
                          onChange={(event) => this.changeL(event, idx)}
                        />
                      </FormControl>
                      <IconButton
                        title="Submit"
                        color="primary"
                        onClick={(event) =>
                          this.updateLimit(tag.tag_name, 'L', 'PVLLMT', idx)
                        }
                        style={{ width: '30px', height: '30px' }}
                      >
                        <CheckCircleIcon />
                      </IconButton>
                      <IconButton
                        title="Cancel"
                        color="secondary"
                        style={{ width: '30px', height: '30px' }}
                        onClick={(event) => this.resetLimit('L', idx)}
                      >
                        <CancelIcon />
                      </IconButton>
                    </div>
                    <div className="row">
                      <FormControl className={classes.textField4}>
                        <TextField
                          id="H"
                          type="number"
                          label="PV H"
                          value={this.state['H' + idx]}
                          onChange={(event) => this.changeH(event, idx)}
                        />
                      </FormControl>
                      <IconButton
                        title="Submit"
                        color="primary"
                        onClick={(event) =>
                          this.updateLimit(tag.tag_name, 'H', 'PVHILMT', idx)
                        }
                        style={{ width: '30px', height: '30px' }}
                      >
                        <CheckCircleIcon />
                      </IconButton>
                      <IconButton
                        title="Cancel"
                        color="secondary"
                        style={{ width: '30px', height: '30px' }}
                        onClick={(event) => this.resetLimit('H', idx)}
                      >
                        <CancelIcon />
                      </IconButton>
                    </div>
                    <div className="row">
                      <FormControl className={classes.textField4}>
                        <TextField
                          id="HH"
                          type="number"
                          label="PV HH"
                          value={this.state['HH' + idx]}
                          onChange={(event) => this.changeHH(event, idx)}
                        />
                      </FormControl>
                      <IconButton
                        title="Submit"
                        color="primary"
                        onClick={(event) =>
                          this.updateLimit(tag.tag_name, 'HH', 'PVHHLMT', idx)
                        }
                        style={{ width: '30px', height: '30px' }}
                      >
                        <CheckCircleIcon />
                      </IconButton>
                      <IconButton
                        title="Cancel"
                        color="secondary"
                        style={{ width: '30px', height: '30px' }}
                        onClick={(event) => this.resetLimit('HH', idx)}
                      >
                        <CancelIcon />
                      </IconButton>
                    </div>
                  </form>
                </div>
              ) : (
                <div>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <button
                            style={{
                              margin: '10px',
                              height: '45px',
                              width: '45px',
                              borderRadius: '50%',
                              color: '#fff',
                              border: 'none',
                              backgroundColor:
                                tag.tag_value === 0
                                  ? '#23b306'
                                  : 'rgba(35, 179, 6, 0.3)',
                            }}
                          ></button>
                        </td>
                        <td>
                          <Typography>{gSettings.STATE0}</Typography>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <button
                            style={{
                              margin: '10px',
                              height: '45px',
                              width: '45px',
                              borderRadius: '50%',
                              color: '#fff',
                              border: 'none',
                              backgroundColor:
                                tag.tag_value === 1
                                  ? '#f72828'
                                  : 'rgba(247, 40, 40, 0.3)',
                            }}
                          ></button>
                        </td>
                        <td>
                          <Typography>{gSettings.STATE1}</Typography>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br></br>
                </div>
              )}

              <Divider />

              {tag.is_alarm === 'Enable' ? (
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <NotificationsActiveIcon
                          className={
                            tag.isAck === 'N' && tag.alarmSTS === 'RTN'
                              ? 'RtnBlink'
                              : tag.isAck === 'N' && tag.alarmSTS === 'ALM'
                              ? alarmColor
                              : tag.isAck === 'Y' && tag.alarmSTS === 'RTN'
                              ? 'Rtn'
                              : tag.isAck === 'Y' && tag.alarmSTS === 'ALM'
                              ? alarmColor
                              : alarmColor
                          }
                          onClick={(event) => this.openSetLimit(event, idx)}
                          onContextMenu={(event) =>
                            this.openSetLimit(event, idx)
                          }
                        ></NotificationsActiveIcon>
                        {/* <NotificationsActiveIcon className={tag.isAck === 'N' && tag.alarmSTS === 'RTN' ? 'RtnBlink' : tag.isAck === 'N' && tag.alarmSTS === 'ALM' && tag.aPrio === 'High' ? 'AlmHighBlink' : tag.isAck === 'N' && tag.alarmSTS === 'ALM' && tag.aPrio === 'Low' ? 'AlmLowBlink' : tag.isAck === 'N' && tag.alarmSTS === 'ALM' && tag.aPrio === 'Emergency' ? 'AlmEmgBlink' : tag.isAck === 'Y' && tag.alarmSTS === 'RTN' ? 'Rtn' : tag.isAck === 'Y' && tag.alarmSTS === 'ALM' && tag.aPrio === 'High' ? 'Almhigh' : tag.isAck === 'Y' && tag.alarmSTS === 'ALM' && tag.aPrio === 'Low' ? 'AlmLow' : 'AlmEmg'} onClick={(event) => this.openSetLimit(event, idx)} onContextMenu={(event) => this.openSetLimit(event, idx)}></NotificationsActiveIcon> */}
                      </td>
                      <td rowSpan="2" style={{ verticalAlign: 'top' }}>
                        {tag.alarmType ? (
                          <Typography>
                            ({tag.alarmType}) - {tag.alarmDescription}
                          </Typography>
                        ) : (
                          <Typography>
                            {tag.alarmType} {tag.alarmDescription}
                          </Typography>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              ) : (
                <NavLink to="/alarms">
                  <NotificationsOffIcon
                    style={{ color: 'red', padding: '3px' }}
                  ></NotificationsOffIcon>
                </NavLink>
              )}

              <Divider />
              <table style={{ margin: '10px' }} cellPadding="3px" key="idx">
                {tag.tag === 'Analog' ? (
                  <tbody>
                    <tr>
                      <td width="5%">
                        <Typography>PV: </Typography>
                      </td>
                      <td width="85%">
                        <button
                          style={{
                            color: '#fff',
                            padding: '8px 16px',
                            borderRadius: '4px',
                            border: 'none',
                            width: '100%',
                            textAlign: 'center',
                            cursor: 'pointer',
                            backgroundColor: aColor,
                          }}
                          onClick={(event) =>
                            this.openDialog(
                              idx,
                              tag.tag_name,
                              tag.tag_value,
                              'PV'
                            )
                          }
                        >
                          {tag.tag_value}
                        </button>
                      </td>
                      <td width="10%">
                        <Typography>{gSettings.ENGUNIT}</Typography>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td width="5%">
                        <Typography>PV: </Typography>
                      </td>
                      <td width="85%">
                        <button
                          style={{
                            color: '#fff',
                            padding: '8px 16px',
                            borderRadius: '4px',
                            border: 'none',
                            width: '100%',
                            textAlign: 'center',
                            cursor: 'pointer',
                            backgroundColor: dTagColor,
                          }}
                          onClick={(event) =>
                            this.openDialog(
                              idx,
                              tag.tag_name,
                              tag.tag_value,
                              'PV'
                            )
                          }
                        >
                          {dTagVal}
                        </button>
                      </td>
                    </tr>
                  </tbody>
                )}

                {tag.tag === 'Analog' && tag.SP_value ? (
                  <tbody>
                    <tr>
                      <td>
                        <Typography>SP: </Typography>
                      </td>
                      <td>
                        <button
                          style={{
                            backgroundColor: '#7DB6EC',
                            color: '#fff',
                            padding: '8px 16px',
                            borderRadius: '4px',
                            border: 'none',
                            width: '100%',
                            textAlign: 'center',
                          }}
                          onClick={(event) =>
                            this.openDialog(
                              idx,
                              tag.tag_name,
                              tag.SP_value,
                              'SP'
                            )
                          }
                        >
                          {tag.SP_value}
                        </button>
                      </td>
                      <td>
                        <Typography>{gSettings.ENGUNIT}</Typography>
                      </td>
                    </tr>
                  </tbody>
                ) : null}

                {tag.tag === 'Analog' && tag.OP_value ? (
                  <tbody>
                    <tr>
                      <td>
                        <Typography>OP: </Typography>
                      </td>
                      <td>
                        <button
                          style={{
                            backgroundColor: '#7DB6EC',
                            color: '#fff',
                            padding: '8px 16px',
                            borderRadius: '4px',
                            border: 'none',
                            width: '100%',
                            textAlign: 'center',
                          }}
                          onClick={(event) =>
                            this.openDialog(
                              idx,
                              tag.tag_name,
                              tag.OP_value,
                              'OP'
                            )
                          }
                        >
                          {tag.OP_value}
                        </button>
                      </td>
                      <td>
                        <Typography>{gSettings.ENGUNIT}</Typography>
                      </td>
                    </tr>
                  </tbody>
                ) : null}
              </table>
            </CardContent>
          </Card>
        </Grid>
      );
    });
    const Chart1Panel = (
      <Grid item xs={12}>
        <Card style={{ height: '415px' }}>
          <CardContent>
            {/* {isC1Spinner ? <CircularProgress className={classes.progress} color="primary" size={30} thickness={3} /> :  */}
            <div id="advaitTrends1"></div>
          </CardContent>
        </Card>
      </Grid>
    );
    const Chart2Panel = (
      <Grid item xs={12}>
        <Card style={{ height: '415px' }}>
          <CardContent>
            {/* {isC2Spinner ? <CircularProgress className={classes.progress} color="primary" size={30} thickness={3} /> :  */}
            <div id="advaitTrends2"></div>
          </CardContent>
        </Card>
      </Grid>
    );
    return (
      <Wrapper>
        <Dialog
          open={openModal}
          onClose={this.closeDialog}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Update {newTprop} Value:<br></br> {newTname}
          </DialogTitle>
          <DialogContent>
            <form>
              <TextField
                className={classes.textField}
                id="updateValue"
                label="New Value"
                type="number"
                value={newTvalue}
                onChange={(event) => this.vChange(event)}
                required={true}
              />
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialog} color="secondary">
              Cancel
            </Button>
            <Button onClick={this.writeValue} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
        {/* <Card>
          <CardContent style={{ padding:'10px' }}> */}
        {this.state.msgStatus ? (
          <CustomizedSnackbars
            opened={this.state.msgStatus}
            snackType={this.state.msgType}
            snackMessage={this.state.msgContent}
            closed={this.closeSnackBar}
          ></CustomizedSnackbars>
        ) : null}
        <Paper className={classes.root}>
          <form className={classes.container}>
            <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
              <Grid container spacing={8}>
                <FormControl className={classes.textField}>
                  <Typography>Group Number:</Typography>
                  <VirtualizedSelect
                    ref="grupNum"
                    options={groups}
                    simpleValue
                    clearable={false}
                    name="select-group"
                    value={selectedGroup}
                    onChange={this.changeGroup}
                    searchable
                    placeholder="Search/Select Group Number"
                  />
                  {/* <Select
                    labelId="grupNum"
                    id="grupNum-select"
                    value={selectedGroup}
                    onChange={this.changeGroup}
                  >
                    {groups.map((g, idx) => {
                      return <MenuItem value={g} key={idx}>{g}</MenuItem>
                    })}
                  </Select> */}
                </FormControl>
                <FormControl className={classes.textField2}>
                  {/* <TextField id="group-desc" label="Group Description" value={groupDescription} inputProps={{ readOnly:true }} /> */}
                  <Typography>
                    Group description: <br></br> {groupDescription}
                  </Typography>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
              <Grid container spacing={8}>
                <FormControl className={classes.textField}>
                  <Typography>Period:</Typography>
                  <VirtualizedSelect
                    ref="period"
                    options={periods}
                    simpleValue
                    clearable={false}
                    name="select-period"
                    value={selectedPeriod}
                    onChange={this.changePeriod}
                    searchable
                  />

                  {/* <InputLabel id="period">Period</InputLabel>
                    <Select
                      labelId="period"
                      id="period-select"
                      value={selectedPeriod}
                      onChange={this.changePeriod}
                    >
                      <MenuItem value={3600}>1 hour</MenuItem>
                      <MenuItem value={7200}>2 hour</MenuItem>
                      <MenuItem value={14400}>4 hour</MenuItem>
                      <MenuItem value={28800}>8 hour</MenuItem>
                      <MenuItem value={43200}>12 hour</MenuItem>
                      <MenuItem value={57600}>16 hour</MenuItem>
                      <MenuItem value={86400}>24 hour</MenuItem>
                    </Select> */}
                </FormControl>
                <FormControl className={classes.textField}>
                  <Typography>Interval:</Typography>
                  <VirtualizedSelect
                    ref="interval"
                    options={intervals}
                    simpleValue
                    clearable={false}
                    name="select-interval"
                    value={selectedInterval}
                    onChange={this.changeInterval}
                    searchable
                  />
                  {/* <InputLabel id="prio">Interval</InputLabel>
                    <Select
                      labelId="interval"
                      id="interval-select"
                      value={selectedInterval}
                      onChange={this.changeInterval}
                    >
                      <MenuItem value={0}>No Interval</MenuItem>
                      <MenuItem value={60}>1 min</MenuItem>
                      <MenuItem value={300}>5 min</MenuItem>
                      <MenuItem value={600}>10 min</MenuItem>
                      <MenuItem value={900}>15 min</MenuItem>
                      <MenuItem value={1800}>30 min</MenuItem>
                      <MenuItem value={3600}>1 hour</MenuItem>
                      <MenuItem value={7200}>2 hour</MenuItem>
                      <MenuItem value={14400}>4 hour</MenuItem>
                      <MenuItem value={28800}>8 hour</MenuItem>
                      <MenuItem value={43200}>12 hour</MenuItem>
                      <MenuItem value={57600}>16 hour</MenuItem>
                      <MenuItem value={86400}>24 hour</MenuItem>
                    </Select> */}
                </FormControl>
              </Grid>
            </Grid>
          </form>
        </Paper>
        {/* </CardContent>
        </Card> */}
        <Paper className={classes.root}>
          {isSpinner ? (
            <CircularProgress
              className={classes.progress}
              color="primary"
              size={50}
              thickness={3}
            />
          ) : (
            <Grid container spacing={8} style={{ backgroundColor: '#EFEFEF' }}>
              <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
                <Grid container spacing={8}>
                  {leftPanel}
                </Grid>
              </Grid>
              {isC1Spinner ? (
                <CircularProgress
                  className={classes.progress}
                  color="primary"
                  size={50}
                  thickness={3}
                />
              ) : (
                <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                  <Grid container spacing={8}>
                    {Chart1Panel}
                  </Grid>
                  {this.state.tagList.length > 3 ? (
                    <Grid container spacing={8} direction="row">
                      {Chart2Panel}
                    </Grid>
                  ) : null}
                </Grid>
              )}
            </Grid>
          )}
        </Paper>
      </Wrapper>
    );
  }
}

GroupDisplay.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GroupDisplay);
