import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import withWidth from "@material-ui/core/withWidth";
import { withStyles } from "@material-ui/core/styles";
import TopbarStyles from "../../styles/Topbar";
import Wrapper from "../Wrapper/Wrapper";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import AlarmService from "../../services/alarms/alarm-service";
import { CustomizedSnackbars } from "../../shared/snackbar-service";
import "../../pages/AlarmActive/AlarmActive.css";
import ColumnResizer from "react-column-resizer";
import ringer from "./warning-alarm.wav";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import { color } from "highcharts";

class Topbar extends Component {
  constructor(props) {
    super(props);
    this.alarmService = new AlarmService();
    this.intervalId = 0;
    this.units = localStorage.getItem("User");
    this.units = JSON.parse(this.units)["unit_id"];
    this.hooterSett = "OFF";
    if(JSON.parse(localStorage.getItem("User"))["user_setting"])
       this.hooterSett = JSON.parse(localStorage.getItem("User"))["user_setting"].hooter;

    if(this.hooterSett === "ON") this.btnName = "Hooter OFF"
    else this.btnName = "Hooter ON"   
    this.flag = "";
    this.audio = new Audio(ringer);
    this.audio.loop = true;
    this.state = {
      alarms: [
        { name: "one" },
        { name: "two" },
        { name: "three" },
        { name: "four" },
        { name: "five" },
      ],
      // alarms: [],
      msgStatus: false,
      msgType: "",
      msgContent: "",
      unitIDs: this.units,
      leftLogo: "",
      rightLogo: "",
      alarmGen: true,
      dOpen: false, btnName: this.btnName,
    };
  }

  componentDidMount() {
    // alert(JSON.stringify(window.performance.navigation))
    // if (performance.navigation.type === 1) {
    //   console.log("This page is reloaded");
    //   this.flag = "reload";
    // }
    // this.unlisten = this.props.history.listen((location, action) => {
    //   alert("on route change");
    // });
    this.latestAlarms();
    this.getLeftLogo();
    this.getRightLogo();
    // setInterval(this.checkRefresh, 100)
    this.intervalId = setInterval(this.latestAlarms, 10000); // 5sec = 5000
  }

  checkRefresh() {
    // if (performance.navigation.type === 1) {
    //   console.log("This page is reloaded");
    //   this.flag = "reload";
    // }else{
    //   this.flag = '';

    // // alert(this.flag)
    // if (this.flag !== "reload"){
    //   // alert("Test")
    const handleTabClose = (event) => {
      event.preventDefault();

      // alert('beforeunload event triggered');
      localStorage.clear();

      // return (event.returnValue = 'Are you sure you want to exit?');
    };

    window.addEventListener("beforeunload", handleTabClose);

    return () => {
      // localStorage.clear();
      window.removeEventListener("beforeunload", handleTabClose);
    };
    // }
    // }
  }
  componentWillUnmount() {
    // this.unlisten();
    clearInterval(this.intervalId);
  }

  latestAlarms = () => {
    // alert('alarms...'+this.state.unitIDs) // ["unit_1"]

    let flag = false;
    let dummyAlarm = [
      { name: "one" },
      { name: "two" },
      { name: "three" },
      { name: "four" },
      { name: "five" },
    ];
    let reqParams = {
      unit_id: JSON.parse(this.state.unitIDs),
      no_of_records: 5,
    };
    this.alarmService.last5activealarms(reqParams).then((res) => {
      if (res.length > 0) {
        res.map((a, index) => {
          dummyAlarm[index] = a;
          // if (flag === 'false') {
          if (a.status === "ALM" && a.is_ack === "N") {
            flag = true;
          }
          // }
          // eqpObj.push({
          //   value: e,
          //   label: e
          // });
        });
        if (flag == true && this.state.alarmGen && this.hooterSett == "ON") {
          this.audio.loop = true;
          this.audio.play();
        } else {
          this.audio.loop = false;
        }
        this.setState({ alarms: dummyAlarm });
      } else {
        this.audio.loop = false;
        // this.setState({msgStatus: true, msgType:'error', msgContent: res, alarms: []});
        this.setState({
          alarms: [
            { name: "one" },
            { name: "two" },
            { name: "three" },
            { name: "four" },
            { name: "five" },
          ],
        });
      }
    });
    // var c = this.getCookie("userLoggedIn");
  };

  getLeftLogo = () => {
    this.alarmService.topLeftLogo().then((res) => {
      if (res && res.data) {
        const leftURL = window.URL.createObjectURL(new Blob([res.data]));
        this.setState({ leftLogo: leftURL });
      } else {
        this.setState({ leftLogo: "/static/images/advaitFull.png" });
      }
    });
  };

  getRightLogo = () => {
    this.alarmService.topRightLogo().then((res) => {
      if (res && res.data) {
        const rightURL = window.URL.createObjectURL(new Blob([res.data]));
        this.setState({ rightLogo: rightURL });
      } else {
        this.setState({ rightLogo: "/static/images/advaitFull.png" });
      }
    });
  };

  handleHooter = () => {
    this.state.alarmGen = !this.state.alarmGen;
    // if (this.state.alarmGen) {
    //   this.audio.loop = true;
    //   this.audio.play();
    // } else {
    //   this.audio.loop = false;
    // }
    if(this.state.alarmGen){
      this.setState({btnName: 'Hooter OFF', dOpen: false});
    }else this.setState({btnName: 'Hooter ON', dOpen: false});
       
    this.latestAlarms();
  };

  openDialog = () => {
    this.setState({ dOpen: true });
  };

  closeDialog = () => {
    this.setState({ dOpen: false });
  };

  render() {
    const { classes } = this.props;
    const { leftLogo, rightLogo, alarmGen, dOpen, btnName} = this.state;
    const leftPanel = (
      <Card style={{ marginTop: "3px", height: "120px" }}>
        <CardContent style={{ padding: "14px 1px" }}>
          <center>
            <img src={leftLogo} alt="logo" />
          </center>
        </CardContent>
      </Card>
    );
    const middlePanel = (
      <Paper>
        {/* {alarmGen ? <audio ref="audio_tag" src="/static/sound/bigbox.mp3" controls autoPlay/>: null} */}
        <div>
        {/* onContextMenu={this.handleHooter} */}
          <a onClick={this.openDialog}>
            <table className="alarmTable" style={{ tableLayout: "fixed" }} >
              <thead>
                <tr style={{ backgroundColor: "rgb(26 21 23)", color: "#FFF" }}>
                  <th style={{ width: "15%" }}>Date Time</th>
                  <ColumnResizer className="columnResizer" minWidth={0} />
                  <th style={{ width: "20%" }}>Tag Name</th>
                  <ColumnResizer className="columnResizer" minWidth={0} />
                  <th style={{ width: "20%" }}>Description</th>
                  <ColumnResizer className="columnResizer" minWidth={0} />
                  <th style={{ width: "15%" }}>Value</th>
                  <ColumnResizer className="columnResizer" minWidth={0} />
                  <th style={{ width: "10%" }}>Priority</th>
                  <ColumnResizer className="columnResizer" minWidth={0} />
                  <th style={{ width: "15%" }}>Unit Id</th>
                  <ColumnResizer className="columnResizer" minWidth={0} />
                  <th style={{ width: "5%" }}>Status</th>
                  <ColumnResizer className="columnResizer" minWidth={0} />
                  <th style={{ width: "5%" }}>Is Ack</th>
                </tr>
              </thead>
              <tbody>
                {this.state.alarms.length > 0 ? (
                  this.state.alarms.map((row) => {
                    return (
                      <tr
                        key={row._id}
                        className={classes.tr}
                        
                      >
                        <td
                          style={{ border: "1px solid #BCBCBC" }}
                          className={
                            row.status === "RTN"
                              ? "rtn"
                              : row.status === "ALM" && row.priority === "High"
                              ? "almhigh"
                              : row.status === "ALM" && row.priority === "Low"
                              ? "almLow"
                              : "almEmg"
                          }
                        >
                          {row.date_time}
                        </td>
                        <td />
                        <td
                          style={{ border: "1px solid #BCBCBC" }}
                          className={
                            row.status === "RTN"
                              ? "rtn"
                              : row.status === "ALM" && row.priority === "High"
                              ? "almhigh"
                              : row.status === "ALM" && row.priority === "Low"
                              ? "almLow"
                              : "almEmg"
                          }
                        >
                          {row.tag_name}
                        </td>
                        <td />
                        <td
                          style={{ border: "1px solid #BCBCBC" }}
                          className={
                            row.status === "RTN"
                              ? "rtn"
                              : row.status === "ALM" && row.priority === "High"
                              ? "almhigh"
                              : row.status === "ALM" && row.priority === "Low"
                              ? "almLow"
                              : "almEmg"
                          }
                        >
                          {row.alarm_desc}
                        </td>
                        <td />
                        <td
                          style={{ border: "1px solid #BCBCBC" }}
                          className={
                            row.status === "RTN"
                              ? "rtn"
                              : row.status === "ALM" && row.priority === "High"
                              ? "almhigh"
                              : row.status === "ALM" && row.priority === "Low"
                              ? "almLow"
                              : "almEmg"
                          }
                        >
                          {row.value}
                        </td>
                        <td />
                        <td
                          style={{ border: "1px solid #BCBCBC" }}
                          className={
                            row.status === "RTN"
                              ? "rtn"
                              : row.status === "ALM" && row.priority === "High"
                              ? "almhigh"
                              : row.status === "ALM" && row.priority === "Low"
                              ? "almLow"
                              : "almEmg"
                          }
                        >
                          {row.priority}
                        </td>
                        <td />
                        <td
                          style={{ border: "1px solid #BCBCBC" }}
                          className={
                            row.status === "RTN"
                              ? "rtn"
                              : row.status === "ALM" && row.priority === "High"
                              ? "almhigh"
                              : row.status === "ALM" && row.priority === "Low"
                              ? "almLow"
                              : "almEmg"
                          }
                        >
                          {row.unit_id}
                        </td>
                        <td />
                        <td
                          style={{ border: "1px solid #BCBCBC" }}
                          className={
                            row.status === "RTN"
                              ? "rtn"
                              : row.status === "ALM" && row.priority === "High"
                              ? "almhigh"
                              : row.status === "ALM" && row.priority === "Low"
                              ? "almLow"
                              : "almEmg"
                          }
                        >
                          {row.status}
                        </td>
                        <td />
                        <td
                          style={{ border: "1px solid #BCBCBC" }}
                          className={
                            row.is_ack === "N" && row.status === "RTN"
                              ? "rtnBlink"
                              : row.is_ack === "N" &&
                                row.status === "ALM" &&
                                row.priority === "High"
                              ? "almHighBlink"
                              : row.is_ack === "N" &&
                                row.status === "ALM" &&
                                row.priority === "Low"
                              ? "almLowBlink"
                              : row.is_ack === "N" &&
                                row.status === "ALM" &&
                                row.priority === "Emergency"
                              ? "almEmgBlink"
                              : row.is_ack === "Y" && row.status === "RTN"
                              ? "rtn"
                              : row.is_ack === "Y" &&
                                row.status === "ALM" &&
                                row.priority === "High"
                              ? "almhigh"
                              : row.is_ack === "Y" &&
                                row.status === "ALM" &&
                                row.priority === "Low"
                              ? "almLow"
                              : "almEmg"
                          }
                        >
                          {row.is_ack}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <p>
                    <center>No data found!</center>
                  </p>
                )}
              </tbody>
            </table>
          </a>
        </div>
      </Paper>
    );
    const rightPanel = (
      <Card style={{ marginTop: "3px", height: "120px" }}>
        <CardContent style={{ padding: "14px 1px" }}>
          <center>
            <img src={rightLogo} alt="logo" />
          </center>
        </CardContent>
      </Card>
    );
    return (
      <Wrapper>
        
        <Dialog
          open={dOpen}
          onClose={this.closeDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Options</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            <FormControl style={{ float: "left", marginRight: "15px" }}>

                <a href="/alarmActive" class="buttonA js-buttonA"
                  style={{ backgroundColor: 'rgb(0, 153, 204' }} role="button">Realtime Alarms</a>
              </FormControl>

              <FormControl style={{ float: "right", color:"white"}}>

                <a onClick={this.handleHooter} class="buttonA js-buttonA"
                  style={{ backgroundColor: 'rgb(0, 153, 204' }} role="button">{btnName}</a>
              </FormControl>
            </DialogContentText>
          </DialogContent>
       
        </Dialog>

        {this.state.msgStatus ? (
          <CustomizedSnackbars
            opened={this.state.msgStatus}
            snackType={this.state.msgType}
            snackMessage={this.state.msgContent}
          ></CustomizedSnackbars>
        ) : null}
        {/* <Grid item xs={12} sm={6} md={6} lg={4}>{leftPanel}</Grid> */}
        <Grid
          container
          spacing={8}
          style={{ backgroundColor: "#eee", border: "1px solid #bbb" }}
        >
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
            {leftPanel}
          </Grid>
          <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
            {middlePanel}
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
            {rightPanel}
          </Grid>
          {/* <IconButton
            color="inherit"
            onClick={this.handleHooter}
            title="Top Menu Settings"
          >
            <SettingsIcon />
          </IconButton> */}
        </Grid>
      </Wrapper>
    );
  }
}

Topbar.prototypes = {
  classes: PropTypes.object.isRequired,
  opened: PropTypes.func,
  toggleDrawer: PropTypes.func,
  closeDrawer: PropTypes.func,
  openDrawer: PropTypes.func,
  routes: PropTypes.object,
};

const TopbarWithRouter = withRouter(Topbar);

export default withStyles(TopbarStyles)(withWidth()(TopbarWithRouter));
