import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Wrapper } from '../../components';
import Paper from '@material-ui/core/Paper';
import ReportService from '../../services/report/report-service';
import { CustomizedSnackbars } from '../../shared/snackbar-service';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import VirtualizedSelect from 'react-virtualized-select';
import { Card } from '@material-ui/core';
import 'react-select/dist/react-select.css';
import 'react-virtualized/styles.css';
import 'react-virtualized-select/styles.css';
import * as FileSaver from 'file-saver';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { OutTable, ExcelRenderer } from 'react-excel-renderer';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Toolbar from '@material-ui/core/Toolbar';
import InputAdornment from '@material-ui/core/InputAdornment';
import GetAppIcon from '@material-ui/icons/GetApp';

//*** Toolbar-starts here */
const toolbarStyles = (theme) => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
});

let EnhancedTableToolbar = (props) => {
  const { classes, value, handleSearch } = props;

  return (
    <Toolbar className={classes.root}>
      <div className={classes.title}>
        <Typography variant="title" id="tableTitle">
          Reports
        </Typography>
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        <TextField
          placeholder="Search"
          onChange={handleSearch}
          value={value}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon>search</Icon>
              </InputAdornment>
            ),
          }}
          style={{ float: 'right' }}
        />
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);
//*** Toolbar-ends here */

const actionsStyles = (theme) => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 2.5,
  },
});

class TablePaginationActions extends React.Component {
  handleFirstPageButtonClick = (event) => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = (event) => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1)
    );
  };

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowRight />
          ) : (
              <KeyboardArrowLeft />
            )}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowLeft />
          ) : (
              <KeyboardArrowRight />
            )}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
};

const TablePaginationActionsWrapped = withStyles(actionsStyles, {
  withTheme: true,
})(TablePaginationActions);

const styles = (theme) => ({
  root: {
    width: '100%',
    height: '100%',
    marginTop: theme.spacing.unit * 1,
  },
  table: {
    minWidth: 300,
    width: '50%',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  progress: {
    marginLeft: '50%',
    position: 'absolute',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingBottom: '5px',
  },
  textField: {
    marginLeft: theme.spacing.unit * 1,
    marginRight: theme.spacing.unit * 1,
    width: 218,
  },
  textField2: {
    marginLeft: theme.spacing.unit * 1,
    marginRight: theme.spacing.unit * 1,
    width: 350,
  },
  textField3: {
    marginLeft: theme.spacing.unit * 1,
    marginRight: theme.spacing.unit * 1,
    width: 150,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  title: {
    flex: '0 0 auto',
    padding: '10px',
  },
});

var divStyle = {
  paddingLeft: '22px',
  paddingTop: '10px',
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class ReportDownload extends Component {
  constructor(props) {
    super(props);
    this.reportService = new ReportService();
    let dt = new Date();
    let currYear = dt.getFullYear();
    this.montArr = ["January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"];
    this.month = this.montArr[dt.getMonth()];
    this.day = dt.getDate();
    // if (this.month.toString().length < 2) this.month = '0' + this.month;
    // if (this.day.toString().length < 2) this.day = '0' + this.day;
    // alert(this.month)
    let tm =
      ('0' + dt.getHours()).slice(-2) + ':' + ('0' + dt.getMinutes()).slice(-2);
    dt = dt.toISOString().split('T')[0];
    console.log(dt + '   ' + tm);
    this.years = []
    for (let i = 2022; i <= currYear; i++) {
      this.years.push({
        value: i,
        label: i
      })
    }
    this.units = localStorage.getItem('User');
    this.units = JSON.parse(JSON.parse(this.units)['unit_id']);
    const unitIds = this.units.map((u) => {
      return Object.assign(
        {},
        {
          value: u,
          label: u,
        }
      );
    });
    this.state = {
      tempList: [],
      selectedTemplate: '',
      reportName: '',
      mainTempList: [],
      selectedFrom: dt + 'T00:00',
      selectedTo: dt + 'T' + tm,
      showSheet: false,
      dataLoaded: false,
      cols: null,
      reportType: [
        { label: 'DPR', value: 'DPR' }, { label: 'COMPRESSOR', value: 'COMPRESSOR' }
      ],
      selectedType: 'DPR',
      unitList: unitIds,
      selectedUnit: this.units[0],
      years: this.years,
      selectedYear: this.years[0].label,
      months: [{ label: 'January', value: 'January' }, { label: 'February', value: 'February' }, { label: 'March', value: 'March' }, { label: 'April', value: 'April' }, { label: 'May', value: 'May' }, { label: 'June', value: 'June' }, { label: 'July', value: 'July' }, { label: 'August', value: 'August' }, { label: 'September', value: 'September' }, { label: 'October', value: 'October' }, { label: 'November', value: 'November' }, { label: 'December', value: 'December' }],
      selectedMonth: this.month,
      days: [],
      selectedDay: '',
      buttonDisable: false,
      rows: [],
      sRows: [],
      page: 0,
      rowsPerPage: 10,
      order: 'desc',
      orderBy: 'date_time',
      searchValue: '',
    };
    // this.renderFile = this.renderFile.bind(this);
  }

  componentDidMount() {
    this.getDays();
    this.onSubmit();
    // this.getUnitList();
  }

  componentWillUnmount() { }

  closeSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ msgStatus: false });
  };


  handleChangePage = (event, page) => {
    this.setState({ page: page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  getDays() {
    let days = [];
    for (let i = 1; i <= 31; i++) {

      // if (i.toString().length < 2) days.push({ label: '0' + i, value: '0' + i });
      // else 
      days.push({ label: i, value: i })
    }
    this.setState({ days: days, selectedDay: this.day })
  }

  getUnitList() {
    this.state.unitList = [];
    if (JSON.parse(localStorage.getItem('User')).user_role == 'TAS_ADMIN') {
      this.reportService.getUnit().then(
        (result) => {
          // this.state.unitList = JSON.parse(result.unit_id);
          JSON.parse(result.unit_id).map((r) => {
            this.state.unitList.push({
              label: r,
              value: r,
            });
          });
          this.state.selectedUnit = this.state.unitList[0].label;
          // this.getTemplateByUnit();
        },
        (error) => { }
      );
    } else {
      var units = JSON.parse(
        JSON.parse(localStorage.getItem('User'))['unit_id']
      );
      units.map((u) => {
        this.state.unitList.push({ label: u, value: u });
      });
      this.state.selectedUnit = units[0];
      // this.getAssetByUnit();
      // alert(JSON.stringify(this.state.unitList));
    }
  }




  onChangeType = (event) => {
    // alert(JSON.stringify(event));
    this.state.selectedType = event;
    this.setState({ selectedType: event });
  };

  onChangeUnit = (event) => {
    this.state.selectedUnit = event;
    this.setState({ selectedUnit: event });
    // this.getAssetByUnit();
  };

  onChangeYear = (event) => {
    this.state.selectedYear = event;
    this.setState({ selectedYear: event });
    // this.getAssetByUnit();
  };


  onChangeMonth = (event) => {
    this.state.selectedMonth = event;
    this.setState({ selectedMonth: event });
    // this.getAssetByUnit();
  };

  onChangeDay = (event) => {
    this.state.selectedDay = event;
    this.setState({ selectedDay: event });
    // this.getAssetByUnit();
  };

  onSubmit = () => {
    if (this.state.selectedUnit == '') {
      this.setState({
        msgStatus: true,
        msgType: 'warning',
        msgContent: 'Unit ID can not empty !!!',
        isSpinner: false,
      });
    } else {
      this.state.isSpinner = true;
      let req;
      
      if (this.state.selectedType === "DPR"){
        req = { "unit_id": "Report/"+this.state.selectedUnit + "/" + this.state.selectedYear + "/" + this.state.selectedMonth.toUpperCase() }
      }else if (this.state.selectedType === "COMPRESSOR"){
        req = { "unit_id": "COMP_REP/"+this.state.selectedUnit + "/" + this.state.selectedYear + "/" + this.state.selectedMonth.toUpperCase()+ "/"+ this.state.selectedDay }
      }
      
      // alert(JSON.stringify(req))
      this.reportService.getFilePath(req).then((res) => {
        console.log('Response from server: ' + JSON.stringify(res));

        if (res.length > 0) {
          this.setState({ rows: res, sRows: res, isSpinner: false });
        } else {
          this.setState({
            msgStatus: true,
            msgType: 'error',
            msgContent: 'No data found!',
            rows: [],
            isSpinner: false,
          });
        }
      });

    }
  };

  handleSearch = (event) => {
    const searchInput = event.target.value;
    const { sRows } = this.state;
    let filteredDatas = [];
    filteredDatas = sRows.filter((e) => {
      return (
        e.date_time.toString().includes(searchInput.toLowerCase()) ||
        e.user_email.toLowerCase().includes(searchInput.toLowerCase()) ||
        e.event.toLowerCase().includes(searchInput.toLowerCase()) ||
        e.ip_address.toLowerCase().includes(searchInput.toLowerCase()) ||
        e.port.toString().includes(searchInput.toLowerCase())
      );
    });
    this.setState({ rows: filteredDatas, searchValue: event.target.value });
  };

  downloadReport = (filePath) => {
    // alert(filePath)
    let req = { file_path: filePath }
    // alert(JSON.stringify(req))
    this.reportService.downloadReport(req).then((res) => {
      console.log('Response from server: ' + JSON.stringify(res));
      if (res == {}){
        
        this.setState({
          msgStatus: true,
          msgType: 'error',
          msgContent: 'Something went wromg.',
          isSpinner: false,
        });
      } else {
        var data;
        data = new Blob([res], {
          type:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        var objectUrl = URL.createObjectURL(data);
        // this.state.isSpinner = false;
        FileSaver.saveAs(data, filePath);
      }
    });
  };

  render() {
    console.log('.. in render..');
    const { classes, defaultOptions, placeholder, inputId } = this.props;
    const {
      isSpinner,
      errorStyle,
      errorStyleU,
      hasError,
      unitList,
      selectedUnit,
      years, selectedYear, months, selectedMonth, days, selectedDay,
      rowsPerPage,
      page,
      rows,
      searchValue,
      buttonDisable, reportType, selectedType
    } = this.state;

    // alert(dataLoaded + ' -- ' + JSON.stringify(unitList));
    return (
      <Wrapper>
        {/* <Card>
          <CardContent style={{ padding:'10px' }}> */}


        <Paper className={classes.root}>
          {this.state.msgStatus ? (
            <CustomizedSnackbars
              opened={this.state.msgStatus}
              snackType={this.state.msgType}
              snackMessage={this.state.msgContent}
              closed={this.closeSnackBar}
            ></CustomizedSnackbars>
          ) : null}
          <div className={classes.title}>
            <Typography variant="title" id="tableTitle">
              Report Filter
            </Typography>
          </div>
          <form className={classes.container}>
            <div className="row" style={divStyle}>

            <FormControl className={classes.textField}>
                <Typography style={errorStyleU}>
                  Report Type <sup>*</sup>
                </Typography>

                <VirtualizedSelect
                  ref="reportType"
                  options={reportType}
                  simpleValue
                  clearable={false}
                  name="select-reportType"
                  value={selectedType}
                  onChange={(event) => this.onChangeType(event)}
                  searchable
                />
              </FormControl>

              <FormControl className={classes.textField}>
                <Typography style={errorStyleU}>
                  Unit ID <sup>*</sup>
                </Typography>

                <VirtualizedSelect
                  ref="unitList"
                  options={unitList}
                  simpleValue
                  clearable={false}
                  name="select-unitList"
                  value={selectedUnit}
                  onChange={(event) => this.onChangeUnit(event)}
                  searchable
                />
                {hasError && (
                  <FormHelperText style={errorStyleU}>
                    Unit is required!
                  </FormHelperText>
                )}
              </FormControl>


              <FormControl className={classes.textField}>
                <Typography style={errorStyleU}>
                  Year <sup>*</sup>
                </Typography>

                <VirtualizedSelect
                  ref="year"
                  options={years}
                  simpleValue
                  clearable={false}
                  name="select-year"
                  value={selectedYear}
                  onChange={(event) => this.onChangeYear(event)}
                  searchable
                />
              </FormControl>
              <FormControl className={classes.textField}>
                <Typography style={errorStyleU}>
                  Month <sup>*</sup>
                </Typography>

                <VirtualizedSelect
                  ref="months"
                  options={months}
                  simpleValue
                  clearable={false}
                  name="select-month"
                  value={selectedMonth}
                  onChange={(event) => this.onChangeMonth(event)}
                  searchable
                />
              </FormControl>
              {selectedType === "COMPRESSOR" ? <FormControl className={classes.textField}>
                <Typography style={errorStyleU}>
                  Day <sup>*</sup>
                </Typography>

                <VirtualizedSelect
                  ref="days"
                  options={days}
                  simpleValue
                  clearable={false}
                  name="select-day"
                  value={selectedDay}
                  onChange={(event) => this.onChangeDay(event)}
                  searchable
                />
              </FormControl>: null}
              <FormControl>
                <Typography>&nbsp;</Typography>
                <Button
                  disabled={buttonDisable}
                  color="primary"
                  variant="contained"
                  size="small"
                  title="Get Reports"
                  onClick={this.onSubmit}
                  style={{ backgroundColor: 'rgb(0, 153, 204' }}
                >
                  Get Reports
                </Button>
              </FormControl>
            </div>
          </form>
        </Paper>


        {/* <Paper className={classes.root}>
          {isSpinner ? (
            <CircularProgress
              className={classes.progress}
              color="primary"
              size={50}
              thickness={3}
            />
          ) : null}
        </Paper> */}

        <Paper className={classes.root}>
          {/* <EnhancedTableToolbar
            handleSearch={(event) => this.handleSearch(event)}
            value={searchValue}
          /> */}
          <div className={classes.tableWrapper}>
            <Table style={{width:'50%'}}>
              <TableHead>
                <TableRow>
                  <TableCell>S/N</TableCell>
                  <TableCell>File Path</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              {isSpinner ? (
                <CircularProgress
                  className={classes.progress}
                  color="primary"
                  size={50}
                  thickness={3}
                />
              ) : (
                  <TableBody>
                    {/* slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage). */}
                    {rows.length > 0 ? (
                      rows
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, i) => {
                          return (
                            <TableRow key={row} style={{ fontSize: '16px' }}>
                              <TableCell>{i + 1}</TableCell>
                              <TableCell>{row.split('*')[1].split('/').pop()}</TableCell>
                              <TableCell><IconButton onClick={(e) => this.downloadReport(row.split('*')[1])}>
                                <GetAppIcon color="primary" />
                              </IconButton></TableCell>
                            </TableRow>
                          );
                        })
                    ) : (
                        <p>
                          <center>No data found!</center>
                        </p>
                      )}
                    {/* {emptyRows > 0 && (
                  <TableRow style={{ height: 48 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
                  </TableBody>
                )}
              <TableFooter>
                <TableRow>
                  <TablePagination
                    colSpan={3}
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActionsWrapped}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </div>
        </Paper>
      </Wrapper>
    );
  }
}

ReportDownload.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ReportDownload);

/******** Pagination for Table */
