//////////////////////////////////////////////////////////////////////////////
// Extends HMIPageBase with functionality to handle pages that need to handle
// tag-based data updates, but do not need any other special handling.
//////////////////////////////////////////////////////////////////////////////

import { HMIPageBase } from './scada_hmi_page_base';

// export function DefaultHMIPage(){}

export class DefaultHMIPage extends HMIPageBase {
  //  constructor(PageType, RandomData, AERATION_PAGE, CIRCUIT_PAGE, RT_CHART_PAGE, TEST_COMMANDS_PAGE){
  //      this.PageType = PageType;
  //      this.RandomData = RandomData;
  //      this.AERATION_PAGE = AERATION_PAGE; this.CIRCUIT_PAGE = CIRCUIT_PAGE; this.RT_CHART_PAGE = RT_CHART_PAGE; this.TEST_COMMANDS_PAGE = TEST_COMMANDS_PAGE;
  //  }
  constructor() {
    super();
  }
  // DefaultHMIPage.prototype = Object.create( HMIPageBase.prototype );
  // DefaultHMIPage.prototype.constructor = DefaultHMIPage;

  // Returns an update interval in msec for animating drawing with data.
  GetUpdateInterval /* int */() {
    switch (this.PageType) {
      //  case this.AERATION_PAGE:      return 2000;
      //  case this.CIRCUIT_PAGE:       return 1000;
      //  case this.RT_CHART_PAGE:      return 30;
      case this.TEST_COMMANDS_PAGE:
        return 3000;
      default:
        return 5000; /* Use default update interval. */
    }
  }

  //////////////////////////////////////////////////////////////////////////////
  // Returns true if tag sources need to be remapped for the page.
  //////////////////////////////////////////////////////////////////////////////
  NeedTagRemapping /* boolean */() {
    // In demo mode, unset tags need to be remapped to enable animation.
    if (this.RandomData) return true;
    else return false; // Remap tags only if necessary.
  }

  //////////////////////////////////////////////////////////////////////////////
  // Reassign TagSource parameter for a given tag object to a new
  // TagSource value. tag_source and tag_name parameters are the current
  // TagSource and TagName of the tag_obj.
  //////////////////////////////////////////////////////////////////////////////
  RemapTagObject(
    /* GlgObject */ tag_obj,
    /* String */ tag_name,
    /* String */ tag_source, asset_id
  ) {
    // alert(Viewport)
    if (this.RandomData) {
      // Skip tags with undefined TagName.
      if (this.IsUndefined(tag_name)) return;

      /* In demo mode, assign unset tag sources to be the same as tag names
         to enable animation with demo data.
      */
      if (this.IsUndefined(tag_source)) this.AssignTagSource(tag_obj, tag_name);
    } else {
      // Assign new TagSource as needed.
      // AssignTagSource( tag_obj, new_tag_source );
      // console.log("tag test "+tag_source)
      if ((tag_source.startsWith("*"))) {
        var tag_name1 = tag_obj.GetSResource("TagName");
          //  alert("tag_name1 >cgggggggggggggggggggggggggggggggggggggggggg> " + JSON.stringify(tag_source));
        var new_tag_source = tag_name1;
        // alert(tag_name)
        try {
            //System.err.println(" >> " + symbolTag.get("*"));
            new_tag_source = tag_source.replace("*", asset_id);
        } catch (e) {
            // System.err.println(" >> " + "*");
        }
        // alert(JSON.stringify(new_tag_source));
        this.AssignTagSource(tag_obj, new_tag_source);
      }
    }
  }
  IsUndefined(/* String */ str /* boolean */) {
    if (str == null || str.length == 0 || str == 'unset' || str == '$unnamed')
      return true;

    return false;
  }
  AssignTagSource(/* GlgObject */ tag_obj, /* String */ new_tag_source) {
    tag_obj.SetSResource('TagSource', new_tag_source);
  }
}


