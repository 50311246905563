import React, { Component } from 'react';
// import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import MapService from '../../services/map/map-service';
import Button from '@material-ui/core/Button';
import './GeoMap.css';
import { withStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import { NavLink } from 'react-router-dom';
import { Card, FormGroup, Grid } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import SearchIcon from '@material-ui/icons/Search';
import SettingsIcon from '@material-ui/icons/Settings';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CustomizedSnackbars } from '../../shared/snackbar-service';
import Typography from '@material-ui/core/Typography';
// import Select from 'react-select';
import VirtualizedSelect from 'react-virtualized-select';
import 'react-select/dist/react-select.css';
import 'react-virtualized/styles.css';
import 'react-virtualized-select/styles.css';
import Paper from '@material-ui/core/Paper';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import Iframe from 'react-iframe';
import { Wrapper } from '../../components';
import { makeStyles } from '@material-ui/core/styles';

import FilterListIcon from '@material-ui/icons/FilterList';
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
import SelectField from 'material-ui/SelectField';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { deepOrange500 } from 'material-ui/styles/colors';
import GoogleMapReact from 'google-map-react';
import Multiselect from 'multiselect-react-dropdown';
import Drawer from 'react-modern-drawer';

//import styles ?
import 'react-modern-drawer/dist/index.css';
import { default as ReactSelect } from 'react-select';
import { components } from 'react-select';
import Select from '@material-ui/core/Select';


import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const Marker1 = (props) => (
  <React.Fragment>
    <div
      style={{
        border: '5px solid white',
        borderRadius: 20,
        height: 20,
        width: 20,
      }}
    />
    {/* Below is info window component */}
    {props.show && (
      <div
        style={{
          width: 100,
          height: 100,
        }}
      >
        Info window
      </div>
    )}
  </React.Fragment>
);

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{' '}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const muiTheme = getMuiTheme({
  palette: {
    accent1Color: deepOrange500,
  },
});
// const useStyles = makeStyles({
//   paper: {
//     position: 'absolute',
//     left: 0,
//     bottom: 0,
//   },
// });

const styles = (theme) => ({
  searchForm: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  button: {
    margin: theme.spacing.unit,
    float: 'right',
    zIndex: 9999,
  },
  textField: {
    marginLeft: theme.spacing.unit * 1,
    marginRight: theme.spacing.unit * 1,
    minWidth: 180,
  },
  root: {
    width: '100%',
    height: '100%',
    marginTop: theme.spacing.unit * 1,
  },
  table: {
    minWidth: 500,
    width: '100%',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  progress: {
    marginLeft: '50%',
    position: 'absolute',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingBottom: '5px',
  },
  textField: {
    marginLeft: theme.spacing.unit * 1,
    marginRight: theme.spacing.unit * 1,
    width: 218,
  },
  textField2: {
    marginLeft: theme.spacing.unit * 1,
    marginRight: theme.spacing.unit * 1,
    width: 350,
  },
  textField3: {
    marginLeft: theme.spacing.unit * 1,
    marginRight: theme.spacing.unit * 1,
    width: 150,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },

  formControl: {
    width: 420,
  },
  indeterminateColor: {
    color: '#f50057',
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
});

const containerStyle = {
  width: '400px',
  height: '400px',
};

var divStyle = {
  paddingLeft: '20px',
  paddingTop: '15px',
  fontSize: '11px',
};


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  variant: 'menu',
};

// const GeoMap = () => (
class GeoMap extends Component {
  static defaultProps = {
    center: {
      lat: 20.5937,
      lng: 78.9629,
    },
    zoom: 5,

    formControl: {
      width: 820,
    },
    indeterminateColor: {
      color: '#f50057',
    },
    selectAllText: {
      fontWeight: 500,
    },
    selectedAll: {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
      },
    },
  };
  constructor(props) {
    // const useClass = useStyles();
    // this.isLoaded1 = useJsApiLoader({
    //   id: 'google-map-script',
    //   googleMapsApiKey: 'AIzaSyAvX4wr-12hW9oIk7UEyhfU9z0IasWgTwg',
    // });

    // const onUnmount = React.useCallback(function callback(map) {}, []);

    const path = [
      { lat: -3.855407, lng: -38.465306 },
      { lat: -3.834648, lng: -38.46047 },
    ];

    const option = { closeBoxURL: '', enableEventPropagation: true };

    const options = {
      strokeColor: '#00ff00',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#FFf000',
      fillOpacity: 0.35,
      clickable: false,
      draggable: false,
      editable: false,
      visible: true,
      radius: 30000,
      paths: [
        { lat: -3.855407, lng: -38.465306 },
        { lat: -3.834648, lng: -38.46047 },
      ],
      zIndex: 1,
    };

    super(props);
    this.perm = localStorage.getItem('User');
    this.units = JSON.parse(JSON.parse(this.perm)['unit_id']);
    this.mapService = new MapService();
    this.map = [];
    this.maps = [];
    this.state = {
      markers: [],
      sLocation: '',
      mapCenter: {
        lat: 20.5937,
        lng: 78.9629,
      },
      zoom: 7,
      openModal: false,
      sMaptype: 'StreetMapLight',
      dbMapType: 'StreetMapLight',
      sLat: 20.5937,
      sLong: 78.9629,
      sZoom: 5,
      msgStatus: false,
      msgType: '',
      msgContent: '',
      hasError: false,
      errorStyle: {},
      openSearchModal: false,
      user_perm: JSON.parse(this.perm)['permissions'],
      mapURL: '',
      serNoList: [],
      selectedSerNo: [],
      custList: [],
      selectedCust: [],
      // stateList: [{ label: 'ALL', value: 'ALL' }],
      selectedState: [],
      regionList: [],
      selectedRegion: [],
      engineerList: [],
      selectedEngineer: [],
      typeList: [],
      selectedType: [],
      articleNoList: [],
      selectedAcrticleNo: [],
      map: null,
      isLoaded: null,
      svgMarker: {
        fillColor: 'green',
        fillOpacity: 0.8,
        strokeWeight: 0,
        rotation: 0,
        scale: 2,
      },
      show: false,
      mapData: [],
      showInfoWindow: false,
      activeMarker: null,
      selectedMarker: {},
      selectedRegion1: ["WEST", "NORTH"],
      isOpen: false,
      value: 15,
      checkedAll: true,
      amount: 15,
      optionSelected: null,

      stateSelected: [],
      isAllSelected: false,
      stateList: [],
      customerSelected: [],
      citySelected: [],
      engineerSelected: [],
      typeSelected: [],
      articleNoSelected: [],
      serialNoSelected: [],
      region1List: ["WEST", "NORTH"],
      regionSelected: ["WEST", "NORTH"],
      test: false

    };
  }

  _onChildClick = (key, childProps) => {
    this.setState({ show: !this.state.show });
  };

  markerClicked(marker, element) {
    // alert(element.lat);
    this.setState({
      showInfoWindow: true,
      activeMarker: marker,
      selectedMarker: element,
    });
  }

  componentWillMount() {
    // this.state.isLoaded = useJsApiLoader({
    //   id: 'google-map-script',
    //   googleMapsApiKey: 'AIzaSyAvX4wr-12hW9oIk7UEyhfU9z0IasWgTwg',
    // });
    // this.onLoad();
    // this.state.isLoaded = this.isLoaded1;
    this.getAssetFields('MACHINE_SR_NO');
    this.getAssetFields('CUSTOMER_NAME');
    this.getAssetFields('STATE');
    this.getAssetFields('CITY');
    this.getAssetFields('FSE');
    this.getAssetFields('ARTICLE_NO');
    this.getAssetFields('DRIVE_TYPE');

    setTimeout(
      this.getMapAsset,
      4000
    );
    //   setTimeout(
    //     function(){
    //       this.state.test = true;
    //     },
    // 4005
    // );
    // this.getMapAsset();
    // this.getMapURL();
    // this.getMapSetting();
  }

  componentDidMount() {
    //     setTimeout(
    //       this.getMapAsset,
    //   4000
    // );
    // this.addControls();
    // this.getMapSetting();
    // this.getMarkers();
    // setInterval(() => {
    // this.getMarkers();
    // }, 10000)
  }

  addControls = () => {
    var control = document.createElement('div');
    control.index = 1;
    this.Map.controls[window.google.maps.ControlPosition.TOP_RIGHT].push(
      control
    );
  };
  getAssetFields = (type) => {
    // alert(JSON.stringify(this.units));
    let req = {
      key: type,
      unit_id: JSON.stringify(this.units),
    };
    this.mapService.getAssetFields(req).then((res) => {

      if (res.length > 0) {
        if (type == 'MACHINE_SR_NO') {
          this.state.serNoList = res;
          this.state.serialNoSelected = res;
          this.state.selectedSerNo = res;
          this.getMapAsset();
          // res.map((t) => {
          //   this.state.serNoList.push({ label: t, value: t });
          // });
        } else if (type == 'CUSTOMER_NAME') {
          this.state.custList = res;
          this.state.customerSelected = res;
          this.state.selectedCust = res;
          this.getMapAsset();
          // res.map((t) => {
          //   this.state.custList.push({ label: t, value: t });
          // });
        } else if (type == 'STATE') {
          this.state.stateList = res;
          this.state.stateSelected = res;
          this.state.selectedState = res;
          this.getMapAsset();
          // res.map((t) => {

          //   this.state.stateList.push({ label: t, value: t });
          // });
        } else if (type == 'CITY') {
          this.state.regionList = res;
          this.state.citySelected = res;
          this.state.selectedRegion = res;
          // res.map((t) => {
          //   this.state.regionList.push({ label: t, value: t });
          // });
        } else if (type == 'FSE') {
          this.state.engineerList = res;
          this.state.engineerSelected = res;
          this.state.selectedEngineer = res;
          this.getMapAsset();
          // res.map((t) => {
          //   this.state.engineerList.push({ label: t, value: t });
          // });
        } else if (type == 'ARTICLE_NO') {
          this.state.articleNoList = res;
          this.state.articleNoSelected = res;
          this.state.selectedAcrticleNo = res;
          this.getMapAsset();
          // console.log(JSON.stringify(res));
          // res.map((t) => {
          //   this.state.articleNoList.push({ label: t, value: t });
          // });
        } else if (type == 'DRIVE_TYPE') {
          this.state.typeList = res;
          this.state.typeSelected = res;
          this.state.selectedType = res;


          this.getMapAsset();
          // console.log(JSON.stringify(res));
          // res.map((t) => {
          //   this.state.articleNoList.push({ label: t, value: t });
          // });
        }
      }
    });
  };

  // getMapAssetFilter = () => {
  //   this.state.markers = [];
  //   this.getEngineerAssets();
  //   let req = {
  //     filter: {
  //       MACHINE_SR_NO: this.state.selectedSerNo,
  //       CUSTOMER_NAME: this.state.selectedCust,
  //       STATE: this.state.selectedState,
  //       CITY: this.state.selectedRegion,
  //       FSE: this.state.selectedEngineer,
  //       ARTICLE_NO: this.state.selectedAcrticleNo,
  //       ZONE: this.state.selectedRegion1,
  //     },
  //     unit_id: JSON.stringify(this.units),
  //   };
  //   console.log('Req ' + JSON.stringify(req));
  //   this.mapService.getMapAsset(req).then((res) => {
  //     console.log('Res Marker' + JSON.stringify(res));
  //     this.setState({ mapData: res });
  //     let testMark = []
  //     // this.ModelsMap(this.map, this.maps);
  //     res.map((t) => {
  //     // for (let i = 0; i < res.length; i++) {
  //       this.state.markers.push({
  //         key1: t._id, 
  //         key: t._id,
  //         serial_no: t.field_data.MACHINE_SR_NO,
  //         lat: parseInt(t.field_data.LATITUDE),
  //         lng: parseInt(t.field_data.LONGITUDE),
  //         station: t.field_data.STATION,
  //         status: t.field_data.MACHINE_STATUS,
  //         type: t.field_data.DRIVE_TYPE,
  //         customer_name: t.field_data.CUSTOMER_NAME,
  //         associate_disp: t.ASS_DISP,
  //         icon: 'blue.png',
  //       });
  //       // alert(JSON.stringify(this.state.markers));
  //     })

  //     // this.state.markers.push({
  //     //   key: 'engineer',
  //     //   lat: 18.5204,
  //     //   lng: 73.8567,
  //     //   icon: 'serv_eng.png',
  //     //   email: 'yogesh.patil@tasind.com',
  //     //   contact: '5255252525',
  //     //   name: 'TEST_USER',
  //     // });
  //     // if (res && res._id) {
  //     //   // alert('map is there...')
  //     //   this.setState({
  //     //     mapURL: res.static_map_url,
  //     //   });
  //     // }
  //   });
  // };

  getMapAsset = () => {
    this.state.markers = [];
    this.getEngineerAssets();
    let req = {
      filter: {
        MACHINE_SR_NO: this.state.selectedSerNo,
        CUSTOMER_NAME: this.state.selectedCust,
        STATE: this.state.selectedState,
        CITY: this.state.selectedRegion,
        FSE: this.state.selectedEngineer,
        ARTICLE_NO: this.state.selectedAcrticleNo,
        ZONE: this.state.selectedRegion1,
      },
      unit_id: JSON.stringify(this.units),
    };
    this.mapService.getMapAsset(req).then((res) => {
      this.setState({ mapData: res });
      let testMark = []
      // this.ModelsMap(this.map, this.maps);
      res.map((t) => {

        let markIcon = 'green_marker.png';
        let compStatus = "RUNNING";
        let sucPress = '-';
        let disPress = '-';
        let showAlm = false;
        // for (let i = 0; i < res.length; i++) {
        let tagData = t.tag_data;
        // let regAdd = [];
        // for (let i = 0; i < tagData.length; i++) {
        //   // let keys = Object.keys(tagData[i]);
        //   // alert(tagData[i].tag_name)
        //   if(tagData[i].tag_name == "SUNCTION_FLOW" || tagData[i].tag_name == "DISCHARGE_FLOW"){
        //      regAdd.push(tagData[i].register_address);
        //      if(regAdd.length == 2){ break; }
        //   }
        // }
        // if(regAdd.length > 0){
        //   this.mapService.liveData(regAdd).then((regRes) => {
        //     if (regRes) {
        //       if(regRes["SUNCTION_FLOW"] != undefined){
        //          sucPress = regRes["SUNCTION_FLOW"]
        //       }
        //       if(regRes["DISCHARGE_FLOW"] != undefined){
        //         disPress = regRes["DISCHARGE_FLOW"]
        //      }
        //     }
        //   })
        // }

        let regAdd = { "operation": "get_asset_live_value", "asset_list": [t.asset_id] }
        this.mapService.getAssetsLiveData(regAdd).then((regRes) => {
          if (regRes) {
            if (regRes[t.asset_id]['tag_data']["SUNCTION_FLOW"] != undefined) {
              sucPress = parseFloat(regRes[t.asset_id]['tag_data']["SUNCTION_FLOW"]).toFixed(1)
            }
            if (regRes[t.asset_id]['tag_data']["DISCHARGE_FLOW"] != undefined) {
              disPress = parseFloat(regRes[t.asset_id]['tag_data']["DISCHARGE_FLOW"]).toFixed(1)
            }

            if (regRes[t.asset_id]['tag_data']['COM_STS'] == 4) {
              markIcon = "green_marker.png"
              compStatus = "RUNNING"
            }
  
            if (regRes[t.asset_id]['tag_data']['COM_STS'] == -2) {
              markIcon = "red_empty_marker.png";
              compStatus = "ALARM"
              showAlm = true;
            }
  
            if (regRes[t.asset_id]['tag_data']['COM_STS'] == -1) {
              markIcon = "gray_marker.png";
              compStatus = "No Communication"
  
            }
  
            if (regRes[t.asset_id]['tag_data']['COM_STS'] == 0) {
              markIcon = "red_marker.png";
              compStatus = "STOPPED";
  
            }
            if (regRes[t.asset_id]['tag_data']['COM_STS'] == 1) {
              markIcon = "green_marker.png"
              compStatus = "STANDBY"
  
            }
  
            if (regRes[t.asset_id]['tag_data']['COM_STS'] == 2) {
              markIcon = "green_marker.png"
              compStatus = "READY"
  
            }
            if (regRes[t.asset_id]['tag_data']['COM_STS'] == 3) {
              markIcon = "green_marker.png"
              compStatus = "STARTING"
  
            }
            if (regRes[t.asset_id]['tag_data']['COM_STS'] == 5) {
              markIcon = "green_marker.png"
              compStatus = "ON HALF LOAD"
  
            }
            if (regRes[t.asset_id]['tag_data']['COM_STS'] == 6) {
              markIcon = "green_marker.png"
              compStatus = "ON FULL LOAD"
  
            }
            if (regRes[t.asset_id]['tag_data']['COM_STS'] == 7) {
              markIcon = "green_marker.png"
              compStatus = "BLOWDOWN"
  
            }
            if (regRes[t.asset_id]['tag_data']['COM_STS'] == 8) {
              markIcon = "green_marker.png"
              compStatus = "STOPING"
  
            }
  
  
  
            testMark.push({
              key1: t._id,
              key: t._id,
              serial_no: t.field_data.MACHINE_SR_NO,
              lat: t.field_data.LATITUDE,
              lng: t.field_data.LONGITUDE,
              station: t.field_data.STATION,
              status: compStatus,
              type: t.field_data.DRIVE_TYPE,
              customer_name: t.field_data.CUSTOMER_NAME,
              associate_disp: t.ass_disp,
              icon: markIcon,
              sucPress: sucPress,
              disPress: disPress,
              asset_id: t.asset_id,
              client_logo: t.field_data.CLIENT_LOGO,
              showAlm: showAlm,
              unit_id: t.field_data.UNIT_ID
            });
  
            this.setState({
              markers: testMark
            });
          }
        })

        // let req = { "operation": "get_asset_status", "asset_list": [t.asset_id] }
        // this.mapService.tcpRequest(req).then((tagres) => {
        //   // alert('Res Marker' + JSON.stringify(tagres));
        //   if (tagres[t.asset_id] == 4) {
        //     markIcon = "green_marker.png"
        //     compStatus = "RUNNING"
        //   }

        //   if (tagres[t.asset_id] == -2) {
        //     markIcon = "red_empty_marker.png";
        //     compStatus = "ALARM"
        //     showAlm = true;
        //   }

        //   if (tagres[t.asset_id] == -1) {
        //     markIcon = "gray_marker.png";
        //     compStatus = "No Communication"

        //   }

        //   if (tagres[t.asset_id] == 0) {
        //     markIcon = "red_marker.png";
        //     compStatus = "STOPPED";

        //   }
        //   if (tagres[t.asset_id] == 1) {
        //     markIcon = "green_marker.png"
        //     compStatus = "STANDBY"

        //   }

        //   if (tagres[t.asset_id] == 2) {
        //     markIcon = "green_marker.png"
        //     compStatus = "READY"

        //   }
        //   if (tagres[t.asset_id] == 3) {
        //     markIcon = "green_marker.png"
        //     compStatus = "STARTING"

        //   }
        //   if (tagres[t.asset_id] == 5) {
        //     markIcon = "green_marker.png"
        //     compStatus = "ON HALF LOAD"

        //   }
        //   if (tagres[t.asset_id] == 6) {
        //     markIcon = "green_marker.png"
        //     compStatus = "ON FULL LOAD"

        //   }
        //   if (tagres[t.asset_id] == 7) {
        //     markIcon = "green_marker.png"
        //     compStatus = "BLOWDOWN"

        //   }
        //   if (tagres[t.asset_id] == 8) {
        //     markIcon = "green_marker.png"
        //     compStatus = "STOPING"

        //   }



        //   testMark.push({
        //     key1: t._id,
        //     key: t._id,
        //     serial_no: t.field_data.MACHINE_SR_NO,
        //     lat: t.field_data.LATITUDE,
        //     lng: t.field_data.LONGITUDE,
        //     station: t.field_data.STATION,
        //     status: compStatus,
        //     type: t.field_data.DRIVE_TYPE,
        //     customer_name: t.field_data.CUSTOMER_NAME,
        //     associate_disp: t.ass_disp,
        //     icon: markIcon,
        //     sucPress: sucPress,
        //     disPress: disPress,
        //     asset_id: t.asset_id,
        //     client_logo: t.field_data.CLIENT_LOGO,
        //     showAlm: showAlm,
        //     unit_id: t.field_data.UNIT_ID
        //   });

        //   this.setState({
        //     markers: testMark
        //   });


        // })
        // alert("regAdd "+regAdd)
        //      break;
        //   }
        // }
        // testMark.push({
        //   key1: t._id, 
        //   key: t._id,
        //   serial_no: t.field_data.MACHINE_SR_NO,
        //   lat: parseFloat(t.field_data.LATITUDE),
        //   lng: parseFloat(t.field_data.LONGITUDE),
        //   station: t.field_data.STATION,
        //   status: t.field_data.MACHINE_STATUS,
        //   type: t.field_data.DRIVE_TYPE,
        //   customer_name: t.field_data.CUSTOMER_NAME,
        //   associate_disp: t.ass_disp,
        //   icon: markIcon,
        //   asset_id: t.asset_id
        // });
        // alert(JSON.stringify(this.state.markers));
      })

      // this.setState({
      //   markers: testMark
      // });
      // this.state.markers.push({
      //   key: 'engineer',
      //   lat: 18.5204,
      //   lng: 73.8567,
      //   icon: 'serv_eng.png',
      //   email: 'yogesh.patil@tasind.com',
      //   contact: '5255252525',
      //   name: 'TEST_USER',
      // });
      // if (res && res._id) {
      //   // alert('map is there...')
      //   this.setState({
      //     mapURL: res.static_map_url,
      //   });
      // }
    });
  };

  getEngineerAssets = () => {
    this.mapService
      .getEngineerAsset({ template_id: '623d8b4c18fc950001465dc5' })
      .then((res) => {
        // alert(JSON.stringify(res));
        for (let i = 0; i < res.length; i++) {
          // alert(JSON.stringify(res[i]));
          this.state.markers.push({
            key1: "engineer",
            key: res[i].field_data.NAME,
            lat: res[i].field_data.LATITUDE,
            lng: res[i].field_data.LONGITUDE,
            icon: 'serv_eng.png',
            email: res[i].field_data.EMAIL,
            contact: res[i].field_data.CONTACT,
            name: res[i].field_data.NAME,
          });
        }
        // alert(JSON.stringify(this.state.markers));
      });
  };
  getMapURL = () => {
    this.mapService.getMapURL().then((res) => {
      // alert(JSON.stringify(res));
      if (res && res._id) {
        // alert('map is there...')
        this.setState({
          mapURL: res.static_map_url,
        });
      }
    });
  };

  getMapSetting = () => {
    this.mapService.getMapSettings().then((res) => {
      // alert(JSON.stringify(res));
      if (res && res._id) {
        // alert('map is there...')
        this.setState({
          mapCenter: [res.center_lat, res.center_long],
          zoom: res.zoom_level,
          sLat: res.center_lat,
          sLong: res.center_long,
          sMaptype: res.map_style,
          sZoom: res.zoom_level,
          dbMapType: res.map_style,
        });
      } else {
        // alert('map is not there...')
        const reqParams = {
          center_lat: this.state.sLat,
          center_long: this.state.sLong,
          zoom_level: this.state.sZoom,
          map_style: this.state.sMaptype,
        };
        this.mapService.addMapSettings(reqParams).then((res) => {
          console.log('map setting added successfully!');
        });
      }
    });
  };

  getMarkers = () => {
    this.mapService.allMapPoints().then(async (res) => {
      let GLGMenus = JSON.parse(localStorage.getItem('GLGMenu'));
      var tagList = [];
      if (res && res.length > 0) {
        res.map((r) => {
          let tagArr = JSON.parse(r['tags']);
          // alert(JSON.stringify(tagArr));
          r.tags = tagArr;
          tagArr.map((t) => {
            tagList.push(t.tag);
          });
          // this.mapService.downloadMarkerIcon(r._id).then(res1 => {
          //     // alert(JSON.stringify(res1));
          //     if(res1 && res1.data){
          //         const iconURL = window.URL.createObjectURL(new Blob([res1.data]));

          this.mapService.liveData([r.status_tag]).then((res3) => {
            // alert(JSON.stringify(res2));
            if (res3) {
              // alert(JSON.stringify(res3))
              if (res3[r.status_tag] == 1) {
                const customIcon = L.icon({
                  iconUrl: '/static/marker/green.png',
                });
                r.mIcon = customIcon;
              } else if (res3[r.status_tag] == 0) {
                const customIcon = L.icon({
                  iconUrl: '/static/marker/red.png',
                });
                r.mIcon = customIcon;
              } else {
                const customIcon = L.icon({
                  iconUrl: '/static/marker/blue.png',
                });
                r.mIcon = customIcon;
              }
            } else {
              const customIcon = L.icon({ iconUrl: '/static/marker/blue.png' });
              r.mIcon = customIcon;
            }
          });
          GLGMenus.map((menus) => {
            if (r.associate_disp === menus.filePath) {
              r.authPer = menus.auth;

            }
          });

          // const customIcon = L.icon({ iconUrl: '/static/marker/red.png' });
          // r.mIcon = customIcon;
          //     }else{
          //         // const defaultIcon = L.icon({ iconUrl: icon });
          //         // r.mIcon = defaultIcon;
          //         r.mIcon = null;
          //     }
          // });
        });
        this.mapService.liveData(tagList).then((res2) => {
          // alert(JSON.stringify(res2));
          if (res2) {
            res.map((m) => {
              var Tags = m.tags;
              Tags.map((t) => {
                // tagData += "<tr><th>"+t.tagName+" </th><th>"+ res[t.tag] +"</th></tr>";
                t.tagValue = res2[t.tag];
              });
            });
            // alert('final marker: '+JSON.stringify(res));
            this.setState({ markers: res });
          }
        });
      } else {
        this.setState({
          msgStatus: true,
          msgType: 'error',
          msgContent: 'No locations found!',
        });
      }
    });
  };

  changeLoctaion = (newLoc) => {
    // alert(newLoc)
    this.state.markers.map((m) => {
      if (m.location_name === newLoc) {
        this.setState({
          sLocation: newLoc,
          zoom: 15,
          mapCenter: [m.lattitude, m.longitude],
          openSearchModal: false,
        });
      }
    });
  };

  locationChange = (event) => {
    // alert(event.target.value)
    this.state.markers.map((m) => {
      if (m.location_name === event.target.value) {
        this.setState({
          sLocation: event.target.value,
          zoom: 15,
          mapCenter: [m.lattitude, m.longitude],
        });
      }
    });
  };

  resetMap = () => {
    this.setState({
      sLocation: '',
      zoom: this.state.sZoom,
      mapCenter: [this.state.sLat, this.state.sLong],
      sMaptype: this.state.dbMapType,
    });
  };

  searchLocation = () => {
    this.setState({ openSearchModal: true });
  };

  closeSearchDialog = () => {
    this.setState({ openSearchModal: false });
  };

  openDialog = () => {
    this.setState({ openModal: true, isOpen: true });
  };

  toggleDrawer = (prevState) => {
    this.setState({
      isOpen: !prevState,
    });
  };

  updateSetting = () => {
    if (this.state.sZoom < 1) {
      this.setState({
        hasError: true,
        errorStyle: { color: 'red', padding: '0px' },
      });
    } else if (this.state.sZoom >= 1 && this.state.sZoom <= 17) {
      const updParams = {
        center_lat: this.state.sLat,
        center_long: this.state.sLong,
        zoom_level: this.state.sZoom,
        map_style: this.state.sMaptype,
      };
      this.mapService.updateMapSettings(updParams).then((res) => {
        if (res.status === 'success') {
          this.setState({
            openModal: false,
            msgStatus: true,
            msgType: 'success',
            msgContent: 'Map setting updated successfully!',
            zoom: this.state.sZoom,
            mapCenter: [this.state.sLat, this.state.sLong],
            dbMapType: this.state.sMaptype,
          });
        } else {
          this.setState({
            msgStatus: true,
            msgType: 'error',
            msgContent: JSON.stringify(res),
          });
        }
      });
    } else {
      this.setState({ hasError: true, errorStyle: { color: 'red' } });
    }
  };

  closeSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ msgStatus: false });
  };

  closeDialog = () => {
    this.setState({ openModal: false, sMaptype: this.state.dbMapType });
  };

  mapTypeChange = (newType) => {
    // alert(newType)
    this.setState({ sMaptype: newType });
  };

  latChange = (event) => {
    this.setState({ sLat: event.target.value });
  };

  longChange = (event) => {
    this.setState({ sLong: event.target.value });
  };

  zoomChange = (event) => {
    this.setState({
      sZoom: +event.target.value,
      hasError: false,
      errorStyle: {},
    });
  };

  changeSerNo = (event) => {
    this.setState({
      selectedSerNo: event,
    });
  };

  changeCustomer = (event) => {
    this.setState({
      selectedCust: event,
    });
  };
  changeState = (event) => {
    this.setState({
      selectedState: event,
    });
    alert(JSON.stringify(this.state.selectedState))
  };
  changeCity = (event) => {
    this.setState({
      selectedRegion: event,
    });
  };
  changeRegion = (event) => {
    this.setState({
      selectedRegion1: event,
    });
  };
  changeEngineer = (event) => {
    this.setState({
      selectedEngineer: event,
    });
  };
  changeType = (event) => {
    this.setState({
      selectedType: event,
    });
  };
  changeArticle = (event) => {
    this.setState({
      selectedAcrticleNo: event,
    });
  };
  ModelsMap = (map, maps) => {
    // maps.Marker().clear();
    this.map = map;
    this.maps = maps;
    // let marker = new maps.Marker({
    //   position: { lat: 18.5204, lng: 73.8567 },
    //   map,
    //   // label: 'Pune',
    // });

    //Loop through the dataArray to create a marker per data using the coordinates in the json
    for (let i = 0; i < this.state.mapData.length; i++) {
      let marker = new maps.Marker({
        position: {
          lat: parseInt(this.state.mapData[i].field_data.LATITUDE),
          lng: parseInt(this.state.mapData[i].field_data.LONGITUDE),
        },
        map,
        // label: this.state.mapData[i]._id,
      });
      // alert(marker);
    }
  };

  // handleChange = (event) => {
  //   alert(event);
  //   // this.setState({ value });
  //   // console.log(value);
  // };

  handleChange = (selected) => {
    this.node.blur();
    this.setState({
      optionSelected: selected,
    });
    alert(JSON.stringify(this.state.optionSelected));
    this.node.focus();
  };

  handleChangeState = (event) => {
    const value = event.target.value;
    // alert(JSON.stringify(value));
    if (value[value.length - 1] === 'all') {
      // setSelected(selected.length === options.length ? [] : options);
      let allOpt =
        this.state.stateSelected.length === this.state.stateList.length
          ? []
          : this.state.stateList;
      this.setState({
        stateSelected: allOpt,
      });

      this.state.stateSelected = allOpt;

      // this.changeState(['ALL']);
      return;
    }
    this.state.stateSelected = value;
    this.setState({ stateSelected: value });
    this.changeState(this.state.stateSelected);
    // alert(JSON.stringify(this.state.selected));
  };

  handleChangeCustomer = (event) => {
    const value = event.target.value;
    // alert(JSON.stringify(value));
    if (value[value.length - 1] === 'all') {
      // setSelected(selected.length === options.length ? [] : options);
      let allOpt =
        this.state.customerSelected.length === this.state.custList.length
          ? []
          : this.state.custList;
      this.setState({
        customerSelected: allOpt,
      });

      this.state.customerSelected = allOpt;

      // this.changeState(['ALL']);
      return;
    }
    this.state.customerSelected = value;
    this.setState({ customerSelected: value });
    this.changeCustomer(this.state.customerSelected);
    // alert(JSON.stringify(this.state.selected));
  };

  handleChangeRegion = (event) => {
    const value = event.target.value;
    // alert(JSON.stringify(value));
    if (value[value.length - 1] === 'all') {
      // setSelected(selected.length === options.length ? [] : options);
      let allOpt =
        this.state.regionSelected.length === this.state.region1List.length
          ? []
          : this.state.region1List;
      this.setState({
        regionSelected: allOpt,
      });

      this.state.regionSelected = allOpt;

      // this.changeState(['ALL']);
      return;
    }
    this.state.regionSelected = value;
    this.setState({ regionSelected: value });
    this.changeRegion(this.state.regionSelected);
    // alert(JSON.stringify(this.state.selected));
  };

  handleChangeEngineer = (event) => {
    const value = event.target.value;
    // alert(JSON.stringify(value));
    if (value[value.length - 1] === 'all') {
      // setSelected(selected.length === options.length ? [] : options);
      let allOpt =
        this.state.engineerSelected.length === this.state.engineerList.length
          ? []
          : this.state.engineerList;
      this.setState({
        engineerSelected: allOpt,
      });

      this.state.engineerSelected = allOpt;

      // this.changeState(['ALL']);
      return;
    }
    this.state.engineerSelected = value;
    this.setState({ engineerSelected: value });
    this.changeEngineer(this.state.engineerSelected);
    // alert(JSON.stringify(this.state.selected));
  };

  handleChangeType = (event) => {
    const value = event.target.value;
    // alert(JSON.stringify(value));
    if (value[value.length - 1] === 'all') {
      // setSelected(selected.length === options.length ? [] : options);
      let allOpt =
        this.state.typeSelected.length === this.state.typeList.length
          ? []
          : this.state.typeList;
      this.setState({
        typeSelected: allOpt,
      });

      this.state.typeSelected = allOpt;

      // this.changeState(['ALL']);
      return;
    }
    this.state.typeSelected = value;
    this.setState({ typeSelected: value });
    this.changeType(this.state.typeSelected);
    // alert(JSON.stringify(this.state.selected));
  };

  handleChangeArticle = (event) => {
    const value = event.target.value;
    // alert(JSON.stringify(value));
    if (value[value.length - 1] === 'all') {
      // setSelected(selected.length === options.length ? [] : options);
      let allOpt =
        this.state.articleNoSelected.length === this.state.articleNoList.length
          ? []
          : this.state.articleNoList;
      this.setState({
        articleNoSelected: allOpt,
      });

      this.state.articleNoSelected = allOpt;

      // this.changeState(['ALL']);
      return;
    }
    this.state.articleNoSelected = value;
    this.setState({ articleNoSelected: value });
    this.changeArticle(this.state.articleNoSelected);
    // alert(JSON.stringify(this.state.selected));
  };

  handleChangeCity = (event) => {
    const value = event.target.value;
    // alert(JSON.stringify(value));
    if (value[value.length - 1] === 'all') {
      // setSelected(selected.length === options.length ? [] : options);
      let allOpt =
        this.state.citySelected.length === this.state.regionList.length
          ? []
          : this.state.regionList;
      this.setState({
        citySelected: allOpt,
      });

      this.state.citySelected = allOpt;

      // this.changeState(['ALL']);
      return;
    }
    this.state.citySelected = value;
    this.setState({ citySelected: value });
    this.changeCity(this.state.citySelected);
    // alert(JSON.stringify(this.state.selected));
  };

  handleChangeSerial = (event) => {
    const value = event.target.value;
    // alert(JSON.stringify(value));
    if (value[value.length - 1] === 'all') {
      // setSelected(selected.length === options.length ? [] : options);
      let allOpt =
        this.state.serialNoSelected.length === this.state.serNoList.length
          ? []
          : this.state.serNoList;
      this.setState({
        serialNoSelected: allOpt,
      });

      this.state.serialNoSelected = allOpt;

      // this.changeState(['ALL']);
      return;
    }
    this.state.serialNoSelected = value;
    this.setState({ serialNoSelected: value });
    this.changeSerNo(this.state.serialNoSelected);
    // alert(JSON.stringify(this.state.selected));
  };

  render() {
    const {
      markers,
      sLocation,
      mapCenter,
      zoom,
      openModal,
      sMaptype,
      sLat,
      sLong,
      sZoom,
      hasError,
      errorStyle,
      dbMapType,
      openSearchModal,
      user_perm,
      mapURL,
      serNoList,
      selectedSerNo,
      custList,
      selectedCust,
      stateList,
      selectedState,
      regionList,
      selectedRegion,
      engineerList,
      selectedEngineer,
      isLoaded,
      svgMarker,
      showInfoWindow,
      activeMarker,
      selectedRegion1,
      typeList,
      selectedType,
      articleNoList,
      selectedAcrticleNo,
      selectedMarker,
      isOpen, stateSelected, isAllSelected, customerSelected,
      citySelected,
      engineerSelected,
      typeSelected,
      articleNoSelected,
      serialNoSelected,
      region1List,
      regionSelected,
    } = this.state;
    const { classes } = this.props;
    const mapTypes = [
      { label: 'Street Map Light', value: 'StreetMapLight' },
      { label: 'Street Map Dark', value: 'StreetMapDark' },
      { label: 'Satellite', value: 'Satellite' },
    ];
    var mapDetails = '';
    if (mapURL != '' && mapURL != undefined) {
      mapDetails = (
        <Iframe
          url={mapURL}
          width="100%"
          height="500px"
          id="myId"
          className="myClassname"
          display="initial"
          position="relative"
          allowFullScreen
        />
      );
    } else {
      mapDetails = (
        <div></div>
        // <Map center={mapCenter} zoom={zoom}>
        //   {this.state.msgStatus ? (
        //     <CustomizedSnackbars
        //       opened={this.state.msgStatus}
        //       snackType={this.state.msgType}
        //       snackMessage={this.state.msgContent}
        //       closed={this.closeSnackBar}
        //     ></CustomizedSnackbars>
        //   ) : null}
        //   <Dialog
        //     open={openModal}
        //     onClose={this.closeDialog}
        //     aria-labelledby="form-dialog-title"
        //     maxWidth="lg"
        //   >
        //     <DialogTitle id="form-dialog-title">
        //       Initial Map Settings
        //     </DialogTitle>
        //     <DialogContent>
        //       <form error={hasError.toString()}>
        //         <TextField
        //           className={classes.textField}
        //           id="lat"
        //           label="Latitude"
        //           type="number"
        //           value={sLat}
        //           onChange={(event) => this.latChange(event)}
        //         />
        //         <TextField
        //           className={classes.textField}
        //           id="long"
        //           label="Longitude"
        //           type="number"
        //           value={sLong}
        //           onChange={(event) => this.longChange(event)}
        //         />
        //         <br></br>
        //         <FormControl className={classes.formControl}>
        //           <Typography></Typography>
        //           <TextField
        //             className={classes.textField}
        //             id="initialzoom"
        //             label="Zoom"
        //             type="number"
        //             value={sZoom}
        //             onChange={(event) => this.zoomChange(event)}
        //             InputProps={{ inputProps: { max: 17, min: 1 } }}
        //           />
        //           {hasError && (
        //             <FormHelperText style={errorStyle}>
        //               Zoom level should be 1-17
        //             </FormHelperText>
        //           )}
        //         </FormControl>
        //         <FormControl className={classes.formControl}>
        //           <Typography></Typography>
        //           <Typography>Map Type</Typography>
        //           <VirtualizedSelect
        //             ref="mapTypes"
        //             options={mapTypes}
        //             simpleValue
        //             clearable={false}
        //             name="select-map-type"
        //             onChange={this.mapTypeChange}
        //             value={sMaptype}
        //             menuContainerStyle={{ zIndex: 999999999999 }}
        //             required={true}
        //           />
        //           <br></br>
        //           <br></br>
        //           <br></br>
        //           <br></br>

        //         </FormControl>
        //       </form>
        //     </DialogContent>
        //     <DialogActions>
        //       <Button onClick={this.closeDialog} color="secondary">
        //         Cancel
        //       </Button>
        //       <Button onClick={this.updateSetting} color="primary">
        //         Submit
        //       </Button>
        //     </DialogActions>
        //   </Dialog>
        //   <Dialog
        //     open={openSearchModal}
        //     onClose={this.closeSearchDialog}
        //     aria-labelledby="form-dialog-search"
        //     PaperProps={{ style: { overflowY: 'visible' } }}
        //   >
        //     <DialogTitle id="form-dialog-search">Search Location</DialogTitle>
        //     <DialogContent style={{ overflowY: 'visible' }}>
        //       <form className={classes.searchForm}>
        //         <FormControl className={classes.formControl}>
        //           <VirtualizedSelect
        //             ref="markers"
        //             options={markers}
        //             simpleValue
        //             clearable={false}
        //             name="select-markers"
        //             onChange={this.changeLoctaion}
        //             value={sLocation}
        //             searchable
        //             labelKey="location_name"
        //             valueKey="location_name"
        //             menuContainerStyle={{ zIndex: 9999999 }}
        //           />
        //         </FormControl>
        //       </form>
        //     </DialogContent>
        //     <DialogActions>
        //       <Button onClick={this.closeSearchDialog} color="secondary">
        //         Cancel
        //       </Button>
        //     </DialogActions>
        //   </Dialog>
        //   <div className="searchBox">

        //     <br></br>
        //     <Button
        //       title="Search Location"
        //       variant="fab"
        //       mini
        //       color="primary"
        //       aria-label="Search"
        //       className={classes.button}
        //       onClick={this.searchLocation}
        //       style={{ backgroundColor: 'rgb(0, 153, 204' }}
        //     >
        //       <SearchIcon />
        //     </Button>
        //     <br></br>
        //     <br></br>
        //     <br></br>
        //     <br></br>
        //     <Button
        //       title="Reset Map"
        //       variant="fab"
        //       mini
        //       color="primary"
        //       aria-label="Reset"
        //       className={classes.button}
        //       onClick={this.resetMap}
        //       style={{ backgroundColor: 'rgb(0, 153, 204' }}
        //     >
        //       <RefreshIcon />
        //     </Button>
        //     <br></br>
        //     <br></br>
        //     <br></br>
        //     <br></br>
        //     <Button
        //       title="Map Settings"
        //       variant="fab"
        //       mini
        //       color="primary"
        //       aria-label="Settings"
        //       className={classes.button}
        //       onClick={this.openDialog}
        //       style={{ backgroundColor: 'rgb(0, 153, 204' }}
        //     >
        //       <SettingsIcon />{' '}
        //     </Button>
        //   </div>

        //   {dbMapType === 'StreetMapDark' ? (
        //     <TileLayer
        //       attribution='&amp;copy <a href="https://stadiamaps.com/">Stadia Maps</a>, &amp;copy <a href="https://openmaptiles.org/">OpenMapTiles</a>&amp;copy <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
        //       url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png"
        //     />
        //   ) : dbMapType === 'Satellite' ? (
        //     <TileLayer
        //       attribution="Tiles &amp;copy Esri &amp; dash Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
        //       url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
        //     />
        //   ) : (
        //     <TileLayer
        //       attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        //       url="https://openstreetmap.in/#3.74/30.11/84.42"
        //     />
        //   )}

        //   {markers.map((m, idx) => {
        //     if (m.mIcon) {
        //       return (
        //         <Marker
        //           position={[m.lattitude, m.longitude]}
        //           icon={m.mIcon}
        //           key={idx}
        //           style={{ position: 'fixed' }}
        //         >
        //           <Popup>
        //             <div>
        //               <h3
        //                 style={{
        //                   color: '#fff',
        //                   backgroundColor: '#505050',
        //                   padding: '5px',
        //                 }}
        //               >
        //                 {m.location_name}
        //               </h3>
        //               <table className="iwTable">
        //                 <tr>
        //                   <th>Description</th>
        //                   <td style={{ wordWrap: 'break-word' }}>
        //                     {m.description}
        //                   </td>
        //                 </tr>
        //                 {m.tags.map((t) => {
        //                   return (
        //                     <tr key={idx}>
        //                       <th>{t.tag}</th>
        //                       <td>{t.tagValue}</td>
        //                     </tr>
        //                   );
        //                 })}
        //               </table>
        //               <div style={{ paddingTop: '5px' }} key={idx}>
        //                 {m.associate_disp != 'NONE' && m.authPer == 0 ? (
        //                   <NavLink to={`/graphics/${m.associate_disp}`}>
        //                     <Button variant="contained" size="small">
        //                       Visit to Dashboard!
        //                     </Button>
        //                   </NavLink>
        //                 ) : null}
        //                 {m.associate_disp != 'NONE' &&
        //                 m.authPer != 0 &&
        //                 user_perm.includes(m.authPer) ? (
        //                   <NavLink to={`/graphics/${m.associate_disp}`}>
        //                     <Button variant="contained" size="small">
        //                       Visit to Dashboard!
        //                     </Button>
        //                   </NavLink>
        //                 ) : null}
        //               </div>
        //             </div>
        //           </Popup>
        //         </Marker>
        //       );
        //     } else {
        //       return (
        //         <Marker position={[m.lattitude, m.longitude]} key={idx}>
        //           <Popup maxWidth="100%" maxHeight="auto">
        //             <div>
        //               <h3
        //                 style={{
        //                   color: '#fff',
        //                   backgroundColor: '#505050',
        //                   padding: '5px',
        //                 }}
        //               >
        //                 {m.location_name}
        //               </h3>
        //               <table className="iwTable">
        //                 <tr>
        //                   <th>Description</th>
        //                   <td style={{ wordWrap: 'break-word' }}>
        //                     {m.description}
        //                   </td>
        //                 </tr>
        //                 {m.tags.map((t, i) => {
        //                   return (
        //                     <tr key={idx}>
        //                       <th>{t.tag}</th>
        //                       <td>{t.tagValue}</td>
        //                     </tr>
        //                   );
        //                 })}
        //               </table>
        //               <div style={{ paddingTop: '5px' }}>
        //                 {m.associate_disp != 'NONE' && m.authPer == 0 ? (
        //                   <NavLink to={`/graphics/${m.associate_disp}`}>
        //                     <Button variant="contained" size="small">
        //                       Visit to Dashboard!
        //                     </Button>
        //                   </NavLink>
        //                 ) : null}
        //                 {m.associate_disp != 'NONE' &&
        //                 m.authPer != 0 &&
        //                 user_perm.includes(m.authPer) ? (
        //                   <NavLink to={`/graphics/${m.associate_disp}`}>
        //                     <Button variant="contained" size="small">
        //                       Visit to Dashboard!
        //                     </Button>
        //                   </NavLink>
        //                 ) : null}
        //               </div>
        //             </div>
        //           </Popup>
        //         </Marker>
        //       );
        //     }
        //   })}
        // </Map>
      );
    }

    return (
      <Wrapper>
        <div style={{ height: '80vh', width: '100%' }}>
          {/* <GoogleMapReact
            bootstrapURLKeys={{
              key: 'AIzaSyAvX4wr-12hW9oIk7UEyhfU9z0IasWgTwg',
            }}
            defaultCenter={this.props.center}
            defaultZoom={this.props.zoom}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => this.ModelsMap(map, maps)}
          >
          </GoogleMapReact> */}

          <Map
            google={this.props.google}
            zoom={this.state.zoom}
            center={
              {
                lat: 22.2587,
                lng: 71.1924,
              }
            }
            controls={this.addControls}
          >
            {this.state.markers.map((m, idx) => {
              return (
                <Marker
                  key={m.key}
                  icon={{
                    url: 'static/marker/' + m.icon,
                    strokeColor: "green"
                  }}
                  position={{
                    lat: m.lat,
                    lng: m.lng,
                  }}
                  onClick={(props, marker) => {
                    this.markerClicked(marker, m);
                  }}
                />
              );
            })}

            <InfoWindow
              onCloseClick={this.props.handleCloseCall}
              marker={activeMarker}
              visible={showInfoWindow}
            >
              {selectedMarker.key1 != 'engineer' ? (
                  <Grid
                  container
                  spacing={5}
                  style={{ backgroundColor: '#eee', border: '1px solid #bbb' }}
                >
                  <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                  <img src={`static/images/${selectedMarker.client_logo}`} width="110%" height="100%"></img>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                  <div
                    className="map_info_wrapper"
                    style={{
                      paddingBottom: '5%',
                      borderTop: '1px solid rgb(204, 204, 204)',
                      paddingLeft: '38px'
                    }}
                  >
                    <div className="property_content_wrap">
                      <div className="property_price" style={{ fontSize: '25px', backgroundColor: '#72a3f4', padding: '10px', color: 'white', textAlign: 'center' }}>
                        <span>
                          <b>{selectedMarker.serial_no} 
                          {/* -
                            &nbsp;
                                    {selectedMarker.station} */}
                                    </b>
                        </span>
                      </div>
                      <div className="property_title" style={{ fontSize: '20px', marginTop: '2%' }}>
                        <span>
                          <b>Customer :  &nbsp;
                            {selectedMarker.customer_name}</b>
                        </span>
                      </div>
                      <div className="property_title" style={{ fontSize: '20px', marginTop: '2%' }}>
                        <span>
                          <b>Station :  &nbsp;
                            {selectedMarker.station}</b>
                        </span>
                      </div>
                      <div className="property_title" style={{ fontSize: '20px', marginTop: '2%', marginRight: '9%' }}>
                        <span>
                          <b>Suc. Flow :  &nbsp;
                            {selectedMarker.sucPress}</b>
                        </span>
                      </div>

                      <div className="property_title" style={{ fontSize: '20px', marginTop: '2%', marginRight: '15%' }}>
                        <span>
                          <b>Dis. Flow :  &nbsp;{selectedMarker.disPress}</b>
                        </span>
                      </div>

                      <div className="property_title" style={{ fontSize: '20px', marginTop: '2%' }}>
                        <span>
                          <b>Status :  &nbsp;
                            {selectedMarker.status}</b>
                        </span>
                      </div>
                      </div>
                      <div
                    style={{
                      paddingTop: '2%'
                    }}
                  >
                  { selectedMarker.showAlm ?
                    <a href="/alarmActive" class="buttonA js-buttonA"
                      style={{ backgroundColor: '#b91616' }} onClick={localStorage.setItem('select_unit', selectedMarker.unit_id)} role="button">ALM </a> : null}

                    <a href={'graphics/' + selectedMarker.asset_id+'_'+selectedMarker.associate_disp} class="buttonA js-buttonA"
                      style={{ backgroundColor: 'gray', float: 'right' }} onClick={localStorage.setItem('asset_id', selectedMarker.asset_id)} role="button">More Information </a>

                  </div>
                      </div>
                      
                  </Grid>
                </Grid>


              ) : (
                  <div>
                    <Grid>
                      <h5>{selectedMarker.name}</h5>
                    </Grid>

                    <div
                      className="map_info_wrapper"
                      style={{
                        paddingBottom: '5%',
                        paddingTop: '5%',
                        borderTop: '1px solid rgb(204, 204, 204)',
                      }}
                    >
                      <div className="img_wrapper"></div>
                      <div class="property_content_wrap">
                        {/* <div className="property_price">
                      <span>
                        <strong>{selectedMarker.customer_name}</strong>
                      </span>

                      <span style={{ float: 'right', color: 'green' }}>
                        <strong>{selectedMarker.status}</strong>
                      </span>
                    </div> */}

                        <div className="property_title">
                          <span>{selectedMarker.contact}</span>
                        </div>

                        <div className="property_title">
                          <span>{selectedMarker.email}</span>
                        </div>
                      </div>
                    </div>

                    {/* <div
                  style={{
                    paddingTop: '5%',
                    borderTop: '1px solid rgb(204, 204, 204)',
                  }}
                >
                
                  <a
                    href={'graphics/' + selectedMarker.associate_disp}
                    rel="noopener"
                    style={{
                      cursor: 'pointer',
                      color: 'rgb(66, 127, 237)',
                      textDecoration: 'none',
                    }}
                  >
                    Visit To Dashboard
                  </a>
                </div> */}
                  </div>
                )}
            </InfoWindow>


            {/* <InfoWindow
              onCloseClick={this.props.handleCloseCall}
              marker={activeMarker}
              visible={showInfoWindow}
            >
              {selectedMarker.key1 != 'engineer' ? (
                <div>
                  <img src="static/images/gas_station.jpg" width="400" height="190"></img>



                  <div
                    className="map_info_wrapper"
                    style={{
                      paddingBottom: '5%',
                      borderTop: '1px solid rgb(204, 204, 204)',
                    }}
                  >
                    <div className="property_content_wrap">
                      <div className="property_price" style={{ fontSize: '25px', backgroundColor: '#5872dc', padding: '10px', color: 'white', textAlign: 'center' }}>
                        <span>
                          <b>{selectedMarker.serial_no} -
                            &nbsp;
                                    {selectedMarker.station}</b>
                        </span>
                      </div>

                      <div className="property_title" style={{ fontSize: '20px', textAlign: 'center', marginTop: '2%' }}>
                        <span>
                          <b>Customer :  &nbsp;
                            {selectedMarker.customer_name}</b>
                        </span>
                      </div>

                      <div className="property_title" style={{ fontSize: '20px', textAlign: 'center', marginTop: '2%', marginRight: '9%' }}>
                        <span>
                          <b>Suction Pres :  &nbsp;
                            {selectedMarker.sucPress}</b>
                        </span>
                      </div>

                      <div className="property_title" style={{ fontSize: '20px', textAlign: 'center', marginTop: '2%', marginRight: '15%' }}>
                        <span>
                          <b>Discharge Pres :  &nbsp;</b>
                          <b> {selectedMarker.disPress}</b>
                        </span>
                      </div>

                      <div className="property_title" style={{ fontSize: '20px', textAlign: 'center', marginTop: '2%', marginLeft: '20%' }}>
                        <span>
                          <b>Status :  &nbsp;
                            {selectedMarker.status}</b>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      paddingTop: '3%'
                    }}
                  >
                    <a href="/alarmActive" class="buttonA js-buttonA"
                      style={{ backgroundColor: '#b91616' }} role="button">ALM </a>

                    <a href={'graphics/' + selectedMarker.associate_disp} class="buttonA js-buttonA"
                      style={{ backgroundColor: 'gray', float: 'right' }} onClick={localStorage.setItem('asset_id', selectedMarker.asset_id)} role="button">More Information </a>

                  </div>
                </div>


              ) : (
                  <div>
                    <Grid>
                      <h5>{selectedMarker.name}</h5>
                    </Grid>

                    <div
                      className="map_info_wrapper"
                      style={{
                        paddingBottom: '5%',
                        paddingTop: '5%',
                        borderTop: '1px solid rgb(204, 204, 204)',
                      }}
                    >
                      <div className="img_wrapper"></div>
                      <div class="property_content_wrap">


                        <div className="property_title">
                          <span>{selectedMarker.contact}</span>
                        </div>

                        <div className="property_title">
                          <span>{selectedMarker.email}</span>
                        </div>
                      </div>
                    </div>

                  </div>
                )}
            </InfoWindow> */}

            {!isOpen ? (
              <div className="searchBox">
                <br></br>

                <Button
                  title="Filter"
                  variant="fab"
                  mini
                  color="primary"
                  aria-label="Settings"
                  onClick={this.openDialog}
                  style={{
                    backgroundColor: 'rgb(0, 153, 204',
                    margin: '1%',
                    float: 'left',
                    zIndex: 9999,
                    marginLeft: '182px',
                    marginTop: '-14px',
                  }}
                >
                  <FilterListIcon />{' '}
                </Button>
              </div>
            ) : null}
            <div style={{ height: 'auto' }}>
              <Drawer
                open={isOpen}
                onClose={this.toggleDrawer}
                direction="left"
                size={270}
                style={{ padding: 20, paddingTop: '70px' }}
              >
                <form
                  style={{
                    // display: 'flex',
                    flexWrap: 'wrap',
                    // paddingBottom: '5px',
                  }}
                >
                  <div className="row" style={divStyle}>
                    <FormControl
                      style={{
                        width: 180,
                        marginLeft: '1%',
                      }}
                    >
                      <InputLabel id="mutiple-select-label">
                        Region<sup>*</sup>
                      </InputLabel>
                      <Select
                        labelId="mutiple-select-label"
                        multiple
                        value={regionSelected}
                        onChange={this.handleChangeRegion}
                        renderValue={(regionSelected) => regionSelected.join(', ')}
                        MenuProps={MenuProps}
                      >
                        <MenuItem
                          value="all"
                          classes={{
                            root: isAllSelected ? classes.selectedAll : '',
                          }}
                        >
                          <ListItemIcon>
                            <Checkbox
                              classes={{ indeterminate: this.props.indeterminateColor }}
                              checked={isAllSelected}
                              indeterminate={
                                regionSelected.length > 0 &&
                                regionSelected.length < region1List.length
                              }
                            />
                          </ListItemIcon>
                          <ListItemText
                            classes={{ primary: this.props.selectAllText }}
                            primary="Select All"
                          />
                        </MenuItem>
                        {region1List.map((option) => (
                          <MenuItem key={option} value={option}>
                            <ListItemIcon>
                              <Checkbox checked={regionSelected.indexOf(option) > -1} />
                            </ListItemIcon>
                            <ListItemText primary={option} />
                          </MenuItem>
                        ))}
                      </Select>

                    </FormControl>
                  </div>
                  {/* <div className="row" style={divStyle}>
                    <FormControl
                      style={{
                        minWidth: 180,
                        marginLeft: '1%',
                      }}
                    >
                      <Typography style={{ fontSize: '13px' }}>
                        Region
                      </Typography>
                      <VirtualizedSelect
                        ref="region"
                        options={[
                          { label: 'West', value: 'WEST' },
                          { label: 'North', value: 'NORTH' },
                        ]}
                        simpleValue
                        clearable={false}
                        name="select-region"
                        value={selectedRegion1}
                        onChange={(event) => this.changeRegion(event)}
                        searchable
                      />
                    </FormControl>
                  </div> */}


                  <div className="row" style={divStyle}>
                    <FormControl
                      style={{
                        width: 180,
                        marginLeft: '1%',
                      }}
                    >
                      <InputLabel id="mutiple-select-label">
                        Customer<sup>*</sup>
                      </InputLabel>
                      <Select
                        labelId="mutiple-select-label"
                        multiple
                        value={customerSelected}
                        onChange={this.handleChangeCustomer}
                        renderValue={(customerSelected) => customerSelected.join(', ')}
                        MenuProps={MenuProps}
                      >
                        <MenuItem
                          value="all"
                          classes={{
                            root: isAllSelected ? classes.selectedAll : '',
                          }}
                        >
                          <ListItemIcon>
                            <Checkbox
                              classes={{ indeterminate: this.props.indeterminateColor }}
                              checked={isAllSelected}
                              indeterminate={
                                customerSelected.length > 0 &&
                                customerSelected.length < custList.length
                              }
                            />
                          </ListItemIcon>
                          <ListItemText
                            classes={{ primary: this.props.selectAllText }}
                            primary="Select All"
                          />
                        </MenuItem>
                        {custList.map((option) => (
                          <MenuItem key={option} value={option}>
                            <ListItemIcon>
                              <Checkbox checked={customerSelected.indexOf(option) > -1} />
                            </ListItemIcon>
                            <ListItemText primary={option} />
                          </MenuItem>
                        ))}
                      </Select>

                    </FormControl>
                  </div>


                  {/* <div className="row" style={divStyle}>
                    <FormControl
                      style={{
                        minWidth: 180,
                        marginLeft: '1%',
                      }}
                    >
                      <Typography style={{ fontSize: '13px' }}>
                        Customer
                      </Typography>
                      <VirtualizedSelect
                        ref="customer"
                        options={custList}
                        simpleValue
                        clearable={false}
                        name="select-customer"
                        value={selectedCust}
                        onChange={(event) => this.changeCustomer(event)}
                        searchable
                      />
                    </FormControl>
                  </div> */}



                  <div className="row" style={divStyle}>
                    <FormControl
                      style={{
                        width: 180,
                        marginLeft: '1%',
                      }}
                    >
                      <InputLabel id="mutiple-select-label">
                        State<sup>*</sup>
                      </InputLabel>
                      <Select
                        labelId="mutiple-select-label"
                        multiple
                        value={stateSelected}
                        onChange={this.handleChangeState}
                        renderValue={(stateSelected) => stateSelected.join(', ')}
                        MenuProps={MenuProps}
                      >
                        <MenuItem
                          value="all"
                          classes={{
                            root: isAllSelected ? classes.selectedAll : '',
                          }}
                        >
                          <ListItemIcon>
                            <Checkbox
                              classes={{ indeterminate: this.props.indeterminateColor }}
                              checked={isAllSelected}
                              indeterminate={
                                stateSelected.length > 0 &&
                                stateSelected.length < stateList.length
                              }
                            />
                          </ListItemIcon>
                          <ListItemText
                            classes={{ primary: this.props.selectAllText }}
                            primary="Select All"
                          />
                        </MenuItem>
                        {stateList.map((option) => (
                          <MenuItem key={option} value={option}>
                            <ListItemIcon>
                              <Checkbox checked={stateSelected.indexOf(option) > -1} />
                            </ListItemIcon>
                            <ListItemText primary={option} />
                          </MenuItem>
                        ))}
                      </Select>

                    </FormControl>
                  </div>

                  {/* <div className="row" style={divStyle}>
                    <FormControl
                      style={{
                        minWidth: 180,
                        marginLeft: '1%',
                      }}
                    >
                      <Typography style={{ fontSize: '13px' }}>
                        State
                      </Typography>
                      <VirtualizedSelect
                        ref="state"
                        options={stateList}
                        simpleValue
                        clearable={false}
                        name="select-state"
                        value={selectedState}
                        onChange={(event) => this.changeState(event)}
                        searchable
                      />
                    </FormControl>
                  </div> */}

                  <div className="row" style={divStyle}>
                    <FormControl
                      style={{
                        width: 180,
                        marginLeft: '1%',
                      }}
                    >
                      <InputLabel id="mutiple-select-label">
                        City<sup>*</sup>
                      </InputLabel>
                      <Select
                        labelId="mutiple-select-label"
                        multiple
                        value={citySelected}
                        onChange={this.handleChangeCity}
                        renderValue={(citySelected) => citySelected.join(', ')}
                        MenuProps={MenuProps}
                      >
                        <MenuItem
                          value="all"
                          classes={{
                            root: isAllSelected ? classes.selectedAll : '',
                          }}
                        >
                          <ListItemIcon>
                            <Checkbox
                              classes={{ indeterminate: this.props.indeterminateColor }}
                              checked={isAllSelected}
                              indeterminate={
                                citySelected.length > 0 &&
                                citySelected.length < regionList.length
                              }
                            />
                          </ListItemIcon>
                          <ListItemText
                            classes={{ primary: this.props.selectAllText }}
                            primary="Select All"
                          />
                        </MenuItem>
                        {regionList.map((option) => (
                          <MenuItem key={option} value={option}>
                            <ListItemIcon>
                              <Checkbox checked={citySelected.indexOf(option) > -1} />
                            </ListItemIcon>
                            <ListItemText primary={option} />
                          </MenuItem>
                        ))}
                      </Select>

                    </FormControl>
                  </div>
                  {/* <div className="row" style={divStyle}>
                    <FormControl
                      style={{
                        minWidth: 180,
                        marginLeft: '1%',
                      }}
                    >
                      <Typography style={{ fontSize: '13px' }}>City</Typography>
                      <VirtualizedSelect
                        ref="region"
                        options={regionList}
                        simpleValue
                        clearable={false}
                        name="select-region"
                        value={selectedRegion}
                        onChange={(event) => this.changeCity(event)}
                        searchable
                      />
                    </FormControl>
                  </div> */}

                  <div className="row" style={divStyle}>
                    <FormControl
                      style={{
                        width: 180,
                        marginLeft: '1%',
                      }}
                    >
                      <InputLabel id="mutiple-select-label">
                        Service Engineer<sup>*</sup>
                      </InputLabel>
                      <Select
                        labelId="mutiple-select-label"
                        multiple
                        value={engineerSelected}
                        onChange={this.handleChangeEngineer}
                        renderValue={(engineerSelected) => engineerSelected.join(', ')}
                        MenuProps={MenuProps}
                      >
                        <MenuItem
                          value="all"
                          classes={{
                            root: isAllSelected ? classes.selectedAll : '',
                          }}
                        >
                          <ListItemIcon>
                            <Checkbox
                              classes={{ indeterminate: this.props.indeterminateColor }}
                              checked={isAllSelected}
                              indeterminate={
                                engineerSelected.length > 0 &&
                                engineerSelected.length < engineerList.length
                              }
                            />
                          </ListItemIcon>
                          <ListItemText
                            classes={{ primary: this.props.selectAllText }}
                            primary="Select All"
                          />
                        </MenuItem>
                        {engineerList.map((option) => (
                          <MenuItem key={option} value={option}>
                            <ListItemIcon>
                              <Checkbox checked={engineerSelected.indexOf(option) > -1} />
                            </ListItemIcon>
                            <ListItemText primary={option} />
                          </MenuItem>
                        ))}
                      </Select>

                    </FormControl>
                  </div>

                  {/* <div className="row" style={divStyle}>
                    <FormControl
                      style={{
                        minWidth: 180,
                        marginLeft: '1%',
                      }}
                    >
                      <Typography style={{ fontSize: '13px' }}>
                        Service Engineer
                      </Typography>
                      <VirtualizedSelect
                        ref="engineer"
                        options={engineerList}
                        simpleValue
                        clearable={false}
                        name="select-engineer"
                        value={selectedEngineer}
                        onChange={(event) => this.changeEngineer(event)}
                        searchable
                      />
                    </FormControl>
                  </div> */}
                  <div className="row" style={divStyle}>
                    <FormControl
                      style={{
                        width: 180,
                        marginLeft: '1%',
                      }}
                    >
                      <InputLabel id="mutiple-select-label">
                        Type<sup>*</sup>
                      </InputLabel>
                      <Select
                        labelId="mutiple-select-label"
                        multiple
                        value={typeSelected}
                        onChange={this.handleChangeType}
                        renderValue={(typeSelected) => typeSelected.join(', ')}
                        MenuProps={MenuProps}
                      >
                        <MenuItem
                          value="all"
                          classes={{
                            root: isAllSelected ? classes.selectedAll : '',
                          }}
                        >
                          <ListItemIcon>
                            <Checkbox
                              classes={{ indeterminate: this.props.indeterminateColor }}
                              checked={isAllSelected}
                              indeterminate={
                                typeSelected.length > 0 &&
                                typeSelected.length < typeList.length
                              }
                            />
                          </ListItemIcon>
                          <ListItemText
                            classes={{ primary: this.props.selectAllText }}
                            primary="Select All"
                          />
                        </MenuItem>
                        {typeList.map((option) => (
                          <MenuItem key={option} value={option}>
                            <ListItemIcon>
                              <Checkbox checked={typeSelected.indexOf(option) > -1} />
                            </ListItemIcon>
                            <ListItemText primary={option} />
                          </MenuItem>
                        ))}
                      </Select>

                    </FormControl>
                  </div>
                  {/* <div className="row" style={divStyle}>
                    <FormControl
                      style={{
                        minWidth: 180,
                        marginLeft: '1%',
                      }}
                    >
                      <Typography style={{ fontSize: '13px' }}>Type</Typography>
                      <VirtualizedSelect
                        ref="type"
                        options={typeList}
                        simpleValue
                        clearable={false}
                        name="select-type"
                        value={selectedType}
                        onChange={(event) => this.changeType(event)}
                        searchable
                      />
                    </FormControl>
                  </div> */}

                  <div className="row" style={divStyle}>
                    <FormControl
                      style={{
                        width: 180,
                        marginLeft: '1%',
                      }}
                    >
                      <InputLabel id="mutiple-select-label">
                        Article Number<sup>*</sup>
                      </InputLabel>
                      <Select
                        labelId="mutiple-select-label"
                        multiple
                        value={articleNoSelected}
                        onChange={this.handleChangeArticle}
                        renderValue={(articleNoSelected) => articleNoSelected.join(', ')}
                        MenuProps={MenuProps}
                      >
                        <MenuItem
                          value="all"
                          classes={{
                            root: isAllSelected ? classes.selectedAll : '',
                          }}
                        >
                          <ListItemIcon>
                            <Checkbox
                              classes={{ indeterminate: this.props.indeterminateColor }}
                              checked={isAllSelected}
                              indeterminate={
                                articleNoSelected.length > 0 &&
                                articleNoSelected.length < articleNoList.length
                              }
                            />
                          </ListItemIcon>
                          <ListItemText
                            classes={{ primary: this.props.selectAllText }}
                            primary="Select All"
                          />
                        </MenuItem>
                        {articleNoList.map((option) => (
                          <MenuItem key={option} value={option}>
                            <ListItemIcon>
                              <Checkbox checked={articleNoSelected.indexOf(option) > -1} />
                            </ListItemIcon>
                            <ListItemText primary={option} />
                          </MenuItem>
                        ))}
                      </Select>

                    </FormControl>
                  </div>

                  {/* <div className="row" style={divStyle}>
                    <FormControl
                      style={{
                        width: 180,
                        marginLeft: '1%',
                      }}
                    >
                      <Typography style={{ fontSize: '13px' }}>
                        Article Number
                      </Typography>
                      <VirtualizedSelect
                        ref="article"
                        options={articleNoList}
                        simpleValue
                        clearable={false}
                        name="select-articleNo"
                        value={selectedAcrticleNo}
                        onChange={(event) => this.changeArticle(event)}
                        searchable
                      />
                    </FormControl>
                  </div> */}
                  <div className="row" style={divStyle}>
                    <FormControl
                      style={{
                        width: 180,
                        marginLeft: '1%',
                      }}
                    >
                      <InputLabel id="mutiple-select-label">
                        Serial No.<sup>*</sup>
                      </InputLabel>
                      <Select
                        labelId="mutiple-select-label"
                        multiple
                        value={serialNoSelected}
                        onChange={this.handleChangeSerial}
                        renderValue={(serialNoSelected) => serialNoSelected.join(', ')}
                        MenuProps={MenuProps}
                      >
                        <MenuItem
                          value="all"
                          classes={{
                            root: isAllSelected ? classes.selectedAll : '',
                          }}
                        >
                          <ListItemIcon>
                            <Checkbox
                              classes={{ indeterminate: this.props.indeterminateColor }}
                              checked={isAllSelected}
                              indeterminate={
                                serialNoSelected.length > 0 &&
                                serialNoSelected.length < serNoList.length
                              }
                            />
                          </ListItemIcon>
                          <ListItemText
                            classes={{ primary: this.props.selectAllText }}
                            primary="Select All"
                          />
                        </MenuItem>
                        {serNoList.map((option) => (
                          <MenuItem key={option} value={option}>
                            <ListItemIcon>
                              <Checkbox checked={serialNoSelected.indexOf(option) > -1} />
                            </ListItemIcon>
                            <ListItemText primary={option} />
                          </MenuItem>
                        ))}
                      </Select>

                    </FormControl>
                  </div>

                  {/* <div className="row" style={divStyle}>
                    <FormControl
                      style={{
                        minWidth: 180,
                        marginLeft: '1%',
                      }}
                    >
                      <Typography style={{ fontSize: '13px' }}>
                        Serial No.
                      </Typography>
                      <VirtualizedSelect
                        ref="serial_no"
                        options={serNoList}
                        simpleValue
                        clearable={false}
                        name="select-ser-no"
                        value={selectedSerNo}
                        onChange={(event) => this.changeSerNo(event)}
                        searchable
                      />
                    </FormControl>
                  </div> */}
                  <div className="row" style={divStyle}>
                    <FormControl style={{ marginLeft: '1%' }}>
                      <Typography>&nbsp;</Typography>
                      <Button
                        color="primary"
                        variant="contained"
                        size="small"
                        title="Get Asset"
                        onClick={this.getMapAsset}
                        style={{ backgroundColor: 'rgb(0, 153, 204' }}
                        ref={input => this.inputElement = input}
                      >
                        Apply Filter
                      </Button>
                    </FormControl>
                  </div>
                </form>
              </Drawer>
            </div>
          </Map>
        </div>
      </Wrapper>
    );

    // mapDetails;

    // <iframe
    //   src="https://www.google.com/maps/embed?api=1ZbSOUlU-99-vaurrvM5oHc6HmPow5j7s&shorturl=1&ll=22.232947376107568%2C71.965573&z=8"
    //   width="100%"
    //   height="300"
    //   style="border:1px solid black;"
    // ></iframe>
  }
}
// export default GeoMap;
// export default withStyles(styles)(GeoMap);
export default GoogleApiWrapper({
  apiKey: 'AIzaSyBTrx7cl90kolb8s06VDY1d1NH89glg6W4',
})(GeoMap);
