import {
  axiosInstance1,
  axiosInstance2,
  axiosInstance7,
} from '../authorization/auth-intercept-service';
class DynamicNavService {
  user = localStorage.getItem('User');
  userEmail = JSON.parse(this.user)['email'];

  async dynamicNav() {
    return axiosInstance1
      .get('web_client_navigation')
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async liveData(tagList) {
    return axiosInstance7
      .post('log_tags_data/getLive', tagList)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async liveTagProperty(tagList) {
    return axiosInstance7
      .post('log_tags_data/liveTagProperty', tagList)
      .then((res) => {
        // console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async glgOptionMenuSettings() {
    return axiosInstance1
      .get('/glg_option_menu_settings/getAll')
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async WriteValue(reqParams) {
    // console.log('Write ' + JSON.stringify(reqParams));
    // alert(JSON.stringify(reqParams));
    return axiosInstance7
      .post('write?email=' + this.userEmail, reqParams)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async downloadGLG(fileName) {
    return axiosInstance1
      .post('glg/download?email=' + this.userEmail, fileName, {
        responseType: 'blob',
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async getTopMenuSettings() {
    return axiosInstance1
      .get('top_menu_setting')
      .then((res) => {
        // console.log(res);
        return res.data;
      })
      .catch((err) => {
        // console.log(err);
        return err;
      });
  }

  async getImages() {
    return axiosInstance1
      .get('general_setting')
      .then((res) => {
        // console.log(res);
        return res.data;
      })
      .catch((err) => {
        // console.log(err);
        return err;
      });
  }

  async saveTopMenuSettings(reqParams) {
    return axiosInstance1
      .post('top_menu_setting?email=' + this.userEmail, reqParams)
      .then((res) => {
        // console.log(res);
        return res.data;
      })
      .catch((err) => {
        // console.log(err);
        return err;
      });
  }

  async updateTopMenuSettings(reqParams) {
    return axiosInstance1
      .put('top_menu_setting?email=' + this.userEmail, reqParams)
      .then((res) => {
        // console.log(res);
        return res.data;
      })
      .catch((err) => {
        // console.log(err);
        return err;
      });
  }

  async ackByAsset(req) {
    return axiosInstance2
      .put('activeAlarms/acknowledgeByEquip?email='+this.userEmail, req)
      .then((res) => {
        // console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }
  // async downloadGLG(){
  //     return axiosInstance1.get('glg/testDownload', {responseType:'blob' }).then(res => {
  //         console.log(res);
  //         return res;
  //     }).catch(err => {
  //         console.log(err);
  //         return err;
  //     });
  // }
}
export default DynamicNavService;
