import { drawerWidth, drawerHeight } from './variables';

const WorkspaceStyles = theme => ({
  content: {
    backgroundColor: theme.palette.background.default,
    minWidth: 0,
    width: '100%',
    position: 'relative',
    display: 'block',
    paddingTop: drawerHeight-20,
    [theme.breakpoints.up('sm')]: {
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '-webkit-overflow-scrolling': 'touch',
  },
  'content-left': {
    [theme.breakpoints.up('md')]: {
      marginTop: -(drawerHeight-20),
    },
  },
  'content-right': {
    marginBottom: -drawerHeight,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  'contentShift-left': {
    marginTop: 0,
  },
  'contentShift-right': {
    marginBottom: 0,
  }
});

export default WorkspaceStyles;