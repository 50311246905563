// var DefaultHMIPage;
// var HMIPageBase;
// var ProcessPage;
// var RTChartPage;

// import { Helmet } from "react-helmet";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Wrapper } from "../../components";
import { Card } from "@material-ui/core";
import { CustomizedSnackbars } from "../../shared/snackbar-service";
import CircularProgress from "@material-ui/core/CircularProgress";
import DynamicNavService from "../../services/GLG/dynamicNav-service";
import "./Graphics.css";
import "external/GlgCE";
import { GlgToolkit } from "external/GlgToolkitCE";
// import 'external/GlgInt';
// import { GlgToolkit } from 'external/GlgToolkitInt';
import { LiveDataFeed } from "./LiveDataFeed";
import { DefaultHMIPage } from "./scada_default_hmi_page";
import { HMIPageBase } from "./scada_hmi_page_base";
import { ProcessPage } from "./scada_process_page";
import { RTChartPage } from "./scada_rt_chart_page";
import "external/gunzip.min.js";
import TextField from "@material-ui/core/TextField";

const styles = (theme) => ({
  progress: {
    marginLeft: "50%",
    position: "absolute",
  },
});

class TestGLG extends Component {
  constructor(props) {
    super(props);
    this.assetId = "";
    this.TouchDevice = -1;
    this.intervalId = 1;
    this.GLG = new GlgToolkit();
    this.ConfigFile = "/static/glg/scada_config_menu.txt";
    this.GLGService = new DynamicNavService();

    this.ConfigFileData = null; /* String : content of the configuration file */

    // Set initial size of the drawing.
    // this.SetDrawingSize(false);

    // Increase canvas resoultion for mobile devices.
    this.CoordScale = 0;

    /* Loads misc. assets used by the program and invokes the LoadMainDrawing
        function when done.
        */

    this.RANDOM_DATA = false; /* boolean */

    /* Will be set to true if random data are used for the current page. */
    this.RandomData = false; /* boolean */

    // Page type constants.
    this.UNDEFINED_PAGE_TYPE = -1;
    // Is used in the absence of the PageType property in the loaded drawing.
    this.DEFAULT_PAGE_TYPE = 0;
    this.PROCESS_PAGE = 1;
    this.AERATION_PAGE = 2;
    this.CIRCUIT_PAGE = 3;
    this.RT_CHART_PAGE = 4;
    this.TEST_COMMANDS_PAGE = 5;

    /* Page type table, is used to determine the type of a loaded page based
           on the PageType property of the drawing. May be extended by an 
           application to add custom pages.
        */
    this.PageTypeTable = [
      new this.TypeRecord("Default", this.DEFAULT_PAGE_TYPE),
      new this.TypeRecord("Process", this.PROCESS_PAGE),
      new this.TypeRecord("Aeration", this.AERATION_PAGE),
      new this.TypeRecord("Circuit", this.CIRCUIT_PAGE),
      new this.TypeRecord("RealTimeChart", this.RT_CHART_PAGE),
      new this.TypeRecord("TestCommands", this.TEST_COMMANDS_PAGE),
    ];
    // [{ name: "Default", enum: this.DEFAULT_PAGE_TYPE },
    // { name: "Process", enum: this.PROCESS_PAGE },
    // { name: "Aeration", enum: this.AERATION_PAGE },
    // { name: "Circuit", enum: this.CIRCUIT_PAGE },
    // { name: "RealTimeChart", enum: this.RT_CHART_PAGE },
    // { name: "TestCommands", enum: this.TEST_COMMANDS_PAGE },
    // ];

    this.NO_SCREEN = -1; // Invalid menu screen index constant.

    // CommandType contants.
    this.UNDEFINED_COMMAND_TYPE = -1;
    this.SHOW_ALARMS = 0;
    this.GOTO = 1;
    this.POPUP_DIALOG = 2;
    this.POPUP_MENU = 3;
    this.CLOSE_POPUP_DIALOG = 4;
    this.CLOSE_POPUP_MENU = 5;
    this.WRITE_VALUE = 6;
    this.WRITE_VALUE_FROM_WIDGET = 7;
    this.QUIT = 8;

    // DialogType contants.
    this.UNDEFINED_DIALOG_TYPE = -1;
    this.GLOBAL_POPUP_DIALOG = 0;
    this.CUSTOM_DIALOG = 1;
    this.MAX_DIALOG_TYPE = 2;

    this.CLOSE_ALL = this.UNDEFINED_DIALOG_TYPE;

    // PopupMenuType contants.
    this.UNDEFINED_POPUP_MENU_TYPE = -1;
    this.GLOBAL_POPUP_MENU = 0;

    // Predefined tables, can be extended by the application as needed.
    this.DialogTypeTable = [
      new this.TypeRecord("Popup", this.GLOBAL_POPUP_DIALOG),
      new this.TypeRecord("CustomDialog", this.CUSTOM_DIALOG),
    ];
    // [{name:"Popup", enum: this.GLOBAL_POPUP_DIALOG},
    //  {name: "CustomDialog", enum: this.CUSTOM_DIALOG}
    // ];

    this.PopupMenuTypeTable = [
      new this.TypeRecord("PopupMenu", this.GLOBAL_POPUP_MENU),
    ];
    // [{name:"PopupMenu", enum: this.GLOBAL_POPUP_MENU}];

    this.CommandTypeTable = [
      new this.TypeRecord("ShowAlarms", this.SHOW_ALARMS),
      new this.TypeRecord("GoTo", this.GOTO),
      new this.TypeRecord("PopupDialog", this.POPUP_DIALOG),
      new this.TypeRecord("PopupMenu", this.POPUP_MENU),
      new this.TypeRecord("ClosePopupDialog", this.CLOSE_POPUP_DIALOG),
      new this.TypeRecord("ClosePopupMenu", this.CLOSE_POPUP_MENU),
      new this.TypeRecord("WriteValue", this.WRITE_VALUE),
      new this.TypeRecord("WriteValueFromWidget", this.WRITE_VALUE_FROM_WIDGET),
      new this.TypeRecord("Quit", this.QUIT),
    ];
    //    [{ name: "ShowAlarms", enum: this.SHOW_ALARMS },
    //     { name: "GoTo", enum: this.GOTO },
    //     { name: "PopupDialog", enum: this.POPUP_DIALOG },
    //     { name: "PopupMenu", enum: this.POPUP_MENU },
    //     { name: "ClosePopupDialog", enum: this.CLOSE_POPUP_DIALOG },
    //     { name: "ClosePopupMenu", enum: this.CLOSE_POPUP_MENU },
    //     { name: "WriteValue", enum: this.WRITE_VALUE },
    //     { name: "WriteValueFromWidget", enum: this.WRITE_VALUE_FROM_WIDGET },
    //     { name: "Quit", enum: this.QUIT }];

    // The type of the current page.
    this.PageType = this.UNDEFINED_PAGE_TYPE; /* int */

    /* The object that implements functionality of an HMI page, it is a subclass
           of the HMIPageBase base object. A default HMIPageBase object is provided;
           custom HMI page subclasses may be used to define custom page logic, 
           as shown in the RealTimeChart and Process pages.
        */
    this.HMIPage = ""; /* HMIPageBase */

    /* The object that is used for supplying data for animation, either random data 
           for a demo, or live data in a real application.
        */

    this.DataFeed = new LiveDataFeed(); /* DemoDataFeedObj or LiveDataFeedObj */

    // Datafeed instances.
    // var
    //    DemoDataFeed,      /* DemoDataFeedObj */
    this.LiveDataFeed = new LiveDataFeed(); /* LiveDataFeedObj */

    // Dynamically created array of tag records.
    this.TagRecordArray = []; /* GlgTagRecord[] */

    // Number of tags records in the TagRecordArray.
    this.NumTagRecords = 0; /* int */

    this.DrawingArea = null; /* GlgObject : Subwindow object in top level drawing */
    this.DrawingAreaVP = null; /* GlgObject : Viewport loaded into the Subwindow */
    this.Menu = null; /* GlgObject : Navigation menu viewport */
    this.AlarmDialog = null; /* GlgObject : AlarmDialog viewport  */
    this.AlarmListVP = null; /* GlgObject : Viewport containing the alarm list */
    this.NumAlarmRows = 0; /* int : Number of visible alarms on one alarm page. */
    this.AlarmRows = []; /* GlgObject[] : Keeps object ID's of alarm rows for faster 
                      access. */
    this.EmptyDrawing = []; /* GlgObject */

    this.AlarmStartRow = 0; /* int: Scrolled state of the alarm list. */
    this.AlarmList = []; /* AlarmRecord[] : List of alarms. */

    this.AlarmDialogVisible = false; /* boolean */
    this.FirstAlarmDialog = true; /* boolean : Is used to show a help message when the
                                alarm dialog is shown the first time. */
    this.MessageDialog = null; /* GlgObject : Popup dialog used for messages. */
    this.StartDragging = false; /* boolean */

    // Array of active dialogs. Is used to open/close active dialogs.
    this.ActiveDialogs = null; /* GlgActiveDialogRecord[] */

    // Active popup menu. It is assumed that only one popup menu is active at a time.
    this.ActivePopupMenu = []; /* GlgActivePopupMenuRecord */

    this.UpdateInterval = 0; /* int : Update rate in msec for drawing animation. */
    this.AlarmUpdateInterval = 600000; /* int : Alarm update interval in msec. */

    this.timer = null;
    this.alarm_timer = null;

    this.AssetLoaded = { num_loaded: undefined };
    /* Predefined menu table, is used if the configuration file is not supplied, 
           or if the config file cannot be successfully parsed. 
        */
    this.MenuTable = [
      /* label, drawing name, tooltip, title */
      //    { name: "Solvent\nRecovery", filename:"process.g",
      //         file:"process.g", title:"Solvent Recovery System" },
      //    { name: "Water\nTreatment", filename:"scada_aeration.g",
      //         file:"scada_aeration.g", title:"Aeration Monitoring" },
      //    { name: "Eectrical\nCircuit", filename:"scada_electric.g",
      //         file:"scada_electric.g", title:"Electrical Circuit Monitoring" },
      //    { name: "Real-Time\nStrip-Chart", filename:"scada_chart.g",
      //         file:"scada_chart.g", title:"Real-Time Strip-Chart Sample" },
      //    { name: "Test Object\nCommands", filename:"/static/glg/scada_test_commands.g",
      //         file:"/static/glg/scada_test_commands.g", title:"Test Object Commands" },

      new this.GlgMenuRecord(
        "Solvent\nRecovery",
        "/static/glg/process.g",
        "/static/glg/process.g",
        "Solvent Recovery System"
      ),

      new this.GlgMenuRecord(
        "Water\nTreatment",
        "/static/glg/scada_aeration.g",
        "/static/glg/scada_aeration.g",
        "Aeration Monitoring"
      ),

      new this.GlgMenuRecord(
        "Eectrical\nCircuit",
        "/static/glg/scada_electric.g",
        "/static/glg/scada_electric.g",
        "Electrical Circuit Monitoring"
      ),

      new this.GlgMenuRecord(
        "Real-Time\nStrip-Chart",
        "/static/glg/scada_chart.g",
        "/static/glg/scada_chart.g",
        "Real-Time Strip-Chart Sample"
      ),

      new this.GlgMenuRecord(
        "Test Object\nCommands",
        "/static/glg/scada_test_commands.g",
        "/static/glg/scada_test_commands.g",
        "Test Object Commands"
      ),
    ];

    /* MenuArray is built dynamically by reading a configuration file.
           If a configuration file is not supplied or cannot be successfully 
           parsed, a predefined MenuTable is used to populate MenuArray.
        */
    this.MenuArray = []; /* GlgMenuRecord[] */
    this.NumMenuItems = 0; /* int */

    /* This objects are used to pass data to the load callback. They hava
           an "enabled" field that is used to cancel the current load request if 
           loading of another page (or another popup) was requested while the 
           current request has not finished. This may happen if the user clicks 
           on one page load button and then clicks on another page load button 
           before the first page finishes loading.
        */
    this.PageLoadRequest = null;
    this.PopupLoadRequest = null;

    /* Flag indicating how to supply a time stamp for a RealTimeChart embedded 
           into an HMI page: if set to 1, the application will supply a time stamp   
           explicitly. Otherwise, a time stamp will be supplied automatically by
           chart using current time. 
        */
    this.SUPPLY_PLOT_TIME_STAMP = false; /* boolean */

    // Is used by DataFeed to return data values.
    this.data_point = [];
    // this.data_point = new this.DataPoint();      /* DataPoint */
    this.SetDrawingSize = { size_index: undefined };
    this.AssetLoaded = { num_loaded: undefined };
    this.refreshed = false;
    // https://advait-cloud-glg-files.s3-ap-northeast-1.amazonaws.com/1/process_overview.g
    this.state = {
      DEFAULT_DRAWING_NAME: "/static/glg/" + this.props.match.params.filename,
      // F://GLG_AdvaitWebClient/writeCommand.g
      // DEFAULT_DRAWING_NAME: "/glg/tmp.g",
      DEFAULT_DRAWING_TITLE: "",
      // DrawingLoadRequest: {
      //     enabled: false,
      //     drawing_name: '',
      //     drawing_title : ''
      // },
      DrawingNameLoaded: null,
      MainViewport: null,
      msgStatus: false,
      msgType: "",
      msgContent: "",
      isSpinner: false,
      dOpen: false,
      writeConfirm: false,
      write_vp: null,
      write_obj: null,
      writecmd_obj: null,
      write_type: "",
      password: "",
      confirmOpen: false,
      authentication_status: false,
    };
  }

  componentWillUnmount() {
    // alert('interval end..')
    // if (window.performance) {
    //   if (performance.navigation.type == 1) {
    //     this.refreshed = true;
    //   } else {
    //     this.refreshed = false;
    //   }
    // }
    this.clearTimeOUT();
  }

  clearTimeOUT() {
    clearInterval(this.timer);
    this.timer = 0;
  }

  componentWillMount() {
    // if(this.props.match.params.filename.split('_')[1] == undefined){
    //   this.state.DEFAULT_DRAWING_NAME = this.props.match.params.filename
    // }else {
    this.state.DEFAULT_DRAWING_NAME = this.props.match.params.filename.split(
      "_"
    )[1];
    this.assetId = this.props.match.params.filename.split("_")[0];
    // }
    // alert("componentWillMount "+this.state.DEFAULT_DRAWING_NAME)
    // window.open("http://localhost:8102/static/glg_demo/scada_viewer.html", "_blank");
    // alert("file name: "+this.props.match.params.filename);  //** Get the GLG filename from URL */
    // this.LoadDrawing("/static/glg/scada_main.g", "Main");
    // this.LoadAssets( this.LoadMainDrawing.bind(this) );
  }

  componentDidUpdate(prevProps) {
    //    this.state.DEFAULT_DRAWING_NAME = this.props.match.params.filename;
    // alert("componentDidUpdate "+this.state.DEFAULT_DRAWING_NAME)
    let currFile = " ",
      prevFile = "";
    //   if(this.props.match.params.filename.split('_'[1]) != undefined){
    //      currFile = this.props.match.params.filename.split('_'[1]);
    //   }else{
    //     currFile = this.props.match.params.filename;
    //   }

    //   if(this.props.match.params.filename.split('_'[1]) != undefined){
    //     currFile = this.props.match.params.filename.split('_'[1]);
    //  }else{
    //    currFile = this.props.match.params.filename;
    //  }

    // alert(localStorage.getItem('trigger'))

    this.assetId = this.props.match.params.filename.split("_")[0];
    if (localStorage.getItem("trigger") == "true") {
      // this.refreshed = false;
      localStorage.setItem("trigger", "false");
      this.state.isSpinner = true;
      this.InitAfterSetup();
    } else {
      if (
        this.props.match.params.filename.split("_")[1] !==
        prevProps.match.params.filename.split("_")[1]
      ) {
        this.state.isSpinner = true;
        this.InitAfterSetup();
      }
    }
    // window.open(window.location.protocol+'//'+window.location.hostname+":8102/static/glg_demo/scada_viewer.html?file=scada_test_commands.g", "_blank");

    // this.LoadMainDrawing();
    // this.LoadAssets();
    // this.LoadDrawing("/static/glg/scada_main.g", "Main");
    // this.LoadAssets( this.LoadMainDrawing.bind(this) );
  }

  componentDidMount() {
    this.CoordScale = this.SetCanvasResolution();
    this.LoadMainDrawing();
    this.LoadAssets();
    // this.LoadAssets( this.ProcessLoadRequest.bind(this), this.DrawingLoadRequest );
  }

  // LoadDrawing( /*String*/ filename, /*String*/ title) {
  //     /* Don't reload the drawing if the filename is invalid or it matches
  //     currently loaded drawing name.
  //     */
  //     // if (filename === null || filename === undefined || filename === this.state.DrawingNameLoaded)
  //     //     return;

  //     // New drawing was requested, cancel any pending drawing load requests.
  //     // this.AbortPendingLoadRequests();
  //     // Create a new load request.
  //     this.DrawingLoadRequest = { enabled: true, drawing_name: filename, drawing_title: title };

  //     // Proceed to the drawing loading.
  //     this.ProcessLoadRequest(this.DrawingLoadRequest);
  // }

  // //////////////////////////////////////////////////////////////////////////////
  // ProcessLoadRequest( /*Object*/ load_request) {
  //     // Display status info.
  //     // this.DisplayStatus("Loading drawing, please wait: " + load_request.drawing_name);

  //     /* Load a drawing from the specified drawing file.
  //     The LoadCB callback will be invoked when the drawing has been loaded.
  //     */
  //     //    alert(JSON.stringify(load_request))
  //     this.GLG.LoadWidgetFromURL( /*String*/ load_request.drawing_name, null, this.LoadCB.bind(this),
  //                         /*user data*/ load_request);
  // }

  //////////////////////////////////////////////////////////////////////////////
  LoadMainDrawing() {
    /* Load a drawing from the scada_main.g file. 
        The LoadCB callback will be invoked when the drawing has been loaded.
        */
    this.GLG.LoadWidgetFromURL(
      "/static/glg/scada_main.g",
      null,
      this.LoadCB.bind(this),
      null
    );
  }

  LoadCB(drawing, data, path) {
    if (drawing == null) {
      alert("Can't load drawing, check console message for details.");
      return;
    }

    // Define the element in the HTML page to display the drawing in.
    drawing.SetParentElement("glg_area");

    // Disable viewport border to use the border of the glg_area.
    drawing.SetDResource("LineWidth", 0);

    this.StartSCADADemo(drawing);
  }

  //////////////////////////////////////////////////////////////////////////////
  StartSCADADemo(drawing) {
    this.state.MainViewport = drawing;

    this.InitBeforeSetup();

    this.state.MainViewport.SetupHierarchy();

    this.InitAfterSetup();

    this.state.MainViewport.Update(); /* Draw */

    // Start periodic updates.
    this.timer = setTimeout(this.UpdateDrawing.bind(this), this.UpdateInterval);
    // this.alarm_timer = setTimeout(() => this.ProcessAlarms(true), this.AlarmUpdateInterval);
  }

  //////////////////////////////////////////////////////////////////////////////
  InitBeforeSetup() {
    // Initialize MenuArray from the configuration file.
    // this.FillMenuArray(this.ConfigFileData);

    /* Initialize ActiveDialogs array and ActivePopupMenu. */
    this.InitActivePopups();

    /* Store an object ID of the viewport named Menu. It contains navigation
           buttons for switching between drawings.
        */
    // this.Menu = this.state.MainViewport.GetResourceObject("Menu");

    // Store an object ID of the Subwindow object named DrawingArea.
    this.DrawingArea = this.state.MainViewport.GetResourceObject("DrawingArea");
    if (this.DrawingArea == null) {
      // no DrawingArea found
      alert("Can't find DrawingArea Subwindow object.");
      return;
    }

    // Store an object ID of the AlarmDialog viewport.
    //    AlarmDialog = MainViewport.GetResourceObject( "AlarmDialog" );
    //    AlarmListVP = AlarmDialog.GetResourceObject( "Table" );

    /* Make AlarmDialog a free floating dialog. ShellType property
           can be also set at design time.
        */
    //    this.AlarmDialog.SetDResource( "ShellType", this.GLG.GlgShellType.DIALOG_SHELL );

    //    // Initialize alarm list (set initial values in the template).
    //    this.AlarmListVP.SetSResource( "Row/ID", "" );
    //    this.AlarmListVP.SetSResource( "Row/Description", "" );
    //    this.AlarmListVP.SetDResource( "Row/UseStringValue", 0.0 );
    //    this.AlarmListVP.SetDResource( "Row/DoubleValue", 0.0 );
    //    this.AlarmListVP.SetSResource( "Row/StringValue", "" );
    //    this.AlarmListVP.SetDResource( "Row/AlarmStatus", 0.0 );
    //    this.AlarmListVP.SetDResource( "Row/RowVisibility", 0.0 );

    // Make AlarmDialog initially invisible.
    //    this.AlarmDialog.SetDResource( "Visibility", 0.0 );
    // this.AlarmDialogVisible = false;

    //    // Set title for the AlarmDialog.
    //    this.AlarmDialog.SetSResource( "ScreenName", "Alarm List" );

    // Set initial state of the alarm button.
    // this.state.MainViewport.SetDResource("AlarmButton/Blinking", 0.0);

    /* Make Global PopupDialog a floating DialogShell type. */
    this.state.MainViewport.SetDResource(
      "PopupDialog/ShellType",
      this.GLG.GlgShellType.DIALOG_SHELL
    );
    // Make Global PopupDialog initially invisible.
    this.state.MainViewport.SetDResource("PopupDialog/Visibility", 0.0);

    // Make Global PopupMenu initially invisible.
    this.state.MainViewport.SetDResource("PopupMenu/Visibility", 0.0);

    // Make message dialog invisible on startup.
    this.MessageDialog = this.state.MainViewport.GetResourceObject(
      "MessageDialog"
    );
    this.MessageDialog.SetDResource("Visibility", 0.0);

    /* Make MessageDialog a free floating dialog. ShellType property
           can be also set at design time.
        */
    this.MessageDialog.SetDResource(
      "ShellType",
      this.GLG.GlgShellType.DIALOG_SHELL
    );

    // Delete the QuitButton used in a desktop version to exit the demo.
    // var quit_button = this.state.MainViewport.GetResourceObject("QuitButton");
    // var group = this.state.MainViewport.GetResourceObject("ControlsGroup");
    // group.DeleteThisObject(quit_button);

    // Set the number of menu items. It must be done BEFORE hierarchy setup.
    // this.Menu.SetDResource("NumRows", this.NumMenuItems);

    // this.AdjustForMobileDevices();

    // this.NumAlarmRows = Math.trunc(this.AlarmListVP.GetDResource("NumRows"));

    /* Add Hierarchy Listener to handle events when a new drawing is loaded
           into a Subwindow object.
        */
    this.state.MainViewport.AddListener(
      this.GLG.GlgCallbackType.HIERARCHY_CB,
      this.HierarchyCallback.bind(this)
    );

    /* Add Input callback which is invoked when the user interacts with objects
           in a GLG drawing. It is used to handle events occurred in input objects,
           such as a menu, as well as Commands or Custom Mouse Events attached 
           to objects at design time.
        */
    this.state.MainViewport.AddListener(
      this.GLG.GlgCallbackType.INPUT_CB,
      this.InputCallback.bind(this)
    );

    /* Add Trace Listener used to handle low level events, such as obtaining
           coordinates of the mouse click, or keyboard events.
        */
    this.state.MainViewport.AddListener(
      this.GLG.GlgCallbackType.TRACE_CB,
      this.TraceCallback.bind(this)
    );

    this.HMIPage = new EmptyHMIPage(); // Initialize to empty page.
    this.CreateDataFeed();
  }

  //////////////////////////////////////////////////////////////////////////////
  AdjustForMobileDevices() {
    if (this.CoordScale == 1.0) return; // Desktop

    // Increases size of the menu area for mobile devices.
    this.state.MainViewport.SetDResource("CoordScale", 1.4);

    //    // Increase the size of the alarm dialog.
    //    AlarmDialog.SetDResource( "Height", 700 );
    //    AlarmDialog.SetDResource( "Width", 800 );
    //    AlarmDialog.SetGResource( "Point1", -750, 400, 0 );

    /* Increase height of alarm rows to make it easier to acknowledge an alarm
           bu touching the alarm row on mobile devices.
        */
    this.AlarmListVP.SetDResource("NumRows", 7);
    this.AlarmListVP.SetDResource("NumVisibleRows", 7);

    //    // Increase the size of the scroll controls in the alarm dialog.
    //    ScaleParameter( AlarmDialog, "ScrollWidth", 2.0 );
    //    ScaleParameter( AlarmDialog, "TopScrollHeight", 3.0 );
    //    ScaleParameter( AlarmDialog, "BottomScrollHeight", 3.0 );
    //    ScaleParameter( AlarmDialog, "HeaderHeight", 1.4 );

    // Adjust initial position of the popup dialog.
    this.state.MainViewport.SetGResource("PopupDialog/Point1", -400, 500, 0);

    // Increase the size of the message dialog.
    this.MessageDialog.SetDResource("CoordScale", 1.5);
  }

  //////////////////////////////////////////////////////////////////////////////
  InitAfterSetup() {
    // Initialize the navigation menu.
    // this.InitMenu();

    // // Store object ID's of alarm rows for faster access.
    // this.AlarmRows = new Array(this.NumAlarmRows);
    // for (var i = 0; i < this.NumAlarmRows; ++i)
    //     this.AlarmRows[i] = this.AlarmListVP.GetResourceObject("Row" + i);

    // Load drawing corresponding to the first menu item.

    this.state.isSpinner = false;

    this.assetId = this.props.match.params.filename.split("_")[0];
    this.LoadDrawingFromMenu(
      "/static/glg/" + this.props.match.params.filename.split("_")[1]
    );

    // this.LoadDrawingFromMenu("/static/glg/Pharmaa_process.g");
  }

  //////////////////////////////////////////////////////////////////////////////
  // Data feeds are reused for different pages - create just once.
  //////////////////////////////////////////////////////////////////////////////
  CreateDataFeed() {
    // DemoDataFeed = new DemoDataFeedObj();

    this.LiveDataFeed = new LiveDataFeed();
  }

  //////////////////////////////////////////////////////////////////////////////
  // Read MenuArray from a configuration file. If configuration file
  // is not supplied, use predefined MenuTable array.
  //////////////////////////////////////////////////////////////////////////////
  FillMenuArray(/* String */ config_data) {
    this.MenuArray = this.ReadMenuConfig(config_data);
    if (this.MenuArray == null) {
      console.log("Can't read config file: using predefined Menu Table.");

      // Fill MenuArry from a predefined array MenuTable.
      var num_items = this.MenuTable.length;
      this.MenuArray = [];
      // this.MenuArray = new Array(num_items);
      for (var i = 0; i < num_items; ++i) {
        this.MenuArray.push(this.MenuTable[i]);
      }
    }

    this.NumMenuItems = this.MenuArray.length;
  }

  //////////////////////////////////////////////////////////////////////////////
  // Initialize the navigation menu, a viewport named "Menu", based on
  // the menu records from the supplied configuration file.
  //////////////////////////////////////////////////////////////////////////////
  InitMenu() {
    var button; /* GlgObject */
    var menu_record; /* GlgMenuRecord */

    // Populate menu buttons based on the MenuArray.
    for (var i = 0; i < this.NumMenuItems; ++i) {
      button = this.Menu.GetResourceObject("Button" + i);

      menu_record = this.MenuArray[i];
      button.SetSResource("LabelString", menu_record.label_string);
      button.SetSResource("TooltipString", menu_record.tooltip_string);
    }

    // this.SelectMainMenuItem(this.NO_SCREEN, true);
  }

  //////////////////////////////////////////////////////////////////////////////
  UpdateDrawing() {
    this.UpdateData();

    // Restart update timer.
    this.timer = setTimeout(this.UpdateDrawing.bind(this), this.UpdateInterval);
  }

  getTagType(prop) {
    var generalSettingProp = [
      "DESC",
      "UNITID",
      "EQUIPID",
      "MQTTID",
      "PVADDR",
      "PVSCANPER",
      "ENGUNIT",
      "RAWCNTST",
      "RAWLRV",
      "RAWURV",
      "DRDB",
      "ENGURV",
      "ENGLRV",
      "CLAMP",
      "STATE0",
      "STATE1",
      "BITST",
      "BITADD",
      "GRPNUM",
      "GRPDESC",
      "ASODISP",
      "PV",
    ];
    var alarmSettingProp = [
      "IS_ALARM",
      "DB",
      "ROCDB",
      "PVHICHK",
      "PVHILMT",
      "PVHIDESC",
      "PVHIPR",
      "PVHISPR",
      "PVHHICHK",
      "PVHHLMT",
      "PVHHDESC",
      "PVHHPR",
      "PVHHSPR",
      "PVLCHK",
      "PVLLMT",
      "PVLDESC",
      "PVLPR",
      "PVLSPR",
      "PVLLCHK",
      "PVLLLMT",
      "PVLLDESC",
      "PVLLPR",
      "PVLLSPR",
      "PVROCLCHK",
      "PVROCLLMT",
      "PVROCLDESC",
      "PVROCLPR",
      "PVROCLSPR",
      "PVROCHCHK",
      "PVROCHLMT",
      "PVROCHDESC",
      "PVROCHPR",
      "PVROCHSPR",
      "ALMSTATE",
      "ALMSTDESC",
      "ALMSEC",
      "ALMDLY",
      "ALMNORMDESC",
    ];
    var controlSettingProp = [
      "IS_CONTROL",
      "OPSRC",
      "OPDEST",
      "SPSRC",
      "SPDEST",
      "KSRC",
      "KDEST",
      "ISRC",
      "IDEST",
      "DSRC",
      "DDEST",
      "REVOPSRC",
      "REVOPDEST",
      "MANPVSRC",
      "MANPVDEST",
      "BIASSRC",
      "BIASDEST",
      "CTRLSCANPER",
      "CTRLMODE",
      "CTRLCONF",
      "CTRLSEC",
      "CTRLLOLMT",
      "CTRLHILMT",
      "CTRLDB",
      "CTRLFAIL",
      "PSRC",
      "OPDEST",
      "CTRLSEC",
    ];
    var loggingSettingProp = [
      "IS_LOGGING",
      "COMPDB",
      "COMPUNIT",
      "LOGINTV",
      "ISCOMP",
    ];
    if (generalSettingProp.includes(prop.toUpperCase())) {
      return "general_setting";
    } else if (alarmSettingProp.includes(prop.toUpperCase())) {
      return "alarm";
    } else if (controlSettingProp.includes(prop.toUpperCase())) {
      return "control";
    } else if (loggingSettingProp.includes(prop.toUpperCase())) {
      return "logging";
    } else {
      return "";
    }
  }

  //////////////////////////////////////////////////////////////////////////////
  // Traverses an array of tag records, gets new data for each tag and
  // updates the drawing with new values.
  //////////////////////////////////////////////////////////////////////////////
  UpdateData() {
    /* Invoke a page's custom update method, if implemented.
           Don't update any tags if the custom method returns true.
        */
    // console.log('DataFeed ' + this.DataFeed);
    if (!this.HMIPage.UpdateData()) {
      /* Always update all tags defined in the current page, as well as 
               any additional tags defined in popup dialogs in the main drawing, 
               outside of the current page.
            */
      //    this.DataFeed = new LiveDataFeed();
      //    console.log(this.TagRecordArray[0].value+" Tags "+this.TagRecordArray[0].tag_source);

      var tags = [];
      let promise = new Promise((resolve, reject) => resolve());
      this.TagRecordArray.forEach((recArr, idx) => {
        // for (var j = 0; j < this.TagRecordArray.length; j++) {
        // console.log('recArr  ' + recArr['tag_source']);
        // alert(recArr['tag_source']);
        if (recArr["tag_source"].split("#")[1] == "PV") {
          tags.push(recArr["tag_source"].split("#")[0]);
        } else if (recArr["tag_source"].startsWith("(opc)")) {
          tags.push(recArr["tag_source"]);
        } else if (recArr["tag_source"].split("#")[1] != undefined) {
          let tagList = recArr;
          // alert(this.TagRecordArray[j]['tag_source']);
          promise = promise.then(() => {
            var tagProp = {
              tag_name: tagList["tag_source"].split("#")[0],
              prop_type: this.getTagType(tagList["tag_source"].split("#")[1]),
              operation: "read",
              tag_prop: tagList["tag_source"].split("#")[1],
            };

            // alert(this.TagRecordArray[j]['data_type']);
            var TagRecordArray = tagList;
            this.GLGService.liveTagProperty(tagProp).then((res) => {
              // alert(JSON.stringify(res));
              var data = [];
              // alert(TagRecordArray.data_type);
              if (res) {
                data.push(res);
                // console.log('live data res: ' + JSON.stringify(data));
                data.forEach((element) => {
                  // console.log('Element  ' + JSON.stringify(element));
                  if (Object.keys(element).length > 0) {

                    // alert(tagProp.tag_name + ' ele ' + element.value);
                    switch (TagRecordArray.data_type) {
                      case this.GLG.GlgDataType.D:
                        // if()
                        if (
                          element.value == undefined ||
                          element.value == " " ||
                          element.value == ""
                        ) {
                          new_data.push({
                            tag_source: TagRecordArray.tag_source,
                            value: 0,
                          });
                          TagRecordArray.value = 0;
                        } else {
                          new_data.push({
                            tag_source: TagRecordArray.tag_source,
                            value: parseFloat(element.value),
                          });
                          TagRecordArray.value = parseFloat(element.value);
                        }
                        this.TagRecordArray[idx] = TagRecordArray;
                        this.state.MainViewport.SetDTag(
                          TagRecordArray.tag_source,
                          TagRecordArray.value,
                          false
                        );
                        break;
                      case this.GLG.GlgDataType.S:
                        if (
                          element.value == undefined ||
                          element.value == " " ||
                          element.value == ""
                        ) {
                          new_data.push({
                            tag_source: TagRecordArray.tag_source,
                            value: "",
                          });
                          TagRecordArray.value = "";
                        } else {
                          new_data.push({
                            tag_source: TagRecordArray.tag_source,
                            value: element.value,
                          });
                          TagRecordArray.value = element.value;
                        }
                        this.TagRecordArray[idx] = TagRecordArray;

                        this.state.MainViewport.SetSTag(
                          TagRecordArray.tag_source,
                          TagRecordArray.value,
                          false
                        );
                        break;
                    }

                    // alert(
                    //   'Final ' +
                    //     this.TagRecordArray[idx].value +
                    //     ' -- ' +
                    //     this.TagRecordArray[idx].tag_source
                    // );
                    // for (var i = 0; i < this.TagRecordArray.length; i++) {}
                    // console.log('new data: ' + JSON.stringify(new_data));
                  }
                });
              } else {
                switch (TagRecordArray.data_type) {
                  case this.GLG.GlgDataType.D:
                    new_data.push({
                      tag_source: TagRecordArray.tag_source,
                      value: 0,
                    });
                    TagRecordArray.value = 0;
                    this.TagRecordArray[idx] = TagRecordArray;
                    this.state.MainViewport.SetDTag(
                      TagRecordArray.tag_source,
                      TagRecordArray.value,
                      false
                    );
                    break;
                  case this.GLG.GlgDataType.S:
                    new_data.push({
                      tag_source: TagRecordArray.tag_source,
                      value: "",
                    });
                    TagRecordArray.value = "";
                    this.TagRecordArray[idx] = TagRecordArray;
                    this.state.MainViewport.SetSTag(
                      TagRecordArray.tag_source,
                      TagRecordArray.value,
                      false
                    );
                    break;
                }
              }
              // alert(
              //   'Final ' +
              //     this.TagRecordArray[idx].value +
              //     ' -- ' +
              //     this.TagRecordArray[idx].tag_source
              // );
              this.state.MainViewport.Update();
            });
          });
        }
      });
      var data = [];
      var new_data = [];
      if (tags.length > 0) {
        this.GLGService.liveData(tags).then((res) => {
          // console.log(res);
          if (res) {
            data.push(res);
            data.forEach((element) => {
              // console.log('Element  ' + JSON.stringify(element));
              if (Object.keys(element).length > 0) {
                // console.log('Keys ' + Object.keys(element).length);
                for (var i = 0; i < this.TagRecordArray.length; i++) {
                  if (
                    this.TagRecordArray[i]["tag_source"].split("#")[1] !=
                    undefined
                  ) {
                    if (
                      this.TagRecordArray[i]["tag_source"]
                        .split("#")[1]
                        .toString() != "timestamp"
                    ) {
                      for (var p = 0; p < tags.length; p++) {
                        // alert(tags[p]);
                        if (
                          tags[p] ==
                          this.TagRecordArray[i]["tag_source"].split("#")[0]
                        ) {
                          switch (this.TagRecordArray[i].data_type) {
                            case this.GLG.GlgDataType.D:
                              if (
                                element[tags[p]] == undefined ||
                                element[tags[p]] == " " ||
                                element[tags[p]] == ""
                              ) {
                                new_data.push({
                                  tag_source: this.TagRecordArray[i][
                                    "tag_source"
                                  ],
                                  value: 0,
                                });
                                this.TagRecordArray[i]["value"] = 0;
                              } else {
                                new_data.push({
                                  tag_source: this.TagRecordArray[i][
                                    "tag_source"
                                  ],
                                  value: parseFloat(element[tags[p]]),
                                });
                                this.TagRecordArray[i]["value"] = parseFloat(
                                  element[tags[p]]
                                );
                              }
                              break;
                            case this.GLG.GlgDataType.S:
                              if (
                                element[tags[p]] == undefined ||
                                element[tags[p]] == " " ||
                                element[tags[p]] == ""
                              ) {
                                new_data.push({
                                  tag_source: this.TagRecordArray[i][
                                    "tag_source"
                                  ],
                                  value: "",
                                });
                                this.TagRecordArray[i]["value"] = "";
                              } else {
                                new_data.push({
                                  tag_source: this.TagRecordArray[i][
                                    "tag_source"
                                  ],
                                  value: element[tags[p]],
                                });
                                this.TagRecordArray[i]["value"] =
                                  element[tags[p]];
                              }

                              break;
                          }
                          // break;
                        }
                      }
                    }
                  } else if (
                    this.TagRecordArray[i]["tag_source"].startsWith("(opc)")
                  ) {
                    for (var p = 0; p < tags.length; p++) {
                      // alert(tags[p]);
                      if (tags[p] == this.TagRecordArray[i]["tag_source"]) {
                        switch (this.TagRecordArray[i].data_type) {
                          case this.GLG.GlgDataType.D:
                            if (
                              element[tags[p]] == undefined ||
                              element[tags[p]] == " " ||
                              element[tags[p]] == ""
                            ) {
                              new_data.push({
                                tag_source: this.TagRecordArray[i][
                                  "tag_source"
                                ],
                                value: 0,
                              });
                              this.TagRecordArray[i]["value"] = 0;
                            } else {
                              new_data.push({
                                tag_source: this.TagRecordArray[i][
                                  "tag_source"
                                ],
                                value: parseFloat(element[tags[p]]),
                              });
                              this.TagRecordArray[i]["value"] = parseFloat(
                                element[tags[p]]
                              );
                            }
                            break;
                          case this.GLG.GlgDataType.S:
                            if (
                              element[tags[p]] == undefined ||
                              element[tags[p]] == " " ||
                              element[tags[p]] == ""
                            ) {
                              new_data.push({
                                tag_source: this.TagRecordArray[i][
                                  "tag_source"
                                ],
                                value: "",
                              });
                              this.TagRecordArray[i]["value"] = "";
                            } else {
                              new_data.push({
                                tag_source: this.TagRecordArray[i][
                                  "tag_source"
                                ],
                                value: element[tags[p]],
                              });
                              this.TagRecordArray[i]["value"] =
                                element[tags[p]];
                            }

                            break;
                        }
                        // break;
                      }
                    }
                  }
                }
                // console.log('new data: ' + JSON.stringify(new_data));
              }
            });
          } else {
            for (var i = 0; i < this.TagRecordArray.length; i++) {
              if (
                this.TagRecordArray[i]["tag_source"].split("#")[1] != undefined
              ) {
                if (
                  this.TagRecordArray[i]["tag_source"]
                    .split("#")[1]
                    .toString() != "timestamp"
                ) {
                  switch (this.TagRecordArray[i].data_type) {
                    case this.GLG.GlgDataType.D:
                      new_data.push({
                        tag_source: this.TagRecordArray[i]["tag_source"],
                        value: 0,
                      });
                      this.TagRecordArray[i]["value"] = 0;
                      break;
                    case this.GLG.GlgDataType.S:
                      new_data.push({
                        tag_source: this.TagRecordArray[i]["tag_source"],
                        value: "",
                      });
                      this.TagRecordArray[i]["value"] = "";
                      break;
                  }
                }
              } else if (
                this.TagRecordArray[i]["tag_source"].startsWith("(opc)")
              ) {
                switch (this.TagRecordArray[i].data_type) {
                  case this.GLG.GlgDataType.D:
                    new_data.push({
                      tag_source: this.TagRecordArray[i]["tag_source"],
                      value: 0,
                    });
                    this.TagRecordArray[i]["value"] = 0;
                    break;
                  case this.GLG.GlgDataType.S:
                    new_data.push({
                      tag_source: this.TagRecordArray[i]["tag_source"],
                      value: "",
                    });
                    this.TagRecordArray[i]["value"] = "";
                    break;
                }
              }
            }
          }

          var num_tag_records = this.TagRecordArray.length; /* int */
          for (var i = 0; i < num_tag_records; ++i) {
            var tag_record = this.TagRecordArray[i]; /* GlgTagRecord */

            switch (tag_record.data_type) {
              case this.GLG.GlgDataType.D:
                // Obtain a new numerical data value for a given tag.
                // if (this.DataFeed.ReadDTag(tag_record, this.data_point)) {
                /* Push a new data value into a given tag. The last argument 
                        indicates whetgher or not to set the value depending if the 
                        value has changed. If set to true, push the value only if 
                        it has changed.  Otherwise, a new value is always pushed 
                        into the object.
                        */
                //    this.state.MainViewport.SetDTag(tag_record.tag_source, tag_record.value,
                // tag_record.if_changed);
                // console.log('tag_record.value ' + tag_record.value);
                this.state.MainViewport.SetDTag(
                  tag_record.tag_source,
                  tag_record.value,
                  false
                );

                /* Push a time stamp to the TimeEntryPoint of a plot in 
                        a real-time chart, if found.
                        */
                // if (tag_record.plot_time_ep != null)
                //     tag_record.plot_time_ep.SetDResource(null,
                //         this.data_point.time_stamp);
                // }
                break;

              case this.GLG.GlgDataType.S:
                //         // Obtain a new string data value for a given tag.
                //         if (this.DataFeed.ReadSTag(tag_record, this.s_data_point)) {
                //             // Push new data.
                this.state.MainViewport.SetSTag(
                  tag_record.tag_source,
                  tag_record.value,
                  false
                );
                //         }
                break;
            }
          }
        });
      }
      // Update the drawing with new data.
      this.state.MainViewport.Update();
    }
  }

  // Fills AlarmList dialog with received alarm data.
  //////////////////////////////////////////////////////////////////////////////
  ProcessAlarms(/* boolean */ query_new_list) {
    if (query_new_list)
      // Get a new alarm list.
      // this.AlarmList = this.DataFeed.GetAlarms();

      var num_alarms; /* int */
    if (this.AlarmList == null) num_alarms = 0;
    else num_alarms = this.AlarmList.length;

    // Activate Alarms button's blinking if there are unacknowledged alarms.
    var has_active_alarms = false; /* boolean  */
    for (var i = 0; i < num_alarms; ++i) {
      var alarm = this.AlarmList[i]; /* AlarmRecord */
      if (!alarm.ack) {
        has_active_alarms = true;
        break;
      }
    }
    this.state.MainViewport.SetDResourceIf(
      "AlarmButton/Blinking",
      has_active_alarms ? 1.0 : 0.0,
      true
    );

    if (!this.AlarmDialogVisible) {
      this.state.MainViewport.Update();
      return;
    }

    // Fill alarm rows starting with the AlarmStartRow that controls scrolling.
    var num_visible = num_alarms - this.AlarmStartRow; /* int */
    if (num_visible < 0) num_visible = 0;
    else if (num_visible > this.NumAlarmRows) num_visible = this.NumAlarmRows;

    // Fill alarm rows.
    var alarm_row; /* GlgObject */
    for (var i = 0; i < num_visible; ++i) {
      var alarm = this.AlarmList[i]; /* AlarmRecord */
      alarm_row = this.AlarmRows[i];

      alarm_row.SetDResourceIf("AlarmIndex", this.AlarmStartRow + i + 1, true);
      alarm_row.SetDResourceIf("TimeInput", alarm.time, true);
      alarm_row.SetSResourceIf("ID", alarm.tag_source, true);
      alarm_row.SetSResourceIf("Description", alarm.description, true);

      // Set to 1 to supply string value via the StringValue resource.
      // Set to 0 to supply double value via the DoubleValue resource.
      alarm_row.SetDResourceIf(
        "UseStringValue",
        alarm.string_value == null ? 0.0 : 1.0,
        true
      );
      if (alarm.string_value == null)
        alarm_row.SetDResourceIf("DoubleValue", alarm.double_value, true);
      else alarm_row.SetSResourceIf("StringValue", alarm.string_value, true);

      alarm_row.SetDResourceIf("RowVisibility", 1.0, true);
      alarm_row.SetDResourceIf("AlarmStatus", alarm.status, true);

      /* Enable blinking: will be disabled when alarm is ACK'ed. */
      alarm_row.SetDResourceIf("BlinkingEnabled", alarm.ack ? 0.0 : 1.0, true);
    }

    /* Empty the rest of the rows. Use true as the last parameter to update
           only if the value changes.
        */
    for (var i = num_visible; i < this.NumAlarmRows; ++i) {
      alarm_row = this.AlarmRows[i];

      alarm_row.SetDResourceIf("AlarmIndex", this.AlarmStartRow + i + 1, true);
      alarm_row.SetSResourceIf("ID", "", true);

      // Set status to normal to unhighlight the rightmost alarm field.
      alarm_row.SetDResourceIf("AlarmStatus", 0.0, true);

      // Make all text labels invisible.
      alarm_row.SetDResourceIf("RowVisibility", 0.0, true);

      alarm_row.SetDResourceIf("BlinkingEnabled", 0.0, true);
    }

    this.AlarmDialog.Update();

    // Restart alarm update timer.
    // if (query_new_list)
    // this.alarm_timer =
    //     setTimeout(() => this.ProcessAlarms(true), this.AlarmUpdateInterval);
  }

  //////////////////////////////////////////////////////////////////////////////
  // Input callback is invoked when the user interacts with objects in a
  // GLG drawing. It is used to handle events occurred in input objects,
  // such as a menu, as well as Commands or Custom Mouse Events attached
  // to objects at design time.
  //////////////////////////////////////////////////////////////////////////////
  InputCallback(/* GlgObject */ viewport, /* GlgObject */ message_obj) {
    var origin /* String */, format /* String */, action; /* String */

    var menu_index; /* int */
    var selected_obj; /* GlgObject */
    var action_obj; /* GlgObject */

    /* Return if the page's custom input handler processed the input.
           Otherwise, continue to process common events and commands.
        */
    // console.log("HMI Page " + this.HMIPage)
    if (this.HMIPage.InputCallback(viewport, message_obj)) return;

    origin = message_obj.GetSResource("Origin");
    format = message_obj.GetSResource("Format");
    action = message_obj.GetSResource("Action");

    // Retrieve selected object.
    selected_obj = message_obj.GetResourceObject("Object");

    // Handle events from the screen navigation menu, named "Menu".
    // console.log("format " + format)
    // if (format == "Menu") {
    //     if (action != "Activate" || origin != "Menu")
    //         return;

    //     /* User selected a button from the menu object named Menu.
    //        Load a new drawing associated with the selected button.
    //     */
    //     menu_index = Math.trunc(message_obj.GetDResource("SelectedIndex"));

    //     /* Load the drawing associated with the selected menu button and
    //        display it in the DrawingArea.
    //     */
    //     this.LoadDrawingFromMenu(menu_index);

    //     viewport.Update();
    // }

    /* Handle custom commands attached to objects in the drawing at 
           design time.
        */

    if (format == "Command") {
      // console.log("format  " + format);
      action_obj = message_obj.GetResourceObject("ActionObject");
      this.ProcessObjectCommand(viewport, selected_obj, action_obj);
      viewport.Update();
    }

    // Handle zoom controls on the left.
    else if (format == "Button") {
      if (
        origin == null ||
        (action != "Activate" /* Not a push button */ &&
          action != "ValueChanged")
      )
        /* Not a toggle button */
        return;

      if (origin == "MessageDialogOK") {
        // Close alarm dialog.
        this.MessageDialog.SetDResource("Visibility", 0.0);
        this.MessageDialog.Update();
      } else if (origin == "Left") this.Zoom(this.DrawingAreaVP, "l", 0.1);
      /* Zoom and pan buttons. */ else if (origin == "Right")
        this.Zoom(this.DrawingAreaVP, "r", 0.1);
      else if (origin == "Up") this.Zoom(this.DrawingAreaVP, "u", 0.1);
      else if (origin == "Down") this.Zoom(this.DrawingAreaVP, "d", 0.1);
      else if (origin == "ZoomIn") this.Zoom(this.DrawingAreaVP, "i", 1.5);
      else if (origin == "ZoomOut") this.Zoom(this.DrawingAreaVP, "o", 1.5);
      else if (origin == "ZoomTo") {
        this.StartDragging = true;
        this.Zoom(this.DrawingAreaVP, "t", 0.0);
      } else if (origin == "ZoomReset") this.Zoom(this.DrawingAreaVP, "n", 0.0);
      /* Alarm scrolling buttons. */ else if (origin == "ScrollToTop") {
        this.AlarmStartRow = 0;
        // this.ProcessAlarms(false);
      } else if (origin == "ScrollUp") {
        --this.AlarmStartRow;
        if (this.AlarmStartRow < 0) this.AlarmStartRow = 0;
        // this.ProcessAlarms(false);
      } else if (origin == "ScrollUp2") {
        this.AlarmStartRow -= this.NumAlarmRows;
        if (this.AlarmStartRow < 0) this.AlarmStartRow = 0;
        // this.ProcessAlarms(false);
      } else if (origin == "ScrollDown") {
        ++this.AlarmStartRow;
        // this.ProcessAlarms(false);
      } else if (origin == "ScrollDown2") {
        this.AlarmStartRow += this.NumAlarmRows;
        // this.ProcessAlarms(false);
      } else return;

      viewport.Update();
    }

    // Handle custom events.
    else if (format == "CustomEvent") {
      var event_label = message_obj.GetSResource("EventLabel"); /* String */
      var action_data = null; /* GlgObject */

      if (event_label == null || event_label.length == 0) return; // don't process events with empty EventLabel.

      /* Retrieve action object. It will be null for custom events 
               added prior to GLG v.3.5. 
               If action object is present, retrieve its ActionData object.
               If Action Data object present, use its properties for custom event 
               processing as needed.
            */
      action_obj = message_obj.GetResourceObject("ActionObject");
      if (action_obj != null)
        action_data = action_obj.GetResourceObject("ActionData");

      if (event_label == "AlarmRowACK") {
        // The object ID of the alarm row selected by Ctrl-click.
        var alarm_row = selected_obj; /* GlgObject */

        // Retrieve the tag source.
        var tag_source = alarm_row.GetSResource("ID"); /* String */

        this.DataFeed.ACKAlarm(tag_source);
      } else {
        /* Place custom code here to handle custom events as needed. */
      }

      viewport.Update();
    } else if (format == "Chart" && action == "CrossHairUpdate") {
      /* Handle events from a Real-Time Chart. */
      /* To avoid slowing down real-time chart updates, invoke Update() 
               to redraw cross-hair only if the chart is not updated fast 
               enough by the timer.
            */
      if (this.UpdateInterval > 5000) viewport.Update();
    }

    // Handle Timer events, generated by objects with blinking dynamics.
    else if (format == "Timer") viewport.Update();
    // Handle window closing events.
    else if (format == "Window" && action == "DeleteWindow") {
      if (selected_obj == null) return;

      if (selected_obj.Equals(this.MessageDialog)) {
        this.MessageDialog.SetDResource("Visibility", 0.0);
        this.MessageDialog.Update();
      } else if (selected_obj.Equals(this.AlarmDialog)) {
        this.ShowAlarms(); // Toggle the state of alarms dialog to erase it.
      } else {
        /* If the closing window is found in the ActiveDialogs array, 
                   close the active dialog. 
                */
        for (var i = 0; i < this.MAX_DIALOG_TYPE; ++i)
          if (selected_obj.Equals(this.ActiveDialogs[i].dialog)) {
            this.ClosePopupDialog(i);
            break;
          }
      }

      viewport.Update();
    }
  }

  // Is used to handle low level events, such as obtaining coordinates of
  // the mouse click, or keyboard events.
  //////////////////////////////////////////////////////////////////////////////
  TraceCallback(/* GlgObject */ viewport, /* GlgTraceData */ trace_info) {
    // Detect touch device if it hasn't been detected yet.
    // console.log("TouchDevice  " + this)
    if (this.TouchDevice == -1) {
      if (trace_info.event_type == this.GLG.GlgEventType.TOUCH_START) {
        this.TouchDevice = 1;

        /* Allow touch actions regardless of the mouse button and the control key
                   state, which are not present on mobile devices.
                */
        viewport.SetDResource("$config/GlgDisableMouseButtonCheck", 1);
        viewport.SetDResource("$config/GlgDisableControlKeyCheck", 1);
      } else if (trace_info.event_type == this.GLG.GlgEventType.MOUSE_PRESSED)
        this.TouchDevice = 0;
    }
    /* Return if the page's custom trace callback processed the event.
           Otherwise, continue to process common events.
        */
    // if (this.HMIPage.TraceCallback(viewport, trace_info))
    //     return;

    var x, y; /* double */
    var event_vp = trace_info.viewport; /* GlgObject */

    var event_type = trace_info.event_type;
    switch (event_type) {
      case this.GLG.GlgEventType.TOUCH_START:
        // On mobile devices, enable touch dragging for defining ZoomTo region.
        if (!this.StartDragging) return;

        this.GLG.SetTouchMode(); /* Start dragging via touch events. */
        this.StartDragging = false; /* Reset for the next time. */
      /* Fall through */

      case this.GLG.GlgEventType.TOUCH_MOVED:
        if (!this.GLG.GetTouchMode()) return;
      case this.GLG.GlgEventType.MOUSE_PRESSED:
      case this.GLG.GlgEventType.MOUSE_MOVED:
        // Obtain mouse coordinates.
        x = trace_info.mouse_x * this.CoordScale;
        y = trace_info.mouse_y * this.CoordScale;

        /* COORD_MAPPING_ADJ is added to the cursor coordinates for precise
                   pixel mapping.
                */
        x += this.GLG.COORD_MAPPING_ADJ;
        y += this.GLG.COORD_MAPPING_ADJ;
        break;

      case this.GLG.GlgEventType.KEY_DOWN:
        // Handle key codes as needed.
        switch (trace_info.event.keyCode) {
          case 27: // ESC key
            break;
          default:
            break;
        }
        break;
    }
  }

  //////////////////////////////////////////////////////////////////////////////
  // Hierarchy callback, added to the top level viewport and invoked when
  // a new drawing is loaded into any subwindow or subdrawing object.
  // In this demo, this callback processes events only from the reference
  // of type "Subwindow" (such as DrawingArea).
  //////////////////////////////////////////////////////////////////////////////
  HierarchyCallback(
    /* GlgObject */ viewport,
    /* GlgHierarchyData */ info_data
  ) {
    // Handle events only from Subwindow-type reference objects.
    var obj_type /* int */ = Math.trunc(
      info_data.object.GetDResource("ReferenceType")
    );

    if (obj_type != this.GLG.GlgReferenceType.SUBWINDOW_REF) return;

    /* This callback is invoked twice: one time before hierarchy setup
           for the new drawing, and second time after hierarchy setup.
           Drawing initialization can be done here if needed.
        */
    switch (info_data.condition) {
      case this.GLG.GlgHierarchyCallbackType.BEFORE_SETUP_CB:
        var drawing_vp = info_data.subobject; /* GlgObject */
        // console.log("drawing_vp "+JSON.stringify(drawing_vp))
        if (drawing_vp == null) {
          alert("Drawing loading failed");
          if (info_data.object.Equals(this.DrawingArea)) {
            this.DrawingAreaVP = null;
            return;
          }
        }

        /* Set "ProcessMouse" attribute for the loaded viewport, to process
                   custom events and tooltips.
                */
        drawing_vp.SetDResource(
          "ProcessMouse",
          this.GLG.GlgProcessMouseMask.MOUSE_CLICK |
            this.GLG.GlgProcessMouseMask.MOUSE_OVER_TOOLTIP
        );

        /* Set "OwnsInputCB" attribute for the loaded viewport,
                   so that Input callback is invoked with this viewport ID.
                */
        drawing_vp.SetDResource("OwnsInputCB", 1.0);

        if (info_data.object.Equals(this.DrawingArea)) {
          this.DrawingAreaVP = this.GLG.GetReference(drawing_vp);
          this.SetupHMIPage();

          /* Initialize loaded drawing before setup. */
          this.HMIPage.InitBeforeSetup();

          // this.HMIPage.AdjustForMobileDevices();
        }
        break;

      case this.GLG.GlgHierarchyCallbackType.AFTER_SETUP_CB:
        /* Initialize loaded drawing after setup. */
        if (info_data.object.Equals(this.DrawingArea))
          this.HMIPage.InitAfterSetup();
        break;

      default:
        break;
    }
  }

  //////////////////////////////////////////////////////////////////////////////
  // Assigns a new page to HMIPage variable, either as a default or
  // custom HMI page, based on the PageType property of the loaded
  // drawing. The HMI page class handles the page logic and user
  // interaction.
  //
  // If the PageType property doesn't exist in the drawing or is set to
  // "Default", DefaultHMIPage class is used for the new page.
  // Otherwise, a page class type corresponding to the PageType property
  // is assigned to HMIPage variable. For example, RTChartPage class is
  // used if PageType property of the loaded drawing is set to
  // "RealTimeChart".
  //
  // The method also assigns DataFeed for the new page.
  //////////////////////////////////////////////////////////////////////////////
  SetupHMIPage() {
    this.PageType = this.GetPageType(this.DrawingAreaVP); // Get page type.

    /* Use live data if requested with the -live-data command-line option, 
           otherwise use simulated random data for testing. 
           RANDOM_DATA may be set to false to use live data by default.
        */

    // if (this.RANDOM_DATA) { }
    // // DataFeed = DemoDataFeed;
    // else
    this.DataFeed = this.LiveDataFeed;

    switch (this.PageType) {
      case this.UNDEFINED_PAGE_TYPE:
        this.HMIPage = new EmptyHMIPage();
        break;

      case this.DEFAULT_PAGE_TYPE:
      case this.AERATION_PAGE:
      case this.CIRCUIT_PAGE:
        this.HMIPage = new DefaultHMIPage(
          this.PageType,
          this.RandomData,
          this.AERATION_PAGE,
          this.CIRCUIT_PAGE,
          this.RT_CHART_PAGE,
          this.TEST_COMMANDS_PAGE,
          this.state.MainViewport
        );
        break;

      case this.PROCESS_PAGE:
        this.HMIPage = new ProcessPage(
          this.DrawingAreaVP,
          this.GLG,
          this.RandomData,
          this.DataFeed,
          this.CoordScale
        );
        break;

      case this.RT_CHART_PAGE:
        this.HMIPage = new RTChartPage(
          this.DrawingAreaVP,
          this.GLG,
          this.RandomData,
          this.DataFeed,
          this.CoordScale
        );
        break;

      case this.TEST_COMMANDS_PAGE:
        // Test page: always use demo data.
        // this.DataFeed = this.DemoDataFeed;
        this.DataFeed = this.LiveDataFeed;

        this.HMIPage = new DefaultHMIPage(
          this.PageType,
          this.RandomData,
          this.AERATION_PAGE,
          this.CIRCUIT_PAGE,
          this.RT_CHART_PAGE,
          this.TEST_COMMANDS_PAGE,
          this.state.MainViewport
        );
        break;

      default:
        /* New custom page: use live data if requested with the -live-data 
                   command-line option, otherwise use simulated random data for 
                   testing. RANDOM_DATA may be set to false to use live data by 
                   default.
                */
        this.HMIPage = new DefaultHMIPage(
          this.PageType,
          this.RandomData,
          this.AERATION_PAGE,
          this.CIRCUIT_PAGE,
          this.RT_CHART_PAGE,
          this.TEST_COMMANDS_PAGE
        );
        break;
    }

    this.UpdateInterval = this.HMIPage.GetUpdateInterval();

    // True if DemoDataFeed is used for the current page.
    this.RandomData = this.DataFeed == this.DemoDataFeed;
  }

  //////////////////////////////////////////////////////////////////////////////
  // Load a new drawing into the DrawingArea when the user selects an item
  // from the navigation menu object (Menu object).
  //////////////////////////////////////////////////////////////////////////////
  LoadDrawingFromMenu(/* int */ page_name) {
    // if (!this.PageIndexValid(page_index))
    //     return;   // Invalid page index - don't load.

    // Close active popup dialogs and popup menu, if any.
    this.CloseActivePopups(this.CLOSE_ALL);
    // console.log(JSON.stringify(this.MenuArray) + " Get IT " + page_index)
    // var menu_record = this.MenuArray[page_index];   /* GlgMenuRecord */

    if (page_name == null || page_name.length == 0) {
      alert("Can't load drawing - missing filename.");
      return;
    }

    this.AbortPendingPageLoads(); // Cancel any pending page load requests.

    // this.SetLoadingMessage(menu_record.drawing_title);

    /* Store a new load request in a global variable to be able to abort it
           if needed.
        */
    this.PageLoadRequest = { enabled: true, page_name: page_name };
    // console.log("Menu Load drawing  "+menu_record.drawing_name)
    // Request to load the new drawing and invoke the callback when it's ready.
    this.GLG.LoadObjectFromURL(
      page_name,
      null,
      this.LoadDrawingFromMenuCB.bind(this),
      this.PageLoadRequest,
      this.AbortLoad,
      /* subwindow to handle bindings */ this.DrawingArea
    );
  }

  //////////////////////////////////////////////////////////////////////////////
  // This function is invoked after the drawing's raw data has been downloaded
  // and before loading the drawing from raw data. If the function returns true,
  // loading the drawing from raw data is aborted.
  //////////////////////////////////////////////////////////////////////////////
  AbortLoad(load_request) /* boolean */ {
    // Return true to abort if the load request was cancelled.
    return !load_request.enabled;
  }

  // Cancels any pending page load requests.
  //////////////////////////////////////////////////////////////////////////////
  AbortPendingPageLoads() {
    if (this.PageLoadRequest != null) {
      this.PageLoadRequest.enabled = false;
      this.PageLoadRequest = null;
    }
  }

  //////////////////////////////////////////////////////////////////////////////
  // Cancels any pending popup load requests.
  //////////////////////////////////////////////////////////////////////////////
  AbortPendingPopupLoads() {
    if (this.PopupLoadRequest != null) {
      this.PopupLoadRequest.enabled = false;
      this.PopupLoadRequest = null;
    }
  }

  //////////////////////////////////////////////////////////////////////////////
  LoadDrawingFromMenuCB(
    /* GlgObject */ loaded_drawing,
    /* Object */ user_data,
    /* String */ path
  ) {
    var load_request = user_data;

    if (!load_request.enabled)
      /* This load request was aborted by requesting to load another page before
               this load request has finished.
            */
      return;

    this.PageLoadRequest = null; // Reset: we are done with this request.

    if (loaded_drawing == null) {
      alert("Drawing loading failed");
      return;
    }

    /* Close active popup dialogs and popup menu again: they might be activated
           while waiting for the new page being loaded.
        */
    this.CloseActivePopups(this.CLOSE_ALL);

    this.AbortPendingPopupLoads(); // Cancel any pending popup load requests.

    /* Loads a new drawing into the DrawingArea subwindow object.
           DrawingAreaVP is assigned in the Hierarchy callback to the 
           ID of the $Widget viewport of the loaded drawing.
        */
    this.SetDrawing(this.DrawingArea, loaded_drawing);
    if (this.DrawingAreaVP == null) {
      this.DeleteTagRecords();
      this.PageType = this.UNDEFINED_PAGE_TYPE;
      this.HMIPage = new EmptyHMIPage();
      return;
    }

    /* Query a list of tags from the loaded drawing and build a new
           TagRecordArray.
        */
    this.QueryTags(this.DrawingAreaVP);

    // var page_index = load_request.page_index;    /* int */
    // this.SelectMainMenuItem(page_index);

    // Set title and background color.
    // var title = this.MenuArray[page_index].drawing_title;   /* String */
    // this.SetupLoadedPage(title);

    if (this.CoordScale != 1.0)
      /* Increase pick resolution for the water treatment page to make it easier
               to select pump motors by touching on mobile devices.
            */
      this.state.MainViewport.SetDResource(
        "$config/GlgPickResolution",
        this.PageType == this.AERATION_PAGE ? 30 : 5
      );

    this.HMIPage.Ready();

    this.state.MainViewport.Update();
  }

  //////////////////////////////////////////////////////////////////////////////
  SetLoadingMessage(/* String */ title) {
    this.state.MainViewport.SetSResource(
      "Title",
      "Loading " + title + " page..."
    );
    this.state.MainViewport.Update();
  }

  //////////////////////////////////////////////////////////////////////////////
  SetupLoadedPage(/* String */ title) {
    // Set new title.
    this.state.MainViewport.SetSResource("Title", title);

    /* Set the color of the top level window and menus could
           to match the color of the loaded drawing. 
        */
    var color = this.DrawingAreaVP.GetGResource("FillColor"); /* GlgPoint */
    this.state.MainViewport.SetGResourceFromPoint("FillColor", color);
  }

  //////////////////////////////////////////////////////////////////////////////
  // Sets a new drawing as a template of the specified subwindow object
  // and returns a viewport displayed in the subwindow.
  //////////////////////////////////////////////////////////////////////////////
  SetDrawing(
    /* GlgObject */ subwindow,
    /* GlgObject */ drawing
  ) /* GlgObject */ {
    // alert(subwindow);
    if (subwindow == null || drawing == null) {
      alert("Drawing loading failed");
      return null;
    }

    /* Set the new drawing as a template of the subwindow.
           The new drawing will be setup, and HierarchyCallback will be invoked 
           before and after hierarchy setup for new drawing. This callback can be
           used to invoke code for initializing the new drawing using the same
           code logic as in the desktop version of the application, making it 
           easier to port and maintain several versions of the application.
        */

    subwindow.SetTemplate(drawing);
    // Return the viewport displayed in the subwindow.
    return subwindow.GetResourceObject("Instance");
  }

  //////////////////////////////////////////////////////////////////////////////
  // Query tags for a given viewport and rebuild TagRecordArray.
  // The new_drawing parameter is the viewport of the new drawing.
  // TagRecordArray will include tags for the top level viewport
  // of the viewer, including tags for the loaded page, as well as
  // tags for the popup dialogs, if any.
  //////////////////////////////////////////////////////////////////////////////
  QueryTags(/* GlgObject */ new_drawing) {
    // Delete existing tag records from TagRecordArray.
    this.DeleteTagRecords();

    /* Remap tags in the loaded drawing if needed.
           Will invoke HMIPage's RemapTagObject() for each tag.
        */
    //  alert(this.HMIPage.NeedTagRemapping())
    if (new_drawing != null) this.RemapTags(new_drawing);
    // if (new_drawing != null && this.HMIPage.NeedTagRemapping(this.RANDOM_DATA))
    //   this.RemapTags(new_drawing);

    /* Build an array of tag records containing tags information and
           store it in TagRecordArray. TagRecordArray will be used for 
           unimating the drawing with data.
           Always create data for the top level viewport (MainViewport),
           to keep a global list of tags that include tags in any dynamically
           loaded dialogs.
        */
    this.CreateTagRecords(this.state.MainViewport);
  }

  // Create an array of tag records containing tag information.
  //////////////////////////////////////////////////////////////////////////////
  CreateTagRecords(/* GlgObject */ drawing_vp) {
    var tag_obj; /* GlgObject */
    var tag_source /* String  */,
      tag_name /* String  */,
      tag_comment; /* String  */
    var data_type /* int */, access_type; /* int */

    // Obtain a list of tags with unique tag sources.
    var tag_list /* GlgObject  */ = drawing_vp.CreateTagList(
      /* List each tag source only once */ true
    );
    if (tag_list == null) return;

    var size = tag_list.GetSize();
    if (size == 0) return; // no tags found

    for (var i = 0; i < size; ++i) {
      tag_obj = tag_list.GetElement(i);

      // Skip OUTPUT tags.
      access_type = Math.trunc(tag_obj.GetDResource("TagAccessType"));
      if (access_type == this.GLG.GlgTagAccessType.OUTPUT_TAG) continue;

      /* Retrieve TagSource, the name of the database field used as a 
               data source. */
      tag_source = tag_obj.GetSResource("TagSource");

      // Skip undefined tag sources, such as "" or "unset".
      if (this.IsUndefined(tag_source)) continue;

      if (this.RandomData) {
        /* For demo purposes only, skip tags that have:
                   - TagName contains "Speed" or "Test";
                   - TagSource contains "Test";
                   - TagComment contains "Test".
                   Such tags are present in motor_info.g displayed in the 
                   PopupDialog, as well as scada_test_commands.g. 
                   The demo shows how to set the value for these tags 
                   using commands WriteValue or WriteValueFromWidget. 
                */
        tag_name = tag_obj.GetSResource("TagName");
        tag_comment = tag_obj.GetSResource("TagComment");
        if (
          !this.IsUndefined(tag_name) &&
          (tag_name.includes("Speed") || tag_name.includes("Test"))
        )
          continue;
        if (tag_source.includes("Test")) continue;
        if (!this.IsUndefined(tag_comment) && tag_comment.includes("Test"))
          continue;
      }

      // Get tag object's data type: GLG_D, GLG_S or GLG_G
      data_type = Math.trunc(tag_obj.GetDResource("DataType"));

      /* Create a new tag record. plot_time_ep will be assigned in
               SetPlotTimeEP() if needed.
            */
      // var tag_record = new this.GlgTagRecord(data_type, tag_source, tag_obj);
      var tag_record = {
        data_type: data_type,
        tag_source: tag_source,
        tag_obj: tag_obj,
        plot_time_ep: null,
      };

      // Add a new tag record to TagRecordArray
      this.TagRecordArray.push(tag_record);
    }

    // Store number of tag records.
    this.NumTagRecords = this.TagRecordArray.length;

    /* If a drawing contains a chart, ValueEntryPoint of each plot 
           may have a tag to push data values using the common tag mechanism. 
           The time stamp for the corresponding TimeEntryPoint of the plot
           may be supplied either automatically by the chart 
           (SUPPLY_PLOT_TIME_STAMP=false), or the application may supply the
           time stamp explicitly for each data sample 
           (SUPPLY_PLOT_TIME_STAMP=true).
           
           If SUPPLY_PLOT_TIME_STAMP=1, each tag record in TagRecordArray
           should store corresponding plot's TimeEntryPoint (plot_time_ep).
           If not found, plot_time_ep will be null.
        */
    if (this.SUPPLY_PLOT_TIME_STAMP) this.SetPlotTimeEP(drawing_vp);
  }

  //////////////////////////////////////////////////////////////////////////////
  // Delete tag records frogm TagRecordArray.
  //////////////////////////////////////////////////////////////////////////////
  DeleteTagRecords() {
    if (this.TagRecordArray.length > 0) this.TagRecordArray.length = 0;

    this.NumTagRecords = 0;
  }

  // Store TimeEntryPoint (plot_time_ep) in each tag record, if found.
  //////////////////////////////////////////////////////////////////////////////
  SetPlotTimeEP(/* GlgObject */ drawing_vp) {
    if (this.NumTagRecords == 0) return;

    // Obtain a list of all tags, including non-unique tag sources.
    var tag_list /* GlgObject */ = drawing_vp.CreateTagList(
      /* List all tags */ false
    );

    if (tag_list == null) return;

    var size = tag_list.GetSize();
    if (size == 0) return; /* no tags found */

    /* For each tag in the list, check if there is a plot object in the
           drawing with a matching TagSource for the plot's ValueEntryPoint.
           If found, obtain plot's TimeEntryPoint and store it in the
           TagRecordArray for a tag record with a matching tag_source.
        */
    for (var i = 0; i < size; ++i) {
      var tag_obj = tag_list.GetElement(i); /* GlgObject */

      /* Retrieve TagSource and TagComment. In the demo, TagComment is
               not used, but the application may use it as needed.
            */
      var tag_comment = tag_obj.GetSResource("TagComment"); /* String */
      var tag_source = tag_obj.GetSResource("TagSource"); /* String */

      if (this.IsUndefined(tag_source)) return;

      /* Find a TimeEntryPoint of a plot in a RealTimeChart with
               a matching TagSource assigned for the plot's ValueEntryPoint.
               It is assumed that there is ONLY ONE plot in the drawing 
               with a given TagSource. 
            */
      var plot_time_ep = this.FindMatchingTimeEP(tag_obj); /* GlgObject */

      if (plot_time_ep == null)
        continue; /* There is no plot for this tag source. */

      /* Find a tag record in TagRecordArray with a matching tag_source.
               If found, assign plot_time_ep. Otherwise, generate an error.
            */
      var tag_record = this.LookupTagRecords(tag_source);

      if (tag_record != null) tag_record.plot_time_ep = plot_time_ep;
      /* shouldn't happen */ else
        console.error("No matching tag record, TimeEntryPoint not stored.");
    }
  }

  //////////////////////////////////////////////////////////////////////////////
  // For a given tag object, find a parent plot object (PLOT object type).
  // If found, return the plot's TimeEntryPoint.
  // It is assumed that there is ONLY ONE plot in the drawing with a given
  // TagSource.
  //////////////////////////////////////////////////////////////////////////////
  FindMatchingTimeEP(/* GlgObject */ tag_obj) /* */ {
    /* Search for a plot object type, which is a parent of the tag_obj
           (ValueEntryPoint).
        */
    var match_type = this.GLG.GlgObjectMatchType.OBJECT_TYPE_MATCH;
    var find_parents = true;
    var find_first_match = true;
    var search_inside = false;
    var search_drawable_only = false;
    var object_type = this.GLG.GlgObjectType.PLOT;
    // null parameters at the end: may be omitted.
    // var object_name = null;
    // var resource_name = null;
    // var object_id = null;
    // var custom_match = null;

    var rval = tag_obj.FindMatchingObjects(
      match_type,
      find_parents,
      find_first_match,
      search_inside,
      search_drawable_only,
      object_type
      /* omitting trailing null parameters */
    );
    if (rval == null || rval.found_object == null)
      return null; /* matching object not found */

    var plot = rval.found_object; /* GlgObject */
    return plot.GetResourceObject("TimeEntryPoint");
  }

  // Lookup TagRecordArray and return a matching tag record with
  // tag_source=match_tag_source.
  //////////////////////////////////////////////////////////////////////////////
  LookupTagRecords(/* String */ match_tag_source) /* TagRecord */ {
    if (this.IsUndefined(match_tag_source)) return null;

    var num_tag_records = this.TagRecordArray.length;
    for (var i = 0; i < num_tag_records; ++i) {
      var tag_record = this.TagRecordArray[i];

      if (tag_record.tag_source == match_tag_source) return tag_record;
    }

    return null; // not found
  }

  //////////////////////////////////////////////////////////////////////////////
  // Close all active popups, including popup dialogs and popup menus.
  //////////////////////////////////////////////////////////////////////////////
  CloseActivePopups(/* int */ allow_dialog) {
    /* Close all active dialogs, except the ones which may remain
           visible until closed by the user, as specified by the allow_dialog 
           parameter.
        */
    for (var i = 0; i < this.MAX_DIALOG_TYPE; ++i) {
      if (i == allow_dialog) continue;

      this.ClosePopupDialog(i);
    }

    /* Close Global PopupMenu, if any. */
    this.ClosePopupMenu(this.GLOBAL_POPUP_MENU);
  }

  // Process commands attached to objects at design time.
  // Command types are defined in CommandTypeTable.
  // CommandType strings in the table must match the CommandType strings
  // defined in the drawing.
  //////////////////////////////////////////////////////////////////////////////
  ProcessObjectCommand(
    /* GlgObject */ command_vp,
    /* GlgObject */ selected_obj,
    /* GlgObject */ action_obj
  ) {
    var permissions = JSON.parse(
      JSON.parse(localStorage.getItem("User")).permissions
    );
    var command_obj; /* GlgObject */
    var command_type /* int */, dialog_type /* int */, menu_type; /* int */

    if (selected_obj == null || action_obj == null) return;

    /* Retrieve Command object. */
    command_obj = action_obj.GetResourceObject("Command");

    if (command_obj == null) return;

    command_type = this.GetCommandType(command_obj);
    switch (command_type) {
      // case this.SHOW_ALARMS:
      //     //   this.ShowAlarms();
      //     break;
      case this.POPUP_DIALOG:
        this.DisplayPopupDialog(command_vp, selected_obj, command_obj);
        break;
      case this.CLOSE_POPUP_DIALOG:
        dialog_type = this.GetDialogType(command_obj);
        this.ClosePopupDialog(dialog_type);
        break;
      case this.POPUP_MENU:
        this.DisplayPopupMenu(command_vp, selected_obj, command_obj);
        break;
      case this.CLOSE_POPUP_MENU:
        menu_type = this.GetPopupMenuType(command_obj);
        this.ClosePopupMenu(menu_type);
        break;
      case this.GOTO:
        if (selected_obj.HasResourceObject("PARAM")) {
          this.assetId = selected_obj.GetSResource("PARAM");
        }
        this.GoTo(command_vp, selected_obj, command_obj);
        break;
      case this.WRITE_VALUE:
        // alert(
        //   permissions.includes(parseInt(selected_obj.GetDResource('authTag')))
        // );
        if (selected_obj.HasResourceObject("authTag")) {
          if (
            permissions.includes(parseInt(selected_obj.GetDResource("authTag")))
          ) {
            this.setState({ dOpen: true, authentication_status: true });
            // alert(typeof selected_obj);
            this.setState({
              write_vp: command_vp,
              write_obj: selected_obj,
              writecmd_obj: command_obj,
              write_type: command_type,
            });
          } else {
            this.setState({
              msgStatus: true,
              msgType: "warning",
              msgContent: "You are not authorised user to update tag property.",
            });
          }
        } else {
          this.setState({ confirmOpen: true, authentication_status: false });
          // alert(typeof selected_obj);
          this.setState({
            write_vp: command_vp,
            write_obj: selected_obj,
            writecmd_obj: command_obj,
            write_type: command_type,
          });
        }
        // if (this.state.writeConfirm === true) {
        //   this.WriteValue(command_vp, selected_obj, command_obj);
        // }
        break;
      case this.WRITE_VALUE_FROM_WIDGET:
        // alert(selected_obj.GetDResource('authTag'));
        if (selected_obj.HasResourceObject("authTag")) {
          if (
            permissions.includes(parseInt(selected_obj.GetDResource("authTag")))
          ) {
            this.setState({ dOpen: true, authentication_status: true });
            // alert(typeof selected_obj);
            this.setState({
              write_vp: command_vp,
              write_obj: selected_obj,
              writecmd_obj: command_obj,
              write_type: command_type,
            });
          } else {
            this.setState({
              msgStatus: true,
              msgType: "warning",
              msgContent: "You are not authorised user to update tag property.",
            });
          }
        } else {
          this.setState({ confirmOpen: true, authentication_status: false });
          // alert(typeof selected_obj);
          this.setState({
            write_vp: command_vp,
            write_obj: selected_obj,
            writecmd_obj: command_obj,
            write_type: command_type,
          });
        }

        // if (this.state.writeConfirm === true) {
        //   this.WriteValueFromInputWidget(command_vp, selected_obj, command_obj);
        // }
        break;
      default:
        console.log("Command failed: Undefined CommandType.");
        break;
    }
  }

  // //////////////////////////////////////////////////////////////////////////////
  // // Opens or closes the alarm window.
  // //////////////////////////////////////////////////////////////////////////////
  // ShowAlarms()
  // {
  //    AlarmDialogVisible = ToggleResource( "AlarmDialog/Visibility" );

  //    /* If the alarm dialog is becoming visible, fill alarms to show them
  //       right away.
  //    */
  //    if( AlarmDialogVisible )
  //      ProcessAlarms( true );

  //    if( FirstAlarmDialog )   // Show the help message the first time only.
  //    {
  //       FirstAlarmDialog = false;

  //       ShowMessageDialog( ( TouchDevice == 1 ? "Click" : "Ctrl-click" ) +
  //                          " on the alarm row to acknowledge an alarm.", false );

  //       // Use click on touch devices.
  //       if( false && TouchDevice == 1 )
  //         for( var i=0; i<NumAlarmRows; ++i )
  //           AlarmRows[i].SetDResource( "ProcessArmed",
  //                                      GLG.GlgProcessArmedType.ARMED_NONE );
  //    }
  //    MainViewport.Update();
  // }

  //////////////////////////////////////////////////////////////////////////////
  ShowMessageDialog(/* String */ message, /* boolean */ error) {
    this.MessageDialog.SetSResource("MessageString", message);

    /* Set to 1. to highlight the message in red. */
    this.MessageDialog.SetDResource("ShowErrorColor", error ? 1.0 : 0.0);

    this.MessageDialog.SetDResource("Visibility", 1.0);
  }

  //////////////////////////////////////////////////////////////////////////////
  // Toggles resource value between 0 and 1 and returns the new value
  // as a boolean.
  //////////////////////////////////////////////////////////////////////////////
  ToggleResource(/* String */ resource_name) /* boolean */ {
    var current_value = this.state.MainViewport.GetDResource(
      resource_name
    ); /* double */

    current_value = current_value == 0.0 ? 1.0 : 0.0;

    this.state.MainViewport.SetDResource(resource_name, current_value);
    return current_value == 1.0;
  }

  // Process command "PopupDialog". The requested dialog is expected to be
  // embedded into the currently loaded drawing.
  //////////////////////////////////////////////////////////////////////////////
  DisplayPopupDialog(
    /* GlgObject */ command_vp,
    /* GlgObject */ selected_obj,
    /* GlgObject */ command_obj
  ) {
    var subwindow /* GlgObject */, dialog; /* GlgObject */

    /* Retrieve command parameters (strings). */
    var dialog_res = command_obj.GetSResource("DialogResource");
    var drawing_file = command_obj.GetSResource("DrawingFile");
    var destination_res = command_obj.GetSResource("Destination");

    /* Obtain DialogType. */
    var dialog_type = this.GetDialogType(command_obj); /* int */
    if (dialog_type == this.UNDEFINED_DIALOG_TYPE) {
      console.error("PopupDialog Command failed: Unknown DialogType.");
      return;
    }

    /* Close active popups, if any. To avoid flickering, do not close the 
           dialog with the same dialog type that is requested by this command.
        */
    this.CloseActivePopups(dialog_type);

    this.AbortPendingPopupLoads(); // Cancel any pending popup load requests.

    /* DialogResource specifies resource path of the dialog to be displayed.
           If the path starts with '/', it is relative to the top level 
           $Widget viewport. Otherwise, the path is relative to the 
           viewport of the Input callback (command_vp).
        */
    if (this.IsUndefined(dialog_res)) {
      console.error("PopupDialog Command failed: Invalid DialogResource.");
      this.ClosePopupDialog(dialog_type);
      return;
    }

    /* Obtain an object ID of the requested popup dialog. 
           If invalid, abort the command. 
        */
    if (dialog_res.startsWith("/"))
      /* skip '/' */
      dialog = this.state.MainViewport.GetResourceObject(
        dialog_res.substring(1)
      );
    else dialog = command_vp.GetResourceObject(dialog_res);

    if (dialog == null) {
      console.error("PopupDialog Command failed: Dialog not found.");
      this.ClosePopupDialog(dialog_type);
      return;
    }

    if (this.IsUndefined(drawing_file)) {
      /* DrawingFile is not defined, use dialog as a popup viewport. */
      var popup_vp = dialog; /* GlgObject */
      this.FinishDisplayPopupDialog(
        selected_obj,
        command_obj,
        dialog,
        dialog_type,
        null,
        popup_vp
      );
    } /* If DrawingFile is present, load the corresponding drawing into 
                the subwindow object defined by the Destination parameter. */ else {
      /* Use Destination resource, if any, to display the specified drawing. 
               If omitted, use default name "DrawingArea". It is assumed that 
               Destination points to the subwindow object inside a dialog.
            */
      var subwindow_name; /* String */
      if (this.IsUndefined(destination_res)) subwindow_name = "DrawingArea";
      /* Use default name. */ else subwindow_name = destination_res;

      /* Obtain an object ID of the subwindow inside a dialog. */
      subwindow = dialog.GetResourceObject(subwindow_name);
      if (subwindow == null) {
        console.error(
          "PopupDialog Command failed: Destination object not found."
        );
        this.ClosePopupDialog(dialog_type);
        return;
      }

      /* Store a new load request in a global variable to be able to abort it
               if needed.
            */
      this.PopupLoadRequest = {
        enabled: true,
        command_obj: command_obj,
        selected_obj: selected_obj,
        dialog: dialog,
        dialog_type: dialog_type,
        subwindow: subwindow,
      };

      // Request to load the new drawing and invoke the callback when it's ready.
      this.GLG.LoadObjectFromURL(
        "/static/glg/" + drawing_file,
        null,
        this.DisplayPopupDialogCB.bind(this),
        this.PopupLoadRequest,
        this.AbortLoad,
        /* subwindow to handle bindings */ subwindow
      );
    }
  }

  //////////////////////////////////////////////////////////////////////////////
  DisplayPopupDialogCB(
    /* GlgObject */ loaded_drawing,
    /* Object */ user_data,
    /* String */ path
  ) {
    var load_request = user_data;

    if (!load_request.enabled)
      /* This load request was aborted by requesting to load another page before
               this load request has finished.
            */
      return;

    this.PopupLoadRequest = null; // Reset: we are done with this request.

    var dialog_type = load_request.dialog_type;
    var subwindow = load_request.subwindow;

    /* Close active popups again: they might have been activated while the
           dialog was being loaded. To avoid flickering, do not close the 
           dialog with the same dialog type that is requested by this command.
        */
    this.CloseActivePopups(dialog_type);

    if (loaded_drawing == null) {
      alert("Dialog loading failed");
      this.ClosePopupDialog(dialog_type);
      return;
    }

    // Load new popup drawing and obtain an object id of its viewport.
    var popup_vp = this.SetDrawing(subwindow, loaded_drawing); /* GlgObject */
    if (popup_vp == null) {
      console.error("PopupDialog Command failed.");
      this.ClosePopupDialog(dialog_type);
      return;
    }

    var command_obj = load_request.command_obj; /* GlgObject */
    var selected_obj = load_request.selected_obj; /* GlgObject */
    var dialog = load_request.dialog; /* GlgObject */

    this.FinishDisplayPopupDialog(
      selected_obj,
      command_obj,
      dialog,
      dialog_type,
      subwindow,
      popup_vp
    );
  }

  //////////////////////////////////////////////////////////////////////////////
  FinishDisplayPopupDialog(
    /* GlgObject */ selected_obj,
    /* GlgObject */ command_obj,
    /* GlgObject */ dialog,
    /* int */ dialog_type,
    /* GlgObject */ subwindow,
    /* GlgObject */ popup_vp
  ) {
    /* Increase dialog size for mobile devices with coord. scaling. */
    if (this.CoordScale != 1.0 && dialog.HasResourceObject("CoordScale"))
      dialog.SetDResource("CoordScale", 1.5);

    this.DialogAdjustForMobileDevices(popup_vp);

    var title = command_obj.GetSResource("Title"); /* String */

    /* For the tags with matching TagName, transfer tag sources from the 
           selected object to the loaded popup viewport.
        */
    this.TransferTags(selected_obj, popup_vp, false);

    /* If a new dialog drawing was loaded, rebuild TagRecordArray to 
           include tags both for the drawing displayed in the main drawing area 
           and drawing displayed in the popup dialog.
        */
    if (!popup_vp.Equals(dialog)) this.QueryTags(popup_vp);

    /* Poll new data to fill the popup dialog with current values. */
    this.UpdateData();

    /* Display title in the loaded viewport, if Title resource is found. */
    if (popup_vp.HasResourceObject("Title"))
      popup_vp.SetSResource("Title", title);

    /* Display title as the dialog caption. */
    dialog.SetSResource("ScreenName", title);

    /* Display the dialog if it is not up already. */
    dialog.SetDResource("Visibility", 1.0);

    /* Store dialog information in ActiveDialogs array */
    this.ActiveDialogs[dialog_type].dialog_type = dialog_type;
    this.ActiveDialogs[dialog_type].dialog = dialog;
    this.ActiveDialogs[dialog_type].subwindow = subwindow;
    this.ActiveDialogs[dialog_type].popup_vp = popup_vp;
    this.ActiveDialogs[dialog_type].isVisible = true;

    dialog.Update();
  }

  setLoadAssets(callback) {
    /* HTML5 doesn't provide a scrollbar input element (only a range input 
           html element is available). This application needs to load GLG scrollbars
           used for integrated chart scrolling. For each loaded scrollbar, the 
           AssetLoaded callback is invoked with the supplied data.
        */
    this.GLG.LoadObjectFromURL(
      "/static/images/empty_drawing.g",
      null,
      this.AssetLoaded,
      { name: "empty_drawing", callback: callback }
    );
  }
  //////////////////////////////////////////////////////////////////////////////
  ClosePopupDialog(/*int*/ dialog_type) {
    if (
      dialog_type == this.UNDEFINED_DIALOG_TYPE ||
      dialog_type >= this.MAX_DIALOG_TYPE
    ) {
      console.error("Dialog closing failed.");
      return;
    }
    if (
      this.ActiveDialogs[dialog_type].dialog === null ||
      this.ActiveDialogs[dialog_type].dialog === undefined
    )
      return; // Nothing to do.

    if (
      this.ActiveDialogs[dialog_type].subwindow != null &&
      this.ActiveDialogs[dialog_type].popup_vp != null
    ) {
      /* Destroy currently loaded popup drawing and load empty drawing.
               EmptyDrawing is preloaded as an asset to be able to close dialogs
               without waiting for the load to finish.
            */
      this.SetDrawing(
        this.ActiveDialogs[dialog_type].subwindow,
        this.EmptyDrawing.CopyObject()
      );
      // this.setLoadAssets();
      // this.GLG.LoadObjectFromURL( "/static/images/empty_drawing.g", null, this.AssetLoaded,
      //                { name: "/static/images/empty_drawing", callback: callback } );
      /* Rebuild a list of tags to exclude the tags from the previously
               loaded popup viewport.
            */
      this.QueryTags(null);
    }
    /* Hide the dialog */

    this.ActiveDialogs[dialog_type].dialog.SetDResource("Visibility", 0.0);
    /* Clear a dialog record with a specified index (dialog_type)
           in the ActiveDialogs array.
        */
    this.ActiveDialogs[dialog_type].dialog_type = this.UNDEFINED_DIALOG_TYPE;
    this.ActiveDialogs[dialog_type].dialog = null;
    this.ActiveDialogs[dialog_type].subwindow = null;
    this.ActiveDialogs[dialog_type].popup_vp = null;
    this.ActiveDialogs[dialog_type].isVisible = false;
  }

  TransferTags(
    /* GlgObject */ selected_obj,
    /* GlgObject */ viewport,
    /* boolean */ unset_tags
  ) {
    var tag_list /* GlgObject */, tag_obj; /* GlgObject */
    var widget_type /* String */,
      tag_source /* String */,
      tag_name; /* String */
    var num_remapped_tags; /* int */

    /* Retrieve WidgetType from the selected objects, if any. */
    if (selected_obj.HasResourceObject("WidgetType"))
      widget_type = selected_obj.GetSResource("WidgetType");

    /* Place custom code here to initialize the drawing based on WidgetType, 
           if needed. In this demo, the initialization code below is executed 
           regardless of WidgetType.
        */

    /* Obtain a list of tags defined in the selected object. */
    tag_list = selected_obj.CreateTagList(/* List all tags */ false);
    if (tag_list == null) return;

    var size = tag_list.GetSize(); /* int */
    if (size == 0) return; /* no tags found */

    /* Traverse the tag list. For each tag, transfer the TagSource
           defined in the selected object to the tag in the loaded 
           popup drawing that has a matching TagName.
        */
    for (var i = 0; i < size; ++i) {
      tag_obj = tag_list.GetElement(i);

      /* Obtain TagName. */
      tag_name = tag_obj.GetSResource("TagName");

      /* Skip tags with undefined TagName */
      if (this.IsUndefined(tag_name)) continue;

      /* Obtain TagSource. */
      tag_source = tag_obj.GetSResource("TagSource");

      /* Skip tags with undefined TagSource. */
      if (this.IsUndefined(tag_source)) continue;

      /* Remap all tags with the specified tag name (tag_name)
               to use a new tag source (tag_source).
            */
      if (unset_tags)
        num_remapped_tags = this.RemapNamedTags(viewport, tag_name, "unset");
      else
        num_remapped_tags = this.RemapNamedTags(viewport, tag_name, tag_source);
    }
  }

  //////////////////////////////////////////////////////////////////////////////
  DisplayPopupMenu(
    /* GlgObject */ command_vp,
    /* GlgObject */ selected_obj,
    /* GlgObject */ command_obj
  ) {
    var subwindow /* GlgObject */, menu_obj; /* GlgObject */

    /* Retrieve command parameters (strings). */
    var menu_res = command_obj.GetSResource("MenuResource");
    var drawing_file = command_obj.GetSResource("DrawingFile");
    var destination_res = command_obj.GetSResource("Destination");

    /* Obtain MenuType. */
    var menu_type = this.GetPopupMenuType(command_obj); /* int */
    if (menu_type == this.UNDEFINED_POPUP_MENU_TYPE) {
      console.error("PopupMenu Command failed: Unknown MenuType.");
      return;
    }

    /* Close active popups, if any. */
    this.CloseActivePopups(this.CLOSE_ALL);

    this.AbortPendingPopupLoads(); // Cancel any pending popup load requests.

    /* MenuResource specifies resource path of the menu object to be 
           displayed. If the path starts with '/', it is relative to the 
           top level $Widget viewport. Otherwise, the path is relative to 
           the viewport of the Input callback (command_vp).
        */
    if (this.IsUndefined(menu_res)) {
      console.error("PopupMenu Command failed: Invalid MenuResource.");
      return;
    }

    /* Obtain an object ID of the requested popup menu. 
           If invalid, abort the command. 
        */
    if (menu_res.startsWith("/"))
      /* skip '/'*/
      menu_obj = this.state.MainViewport.GetResourceObject(
        menu_res.substring(1)
      );
    else menu_obj = command_vp.GetResourceObject(menu_res);

    if (menu_obj == null) {
      console.error("PopupMenu Command failed: Menu object not found.");
      return;
    }

    if (this.IsUndefined(drawing_file)) {
      /* DrawingFile is not defined, use menu_obj as popup viewport. */
      var popup_vp = menu_obj; /* GlgObject */
      this.FinishDisplayPopupMenu(
        selected_obj,
        command_obj,
        menu_obj,
        menu_type,
        null,
        popup_vp
      );
    } else {
      /* DrawingFile is defined, use it to load the corresponding 
               drawing into the subwindow object defined by the Destination 
               parameter. It is assumed that Destination points to the 
               subwindow object inside the menu object.
            */
      var subwindow_name; /* String */
      if (this.IsUndefined(destination_res)) subwindow_name = "DrawingArea";
      /* Use default name. */ else subwindow_name = destination_res;

      /* Obtain an object ID of the subwindow inside the menu object. */
      subwindow = menu_obj.GetResourceObject(subwindow_name);
      if (subwindow == null) {
        console.error(
          "PopupDialog Command failed: Destination object not found."
        );
        return;
      }

      /* Store a new load request in a global variable to be able to abort it
               if needed.
            */
      this.PopupLoadRequest = {
        enabled: true,
        command_obj: command_obj,
        selected_obj: selected_obj,
        menu_obj: menu_obj,
        menu_type: menu_type,
        subwindow: subwindow,
      };

      // Request to load the new drawing and invoke the callback when it's ready.
      this.GLG.LoadObjectFromURL(
        "/static/glg/" + drawing_file,
        null,
        this.DisplayPopupMenuCB,
        this.PopupLoadRequest,
        this.AbortLoad,
        /* subwindow to handle bindings */ subwindow
      );
    }
  }

  //////////////////////////////////////////////////////////////////////////////
  DisplayPopupMenuCB(
    /* GlgObject */ loaded_drawing,
    /* Object */ user_data,
    /* String */ path
  ) {
    var load_request = user_data;

    if (!load_request.enabled)
      /* This load request was aborted by requesting to load another page before
               this load request has finished.
            */
      return;

    this.PopupLoadRequest = null; // Reset: we are done with this request.

    /* Close active popups again: they might have been activated while the
           menu was being loaded. 
        */
    this.CloseActivePopups(this.CLOSE_ALL);

    if (loaded_drawing == null) {
      alert("Menu loading failed");
      return;
    }

    // Load new menu drawing and obtain an object id of its viewport.
    var subwindow = load_request.subwindow;
    var popup_vp = this.SetDrawing(subwindow, loaded_drawing); /* GlgObject */
    if (popup_vp == null) {
      console.error("PopupMenu Command failed.");
      return;
    }

    var command_obj = load_request.command_obj; /* GlgObject */
    var selected_obj = load_request.selected_obj; /* GlgObject */
    var menu_obj = load_request.menu_obj; /* GlgObject */
    var menu_type = load_request.menu_type; /* GlgObject */

    /* If the viewport has Width and Height resources that define
           its size in pixels, adjust the size of the menu object 
           to match the size of the loaded viewport.
        */
    if (
      popup_vp.HasResourceObject("Width") &&
      menu_obj.HasResourceObject("Width")
    ) {
      var menu_width; /* double */
      menu_width = popup_vp.GetDResource("Width");
      menu_obj.SetDResource("Width", menu_width);
    }

    if (
      popup_vp.HasResourceObject("Height") &&
      menu_obj.HasResourceObject("Height")
    ) {
      var menu_height; /* double */
      menu_height = popup_vp.GetDResource("Height");
      menu_obj.SetDResource("Height", menu_height);
    }

    this.FinishDisplayPopupMenu(
      selected_obj,
      command_obj,
      menu_obj,
      menu_type,
      subwindow,
      popup_vp
    );
  }

  //////////////////////////////////////////////////////////////////////////////
  FinishDisplayPopupMenu(
    /* GlgObject */ selected_obj,
    /* GlgObject */ command_obj,
    /* GlgObject */ menu_obj,
    /* int */ menu_type,
    /* GlgObject */ subwindow,
    /* GlgObject */ popup_vp
  ) {
    /* Increase the size of the menu and its close icon for mobile devices
           with coord. scaling.
        */
    if (this.CoordScale != 1.0) {
      if (menu_obj.HasResourceObject("CoordScale"))
        menu_obj.SetDResource("CoordScale", 2);

      if (popup_vp.HasResourceObject("CloseIcon/CoordScale"))
        popup_vp.SetDResource("CloseIcon/CoordScale", 2);
    }

    /* Transfer tag sources from the selected object to the loaded 
           popup viewport, using tags with a matching TagName.
        */
    this.TransferTags(selected_obj, popup_vp, false);

    /* Display title in the loaded viewport, if Title resource is found. */
    if (popup_vp.HasResourceObject("Title")) {
      var title = command_obj.GetSResource("Title"); /* String */
      popup_vp.SetSResource("Title", title);
    }

    /* Show the menu. */
    menu_obj.SetDResource("Visibility", 1.0);

    /* Store menu information in the global ActivePopupMenu structure, 
           used to close the active popup menu.
        */
    this.ActivePopupMenu.menu_type = menu_type;
    this.ActivePopupMenu.menu_obj = menu_obj;
    this.ActivePopupMenu.subwindow = subwindow;
    this.ActivePopupMenu.menu_vp = popup_vp;
    this.ActivePopupMenu.selected_obj = selected_obj;
    this.ActivePopupMenu.isVisible = true;

    /* Position the menu next to the selected object. */
    this.PositionPopupMenu();

    menu_obj.Update();
  }

  //////////////////////////////////////////////////////////////////////////////
  // Position ActivePopupMenu at the upper right corner of the selected object,
  // if possible. Otherwise, position the menu close to the selected object
  // such that it is displayed within the current viewport.
  //////////////////////////////////////////////////////////////////////////////
  PositionPopupMenu() {
    var /* GlgObject */
      selected_obj_vp, // Viewport that contains selected object.
      menu_parent_vp; // Parent viewport that contains the popup menu.
    var /* double */
      x,
      y,
      offset = 5.0, // offset in pixels.
      menu_width,
      menu_height,
      parent_width,
      parent_height;
    var /* int */
      x_anchor,
      y_anchor;

    if (
      this.ActivePopupMenu.selected_obj == null ||
      this.ActivePopupMenu.menu_obj == null
    )
      return;

    selected_obj_vp = this.ActivePopupMenu.selected_obj.GetParentViewport(true);
    menu_parent_vp = this.ActivePopupMenu.menu_obj.GetParentViewport(true);

    /* Obtain the object's bounding box in screen coordinates. */
    var sel_obj_box = this.ActivePopupMenu.selected_obj.GetBox(); /* GlgCube */
    var converted_box = this.GLG.CopyGlgCube(sel_obj_box); /* GlgCube */

    /* If the menu is located in a different viewport from the viewport
           of the selected object, convert screen coordinates of the 
           selected object box from the viewport of the selected object to the 
           viewport that contains the popup menu.
        */
    if (!selected_obj_vp.Equals(menu_parent_vp)) {
      this.GLG.TranslatePointOrigin(
        selected_obj_vp,
        menu_parent_vp,
        converted_box.p1
      );
      this.GLG.TranslatePointOrigin(
        selected_obj_vp,
        menu_parent_vp,
        converted_box.p2
      );
    }

    /* Obtain width and height in pixels of the parent viewport 
           of the menu. 
        */
    parent_width = menu_parent_vp.GetDResource("Screen/Width");
    parent_height = menu_parent_vp.GetDResource("Screen/Height");

    /* Obtain width and height of the menu object. */
    var menu_obj_box = this.ActivePopupMenu.menu_obj.GetBox(); /* GlgCube */
    menu_width = menu_obj_box.p2.x - menu_obj_box.p1.x;
    menu_height = menu_obj_box.p2.y - menu_obj_box.p1.y;

    /* Position the popup at the upper right or lower left corner of 
           the selected object, if possible. Otherwise (viewport is too small), 
           position it in the center of the viewport.
        */
    if (converted_box.p2.x + menu_width + offset > parent_width) {
      /* Outside of the window right edge. 
               Position the right edge of the popup to the left of the selected object.
               Always use HLEFT anchor to simplify out-of-the-window check.
            */
      x = converted_box.p1.x - offset - menu_width;
      x_anchor = this.GLG.GlgAnchoringType.HLEFT;
    } else {
      // Position the left edge of the popup to the right of the selected object.
      x = converted_box.p2.x + offset;
      x_anchor = this.GLG.GlgAnchoringType.HLEFT;
    }

    /* Anchor is always HLEFT here to make checks simpler. */
    if (x < 0 || x + menu_width > parent_width) {
      /* Not enough space: place in the center. */
      x = parent_width / 2.0;
      x_anchor = this.GLG.GlgAnchoringType.HCENTER;
    }

    if (converted_box.p1.y - menu_height - offset < 0.0) {
      /* Outside of window top edge.
               Position the top edge of the popup below the selected object.
            */
      y = converted_box.p2.y + offset;
      y_anchor = this.GLG.GlgAnchoringType.VTOP;
    } else {
      /* Position the bottom edge of the popup above the selected object.
               Always use GLG_VTOP anchor to simplify out-of-the-window check.
            */
      y = converted_box.p1.y - offset - menu_height;
      y_anchor = this.GLG.GlgAnchoringType.VTOP;
    }

    /* Anchor is always GLG_VTOP here to make checks simpler. */
    if (y < 0 || y + menu_height > parent_height) {
      /* Not enough space: place in the center. */
      y = parent_height / 2.0;
      y_anchor = this.GLG.GlgAnchoringType.HCENTER;
    }

    this.ActivePopupMenu.menu_obj.PositionObject(
      this.GLG.GlgCoordType.SCREEN_COORD,
      x_anchor | y_anchor,
      x,
      y,
      0.0
    );
  }

  //////////////////////////////////////////////////////////////////////////////
  ClosePopupMenu(/* int */ menu_type) {
    if (this.ActivePopupMenu.menu_obj == null) return; /* Nothing to do. */

    /* Hide active popup. */
    this.ActivePopupMenu.menu_obj.SetDResource("Visibility", 0.0);
    this.state.MainViewport.Update();

    if (this.ActivePopupMenu.subwindow != null) {
      /* Destroy currently loaded popup drawing and load empty drawing.
               EmptyDrawing is preloaded as an asset to be able to close dialogs
               without waiting for the load to finish.
            */
      this.SetDrawing(
        this.ActivePopupMenu.subwindow,
        this.EmptyDrawing.CopyObject()
      );
    } else {
      /* Unset tags in the menu object, which were previously
               transfered and assigned from the selected object. 
            */
      if (this.ActivePopupMenu.selected_obj != null)
        this.TransferTags(
          this.ActivePopupMenu.selected_obj,
          this.ActivePopupMenu.menu_obj,
          true
        );
    }

    /* Clear menu record. */
    this.ActivePopupMenu.menu_type = this.UNDEFINED_POPUP_MENU_TYPE;
    this.ActivePopupMenu.menu_obj = null;
    this.ActivePopupMenu.subwindow = null;
    this.ActivePopupMenu.menu_vp = null;
    this.ActivePopupMenu.selected_obj = null;
    this.ActivePopupMenu.isVisible = false;
  }

  //////////////////////////////////////////////////////////////////////////////
  // Process "GoTo" command. The command loads a new drawing specified
  // by the DrawingFile parameter into the subwindow object specified
  // by the Destination parameter. If Destination is omitted, uses
  // main DrawingArea subwindow object.
  //////////////////////////////////////////////////////////////////////////////
  GoTo(
    /* GlgObject */ command_vp,
    /* GlgObject */ selected_obj,
    /* GlgObject */ command_obj
  ) {
    var subwindow; /* GlgObject */

    /* Close active popup dialogs and popup menu. */
    this.CloseActivePopups(this.CLOSE_ALL);

    this.AbortPendingPopupLoads(); // Cancel any pending popup load requests.

    /* Retrieve command parameters (strings). */
    var drawing_file = command_obj.GetSResource("DrawingFile");
    var destination_res = command_obj.GetSResource("Destination");

    // alert(destination_res);
    /* If DrawingFile is not valid, abort the command. */
    if (this.IsUndefined(drawing_file)) {
      console.error("GoTo Command failed: Invalid DrawingFile.");
      return;
    }

    /* Use Destination resource, if any, to display the specified drawing. 
           It is assumed that Destination points to the subwindow object.
           If not defined, use top level DrawingArea subwindow by default.
        */
    if (this.IsUndefined(destination_res)) {
      subwindow = this.DrawingArea;
      // alert('Reached main ');
    } else {
      if (destination_res.startsWith("/")) {
        /* Destination is relative to the top level viewport (MainViewport).
                   Omit the first '/' when using the resource path to obtain the 
                   subwindow object.
                */
        // alert('Reached 1 ');
        subwindow = this.state.MainViewport.GetResourceObject(
          destination_res.substring(1)
        );
        /* Destination is relative to the current viewport, where the
                   command occurred.
                */
      } else {
        subwindow = command_vp.GetResourceObject(destination_res);
        // alert('R ');
      }

      // alert(subwindow);
      if (subwindow == null) {
        console.error("GoTo Command failed: Invalid Destination.");
        return;
      }
    }

    this.AbortPendingPageLoads(); // Cancel any pending page load requests.

    // Get title from the command.
    // var title = command_obj.GetSResource('Title'); /* String */
    // this.SetLoadingMessage(title);

    /* Store a new load request in a global variable to be able to abort it
           if needed.
        */
    this.PageLoadRequest = {
      enabled: true,
      command_obj: command_obj,
      drawing_file: drawing_file,
      subwindow: subwindow,
    };

    // this.LoadDrawingFromMenu('/static/glg/' + drawing_file);
    // Request to load the new drawing and invoke the callback when it's ready.
    this.GLG.LoadObjectFromURL(
      "/static/glg/" + drawing_file,
      null,
      this.GoToCB.bind(this),
      this.PageLoadRequest,
      this.AbortLoad,
      /* subwindow to handle bindings */ subwindow
    );
  }

  //////////////////////////////////////////////////////////////////////////////
  GoToCB(
    /* GlgObject */ loaded_drawing,
    /* Object */ user_data,
    /* String */ path
  ) {
    var load_request = user_data;

    if (!load_request.enabled)
      /* This load request was aborted by requesting to load another page before
               this load request has finished.
            */
      return;

    this.PageLoadRequest = null; // Reset: we are done with this request.

    if (loaded_drawing == null) {
      alert("Drawing loading failed");
      return;
    }

    /* Close active popup dialogs and popup menu again: they might be activated
           while waiting for the new page being loaded.
        */
    this.CloseActivePopups(this.CLOSE_ALL);

    this.AbortPendingPopupLoads(); // Cancel any pending popup load requests.

    var subwindow = load_request.subwindow;

    // Load new drawing and obtain an object id of its viewport.
    var drawing_vp = this.SetDrawing(subwindow, loaded_drawing); /* GlgObject */
    if (drawing_vp == null) {
      /* If drawing loading fails, it will be reported in HierarchyCallback. 
               Generate an additional error indicating command failing.
            */
      console.error("GoTo Command failed.");
      return;
    }

    /* Rebuild TagRecordArray for the newly loaded drawing. */
    this.QueryTags(drawing_vp);

    if (subwindow.Equals(this.DrawingArea)) {
      /* Reset main menu selection. If the new drawing matches one of 
               the drawings defined in the MenuArray, update 
               main menu with the corresponding index.
            */
      var drawing_file = load_request.drawing_file; /* String */
      // alert(drawing_file)

      // var screen_index = this.LookUpMenuArray(drawing_file); /* int */
      // this.SelectMainMenuItem(screen_index, /* update menu */ true);

      var command_obj = load_request.command_obj; /* GlgObject */
      var title = command_obj.GetSResource("Title"); /* String */
      this.SetupLoadedPage(title); // Use title from the command.

      this.HMIPage.Ready();
    }
  }

  //////////////////////////////////////////////////////////////////////////////
  // Process command "WriteValue". The command writes a new value specified
  // by the Value parameter into the tag in the back-end system
  // specfied by the OutputTagHolder.
  //////////////////////////////////////////////////////////////////////////////
  WriteValue(
    /* GlgObject */ command_vp,
    /* GlgObject */ selected_obj,
    /* GlgObject */ command_obj
  ) {
    /* Retrieve tag source to write data to. */
    this.state.writeConfirm = false;
    var tag_source /* String */ = command_obj.GetSResource(
      "OutputTagHolder/TagSource"
    );

    /* Validate. */
    if (this.IsUndefined(tag_source)) {
      console.error("WriteValue Command failed: Invalid TagSource.");
      return;
    }

    /* Retrieve the value to be written to the tag source. */
    var value = command_obj.GetDResource("Value"); /* double */

    /* Place custom code here as needed, to validate the value specified
           in the command.
        */

    /* Write new value to the specified tag source. */
    this.data_point.d_value = value;
    // this.DataFeed.WriteDTag(tag_source, this.data_point);

    this.DataFeed.WriteData(
      tag_source,
      this.state.password, this.state.authentication_status,
      this.data_point.d_value,
      this.writeDataCB.bind(this)
    );

    if (this.RandomData) {
      /* For demo purposes, update the tag value in the drawing. 
               In an application, the input tag will be updated 
               from the back-end system.
            */
      this.state.MainViewport.SetDTag(tag_source, value, true);
      this.state.MainViewport.Update();
    }
  }
  //////////////////////////////////////////////////////////////////////////////
  WriteValueFromInputWidget(command_vp, widget, command_obj) {
    /* Retrieve input widget's resource name that stores the new value 
        when the user uses the input widget to change the value.
        For a spinner, the resource name is "Value"; for a toggle button, 
        it is "OnState".
        */

    this.state.writeConfirm = false;
    var widget_value_res = command_obj.GetSResource("ValueResource");

    /* Obtain object ID of the input resource/tag object we read the 
        new value from.
        */
    var input_tag_obj = widget.GetResourceObject(widget_value_res);
    if (input_tag_obj === null || input_tag_obj === undefined) return;

    // Obtain object ID of the write tag object (output tag).
    var output_tag_obj = command_obj.GetResourceObject("OutputTagHolder");
    if (output_tag_obj === null || output_tag_obj === undefined) return;

    /* Obtain TagSource from the write tag. */
    var output_tag_source = output_tag_obj.GetSResource("TagSource");
    /* Validate. */
    if (this.IsUndefined(output_tag_source)) {
      console.log("Write Command failed: Invalid Output TagSource.");
      return;
    }

    // Retrieve new value from the input widget.
    // console.log('input_tag_obj ' + JSON.stringify(input_tag_obj));
    var value = input_tag_obj.GetDResource(null);
    if (value == 0) value = 1;
    else if (value == 1) value = 0;
    // alert(value);
    this.data_point.d_value = value;
    // this.DataFeed.WriteDTag(output_tag_source, this.data_point);
    this.DataFeed.WriteData(
      output_tag_source,
      this.state.password, this.state.authentication_status,
      this.data_point.d_value,
      this.writeDataCB.bind(this)
    );

    if (this.RandomData) {
      /* Update the tag value in the drawing. In an application,
               the read tag will be updated from the back-end system.
            */
      var read_tag_source = input_tag_obj.GetSResource("TagSource"); // String
      if (!this.IsUndefined(read_tag_source)) {
        this.state.MainViewport.SetDTag(read_tag_source, value, true);
        this.state.MainViewport.Update();
      }
    }
  }

  writeDataCB(wRes) {
    if (wRes) {
      // alert(wRes.tagSource + ' -- ' + wRes.tagVal);
      this.state.MainViewport.SetDTag(wRes.tagSource, wRes.tagVal); //tag_record.if_changed
      this.setState({
        msgStatus: true,
        msgType: "success",
        msgContent: wRes.message,
      });
    }
  }
  // Performs zoom/pan operations of the specified type.
  //////////////////////////////////////////////////////////////////////////////
  Zoom(/* GlgObject */ viewport, /* String */ zoom_type, /* double */ scale) {
    var zoom_reset_type = "n";

    if (viewport == null) return;

    switch (zoom_type) {
      default:
        viewport.SetZoom(null, zoom_type, scale);
        break;

      case "n":
        /* If a viewport is a chart with the chart zoom mode, use 'N'
                   to reset both Time and Y ranges. For a chart, 'n' would reset 
                   only the Time range.
                */
        var zoom_mode_obj /* GlgObject */ = viewport.GetResourceObject(
          "ZoomModeObject"
        );
        if (zoom_mode_obj != null) {
          var object_type /* int */ = Math.trunc(
            zoom_mode_obj.GetDResource("Type")
          );
          if (object_type == this.GLG.GlgObjectType.CHART)
            zoom_reset_type = "N";
        }

        viewport.SetZoom(null, zoom_reset_type, 0.0);
        break;
    }
  }

  //////////////////////////////////////////////////////////////////////////////
  // Initialize ActiveDialogs array and ActivePopupMenu.
  //////////////////////////////////////////////////////////////////////////////
  InitActivePopups() {
    this.ActiveDialogs = new Array(this.MAX_DIALOG_TYPE);

    /* Initialize ActiveDialogs array. */
    for (var i = 0; i < this.MAX_DIALOG_TYPE; ++i)
      this.ActiveDialogs[i] = {
        dialog_type: this.UNDEFINED_DIALOG_TYPE,
        dialog: null, // menu object ID
        subwindow: null, // Subwindow object inside a dialog.
        popup_vp: null, // Viewport loaded into subwindow's drawing_area.
        isVisible: false,
      };

    // new this.GlgActiveDialogRecord(this.UNDEFINED_DIALOG_TYPE,
    //     null, null, null, false);

    /* Initialize ActivePopupMenu. */
    this.ActivePopupMenu = [
      {
        menu_type: this.UNDEFINED_POPUP_MENU_TYPE,
        menu_obj: null, // menu object ID
        subwindow: null, // Subwindow object inside a dialog.
        menu_vp: null, // Viewport loaded into subwindow's drawing_area.
        selected_obj: null, // Symbol that trigerred popup menu.
        isVisible: false,
      },
    ];
    // new this.GlgActivePopupMenuRecord(this.UNDEFINED_POPUP_MENU_TYPE,
    //     null, null, null, null, false);
  }

  //////////////////////////////////////////////////////////////////////////////
  LookUpMenuArray(/* String */ drawing_name) /* int */ {
    var menu_record; /* GlgMenuRecord */

    for (var i = 0; i < this.NumMenuItems; ++i) {
      menu_record = this.MenuArray[i];
      if (drawing_name == menu_record.drawing_name) return i;
    }

    return this.NO_SCREEN;
  }

  //////////////////////////////////////////////////////////////////////////////
  PageIndexValid(/* int */ page_index) {
    if (page_index < this.NO_SCREEN || page_index >= this.NumMenuItems) {
      console.error("Invalid main menu index.");
      return false;
    }
    return true;
  }

  //////////////////////////////////////////////////////////////////////////////
  // Select MainMenu item with a specified index.
  // NO_SCREEN value (-1) unselects a previously selected menu item, if any.
  //////////////////////////////////////////////////////////////////////////////
  SelectMainMenuItem(/* int */ menu_index) {
    if (this.PageIndexValid(menu_index))
      this.Menu.SetDResource("SelectedIndex", menu_index);
  }

  GetPageType(/* GlgObject */ drawing) /* int */ {
    if (drawing == null) return this.DEFAULT_PAGE_TYPE;

    var type_obj = drawing.GetResourceObject("PageType"); /* GlgObject */
    if (type_obj == null) return this.DEFAULT_PAGE_TYPE;

    var type_str = type_obj.GetSResource(null); /* String */

    var page_type /* int */ = this.ConvertStringToType(
      this.PageTypeTable,
      type_str,
      this.UNDEFINED_PAGE_TYPE,
      this.UNDEFINED_PAGE_TYPE
    );
    if (page_type == this.UNDEFINED_PAGE_TYPE) {
      console.error("Invalid PageType.");
      return this.DEFAULT_PAGE_TYPE;
    }
    return page_type;
  }

  //////////////////////////////////////////////////////////////////////////////
  // Returns CommandType integer constant using CommandTypeTable.
  //////////////////////////////////////////////////////////////////////////////
  GetCommandType(/* GlgObject */ command_obj) /* int */ {
    var command_type_str = command_obj.GetSResource("CommandType"); /* String */

    return this.ConvertStringToType(
      this.CommandTypeTable,
      command_type_str,
      this.UNDEFINED_COMMAND_TYPE,
      this.UNDEFINED_COMMAND_TYPE
    );
  }

  //////////////////////////////////////////////////////////////////////////////
  GetPopupMenuType(/* GlgObject */ command_obj) /* int */ {
    var menu_type_str = command_obj.GetSResource("MenuType"); /* String */

    return this.ConvertStringToType(
      this.PopupMenuTypeTable,
      menu_type_str,
      this.GLOBAL_POPUP_MENU,
      this.UNDEFINED_POPUP_MENU_TYPE
    );
  }

  //////////////////////////////////////////////////////////////////////////////
  // Returns DialogType integer constant using DialogTypeTable.
  //////////////////////////////////////////////////////////////////////////////
  GetDialogType(/* GlgObject */ command_obj) /* int */ {
    var dialog_type_str = command_obj.GetSResource("DialogType"); /* String */

    return this.ConvertStringToType(
      this.DialogTypeTable,
      dialog_type_str,
      this.GLOBAL_POPUP_DIALOG,
      this.UNDEFINED_DIALOG_TYPE
    );
  }

  //////////////////////////////////////////////////////////////////////////////
  DialogAdjustForMobileDevices(/* GlgObject */ popup_vp) {
    if (this.CoordScale == 1.0) return; // Desktop

    var chart = popup_vp.GetResourceObject("Chart"); /* GlgObject */
    if (chart != null) {
      /* Adjust chart offsets to fit chart labels on mobile devices with 
               canvas scaling.
            */
      this.AdjustOffset(chart, "OffsetTop", 10);
      this.AdjustOffset(chart, "OffsetLeft", 15);
      this.AdjustOffset(chart, "OffsetBottom", -10);

      chart.SetDResource("XAxis/MajorInterval", -4);
    }
  }

  RemapNamedTags(
    /* GlgObject */ glg_object,
    /* String */ tag_name,
    /* String */ tag_source
  ) /* int */ {
    var tag_obj; /* GlgObject */
    var size; /* int */

    /* Obtain a list of tags with TagName attribute matching the 
           specified tag_name.
        */
    var tag_list /* GlgObject */ = glg_object.GetTagObject(
      tag_name,
      /* by name */ true,
      /* list all tags */ false,
      /* multiple tags mode */ false,
      this.GLG.GlgTagType.DATA_TAG
    );
    if (tag_list == null) size = 0;
    else size = tag_list.GetSize();

    for (var i = 0; i < size; ++i) {
      tag_obj = tag_list.GetElement(i);

      /* In simulation demo mode, assign new tag source unconditionally,
               including INIT ONLY tags. In live data mode, handle INIT ONLY tags 
               using real-time data as shown in the code below. 
            */
      if (this.RandomData) {
        this.AssignTagSource(tag_obj, tag_source);

        /* For demo purposes, initialize tags with TagName="Speed" 
                   using a random data value. These tags are present for motor 
                   ebjects in scada_aeration.g, as well as a gauge and slider 
                   in scada_motor_info.g. 
                */
        if (tag_name == "Speed")
          this.state.MainViewport.SetDTag(
            tag_source,
            this.GLG.Rand(300.0, 1500.0),
            true
          );

        continue;
      }

      /* If tag is INIT_ONLY, initialize its value based on the current 
               data value for the given tag_source. Don't reassign TagSource 
               for this tag_obj, it is initilaized only once and will not be 
               subject to periodic updates.
            */
      var access_type /* int */ = Math.trunc(
        tag_obj.GetDResource("TagAccessType")
      );

      if (access_type == this.GLG.GlgTagAccessType.INIT_ONLY_TAG) {
        var data_type /* int */ = Math.trunc(tag_obj.GetDResource("DataType"));

        if (data_type == this.GLG.GlgDataType.D) {
          var d_value = this.state.MainViewport.GetDTag(
            tag_source
          ); /* double  */
          tag_obj.SetDResource(null, d_value);
        }
      } else this.AssignTagSource(tag_obj, tag_source);
    }

    return size;
  }

  //////////////////////////////////////////////////////////////////////////////
  // Remap tags in the loaded drawing if needed.
  // In demo mode, it assigns unset tag sources to be the same as
  // tag names.
  //////////////////////////////////////////////////////////////////////////////
  RemapTags(/* GlgObject */ drawing_vp) {
    var tag_obj; /* GlgObject */
    var tag_source /* String  */, tag_name; /* String  */

    /* Obtain a list of all tags defined in the drawing and remap them
           as needed.
        */
    var tag_list /* GlgObject */ = drawing_vp.CreateTagList(
      /* List all tags */ false
    );
    if (tag_list == null) return;

    var size = tag_list.GetSize();
    // alert(size)
    if (size == 0) return; // no tags found

    // Traverse the tag list and remap each tag as needed.
    for (var i = 0; i < size; ++i) {
      tag_obj = tag_list.GetElement(i);

      /* Retrieve TagName and TagSource attributes from the
               tag object. TagSource represents the data source variable
               used to supply real-time data. This function demonstrates
               how to reassign the TagSource at run-time.
            */
      tag_name = tag_obj.GetSResource("TagName");
      tag_source = tag_obj.GetSResource("TagSource");

      // alert(tag_name)
      this.HMIPage.RemapTagObject(tag_obj, tag_name, tag_source, this.assetId);
    }
  }

  AssignTagSource(/* GlgObject */ tag_obj, /* String */ new_tag_source) {
    tag_obj.SetSResource("TagSource", new_tag_source);
  }

  //////////////////////////////////////////////////////////////////////////////
  // Utility function to validate the string. Returns true if the string
  // is undefined (invalid).
  //////////////////////////////////////////////////////////////////////////////
  IsUndefined(/* String */ str) /* boolean */ {
    if (str == null || str.length == 0 || str == "unset" || str == "$unnamed")
      return true;

    return false;
  }

  //////////////////////////////////////////////////////////////////////////////
  // Utility function to convert a string to a corresponding int value
  // using a provided table.
  //////////////////////////////////////////////////////////////////////////////
  ConvertStringToType(
    /* TypeRecord[] */ table,
    /* String */ type_str,
    /* int  */ empty_type,
    /* int */ undefined_type
  ) /* int */ {
    if (type_str == null || type_str.length == 0) return empty_type;

    for (var i = 0; table[i].type_str != null; ++i)
      if (type_str == table[i].type_str) return table[i].type_int;

    return undefined_type;
  }

  TypeRecord(/* String */ str, /* int */ value) {
    this.type_str = str;
    this.type_int = value;
  }

  //////////////////////////////////////////////////////////////////////////////
  // ReadMenuConfig is used by GlgSCADAViewer, to read and parse a
  // configuration file. The method stores information in the
  // MenuArray, which is an array of classes of type GlgMenuRecord.
  // Return a number of read records.
  //////////////////////////////////////////////////////////////////////////////
  ReadMenuConfig(/* String */ config_data) /* GlgMenuRecord[] */ {
    if (config_data == null || config_data.length == 0) return null;

    var menu_array = []; /* GlgMenuRecord[] */

    /* Split into text lines. */
    var line_array = config_data.match(/[^\r\n]+/g); /* String[] */

    var num_read_records = 0;
    var num_lines = line_array.length;
    for (var i = 0; i < num_lines; ++i) {
      var line = line_array[i];
      if (line.length == 0 || line.startsWith("#")) continue; // Skip comments and empty lines.

      // Split on commas to get an array of entries.
      var entries = line.split(",");
      var num_entries = entries.length;

      // 5th entry after comma may be "".
      if (num_entries != 4 && num_entries != 5) {
        console.error("Missing entries, line: " + line);
        continue;
      }

      var /* String */
        label_string,
        drawing_name,
        tooltip_string,
        drawing_title;

      for (var j = 0; j < 4; ++j) {
        // Remove heading and trailing spaces
        var entry = entries[j].trim();

        entry = this.HandleLineFeedChar(entry);

        // Fill table elements.
        switch (j) {
          case 0:
            label_string = entry;
            break;
          case 1:
            drawing_name = entry;
            break;
          case 2:
            tooltip_string = entry;
            break;
          case 3:
            drawing_title = entry;
            break;
        }
      }

      var menu_item = new this.GlgMenuRecord(
        label_string,
        drawing_name,
        tooltip_string,
        drawing_title
      );
      menu_array.push(menu_item);
      ++num_read_records;
    }

    return menu_array;
  }

  HandleLineFeedChar(/* String */ string) /* String */ {
    return string.replace(/\\n/g, "\n");
  }

  //////////////////////////////////////////////////////////////////////////////
  EmptyHMIPage() {
    // extends HMIPageBase
    // new HMIPageBase(this)
    HMIPageBase.UpdateData();
  }

  // EmptyHMIPage.prototype = Object.create( HMIPageBase.prototype );
  // EmptyHMIPage.prototype.constructor = EmptyHMIPage;
  // EmptyHMIPage.prototype.GetUpdateInterval = function()
  // {
  //    /* Idle, but invoke periodically to check for a new update interval due to
  //       a page change.
  //    */
  //    return 250;
  // }
  // EmptyHMIPage.prototype.UpdateData = function()
  // {
  //    return true;  // Return true to disable tag updates for an empty page.
  // }

  //////////////////////////////////////////////////////////////////////////////
  // Changes drawing size while maintaining width/height aspect ratio.
  //////////////////////////////////////////////////////////////////////////////
  SetDrawingSizeFunc(next_size) {
    const ASPECT_RATIO = 900 / 700;

    // Settings for desktop displays.
    const MIN_WIDTH = 800;
    const MAX_WIDTH = 1000;
    const SCROLLBAR_WIDTH = 15;

    if (this.SetDrawingSize.size_index == undefined) {
      // first time
      this.SetDrawingSize.size_index = 0;

      this.SetDrawingSize.small_sizes = [1, 1.5, 2, 2.5];
      this.SetDrawingSize.medium_sizes = [1, 0.75, 1.25, 1.5];
      this.SetDrawingSize.large_sizes = [1, 0.6, 1.25, 1.5];
      this.SetDrawingSize.num_sizes = this.SetDrawingSize.small_sizes.length;
      //   this.SetDrawingSize.is_mobile = ( screen.width <= 760 );

      window.addEventListener("resize", () => {
        this.SetDrawingSizeFunc(false);
      });
    } else if (next_size) {
      ++this.SetDrawingSize.size_index;
      this.SetDrawingSize.size_index %= this.SetDrawingSize.num_sizes;
    }

    var drawing_area = document.getElementById("glg_area");
    if (this.SetDrawingSize.is_mobile) {
      /* Mobile devices use constant device-width, adjust only the height 
               of the drawing to keep the aspect ratio.
            */
      drawing_area.style.height =
        "" + drawing_area.clientWidth / ASPECT_RATIO + "px";
    } /* Desktop */ else {
      var span = document.body.clientWidth;
      if (!this.SetDrawingSize.is_mobile) span -= this.SCROLLBAR_WIDTH;

      var start_width;
      if (span < MIN_WIDTH) start_width = MIN_WIDTH;
      else if (span > MAX_WIDTH) start_width = MAX_WIDTH;
      else start_width = span;

      var size_array;
      if (span < 600) size_array = this.SetDrawingSize.small_sizes;
      else if (span < 800) size_array = this.SetDrawingSize.medium_sizes;
      else size_array = this.SetDrawingSize.large_sizes;

      var size_coeff = size_array[this.SetDrawingSize.size_index];
      var width = Math.max(start_width * size_coeff, MIN_WIDTH);

      drawing_area.style.width = "" + width + "px";
      drawing_area.style.height = "" + width / ASPECT_RATIO + "px";
    }
  }

  //////////////////////////////////////////////////////////////////////////////
  SetCanvasResolution() {
    // Set canvas resolution only for mobile devices with devicePixelRatio != 1.
    if (window.devicePixelRatio == 1 || !this.SetDrawingSize.is_mobile)
      return 1.0; // Use coord scale = 1.0 for desktop.

    /* The first parameter defines canvas coordinate scaling with values 
           between 1 and devicePixelRatio. Values greater than 1 increase 
           canvas resolution and result in sharper rendering. The value of 
           devicePixelRatio may be used for very crisp rendering with very thin lines.
     
           Canvas scale > 1 makes text smaller, and the second parameter defines
           the text scaling factor used to increase text size.
     
           The third parameter defines the scaling factor that is used to
           scale down text in native widgets (such as native buttons, toggles, etc.)
           to match the scale of the drawing.
        */
    var coord_scale = 3.0;
    this.GLG.SetCanvasScale(coord_scale, 1.5, 0.5);

    // Mobile devices use fixed device-width: disable Change Drawing Size button.
    var change_size_button = document.getElementById("change_size");
    if (change_size_button != null)
      change_size_button.parentNode.removeChild(change_size_button);

    return coord_scale; // Chosen coord scale for mobile devices.
  }

  //////////////////////////////////////////////////////////////////////////////
  // Adjusts the specified offset by a requested amount.
  //////////////////////////////////////////////////////////////////////////////
  AdjustOffset(
    /* GlgObject */ object,
    /* String */ offset_name,
    /* double */ adjustment
  ) {
    var value = object.GetDResource(offset_name); /* double */
    value += adjustment;
    object.SetDResource(offset_name, value);
  }

  //////////////////////////////////////////////////////////////////////////////
  // Scales the specified offset by a requested scale factor.
  //////////////////////////////////////////////////////////////////////////////
  ScaleParameter(
    /* GlgObject */ object,
    /* String */ offset_name,
    /* double */ scale
  ) {
    var value = object.GetDResource(offset_name); /* double */
    value *= scale;
    object.SetDResource(offset_name, value);
  }

  //////////////////////////////////////////////////////////////////////////////
  // Loads any assets required by the application and invokes the specified
  // callback when done.
  // Alternatively, the application's drawing can be loaded as an asset here
  // as well, so that it starts loading without waiting for other assets to
  // finish loading.
  //////////////////////////////////////////////////////////////////////////////
  LoadAssets(callback) {
    /* HTML5 doesn't provide a scrollbar input element (only a range input 
           html element is available). This application needs to load GLG scrollbars
           used for integrated chart scrolling. For each loaded scrollbar, the 
           AssetLoaded callback is invoked with the supplied data.
        */
    this.GLG.LoadWidgetFromURL(
      "/static/images/scrollbar_h.g",
      null,
      this.AssetLoadedFunc.bind(this),
      { name: "scrollbar_h", callback: callback }
    );
    // this.AssetLoaded()
    this.GLG.LoadWidgetFromURL(
      "/static/images/scrollbar_v.g",
      null,
      this.AssetLoadedFunc.bind(this),
      { name: "scrollbar_v", callback: callback }
    );
    // this.AssetLoaded();
    // this.GLG.LoadAsset(this.ConfigFile, this.GLG.GlgHTTPRequestResponseType.TEXT,
    //     this.AssetLoaded, { name: "config_file", callback: callback });
    this.GLG.LoadObjectFromURL(
      "/static/images/empty_drawing.g",
      null,
      this.AssetLoadedFunc.bind(this),
      { name: "empty_drawing", callback: callback }
    );
    // this.AssetLoaded();
  }

  //////////////////////////////////////////////////////////////////////////////
  AssetLoadedFunc(loaded_obj, data, path) {
    if (data.name == "scrollbar_h") {
      if (loaded_obj != null)
        loaded_obj.SetResourceObject("$config/GlgHScrollbar", loaded_obj);
    } else if (data.name == "scrollbar_v") {
      if (loaded_obj != null)
        loaded_obj.SetResourceObject("$config/GlgVScrollbar", loaded_obj);
    }
    // else if (data.name == "config_file") {
    //     this.ConfigFileData = loaded_obj;
    // }
    else if (data.name == "empty_drawing") {
      this.EmptyDrawing = loaded_obj;
    } else console.error("Unexpected asset name");

    /* Define an internal variable to keep the number of loaded assets. */
    if (this.AssetLoaded.num_loaded == undefined)
      this.AssetLoaded.num_loaded = 1;
    else ++this.AssetLoaded.num_loaded;

    // Invoke the callback after all assets have been loaded.
    if (this.AssetLoaded.num_loaded == 4) data.callback();
  }

  // AssetLoadedFunc(glg_object, data, path) {

  //     if (data.name === "scrollbar_h") {
  //         if (glg_object != null)
  //             glg_object.SetResourceObject("$config/GlgHScrollbar", glg_object);
  //     }
  //     else if (data.name === "scrollbar_v") {
  //         if (glg_object != null)
  //             glg_object.SetResourceObject("$config/GlgVScrollbar", glg_object);
  //     }
  //     else
  //         console.error("Unexpected asset name");

  //     /* Define an internal variable to keep the number of loaded assets. */
  //     if (this.AssetLoaded.num_loaded === undefined)
  //         this.AssetLoaded.num_loaded = 1;
  //     else
  //         ++this.AssetLoaded.num_loaded;

  //     /* Invoke the callback (the second parameter of the data array) after all
  //     assets have been loaded.
  //     */
  //     if (this.AssetLoaded.num_loaded === 2)
  //         data.callback(data.user_data);
  // }
  //////////////////////////////////////////////////////////////////////////
  GetCurrTime() /* double */ {
    return Date.now() / 1000; // seconds
  }

  //////////////////////////////////////////////////////////////////////////
  // Is used to store information for an individual menu item,
  // can be extended as needed.
  //////////////////////////////////////////////////////////////////////////
  GlgMenuRecord(
    /* String */ label_string,
    /* String */ drawing_name,
    /* String */ tooltip_string,
    /* String */ drawing_title
  ) {
    this.label_string = label_string;
    this.drawing_name = drawing_name;
    this.tooltip_string = tooltip_string;
    this.drawing_title = drawing_title;
  }

  GlgMenuRecordDef() {}

  //////////////////////////////////////////////////////////////////////////
  // Is used to store information for a given GLG tag object,
  // can be extended as needed.
  //////////////////////////////////////////////////////////////////////
  GlgTagRecord(
    /* int */ data_type,
    /* String */ tag_source,
    /* GlgObject */ tag_obj
  ) {
    this.data_type = data_type;
    this.tag_source = tag_source;
    this.tag_obj = tag_obj;

    /* plot_time_ep is TimeEntryPoint for a plot in a RealTimeChart, if any.
           If SUPPLY_PLOT_TIME_STAMP flag is true and the drawing contains charts,
           this object is non-null for tags attached to charts' ValueEntryPoint.
           It is set to null by default. It will be assigned in SetPlotTimeEP() 
           if needed.
        */
    this.plot_time_ep = null; /* GlgObject */
  }

  //////////////////////////////////////////////////////////////////////////////
  // Is used to store plot data points.
  //////////////////////////////////////////////////////////////////////////////
  PlotDataPoint(
    /* double */ value,
    /* double */ time_stamp,
    /* boolean */ value_valid
  ) {
    this.value = value;
    this.time_stamp = time_stamp;
    this.value_valid = value_valid;
  }

  ///////////////////////////////////////////////////////////////////////
  // Is used to store data for animating the drawing.
  ///////////////////////////////////////////////////////////////////////
  DataPoint() {
    var value = null; /* double or string */
    var time_stamp = null; /* double */
  }

  //////////////////////////////////////////////////////////////////////////
  // Used to store information for an individual alarm, can be extended
  // as needed.
  //////////////////////////////////////////////////////////////////////
  AlarmRecord() {
    this.time = null; /* double : Epoch time in seconds. */
    this.tag_source = null; /* String */
    this.description = null; /* String */

    /* If string_value is set to null, double_value will be displayed as alarm 
           value; otherwise string_value will be displayed.
        */
    this.string_value = null; /* String  */
    this.double_value = 0; /* double */

    this.status = 0; /* int */
    this.ack = false; /* boolean */

    this.age = 0; /* int: Used for demo alarm simulation only. */
  }

  //////////////////////////////////////////////////////////////////////////
  // Is used to store information for each active popup dialog.
  //////////////////////////////////////////////////////////////////////
  GlgActiveDialogRecord(
    /* int */ dialog_type,
    /* GlgObject */ dialog,
    /* GlgObject */ subwindow,
    /* GlgObject */ popup_vp,
    /* boolean */ isVisible
  ) {
    this.dialog_type = dialog_type;
    this.dialog = dialog; // dialog object ID
    this.subwindow = subwindow; // Subwindow object inside a dialog.
    this.popup_vp = popup_vp; // Viewport loaded into subwindow's DrawingArea.
    this.isVisible = isVisible;
  }

  ///////////////////////////////////////////////////////////////////////
  // Is used to store information for the active popup menu.
  //////////////////////////////////////////////////////////////////////
  GlgActivePopupMenuRecord(
    /* int */ menu_type,
    /* GlgObject */ menu_obj,
    /* GlgObject */ subwindow,
    /* GlgObject */ menu_vp,
    /* GlgObject */ selected_obj,
    /* boolean */ isVisible
  ) {
    this.menu_type = menu_type;
    this.menu_obj = menu_obj; // menu object ID
    this.subwindow = subwindow; // Subwindow object inside a dialog.
    this.menu_vp = menu_vp; // Viewport loaded into subwindow's drawing_area.
    this.selected_obj = selected_obj; // Symbol that trigerred popup menu.
    this.isVisible = isVisible;
  }

  //*** GLG code - ends here */

  closeSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ msgStatus: false });
  };

  // closeDialog = () => {
  //   this.setState({ dOpen: false });
  // };

  // confirmWrite = () => {
  //   // alert('reached1');
  //   this.setState({ writeConfirm: true, dOpen: false });
  //   // var command_type = this.GetCommandType(this.state.write_obj);

  //   // alert();

  //   if (this.state.write_type == this.WRITE_VALUE) {
  //     // alert('Reached2');
  //     this.WriteValue(
  //       this.state.write_vp,
  //       this.state.write_obj,
  //       this.state.writecmd_obj
  //     );
  //   } else if (this.state.write_type == this.WRITE_VALUE_FROM_WIDGET) {
  //     // alert('Reached');
  //     this.WriteValueFromInputWidget(
  //       this.state.write_vp,
  //       this.state.write_obj,
  //       this.state.writecmd_obj
  //     );
  //   }
  // };

  closeDialog = () => {
    this.setState({ dOpen: false, password: "" });
  };
  closeConfirmDialog = () => {
    this.setState({ confirmOpen: false });
  };

  writeWithoutPassword = () => {
    // alert('reached1');
    this.setState({ writeConfirm: true, confirmOpen: false });
    // var command_type = this.GetCommandType(this.state.write_obj);

    // alert();

    if (this.state.write_type == this.WRITE_VALUE) {
      // alert('Reached2');
      this.WriteValue(
        this.state.write_vp,
        this.state.write_obj,
        this.state.writecmd_obj
      );
    } else if (this.state.write_type == this.WRITE_VALUE_FROM_WIDGET) {
      // alert('Reached');
      this.WriteValueFromInputWidget(
        this.state.write_vp,
        this.state.write_obj,
        this.state.writecmd_obj
      );
    }
  };
  confirmWrite = () => {
    let ip = window.location.hostname;
    let port = window.location.port;

    // alert('reached1');
    let user = localStorage.getItem("User");

      this.setState({ writeConfirm: true, dOpen: false });
      if (this.state.write_obj.HasResourceObject("AckAlarm")) {
        if (this.state.write_obj.GetSResource("AckAlarm") === "generic") {
          let reqPara = { app_type: "ADVAIT_HMI", equip_ids: [this.assetId] };
          // alert(JSON.stringify(reqPara))
          this.GLGService.ackByAsset(reqPara).then((res) => {
            if (res.status == "success") {
              if (this.state.write_type == this.WRITE_VALUE) {
                // alert('Reached2');
                this.WriteValue(
                  this.state.write_vp,
                  this.state.write_obj,
                  this.state.writecmd_obj
                );
              } else if (
                this.state.write_type == this.WRITE_VALUE_FROM_WIDGET
              ) {
                // alert('Reached');
                this.WriteValueFromInputWidget(
                  this.state.write_vp,
                  this.state.write_obj,
                  this.state.writecmd_obj
                );
              }
            } else {
              this.setState({
                msgStatus: true,
                msgType: "error",
                msgContent: res.response,
              });
            }
          });
        }
      } else {
        if (this.state.write_type == this.WRITE_VALUE) {
          // alert('Reached2');
          this.WriteValue(
            this.state.write_vp,
            this.state.write_obj,
            this.state.writecmd_obj
          );
        } else if (this.state.write_type == this.WRITE_VALUE_FROM_WIDGET) {
          // alert('Reached');
          this.WriteValueFromInputWidget(
            this.state.write_vp,
            this.state.write_obj,
            this.state.writecmd_obj
          );
        }
      }
  };

  onPasswordChange = (event) => {
    this.setState({ password: event.target.value });
  };

  // loadGLG() {
  //     window.open("http://localhost:8102/static/glg_demo/scada_viewer.html", "_blank");
  // }

  render() {
    const {
      msgContent,
      msgStatus,
      msgType,
      isSpinner,
      dOpen,
      confirmOpen,
    } = this.state;
    const { classes } = this.props;
    return (
      <Wrapper>
        <Dialog
          open={confirmOpen}
          onClose={this.closeConfirmDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Write value confirmation."}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to write?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.closeConfirmDialog}
              color="secondary"
              variant="outlined"
            >
              No
            </Button>
            <Button
              onClick={this.writeWithoutPassword}
              color="primary"
              variant="outlined"
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={dOpen}
          onClose={this.closeDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Write value confirmation."}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <TextField
                id="password"
                label="Password"
                type="password"
                fullWidth
                margin="normal"
                value={this.state.password}
                onChange={this.onPasswordChange}
                required={true}
                // InputProps={{
                //   className: classes.input
                // }}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.closeDialog}
              color="secondary"
              variant="outlined"
            >
              No
            </Button>
            <Button
              onClick={this.confirmWrite}
              color="primary"
              variant="outlined"
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>

        <Card>
          {isSpinner ? (
            <CircularProgress
              className={classes.progress}
              color="primary"
              size={50}
              thickness={3}
            />
          ) : (
            <div className="glg_wrapper" id="glg_area"></div>
          )}
          <div id="status_div"></div>
          {msgStatus ? (
            <CustomizedSnackbars
              opened={msgStatus}
              snackType={msgType}
              snackMessage={msgContent}
              closed={this.closeSnackBar}
            ></CustomizedSnackbars>
          ) : null}
        </Card>
      </Wrapper>
    );
  }
}

class EmptyHMIPage extends HMIPageBase {
  // new HMIPageBase(this)
  // UpdateData();
}

TestGLG.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TestGLG);

// export default Graphics;
