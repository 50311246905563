import {
    axiosInstance1,
    axiosInstance2,
    axiosInstance7,
  } from '../authorization/auth-intercept-service';
  import axios from 'axios';
  class AssetHomeService {
    user = localStorage.getItem('User');
    userEmail = JSON.parse(this.user)['email'];
  
    
  
    
  async getAssetByUnit(req) {
    return axiosInstance1
      .post('assets/getAssetIdByUnitIds', req)
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }
  
  async tcpRequest(reqParams) {
    return axiosInstance1
      .post('tcp_request', reqParams)
      .then((res) => {
        console.log("TCP Req"+JSON.stringify(res));
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async tcpRequest2(reqParams) {
    return axiosInstance1
      .post('tcp_request', reqParams)
      .then((res) => {
        console.log("TCP Req"+JSON.stringify(res));
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }
  
  
  async getAssetFields(req) {
    return axiosInstance1
      .post('assets/getFieldData', req)
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async getMapAsset(req) {
    return axiosInstance1
      .post('assets/getAssetNavigation', req)
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }
  
  async getAssetsLiveData(reqParams) {
    return axiosInstance7
      .post('/assets/get_assets_live_value', reqParams)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }
    handleResponseError(response) {
      throw new Error('HTTP error, status = ' + response.status);
    }
    handleError(error) {
      console.log(error.message);
    }
  }
  export default AssetHomeService;
  