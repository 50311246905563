import { axiosInstance4 } from '../authorization/auth-intercept-service';
class LicenceService {

    async validateLicence(){
        return axiosInstance4.get('/slt/init').then(res => {
            console.log(res);
            return res.data;
        }).catch(err => {
            console.log(err);
            return err;
        });
    }

    async getLicensedModules(){
        return axiosInstance4.get('/slt/getAllModule').then(res => {
            console.log(res);
            return res.data;
        }).catch(err => {
            console.log(err);
            return err;
        });
    }
}
export default LicenceService;