import {
  axiosInstance1,
  axiosInstance2,
  axiosInstance5,
} from '../authorization/auth-intercept-service';
class GroupService {
  user = localStorage.getItem('User');
  userEmail = JSON.parse(this.user)['email'];
  // userId = 'xxx';

  async getAllGroupNumbers() {
    return axiosInstance1
      .get('tag/getGrpNos')
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async getTagsByGroupNumber(grpNum) {
    return axiosInstance5
      .post('tag/getAllByGrpNo', grpNum)
      .then((res) => {
        // tag/getAllByGrpNo
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async liveData(tagList) {
    return axiosInstance2
      .post('log_tags_data/getLive', tagList)
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async WriteValue(reqParams) {
    return axiosInstance1
      .post('write?email=' + this.userEmail, reqParams)
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async tagAlarm(reqParams) {
    return axiosInstance2
      .post('activeAlarms/latestByTags', reqParams)
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async OPC(reqParams) {
    return axiosInstance1
      .post('topic/opc_node/value/' + this.userEmail, reqParams)
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }
}
export default GroupService;
