import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Wrapper } from '../../components';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Card } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import AlarmService from '../../services/alarms/alarm-service';
import { CustomizedSnackbars } from '../../shared/snackbar-service';
import './AlarmActive.css';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import CircularProgress from '@material-ui/core/CircularProgress';
// import ConfirmDialog from '../../shared/confirmationBox';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import VirtualizedSelect from 'react-virtualized-select';
import 'react-select/dist/react-select.css';
import 'react-virtualized/styles.css';
import 'react-virtualized-select/styles.css';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Icon from '@material-ui/core/Icon';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import FilterListIcon from '@material-ui/icons/FilterList';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import FormHelperText from '@material-ui/core/FormHelperText';
import ListItemIcon from '@material-ui/core/ListItemIcon';

//*** Enhanced table: start */
function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  {
    id: 'date_time',
    numeric: false,
    disablePadding: false,
    label: 'Date Time',
  },
  { id: 'tag_name', numeric: false, disablePadding: false, label: 'Tag' },
  {
    id: 'alarm_desc',
    numeric: false,
    disablePadding: false,
    label: 'Description',
  },
  { id: 'value', numeric: false, disablePadding: false, label: 'Value' },
  { id: 'priority', numeric: false, disablePadding: false, label: 'Priority' },
  { id: 'unit_id', numeric: false, disablePadding: false, label: 'Unit' },
  {
    id: 'equipment_id',
    numeric: false,
    disablePadding: false,
    label: 'Equipment',
  },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
  { id: 'is_ack', numeric: false, disablePadding: false, label: 'Is Ack' },
  {
    id: 'acknowledged_by',
    numeric: false,
    disablePadding: false,
    label: 'Ack By',
  },
  {
    id: 'acknowledged_at',
    numeric: false,
    disablePadding: false,
    label: 'Ack At',
  },
];

class EnhancedTableHead extends React.Component {
  createSortHandler = (property) => (event) => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
    } = this.props;

    return (
      <TableHead>
        <TableRow style={{ backgroundColor: '#BBB', fontSize: '18px' }}>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
              color="default"
            />
          </TableCell>
          {rows.map((row) => {
            return (
              <TableCell
                key={row.id}
                numeric={row.numeric}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false}
                style={{ color: '#333' }}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = (theme) => ({
  root: {
    paddingRight: theme.spacing.unit,
    minHeight: '30px',
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.primary.main,
        backgroundColor: lighten(theme.palette.primary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.primary.dark,
      },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.primary,
  },
  title: {
    flex: '0 0 auto',
  },
});

let EnhancedTableToolbar = (props) => {
  const { numSelected, classes, onAck } = props;

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subheading">
            {numSelected} selected
          </Typography>
        ) : (
            <Typography variant="title" id="tableTitle">
              Active Alarm
          </Typography>
          )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <Tooltip title="Acknowledge Alarm">
            <IconButton aria-label="Acknowledge">
              <CheckCircleIcon onClick={onAck} color="primary" />
            </IconButton>
          </Tooltip>
        ) : (
            <Tooltip title="Filter list">
              <IconButton aria-label="Filter list">
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          )}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onAck: PropTypes.func,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

//*** Enhanced table: end */

const actionsStyles = (theme) => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 2.5,
  },
});

class TablePaginationActions extends React.Component {
  handleFirstPageButtonClick = (event) => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = (event) => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1)
    );
  };

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowRight />
          ) : (
              <KeyboardArrowLeft />
            )}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowLeft />
          ) : (
              <KeyboardArrowRight />
            )}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
};

const TablePaginationActionsWrapped = withStyles(actionsStyles, {
  withTheme: true,
})(TablePaginationActions);

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 1,
  },
  table: {
    minWidth: 500,
    color: '#FFF',
    width: '100%',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  progress: {
    marginLeft: '50%',
    position: 'absolute',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: theme.spacing.unit * 1,
    paddingBottom: '5px',
  },
  textField1: {
    marginLeft: theme.spacing.unit * 1,
    marginRight: theme.spacing.unit * 1,
    width: 220,
  },
  textField2: {
    marginLeft: theme.spacing.unit * 1,
    marginRight: theme.spacing.unit * 1,
    width: 350,
  },
  textField3: {
    marginLeft: theme.spacing.unit * 1,
    marginRight: theme.spacing.unit * 1,
    width: 130,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  formControl: {
    width: 420,
  },
  indeterminateColor: {
    color: '#f50057',
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  variant: 'menu',
};

class AlarmActive extends Component {
  constructor(props) {
    super(props);
    this.alarmService = new AlarmService();
    this.intervalId = 0;
    let dt = new Date();
    let tm =
      ('0' + dt.getHours()).slice(-2) + ':' + ('0' + dt.getMinutes()).slice(-2);
    dt = dt.toISOString().split('T')[0];
    this.units = localStorage.getItem('User');
    this.units = JSON.parse(JSON.parse(this.units)['unit_id']);
    const unitIds = this.units.map((u) => {
      return Object.assign(
        {},
        {
          value: u,
          label: u,
        }
      );
    });
    this.state = {
      rows: [],
      msgStatus: false,
      msgType: '',
      msgContent: '',
      unitIDs: unitIds,
      equipIDs: [],
      page: 0,
      rowsPerPage: 50,
      isSpinner: false,
      open: false,
      selectedUnit: this.units,
      selectedAck: 'N',
      order: 'desc',
      orderBy: 'date_time',
      selected: [],
      inputValue: '',
      hasError5: false,
      errorStyle5: {},
      totalRowCount: 0,
      refreshButton: 'Stop Refresh',
      unitSelected: this.units,
      isAllSelected: false,
      unitList: this.units
    };
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }
    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState((state) => ({
        selected: state.rows.map((n) => n._id + '*' + n.tag_name),
      }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({ selected: newSelected });
  };

  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  handleChangePage = (event, page) => {
    this.setState({ page: page, selected: [] });
    this.getActiveAlarmsByPagination(page);
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  componentDidMount() {
    if(localStorage.getItem("select_unit") != undefined){
      this.state.selectedUnit = [localStorage.getItem('select_unit')];
      this.state.unitSelected = [localStorage.getItem('select_unit')];
      // alert(this.state.selectedUnit)
      localStorage.removeItem("select_unit");
    }
    this.getEquipments();
    this.getTotalCount();
    // this.getFilteredAlarms();
    this.intervalId = setInterval(this.getTotalCount, 5000); // 5sec = 5000
  }

  componentWillUnmount() {
    console.log('interval end: ' + this.intervalId);
    clearInterval(this.intervalId);
  }

  handleChangeUnit = (event) => {
    const value = event.target.value;
    // alert(JSON.stringify(value));
    if (value[value.length - 1] === 'all') {
      // setSelected(selected.length === options.length ? [] : options);
      let allOpt =
        this.state.selectedUnit.length === this.units.length
          ? []
          : this.units;
      this.setState({
        selectedUnit: allOpt,
      });

      // this.state.unitSelected = allOpt;

      return;
    }
    this.state.selectedUnit = value;
    this.setState({ selectedUnit: value });
    // this.changeUnitID(this.state.unitSelected);
    // alert(JSON.stringify(this.state.selected));
  };

  getEquipments() {
    this.alarmService.getEquipments().then((res) => {
      // alert('res in page: '+JSON.stringify(res))
      if (res && res.length > 0) {
        const eqpObj = res.map((e) => {
          return Object.assign(
            {},
            {
              value: e,
              label: e,
            }
          );
        });
        this.setState({ equipIDs: eqpObj });
      } else {
        this.setState({
          msgStatus: true,
          msgType: 'error',
          msgContent: 'No equipments found!',
        });
      }
    });
  }

  getActiveAlarms = () => {
    this.setState({ isSpinner: true });
    this.state.selectedAck = 'ALL';
    let reqParams = { unit_id: this.state.selectedUnit, ack: 'N' };
    // alert(JSON.stringify(reqParams));
    this.alarmService.getActiveAlarm(reqParams).then((res) => {
      if (res && res.length > 0) {
        this.setState({ rows: res, isSpinner: false });
      } else {
        // this.setState({ msgStatus: true, msgType:'error', msgContent:'No data found!', rows: [], isSpinner:false });
        this.setState({ rows: [], isSpinner: false });
      }
    });
  };

  getBackgoundColor(r) {
    if (r.status === 'RTN') {
      return '#49CC90'; //Green #49CC90
    } else if (r.status === 'ALM') {
      if (r.priority === 'High') {
        return '#fc5a23'; //Orange #FF4400
      } else if (r.priority === 'Low') {
        return '#FCA130'; //Yellow #FFBB00 #FC9403 #FCA130
      } else {
        return '#F44336'; //Red  #D50000  #FF1100 #F93E3E
      }
    }
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  openDialog = () => {
    this.setState({ open: true });
  };

  acknowledgeAlarm = () => {
    let ackArr = [],
      tags = [];
    this.state.selected.map((s) => {
      ackArr.push(s.split('*')[0]);
      tags.push(s.split('*')[1]);
    });

    let ackReq = { app_type: 'ADVAIT_HMI', _id: ackArr, tag_name: tags };
    this.alarmService.ackActiveAlarms(ackReq).then((res) => {
      if (res.length > 0) {
        // this.getFilteredAlarms();
        this.getTotalCount();
        this.setState({
          open: false,
          msgStatus: true,
          msgType: 'success',
          msgContent: 'Alarm acknowledged successfully!',
        });
      } else {
        this.setState({
          open: false,
          msgStatus: true,
          msgType: 'error',
          msgContent: JSON.stringify(res),
        });
      }
    });
  };

  changeAck = (newAck) => {
    if (newAck) {
      this.setState({ selectedAck: newAck, hasError5: false, errorStyle5: {} });
    } else {
      this.setState({
        selectedAck: newAck,
        hasError5: true,
        errorStyle5: { color: 'red' },
      });
    }
    // this.setState( { selectedAck:newAck });
  };

  getTotalCount = () => {
    this.setState({ totalRowCount: 0, page: 0, rowsPerPage: 50 });
    if (!this.state.hasError5) {
      this.setState({ isSpinner: true });
      let reqParams = {
        unit_id: this.state.selectedUnit,
        is_ack: this.state.selectedAck,
      };
      // alert(JSON.stringify(reqParams));
      this.alarmService.getActiveAlarmCount(reqParams).then((res) => {
        // alert("getActiveAlarm alarms: "+JSON.stringify(res));
        this.setState({ totalRowCount: res.count });
        this.state.totalRowCount = res.count;
        this.getActiveAlarmsByPagination(0);
        // if(res.length > 0){
        //   this.setState({ rows: res, isSpinner:false });
        // }else{
        //   this.setState({ msgStatus: true, msgType:'error', msgContent:'No data found!', rows: [], isSpinner:false });
        //   // this.setState({ rows: [], isSpinner:false });
        // }
      });
    } else {
      this.setState({
        msgStatus: true,
        msgType: 'warning',
        msgContent: 'Form is invalid!',
        rows: [],
        isSpinner: false,
      });
    }
  };

  getActiveAlarmsByPagination = (page) => {
    // alert(page)
    let reqParams = {
      unit_id: this.state.selectedUnit,
      is_ack: this.state.selectedAck,
      page_no: page,
      no_of_records: this.state.rowsPerPage,
    };
    this.alarmService.getActiveAlarmsByPage(reqParams).then((res) => {
      // alert("history alarms: " + JSON.stringify(res));
      if (res.length > 0) {
        this.setState({ rows: res, isSpinner: false });
      } else {
        this.setState({
          msgStatus: false,
          msgType: 'error',
          msgContent: 'No data found!',
          rows: [],
          isSpinner: false,
        });
        // this.setState({ rows: [], isSpinner:false });
      }
    });
  };

  getFilteredAlarms = () => {
    if (!this.state.hasError5) {
      this.setState({ isSpinner: true });
      let reqParams = {
        unit_id: this.state.selectedUnit,
        ack: this.state.selectedAck,
      };
      // alert(JSON.stringify(reqParams));
      this.alarmService.getActiveAlarm(reqParams).then((res) => {
        if (res.length > 0) {
          this.setState({ rows: res, isSpinner: false });
        } else {
          this.setState({
            msgStatus: true,
            msgType: 'error',
            msgContent: 'No data found!',
            rows: [],
            isSpinner: false,
          });
          // this.setState({ rows: [], isSpinner:false });
        }
      });
    } else {
      this.setState({
        msgStatus: true,
        msgType: 'warning',
        msgContent: 'Form is invalid!',
        rows: [],
        isSpinner: false,
      });
    }
  };

  closeSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ msgStatus: false });
  };

  startStopRefresh = () => {
    if (this.state.refreshButton == "Stop Refresh") {
      this.state.refreshButton = 'Start Refresh';
      this.setState({ refreshButton: "Start Refresh" });
      clearInterval(this.intervalId);
    } else {
      this.state.refreshButton = 'Stop Refresh';
      this.setState({ refreshButton: "Stop Refresh" });
      this.intervalId = setInterval(this.getTotalCount, 5000); // 5sec = 5000
    }
  }

  render() {
    console.log('.. in render..');
    const { classes } = this.props;
    const {
      unitIDs,
      equipIDs,
      rowsPerPage,
      page,
      rows,
      isSpinner,
      open,
      selectedAck,
      order,
      orderBy,
      selected,
      hasError5,
      errorStyle5,
      totalRowCount,
      refreshButton, isAllSelected, unitList, selectedUnit
    } = this.state;
    const acknowledged = [
      { label: 'All', value: 'ALL' },
      { label: 'Acknowledge', value: 'Y' },
      { label: 'UnAcknowledge', value: 'N' },
    ];
    // const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
    return (
      <Wrapper>
        {/* <Card>
          <CardContent style={{ padding:'10px' }}> */}
        <Paper className={classes.root}>
          {this.state.msgStatus ? (
            <CustomizedSnackbars
              opened={this.state.msgStatus}
              snackType={this.state.msgType}
              snackMessage={this.state.msgContent}
              closed={this.closeSnackBar}
            ></CustomizedSnackbars>
          ) : null}
          <form className={classes.container}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {/* <Grid container spacing={12}> */}
              <FormControl
                  className={classes.textField3}
                  style={{ marginTop: '10px' }}
                >
                  <Typography style={errorStyle5}>
                    Acknowledged <sup>*</sup>
                  </Typography>
                  <VirtualizedSelect
                    ref="acknowledged"
                    options={acknowledged}
                    simpleValue
                    clearable={false}
                    name="select-acknowledged"
                    value={selectedAck}
                    onChange={this.changeAck}
                    searchable
                    isRequired={true}
                  />
                  {hasError5 && (
                    <FormHelperText style={errorStyle5}>
                      Ack is required!
                    </FormHelperText>
                  )}
                </FormControl>

                <FormControl className={classes.textField2}>
                {/* <InputLabel style={errorStyle} id="mutiple-select-label">
                  Unit Id<sup>*</sup>
                </InputLabel> */}
                <Typography>
                Unit Id <sup>*</sup>
              </Typography>
                <Select
                  labelId="mutiple-select-label"
                  multiple
                  value={selectedUnit}
                  onChange={this.handleChangeUnit}
                  renderValue={(selectedUnit) => selectedUnit.join(', ')}
                  MenuProps={MenuProps}
                >
                  <MenuItem
                    value="all"
                    classes={{
                      root: isAllSelected ? classes.selectedAll : '',
                    }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        classes={{ indeterminate: classes.indeterminateColor }}
                        checked={isAllSelected}
                        indeterminate={
                          selectedUnit.length > 0 &&
                          selectedUnit.length < unitList.length
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.selectAllText }}
                      primary="Select All"
                    />
                  </MenuItem>
                  {unitList.map((option) => (
                    <MenuItem key={option} value={option}>
                      <ListItemIcon>
                        <Checkbox checked={selectedUnit.indexOf(option) > -1} />
                      </ListItemIcon>
                      <ListItemText title={option} primary={option} />
                    </MenuItem>
                  ))}
                </Select>
                {/* {hasError && (
                <FormHelperText style={errorStyle}>
                  Unit Id is required!
                </FormHelperText>
              )} */}
              </FormControl>

                <FormControl>
                  <Typography style={{ marginTop: '10px' }}>&nbsp;</Typography>
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    title="Get Alarms"
                    onClick={this.getTotalCount}
                    style={{ backgroundColor: 'rgb(0, 153, 204' }}
                  >
                    Get <Icon className={classes.rightIcon}>alarm</Icon>
                  </Button>
                </FormControl>

              <FormControl style={{ float: "right" }}>
                <Typography>&nbsp;</Typography>

                <a href="/alarms" class="buttonA js-buttonA"
                  style={{ backgroundColor: 'rgb(0, 153, 204' }} role="button">Historical Alarms <Icon style={{ paddingTop: '3px' }} className={classes.rightIcon}>alarm</Icon></a>
              </FormControl>


              <FormControl style={{ float: 'right' }}>
                <Typography style={{ marginTop: '0px' }}>&nbsp;</Typography>
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  title="Refresh"
                  onClick={this.startStopRefresh}
                  style={{ backgroundColor: 'rgb(0, 153, 204', height: '38px', marginRight: '10px' }}
                >
                  {refreshButton}
                  {/* <Icon className={classes.rightIcon} color='rgb(0, 153, 204'>alarm</Icon> */}
                </Button>
              </FormControl>

              {/* </Grid> */}
            </Grid>
            {/* <FormControl style={{ marginLeft: '10px' }}>
              <Typography style={{ marginTop: '10px' }}>&nbsp;</Typography>
              <Button
                color="primary"
                variant="contained"
                size="small"
                title="Refresh"
                onClick={this.getTotalCount}
                style={{ backgroundColor: 'rgb(0, 153, 204' }}
              >
                Refresh <Icon className={classes.rightIcon}>refresh</Icon>
              </Button>
            </FormControl> */}
          </form>
          {/* </CardContent>
        </Card> */}
        </Paper>
        <Paper className={classes.root}>
          <Dialog
            open={open}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {'Acknowledge Alarm?'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure to acknowledge this alarm?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.handleClose}
                color="secondary"
                variant="outlined"
              >
                No
              </Button>
              <Button
                onClick={this.acknowledgeAlarm}
                color="primary"
                variant="outlined"
                autoFocus
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          <EnhancedTableToolbar
            numSelected={selected.length}
            onAck={this.openDialog}
          />
          <div className={classes.tableWrapper}>
            <Table className="alarmTable">
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={rows.length}
              />
              {isSpinner ? (
                <CircularProgress
                  className={classes.progress}
                  color="primary"
                  size={50}
                  thickness={3}
                />
              ) : (
                  <TableBody>
                    {rows.length > 0 ? (
                      rows.sort(getSorting(order, orderBy)).map((row) => {
                        const isSelected = this.isSelected(
                          row._id + '*' + row.tag_name
                        );
                        return (
                          // 'backgroundColor': this.getBackgoundColor(row),
                          <TableRow
                            key={row._id + '*' + row.tag_name}
                            style={{ cursor: 'pointer' }}
                            role="checkbox"
                            tabIndex={-1}
                            selected={isSelected}
                            onClick={(event) =>
                              this.handleClick(
                                event,
                                row._id + '*' + row.tag_name
                              )
                            }
                          >
                            <TableCell
                              padding="checkbox"
                              style={{ 'padding-left': '6px' }}
                            >
                              <Checkbox checked={isSelected} color="default" />
                            </TableCell>
                            <TableCell
                              style={{ 'padding-left': '6px' }}
                              className={
                                row.status === 'RTN'
                                  ? 'rtn'
                                  : row.status === 'ALM' &&
                                    row.priority === 'High'
                                    ? 'almhigh'
                                    : row.status === 'ALM' && row.priority === 'Low'
                                      ? 'almLow'
                                      : 'almEmg'
                              }
                            >
                              {row.date_time}
                            </TableCell>
                            <TableCell
                              style={{ 'padding-left': '6px' }}
                              className={
                                row.status === 'RTN'
                                  ? 'rtn'
                                  : row.status === 'ALM' &&
                                    row.priority === 'High'
                                    ? 'almhigh'
                                    : row.status === 'ALM' && row.priority === 'Low'
                                      ? 'almLow'
                                      : 'almEmg'
                              }
                            >
                              {row.tag_name}
                            </TableCell>
                            <TableCell
                              style={{ 'padding-left': '6px' }}
                              className={
                                row.status === 'RTN'
                                  ? 'rtn'
                                  : row.status === 'ALM' &&
                                    row.priority === 'High'
                                    ? 'almhigh'
                                    : row.status === 'ALM' && row.priority === 'Low'
                                      ? 'almLow'
                                      : 'almEmg'
                              }
                            >
                              {row.alarm_desc}
                            </TableCell>
                            <TableCell
                              style={{ 'padding-left': '6px' }}
                              className={
                                row.status === 'RTN'
                                  ? 'rtn'
                                  : row.status === 'ALM' &&
                                    row.priority === 'High'
                                    ? 'almhigh'
                                    : row.status === 'ALM' && row.priority === 'Low'
                                      ? 'almLow'
                                      : 'almEmg'
                              }
                            >
                              {row.value}
                            </TableCell>
                            <TableCell
                              style={{ 'padding-left': '6px' }}
                              className={
                                row.status === 'RTN'
                                  ? 'rtn'
                                  : row.status === 'ALM' &&
                                    row.priority === 'High'
                                    ? 'almhigh'
                                    : row.status === 'ALM' && row.priority === 'Low'
                                      ? 'almLow'
                                      : 'almEmg'
                              }
                            >
                              {row.priority}
                            </TableCell>
                            <TableCell
                              style={{ 'padding-left': '6px' }}
                              className={
                                row.status === 'RTN'
                                  ? 'rtn'
                                  : row.status === 'ALM' &&
                                    row.priority === 'High'
                                    ? 'almhigh'
                                    : row.status === 'ALM' && row.priority === 'Low'
                                      ? 'almLow'
                                      : 'almEmg'
                              }
                            >
                              {row.unit_id}
                            </TableCell>
                            <TableCell
                              style={{ 'padding-left': '6px' }}
                              className={
                                row.status === 'RTN'
                                  ? 'rtn'
                                  : row.status === 'ALM' &&
                                    row.priority === 'High'
                                    ? 'almhigh'
                                    : row.status === 'ALM' && row.priority === 'Low'
                                      ? 'almLow'
                                      : 'almEmg'
                              }
                            >
                              {row.equipment_id}
                            </TableCell>
                            <TableCell
                              style={{ 'padding-left': '6px' }}
                              className={
                                row.status === 'RTN'
                                  ? 'rtn'
                                  : row.status === 'ALM' &&
                                    row.priority === 'High'
                                    ? 'almhigh'
                                    : row.status === 'ALM' && row.priority === 'Low'
                                      ? 'almLow'
                                      : 'almEmg'
                              }
                            >
                              {row.status}
                            </TableCell>
                            <TableCell
                              style={{ 'padding-left': '6px' }}
                              className={
                                row.is_ack === 'N' && row.status === 'RTN'
                                  ? 'rtnBlink'
                                  : row.is_ack === 'N' &&
                                    row.status === 'ALM' &&
                                    row.priority === 'High'
                                    ? 'almHighBlink'
                                    : row.is_ack === 'N' &&
                                      row.status === 'ALM' &&
                                      row.priority === 'Low'
                                      ? 'almLowBlink'
                                      : row.is_ack === 'N' &&
                                        row.status === 'ALM' &&
                                        row.priority === 'Emergency'
                                        ? 'almEmgBlink'
                                        : row.is_ack === 'Y' && row.status === 'RTN'
                                          ? 'rtn'
                                          : row.is_ack === 'Y' &&
                                            row.status === 'ALM' &&
                                            row.priority === 'High'
                                            ? 'almhigh'
                                            : row.is_ack === 'Y' &&
                                              row.status === 'ALM' &&
                                              row.priority === 'Low'
                                              ? 'almLow'
                                              : 'almEmg'
                              }
                            >
                              {row.is_ack}
                            </TableCell>
                            <TableCell
                              style={{ 'padding-left': '6px' }}
                              className={
                                row.status === 'RTN'
                                  ? 'rtn'
                                  : row.status === 'ALM' &&
                                    row.priority === 'High'
                                    ? 'almhigh'
                                    : row.status === 'ALM' && row.priority === 'Low'
                                      ? 'almLow'
                                      : 'almEmg'
                              }
                            >
                              {row.acknowledged_by}
                            </TableCell>
                            <TableCell
                              style={{ 'padding-left': '6px' }}
                              className={
                                row.status === 'RTN'
                                  ? 'rtn'
                                  : row.status === 'ALM' &&
                                    row.priority === 'High'
                                    ? 'almhigh'
                                    : row.status === 'ALM' && row.priority === 'Low'
                                      ? 'almLow'
                                      : 'almEmg'
                              }
                            >
                              {row.acknowledged_at}
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                        <p>
                          <center>No data found!</center>
                        </p>
                      )}
                    {/* {emptyRows > 0 && (
                  <TableRow style={{ height: 48 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
                  </TableBody>
                )}
              <TableFooter>
                <TableRow>
                  <TablePagination
                    colSpan={11}
                    count={totalRowCount}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[]}
                    page={page}
                    onChangePage={this.handleChangePage}
                    // onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActionsWrapped}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </div>
        </Paper>
      </Wrapper>
    );
  }
}

AlarmActive.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AlarmActive);

// export default Alarms;
