import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
// import Badge from '@material-ui/core/Badge';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import Collapse from "@material-ui/core/Collapse";
import SearchIcon from "@material-ui/icons/Search";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
// import NotificationsIcon from '@material-ui/icons/Notifications';
import SettingsIcon from "@material-ui/icons/Settings";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
// import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
// import MoreVertIcon from '@material-ui/icons/MoreVert';
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MenuIcon from "@material-ui/icons/Menu";
import HeaderStyles from "../../styles/Header";
import PersonRoundedIcon from "@material-ui/icons/PersonRounded";
import MyBottomNavigation from "./topNavigation";
import { Redirect } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import DynamicNavService from "../../services/GLG/dynamicNav-service";
import { CustomizedSnackbars } from "../../shared/snackbar-service";
import FormControl from "@material-ui/core/FormControl";
import VirtualizedSelect from "react-virtualized-select";
import Typography from "@material-ui/core/Typography";
import { Router } from "react-router-dom";

class Header extends Component {
  navService = new DynamicNavService();
  _id;

  // perm = sessionStorage.getItem('User');
  perm = localStorage.getItem("User");
  perm = JSON.parse(this.perm)["permissions"];
  state = {
    user: "",
    anchorEl: null,
    searchExpanded: false,
    logout: false,
    dOpen: false,
    openModal: false,
    checkedHome: false,
    checkedMap: false,
    checkedAlarm: false,
    checkedTrend: false,
    checkedGrpDisp: false,
    checkedEvents: false,
    checkedReport: false,
    checkedManualEtry: false,
    msgStatus: false,
    msgType: "",
    msgContent: "",
    selectedMenu: "home",
    selectedGraphic: "",
    glgFileList: [],
    permissions: this.perm,
    pageRedirect: false,
    glgPath: "",
    menuList: [],
  };
  componentWillMount() {
    // this.handleDrawerToggle();
    this.glgFiles();
    this.getTopMenuSettings();
    // this.state.user = JSON.parse(sessionStorage.getItem('User'))['fname'];
    this.state.user = JSON.parse(localStorage.getItem("User"))["fname"];
  }

  handleSettingdToggle = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCloseMenu = () => {
    this.setState({ anchorEl: null });
  };

  handleSearchExpandToggle = () => {
    this.setState({ searchExpanded: !this.state.searchExpanded });
  };

  handleDrawerToggle = () => {
    this.props.toggleDrawer();
    if (this.state.searchExpanded) this.handleSearchExpandToggle();
  };

  glgFiles = () => {
    this.state.glgFileList = [];
    this.state.menuList = [
      { label: "Home", value: "home" },
      { label: "Map", value: "geomap" },
      { label: "Alarms", value: "alarmActive" },
      { label: "Trends", value: "trends" },
      { label: "Group Display", value: "groupDisplay" },
      { label: "User Events", value: "events" },
      { label: "Report", value: "oneTimeReport" },
    ];
    // var glgNav = JSON.parse(sessionStorage.getItem('GLGMenu'));
    // alert(this.state.permissions);
    this.navService.dynamicNav().then((res) => {
      if (res && res.navigation) {
        // if (glgNav != null && glgNav != undefined) {
        JSON.parse(res.navigation).map((menuItem, i) => {
          let perm1;
          if (JSON.parse(this.state.permissions).includes(0)) {
            perm1 = true;
          }else if(menuItem.auth === 0){
            perm1 = true;
          } else {
            perm1 = JSON.parse(this.state.permissions).includes(
              parseInt(menuItem.auth)
            );
          }
          // alert(perm1 || menuItem.auth === 0);
          if (
            menuItem.subtasks !== undefined &&
            (perm1 || menuItem.auth === 0)
          ) {
            menuItem.subtasks.map((subMenu, j) => {
              // alert(JSON.stringify(subMenu));
              let perm2;
              if (subMenu.subtasks !== undefined) {
                subMenu.subtasks.map((subsubMenu, k) => {
                  let perm3;
                  if (subsubMenu.subtasks !== undefined) {
                    subsubMenu.subtasks.map((subsubsubMenu, l) => {
                      // perm3 = permissions.includes(subsubsubMenu.auth);
                      if (JSON.parse(this.state.permissions).includes(0)) {
                        perm3 = true;
                      } else {
                        perm3 = JSON.parse(this.state.permissions).includes(subsubsubMenu.auth);
                      }

                      if (perm3 || subsubsubMenu.auth === 0) {
                        this.state.glgFileList.push({
                          label: subsubsubMenu.menuName,
                          value:
                            subsubsubMenu.menuName +
                            "_" +
                            subsubsubMenu.filePath,
                        });
                        this.state.menuList.push({
                          label: subsubsubMenu.menuName,
                          value:
                            "graphics/" +
                            subsubsubMenu.menuName +
                            "_" +
                            subsubsubMenu.filePath,
                        });
                      }
                    });
                  } else {
                    // perm3 = permissions.includes(subsubMenu.auth);
                    if (JSON.parse(this.state.permissions).includes(0)) {
                      perm3 = true;
                    } else {
                      perm3 = JSON.parse(this.state.permissions).includes(subsubMenu.auth);
                    }
                    // console.log(
                    //   "Para3 " + perm3 + " Test " + perm3 ||
                    //     subsubMenu.auth === 0
                    // );
                    // console.log(
                    //   "Para3 " + JSON.parse(this.state.permissions) + " -- " + subsubMenu.auth
                    // );
                    if (perm3 || subsubMenu.auth === 0) {
                      this.state.glgFileList.push({
                        label: subsubMenu.menuName,
                        value: subsubMenu.menuName + "_" + subsubMenu.filePath,
                      });
                      this.state.menuList.push({
                        label: subsubMenu.menuName,
                        value:
                          "graphics/" +
                          subsubMenu.menuName +
                          "_" +
                          subsubMenu.filePath,
                      });
                    }
                  }
                });
              }
            });
            // this.state.glgFileList.push({
            //   label: menuItem.menuName,
            //   value: menuItem.menuName + '_' + menuItem.filePath,
            // });
            // this.state.menuList.push({
            //   label: menuItem.menuName,
            //   value: 'graphics/' + menuItem.menuName + '_' + menuItem.filePath,
            // });
          } else {
            // perm1 = permissions.includes(menuItem.auth);
            // alert(perm1 || menuItem.auth === 0);
            if (perm1) {
              this.state.glgFileList.push({
                label: menuItem.menuName,
                value: menuItem.menuName + "_" + menuItem.filePath,
              });
              this.state.menuList.push({
                label: menuItem.menuName,
                value:
                  "graphics/" + menuItem.menuName + "_" + menuItem.filePath,
              });
            }
          }
        });
        // }
      } else {
        this.state.glgFileList = [];
      }
    });
  };

  getTopMenuSettings = () => {
    this.navService.getTopMenuSettings().then((res) => {
      // alert(res.menu_arr);
      if (res.menu_arr == undefined) {
      } else {
        this._id = res._id;
        var menuArr = res.menu_arr;
        // sessionStorage.setItem('top_menu', menuArr);
        localStorage.setItem("top_menu", menuArr);
        menuArr.map((menu, l) => {
          if (menu == "Home") this.setState({ checkedHome: true });
          if (menu == "Map") this.setState({ checkedMap: true });
          if (menu == "Trend") this.setState({ checkedTrend: true });
          if (menu == "Alarm") this.setState({ checkedAlarm: true });
          if (menu == "Event") this.setState({ checkedEvents: true });
          if (menu == "Group") this.setState({ checkedGrpDisp: true });
          if (menu == "Report") this.setState({ checkedReport: true });
          if (menu == "Manual") this.setState({ checkedManualEtry: true });
          // return <li><a href="#">{subsubsubMenu.menuName}</a></li>
        });
        this.setState({ selectedMenu: res.defaultPage });
      }
    });
  };

  submitTopSetting = () => {
    var menuArr = [];
    if (this.state.checkedHome) menuArr.push("Home");
    if (this.state.checkedMap) menuArr.push("Map");
    if (this.state.checkedTrend) menuArr.push("Trend");
    if (this.state.checkedAlarm) menuArr.push("Alarm");
    if (this.state.checkedEvents) menuArr.push("Event");
    if (this.state.checkedGrpDisp) menuArr.push("Group");
    if (this.state.checkedReport) menuArr.push("Report");
    if (this.state.checkedManualEtry) menuArr.push("Manual");
    //  alert(this._id)
    if (this._id == undefined) {
      var setReq = { menu_arr: menuArr, defaultPage: this.state.selectedMenu };
      //  alert(JSON.stringify(setReq))
      this.navService.saveTopMenuSettings(setReq).then((res) => {
        // alert(JSON.stringify(res))
        if (res.status == "success") {
          // this.getFilteredAlarms();
          // this.getTopMenuSettings();
          this.setState({
            openModal: true,
            msgStatus: true,
            msgType: "success",
            msgContent: "Top Menu Setting Saved successfully!",
          });
          window.location.reload();
        } else {
          this.setState({
            openModal: true,
            msgStatus: true,
            msgType: "error",
            msgContent: JSON.stringify(res.message),
          });
        }
      });
    } else {
      var setReq = {
        _id: this._id,
        menu_arr: menuArr,
        defaultPage: this.state.selectedMenu,
      };

      this.navService.updateTopMenuSettings(setReq).then((res) => {
        // alert(JSON.stringify(res))
        if (res.status == "success") {
          // this.getFilteredAlarms();
          // this.getTopMenuSettings();
          this.setState({
            openModal: false,
            msgStatus: true,
            msgType: "success",
            msgContent: "Top Menu Setting updated successfully!",
          });
          window.location.reload();
        } else {
          this.setState({
            openModal: true,
            msgStatus: true,
            msgType: "error",
            msgContent: JSON.stringify(res.message),
          });
        }
      });
    }
  };

  handleNotificationToggle = () => {
    this.props.toogleNotifications();
    if (this.state.searchExpanded) this.handleSearchExpandToggle();
  };

  openDialog = () => {
    this.setState({ dOpen: true, anchorEl: null });
  };

  openSettingsDialog = () => {
    this.setState({ openModal: true, anchorEl: null });
  };

  closeSettingsDialog = () => {
    this.setState({ openModal: false });
  };

  closeDialog = () => {
    this.setState({ dOpen: false });
  };

  handleChange = (event, menu) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  logOut = () => {
    // localStorage.clear();
    localStorage.clear();
    this.setState({ logout: true });
    window.location.reload();
  };

  closeSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ msgStatus: false });
  };

  onChangeMenu = (selectedMenu) => {
    this.setState({ selectedMenu: selectedMenu.value });
  };

  onChangeGraphic = (selectedGraphic) => {
    // alert(selectedGraphic.value);
    localStorage.setItem("asset_id", selectedGraphic.value.split("_")[0]);
    this.setState({
      glgPath: "/graphics/" + selectedGraphic.value,
      selectedGraphic: selectedGraphic.value,
      pageRedirect: true,
    });
    window.location.replace("/graphics/" + selectedGraphic.value);

    // Router.replace('/graphics/' + selectedGraphic.value);
  };

  render() {
    const { classes, logo, logoAltText, toggleFullscreen } = this.props;
    const {
      anchorEl,
      searchExpanded,
      logout,
      dOpen,
      openModal,
      checkedHome,
      checkedMap,
      checkedAlarm,
      checkedTrend,
      checkedGrpDisp,
      checkedEvents,
      checkedReport,
      selectedMenu,
      menuList,
      selectedGraphic,
      glgFileList,
      pageRedirect,
      glgPath,
      checkedManualEtry,
    } = this.state;
    var user = this.state.user;

    
    return (
      <AppBar
        position="static"
        className={classes.appBar}
        style={{ height: "auto" }}
      >
        <Toolbar className={classes.toolBar}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={this.handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>

          {/* <div className={classes.branding}>
            <img src={logo} alt={logoAltText} className={classes.logo} />
          </div> */}

          {/* <Hidden xsDown>
            <div className={classes.searchWrapper}>
              <form className={classes.searchForm}>
                <IconButton
                  aria-label="Search"
                  className={classes.searchIcon}
                >
                  <SearchIcon />
                </IconButton>
                <input className={classes.searchInput} type="text" placeholder="Search" autoFocus={true} />
              </form>
            </div>
          </Hidden> */}

          {/* <Hidden smUp>
            <span className="flexSpacer" />
          </Hidden>

          <Hidden smUp>
            <IconButton
              color="inherit"
              onClick={this.handleSearchExpandToggle}
              aria-expanded={searchExpanded}
              aria-label="Show searchbar"
            >
              <SearchIcon />
            </IconButton>
          </Hidden> */}

          {/* <TopBottomNavigation /> */}
          <MyBottomNavigation />

          <FormControl className={classes.textField3}>
            <VirtualizedSelect
              ref="glgFileList"
              options={glgFileList}
              clearable={true}
              name="select graphics"
              value={selectedGraphic}
              onChange={this.onChangeGraphic}
              searchable
              required={true}
            />
          </FormControl>

          <IconButton
            color="inherit"
            onClick={this.openSettingsDialog}
            title="Top Menu Settings"
          >
            <SettingsIcon />
          </IconButton>

          <Hidden xsDown>
            <IconButton
              color="inherit"
              onClick={toggleFullscreen}
              title="Full Screen"
            >
              <FullscreenIcon />
            </IconButton>
          </Hidden>

          <label>{user}</label>
          <IconButton color="inherit" onClick={this.openDialog} title="Logout">
            <ExitToAppIcon />
          </IconButton>

          {/* <IconButton
            aria-label="User Settings"
            aria-owns={anchorEl ? 'user-menu' : null}
            aria-haspopup="true"
            color="inherit"
            onClick={this.handleSettingdToggle}
            edge="end"
          >
            <PersonRoundedIcon />
          </IconButton>

          <Menu
            id="user-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleCloseMenu}
          >
            <MenuItem onClick={this.handleCloseMenu}>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText inset primary="Settings" />
            </MenuItem>
            <MenuItem onClick={this.handleCloseMenu}>
              <ListItemIcon>
                <AccountBoxIcon />
              </ListItemIcon>
              <ListItemText inset primary="Profile" />
            </MenuItem> */}
          {/* <MenuItem onClick={this.handleCloseMenu}>
              <ListItemIcon>
                <NotificationsOffIcon />
              </ListItemIcon>
              <ListItemText inset primary="Disable notifications" />
            </MenuItem> */}
          {/* <MenuItem onClick={this.handleCloseMenu, this.openDialog}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText inset primary="Sign out" />
            </MenuItem>
          </Menu> */}
          {logout ? <Redirect to="/signin" /> : null}
        </Toolbar>
        <Hidden smUp>
          <Collapse in={searchExpanded} timeout="auto" unmountOnExit>
            <Toolbar className={classes.toolBar}>
              <div className={classes.searchWrapper}>
                <form className={classNames(classes.searchForm, "mr-0")}>
                  <IconButton
                    aria-label="Search"
                    className={classes.searchIcon}
                  >
                    <SearchIcon />
                  </IconButton>
                  <input
                    className={classes.searchInput}
                    type="text"
                    placeholder="Search"
                    autoFocus="true"
                  />
                </form>
              </div>
            </Toolbar>
          </Collapse>
        </Hidden>
        <Dialog
          open={dOpen}
          onClose={this.closeDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Logout?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure to logout from this session?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.closeDialog}
              color="secondary"
              variant="outlined"
            >
              No
            </Button>
            <Button
              onClick={this.logOut}
              color="primary"
              variant="outlined"
              style={{ color: "rgb(0, 153, 204)" }}
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openModal}
          onClose={this.closeSettingsDialog}
          aria-labelledby="form-dialog-title"
          maxWidth="lg"
        >
          <DialogTitle id="form-dialog-title">Top Menu Settings</DialogTitle>
          <DialogContent>
            <form>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedHome}
                      onChange={(e) => {
                        this.handleChange(e, "Home");
                      }}
                      name="checkedHome"
                      color="primary"
                    />
                  }
                  label="Home"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedMap}
                      onChange={(e) => {
                        this.handleChange(e, "Map");
                      }}
                      name="checkedMap"
                      color="primary"
                    />
                  }
                  label="Map"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedAlarm}
                      onChange={(e) => {
                        this.handleChange(e, "Alarm");
                      }}
                      name="checkedAlarm"
                      color="primary"
                    />
                  }
                  label="Alarms"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedTrend}
                      onChange={(e) => {
                        this.handleChange(e, "trend");
                      }}
                      name="checkedTrend"
                      color="primary"
                    />
                  }
                  label="Trends"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedGrpDisp}
                      onChange={(e) => {
                        this.handleChange(e, "Group");
                      }}
                      name="checkedGrpDisp"
                      color="primary"
                    />
                  }
                  label="Group Display"
                />
              </FormGroup>

              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedEvents}
                      onChange={(e) => {
                        this.handleChange(e, "Events");
                      }}
                      name="checkedEvents"
                      color="primary"
                    />
                  }
                  label="User Events"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedReport}
                      onChange={(e) => {
                        this.handleChange(e, "Report");
                      }}
                      name="checkedReport"
                      color="primary"
                    />
                  }
                  label="Report"
                />

                {/* <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedManualEtry}
                      onChange={(e) => {
                        this.handleChange(e, 'Manual_Entry');
                      }}
                      name="checkedManualEtry"
                      color="primary"
                    />
                  }
                  label="Manual Entry"
                /> */}
              </FormGroup>

              <FormControl className={classes.textField2}>
                <Typography>Select Default Page</Typography>
                <VirtualizedSelect
                  ref="menuList"
                  options={menuList}
                  clearable={true}
                  name="select-menuList"
                  value={selectedMenu}
                  onChange={this.onChangeMenu}
                  searchable
                  required={true}
                />
              </FormControl>
            </form>
            {/* </DialogContent>
          <DialogActions> */}
            <br />
            <br />
            <br />
            <br />
            <br />
            <div style={{ float: "right", margin: "10px" }}>
              <Button
                onClick={this.submitTopSetting}
                variant="contained"
                color="primary"
                style={{ margin: "5px", backgroundColor: "rgb(0, 153, 204)" }}
              >
                Submit
              </Button>
              {/* <Button style={{ margin: '5px' }} onClick={this.clearView} variant="contained" color="primary">Clear</Button>
              <Button style={{ margin: '5px' }} onClick={this.openDeleteAll} variant="contained" color="secondary">Delete All</Button> */}
              <Button
                style={{ margin: "5px" }}
                onClick={this.closeSettingsDialog}
                variant="contained"
                color="secondary"
              >
                Cancel
              </Button>
            </div>
          </DialogContent>
        </Dialog>
        {this.state.msgStatus ? (
          <CustomizedSnackbars
            opened={this.state.msgStatus}
            snackType={this.state.msgType}
            snackMessage={this.state.msgContent}
            closed={this.closeSnackBar}
          ></CustomizedSnackbars>
        ) : null}
      </AppBar>
    );
  }
}

Header.prototypes = {
  classes: PropTypes.object.isRequired,
  logo: PropTypes.string,
  logoAltText: PropTypes.string,
};

export default withStyles(HeaderStyles)(Header);
