import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import DynamicNavService from '../../services/GLG/dynamicNav-service';
import LicenceService from '../../services/license/licence-service';
import { Redirect } from 'react-router-dom';
import './nav.css';

class MyBottomNavigation extends Component {
  constructor(props) {
    super(props);
    this.navService = new DynamicNavService();
    this.licService = new LicenceService();
    this.perm = localStorage.getItem('User');
    this.perm = JSON.parse(this.perm)['permissions'];
    this.intervalId = 0;
    this.state = {
      anchorEl: null,
      searchExpanded: false,
      value: 0,
      menuItems: [],
      permissions: this.perm,
      pageRedirect: false,
      manulEntryModule: false,
      id: '',
      menuArr: [],
    };
  }

  componentDidMount() {
    this.getTopMenuSettings();
    this.glgNav();
    this.licensedModules();
    this.isLicenceValid();
    this.intervalId = setInterval(this.isLicenceValid, 300000); // 1min = 60000
  }

  componentWillUnmount() {
    
    clearInterval(this.intervalId);
    
  }

  glgNav = () => {
    this.navService.dynamicNav().then((res) => {
      if (res && res.navigation) {
        localStorage.setItem('GLGMenu', res.navigation);
        this.setState({ menuItems: JSON.parse(res.navigation) });
      } else {
        localStorage.setItem('GLGMenu', []);
        this.setState({ menuItems: [] });
      }
    });
  };

  licensedModules = () => {
    this.licService.getLicensedModules().then((res) => {
      if (res && res.modulesResponse) {
        if (
          res.modulesResponse.MANUAL_ENTRY &&
          res.modulesResponse.MANUAL_ENTRY === '1'
        ) {
          this.setState({ manulEntryModule: true });
        } else {
          this.setState({ manulEntryModule: false });
        }
      }
    });
  };

  isLicenceValid = () => {
    this.licService.validateLicence().then((res) => {
      if (
        res &&
        (res.validationStatus === 'LICENSE_VALID' ||
          res.validationStatus === 'DEMO_LICENSE_VALID')
      ) {
        console.log('licence is valid!');
      } else {
        console.log('licence is expired!');
        this.setState({ pageRedirect: true });
      }
    });
  };

  getTopMenuSettings = () => {
    this.navService.getTopMenuSettings().then((res) => {
      if (res.menu_arr == undefined) {
      } else {
        this.setState({ id: res._id, menuArr: res.menu_arr });
      }
    });
  };

  render() {
    const {
      menuItems,
      permissions,
      pageRedirect,
      manulEntryModule,
      id,
      menuArr,
    } = this.state;
    if (pageRedirect) {
      return <Redirect to="/signin" />;
    } else {
      return (
        <ul className="menu">
          {id == '' ? (
            <li className="parent">
              <NavLink to="/home" activeClassName="active">
                Home
              </NavLink>
            </li>
          ) : null}
          {id != '' && menuArr.includes('Home') ? (
            <li className="parent">
              <NavLink to="/home" activeClassName="active">
                Home
              </NavLink>
            </li>
          ) : null}
          {/* <NavLink to="/"><li className="parent active" id='home' onClick={event => this.changeClass(event,'home')}><a href={void(0)}>Home</a></li></NavLink> */}
          {/* <NavLink to="/graphics/writeCommand.g"><li className="parent" id='graphics' onClick={event => this.changeClass(event,'graphics')}><a href="">GLG</a></li></NavLink> */}
          {/* {menuItems.map((menuItem, i) => {
            // let perm1;
            // let perm1 = permissions.includes(menuItem.auth);

            let perm1;
            if (permissions.includes(0)) {
              perm1 = true;
            } else {
              perm1 = permissions.includes(menuItem.auth)
            }
            if (menuItem.subtasks !== undefined && (perm1 || menuItem.auth === 0)) {
              // alert(menuItem.menuName);
              return (
                // <NavLink to={`/graphics/${menuItem.filePath}`}>
                <li key={i} className="parent"><NavLink to={`/graphics/${menuItem.filePath}`} activeClassName="active">{menuItem.menuName}</NavLink>
                  <ul className="child">
                    {menuItem.subtasks.map((subMenu, j) => {
                      // alert(JSON.stringify(subMenu));
                      let perm2;
                      if (subMenu.subtasks !== undefined) {
                        return (
                          <li key={j} className="parent"><NavLink to={`/graphics/${subMenu.filePath}`}>{subMenu.menuName} <span className="expand">»</span></NavLink>
                            <ul className="child">
                              {subMenu.subtasks.map((subsubMenu, k) => {
                                let perm3;
                                if (subsubMenu.subtasks !== undefined) {
                                  return (
                                    <li key={k} className="parent"><NavLink to={`/graphics/${subsubMenu.filePath}`}>{subsubMenu.menuName} <span className="expand">»</span></NavLink>
                                      <ul className="child">
                                        {subsubMenu.subtasks.map((subsubsubMenu, l) => {
                                          perm3 = permissions.includes(subsubsubMenu.auth);
                                          if (perm3 || subsubsubMenu.auth === 0) {
                                            return <li key={l}><NavLink to={`/graphics/${subsubsubMenu.filePath}`}>{subsubsubMenu.menuName}</NavLink></li>
                                          }
                                          // return <li><a href="#">{subsubsubMenu.menuName}</a></li>
                                        })}
                                      </ul>
                                    </li>
                                  )
                                } else {
                                  // perm3 = permissions.includes(subsubMenu.auth);
                                  if (permissions.includes(0)) {
                                    perm3 = true;
                                  } else {
                                    perm3 = permissions.includes(subsubMenu.auth)
                                  }
                                  console.log("Para3 "+perm3)
                                  if (perm3 || subsubMenu.auth === 0) {
                                    return <li key={k}><NavLink to={`/graphics/${subsubMenu.filePath}`}>{subsubMenu.menuName}</NavLink></li>
                                  }
                                  // return <li><a href="#">{subsubMenu.menuName}</a></li>
                                }
                              })}
                            </ul></li>
                        )
                      } else {
                        // alert('here...'+subMenu.menuName+'  '+subMenu.filePath)
                        // perm2 = permissions.includes(subMenu.auth);
                        if (permissions.includes(0)) {
                          perm2 = true;
                        } else {
                          perm2 = permissions.includes(subMenu.auth)
                        }
                        console.log("Para2 "+perm2)
                        if (perm2 || subMenu.auth === 0) {
                          // const file = 'F:/GLG_AdvaitWebClient/writeCommand.g';
                          // return <Link to={{pathname: `/graphics/${subMenu.filePath}`, search: `?filename=${file}` }}><li key={j}><a href="">{subMenu.menuName}</a></li></Link>

                          return <li key={j} className="parent"><NavLink to={`/graphics/${subMenu.filePath}`} activeClassName="active">{subMenu.menuName}</NavLink></li>
                        }
                      }
                    })}
                  </ul></li>
                // </NavLink>
              )
            } else {
              // perm1 = permissions.includes(menuItem.auth);
              console.log("Para1 "+perm1 +" Test "+perm1 || menuItem.auth === 0)
              console.log("Para1 "+permissions +" -- "+menuItem.auth)
              if (perm1 || menuItem.auth === 0) {
                console.log("Para1 In show")
                return <li key={i} className="parent"><NavLink to={`/graphics/${menuItem.filePath}`} activeClassName="active">{menuItem.menuName}</NavLink></li>
              }
              // return (
              //   <li class="parent"><a href="#">{menuItem.menuName}</a></li>
              // )
            }
          })} */}
          {id == '' ? (
            <li className="parent">
              <NavLink to="/geomap" activeClassName="active">
                Map
              </NavLink>
            </li>
          ) : null}
          {id != '' && menuArr.includes('Map') ? (
            <li className="parent">
              <NavLink to="/geomap" activeClassName="active">
                Map
              </NavLink>
            </li>
          ) : null}
          {/* {id == '' ? (
            <li className="parent">
              Alarms
              <ul className="child">
                <li className="parent">
                  <NavLink to="/alarmActive" activeClassName="active">
                    Realtime
                  </NavLink>
                </li>
                <li className="parent">
                  <NavLink to="/alarms" activeClassName="active">
                    Historical
                  </NavLink>
                </li>
              </ul>
            </li>
          ) : null}
          {id != '' && menuArr.includes('Alarm') ? (
            <li className="parent">
              Alarms
              <ul className="child">
                <li className="parent">
                  <NavLink to="/alarmActive" activeClassName="active">
                    Realtime
                  </NavLink>
                </li>
                <li className="parent">
                  <NavLink to="/alarms" activeClassName="active">
                    Historical
                  </NavLink>
                </li>
              </ul>
            </li>
          ) : null} */}

{id == '' ? (
            <li className="parent">
              <NavLink to="/alarmActive" activeClassName="active">
                Realtime Alarms
              </NavLink>
            </li>
          ) : null}
          {id != '' && menuArr.includes('Trend') ? (
            <li className="parent">
              <NavLink to="/alarmActive" activeClassName="active">
              Realtime Alarms
              </NavLink>
            </li>
          ) : null}

          {id == '' ? (
            <li className="parent">
              <NavLink to="/trends" activeClassName="active">
                Trends
              </NavLink>
            </li>
          ) : null}
          {id != '' && menuArr.includes('Trend') ? (
            <li className="parent">
              <NavLink to="/trends" activeClassName="active">
                Trends
              </NavLink>
            </li>
          ) : null}
          {/* {manulEntryModule && id == '' ? (
            <li className="parent">
              <NavLink to="/maualEntry" activeClassName="active">
                Manual Entry
              </NavLink>
            </li>
          ) : null}
          {manulEntryModule && id != '' && menuArr.includes('Manual') ? (
            <li className="parent">
              <NavLink to="/maualEntry" activeClassName="active">
                Manual Entry
              </NavLink>
            </li>
          ) : null} */}

          {id == '' ? (
            <li className="parent">
              <NavLink to="/groupDisplay" activeClassName="active">
                Group Display
              </NavLink>
            </li>
          ) : null}
          {id != '' && menuArr.includes('Group') ? (
            <li className="parent">
              <NavLink to="/groupDisplay" activeClassName="active">
                Group Display
              </NavLink>
            </li>
          ) : null}
          {id == '' ? (
            <li className="parent">
              <NavLink to="/events" activeClassName="active">
                User Events
              </NavLink>
            </li>
          ) : null}
          {id != '' && menuArr.includes('Event') ? (
            <li className="parent">
              <NavLink to="/events" activeClassName="active">
                User Events
              </NavLink>
            </li>
          ) : null}
          {id == '' ? (
            <li className="parent">
              <NavLink to="/oneTimeReport" activeClassName="active">
                Report
              </NavLink>
            </li>
          ) : null}
          {id != '' && menuArr.includes('Report') ? (
            <li className="parent">
              <NavLink to="/oneTimeReport" activeClassName="active">
                Report
              </NavLink>
            </li>
          ) : null}

<li className="parent">
              <NavLink to="/downloadReport" activeClassName="active">
                Report Download
              </NavLink>
            </li>
          {/* <li className="parent"><NavLink to="/testGLG" activeClassName="active">Test GLG</NavLink></li> */}
        </ul>
      );
    }
  }
}
export default MyBottomNavigation;
