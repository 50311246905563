import DynamicNavService from '../../services/GLG/dynamicNav-service';
import { generateHash } from '../../shared/encryptDecrypt';

export class LiveDataFeed {
  constructor() {
    this.GLGService = new DynamicNavService();
    this.user = localStorage.getItem('User');
    this.userEmail = JSON.parse(this.user)['email'];
  }
  ReadData(tag_list) {
    console.log('in liveData ReadData func ...');
    // alert(JSON.stringify(tag_list));
    var tags = [];
    for (var i = 0; i < tag_list.length; i++) {
      tags.push(tag_list[i]['tag_source'].split('#')[0].toString());
    }
    var data = [];
    var new_data = [];
    // alert("tags:"+JSON.stringify(tags));

    this.GLGService.liveData(tags).then((res) => {
      console.log(res);
      // alert('liveData:'+JSON.stringify(res));
      if (res) {
        data.push(res);
        console.log('live data res: ' + JSON.stringify(data));
        data.forEach((element) => {
          console.log('Element  ' + JSON.stringify(element));
          if (Object.keys(element).length > 0) {
            console.log('Keys ' + Object.keys(element).length);
            for (var i = 0; i < tag_list.length; i++) {
              if (
                element[tags[i]] == undefined ||
                element[tags[i]] == ' ' ||
                element[tags[i]] == ''
              ) {
                // console.log("no val .."+JSON.stringify(tag_list[i]));
                new_data.push({
                  tag_source: tag_list[i]['tag_source'],
                  value: 0,
                });
              } else {
                console.log('val exist..' + JSON.stringify(tag_list[i]));
                new_data.push({
                  tag_source: tag_list[i]['tag_source'],
                  value: parseFloat(element[tags[i]]),
                });
              }
            }
            console.log('new data: ' + JSON.stringify(new_data));
          }
          // Invoke the callback with new_data.
          return new_data;
        });
      } else {
        for (var i = 0; i < tag_list.length; i++) {
          new_data.push({ tag_source: tag_list[i]['tag_source'], value: 0 });
        }
        console.log('new data: ' + JSON.stringify(new_data));
        return new_data;
      }
    });
  }

  getTagType(prop) {
    var generalSettingProp = [
      'DESC',
      'UNITID',
      'EQUIPID',
      'MQTTID',
      'PVADDR',
      'PVSCANPER',
      'ENGUNIT',
      'RAWCNTST',
      'RAWLRV',
      'RAWURV',
      'DRDB',
      'ENGURV',
      'ENGLRV',
      'CLAMP',
      'STATE0',
      'STATE1',
      'BITST',
      'BITADD',
      'GRPNUM',
      'GRPDESC',
      'ASODISP',
      'PV',
    ];
    var alarmSettingProp = [
      'IS_ALARM',
      'DB',
      'ROCDB',
      'PVHICHK',
      'PVHILMT',
      'PVHIDESC',
      'PVHIPR',
      'PVHISPR',
      'PVHHICHK',
      'PVHHLMT',
      'PVHHDESC',
      'PVHHPR',
      'PVHHSPR',
      'PVLCHK',
      'PVLLMT',
      'PVLDESC',
      'PVLPR',
      'PVLSPR',
      'PVLLCHK',
      'PVLLLMT',
      'PVLLDESC',
      'PVLLPR',
      'PVLLSPR',
      'PVROCLCHK',
      'PVROCLLMT',
      'PVROCLDESC',
      'PVROCLPR',
      'PVROCLSPR',
      'PVROCHCHK',
      'PVROCHLMT',
      'PVROCHDESC',
      'PVROCHPR',
      'PVROCHSPR',
      'ALMSTATE',
      'ALMSTDESC',
      'ALMSEC',
      'ALMDLY',
      'ALMNORMDESC',
    ];
    var controlSettingProp = [
      'IS_CONTROL',
      'OPSRC',
      'OPDEST',
      'SPSRC',
      'SPDEST',
      'KSRC',
      'KDEST',
      'ISRC',
      'IDEST',
      'DSRC',
      'DDEST',
      'REVOPSRC',
      'REVOPDEST',
      'MANPVSRC',
      'MANPVDEST',
      'BIASSRC',
      'BIASDEST',
      'CTRLSCANPER',
      'CTRLMODE',
      'CTRLCONF',
      'CTRLSEC',
      'CTRLLOLMT',
      'CTRLHILMT',
      'CTRLDB',
      'CTRLFAIL',
      'PSRC',
      'OPDEST',
      'CTRLSEC',
    ];
    var loggingSettingProp = [
      'IS_LOGGING',
      'COMPDB',
      'COMPUNIT',
      'LOGINTV',
      'ISCOMP',
    ];
    if (generalSettingProp.includes(prop.toUpperCase())) {
      return 'general_setting';
    } else if (alarmSettingProp.includes(prop.toUpperCase())) {
      return 'alarm';
    } else if (controlSettingProp.includes(prop.toUpperCase())) {
      return 'control';
    } else if (loggingSettingProp.includes(prop.toUpperCase())) {
      return 'logging';
    } else {
      return '';
    }
  }

  WriteData(tagSource, password, authentication_status, val, cb) {
    // alert(tagName+'   '+val);
    // alert(tagSource);
    console.log(tagSource);
    var tSource = tagSource.split('#');
    if (tSource[1] == undefined) {
      tSource[1] = ' ';
    }
    console.log(tSource);
    if (tSource[0].startsWith('(opc)')) {
      var reqData = {
        operation: 'write_property',
        tag_name: tSource[0],
        tag_prop: 'PV',
        prop_type: this.getTagType('PV'),
        value: val.toString(),
        app_type: 'ADVAIT_HMI',
        email: this.userEmail,
        control_password: generateHash(password),
        authentication_status,
      };
    } else {
      var reqData = {
        operation: 'write_property',
        tag_name: tSource[0],
        tag_prop: tSource[1],
        prop_type: this.getTagType(tSource[1].toString()),
        value: val.toString(),
        app_type: 'ADVAIT_HMI',
        email: this.userEmail,
        control_password: generateHash(password),
        authentication_status,
      };
    }
    console.log(reqData);
    this.GLGService.WriteValue(reqData).then((res) => {
      if (res) {
        res['tagVal'] = val;
        res['tagName'] = tSource[0];
        res['tagSource'] = tagSource;
        console.log('value is updated .. ' + JSON.stringify(res));
        cb(res);
      } else {
        // alert(res);
        cb(res);
      }
    });
  }

  ReadDTag(/* GlgTagRecord */ tag_record, /* DataPoint*/ data_point) {
    // data_point.d_value = this.GetDemoValue( tag_record.tag_source, false );
    // data_point.time_stamp = GetCurrTime();
    return true;
  }
}
