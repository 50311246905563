//////////////////////////////////////////////////////////////////////////////
// Extends HMIPageBase with functionality to handle the Real-Time Chart.
//////////////////////////////////////////////////////////////////////////////
import { HMIPageBase } from './scada_hmi_page_base';
// export function RTChartPage()
// {

export class RTChartPage {
  constructor(DrawingAreaVP, GLG, RandomData, DataFeed, CoordScale) {
    this.RandomData = RandomData;
    this.DataFeed = DataFeed;
    this.CoordScale = CoordScale;
    this.Viewport = null; /* GlgObject  */
    this.ChartVP = null; /* GlgObject  */
    this.Chart = null; /* GlgObject  */

    /* Convenient time span constants. */
    this.ONE_MINUTE = 60;
    this.ONE_HOUR = 3600;
    this.ONE_DAY = 3600 * 24;

    /* Prefill time interval, specifies amount of data to prefill in the 
       real time chart. */
    this.PREFILL_SPAN = this.ONE_HOUR * 8;

    /* Constants for scrolling to the beginning or the end of the time range. */
    this.DONT_CHANGE = 0;
    this.MOST_RECENT = 1; /* Make the most recent data visible. */
    this.LEAST_RECENT = 2; /* Make the least recent data visible.*/

    this.INIT_SPAN = 0; /* Index of the initial span to display. */

    this.SpanIndex = this.INIT_SPAN; /* Index of the currently displayed 
                                       time span.*/

    // Number of lines and Y axes in a chart as defined in the drawing.
    this.NumPlots = 0;
    this.NumYAxes = 0;

    /* Store object IDs for each plot,  used for performance optimization 
       in the chart data feed.
    */
    this.PlotArray = null; /* GlgObject[] */

    /* Arrays that hold initial Low and High ranges of the Y axes in the drawing.
       Plots' Low and High are assumed to be linked to the corresponding Y axes.
    */
    this.Low = null; /* double[] */
    this.High = null; /* double[] */

    this.TimeSpan = 0; // Time axis span in sec.

    this.PrefillData = true; /* Setting to false suppresses pre-filling the 
                                chart's buffer with data on start-up. */
    this.AutoScroll = 1; /* int: Current auto-scroll state: enabled(1) or 
                                disabled(0). */
    this.StoredScrollState = null; /* int: Stored AutoScroll state to be restored 
                                if ZoomTo is aborted. */

    this.Viewport = DrawingAreaVP; /* GlgObject */
    this.GLG = GLG;
  }

  // RTChartPage.prototype = Object.create( HMIPageBase.prototype );
  // RTChartPage.prototype.constructor = RTChartPage;

  //////////////////////////////////////////////////////////////////////////////
  // Returns an update interval in msec for animating drawing with data.
  //////////////////////////////////////////////////////////////////////////////
  GetUpdateInterval() /* int */ {
    return 5000;
  }

  //////////////////////////////////////////////////////////////////////////////
  // Perform any desired initialization of the drawing before hierarchy setup.
  //////////////////////////////////////////////////////////////////////////////
  InitBeforeSetu() {
    this.ChartVP = this.Viewport.GetResourceObject('ChartViewport');
    if (this.ChartVP == null) alert("Can't find ChartViewport");

    this.Chart = this.ChartVP.GetResourceObject('Chart');
    if (this.Chart == null) alert("Can't find Chart object");

    // Retrieve number of plots and Y axes defined in the drawing.
    this.NumPlots = Math.trunc(this.Chart.GetDResource('NumPlots'));
    this.NumYAxes = Math.trunc(this.Chart.GetDResource('NumYAxes'));

    // Enable AutoScroll, both for the toggle button and the chart.
    this.ChangeAutoScroll(1);

    /* Set Chart Zoom mode. It was set and saved with the drawing, 
       but do it again programmatically just in case.
    */
    this.ChartVP.SetZoomMode(
      null,
      this.Chart,
      null,
      this.GLG.GlgZoomMode.CHART_ZOOM_MODE
    );
  }

  //////////////////////////////////////////////////////////////////////////////
  // Perform any desired initialization of the drawing after hierarchy setup.
  //////////////////////////////////////////////////////////////////////////////
  InitAfterSetup() {
    var i;

    // Store objects IDs for each plot.
    this.PlotArray = new Array(this.NumPlots); /* GlgObject[] */

    var plot_array = this.Chart.GetResourceObject('Plots'); /* GlgObject  */
    for (i = 0; i < this.NumPlots; ++i)
      this.PlotArray[i] = plot_array.GetElement(i);

    /* Store initial range for each Y axis to restore on zoom reset. 
       Assumes that plots are linked with the corresponding axes in the 
       drawing.
    */
    this.Low = new Array(this.NumYAxes); /* double[] */
    this.High = new Array(this.NumYAxes); /* double[] */

    var axis_array = this.Chart.GetResourceObject('YAxisGroup'); /* GlgObject */
    for (i = 0; i < this.NumYAxes; ++i) {
      var axis = axis_array.GetElement(i); /* GlgObject */
      this.Low[i] = axis.GetDResource('Low');
      this.High[i] = axis.GetDResource('High');
    }
  }

  //////////////////////////////////////////////////////////////////////////////
  // Invoked when the page has been loaded and the tags have been remapped.
  //////////////////////////////////////////////////////////////////////////////
  Ready() {
    this.SetChartSpan(this.SpanIndex);

    // Prefill chart's history buffer with data.
    if (this.PrefillData) this.FillChartHistory();
  }

  //////////////////////////////////////////////////////////////////////////////
  // Pre-fill the graph's history buffer with data.
  //////////////////////////////////////////////////////////////////////////////
  GetCurrTime() /* double */ {
    return Date.now() / 1000; // seconds
  }

  FillChartHistory() {
    var current_time = this.GetCurrTime(); /* double */

    /* Fill the amount of data requested by the PREFILL_SPAN, up to the 
       available chart's buffer size defined in the drawing.
       Add an extra second to avoid rounding errors.
    */
    var num_seconds = this.PREFILL_SPAN + 1;

    var buffer_size /* int */ = Math.trunc(
      this.Chart.GetDResource('BufferSize')
    );
    if (buffer_size < 1) buffer_size = 1;

    var max_num_samples; /* int */
    if (this.RandomData) {
      /* In random demo data mode, simulate data stored once per second. */
      var samples_per_second = 1.0; /* double */
      max_num_samples = Math.trunc(num_seconds * samples_per_second);

      if (max_num_samples > buffer_size) max_num_samples = buffer_size;
    } else max_num_samples = buffer_size;

    /* Start and end time for querying data. */
    var start_time = current_time - num_seconds; /* double */
    var end_time = current_time; /* double : Stop at the current time. */

    for (var i = 0; i < this.NumPlots; ++i) {
      // Get tag source of the plot's ValueEntryPoint.
      var tag_source /* String  */ = this.PlotArray[i].GetSResource(
        'ValueEntryPoint/TagSource'
      );

      var data_array /* PlotDataPoint[] */ = this.DataFeed.GetPlotData(
        tag_source,
        start_time,
        end_time,
        max_num_samples
      );
      if (data_array == null) continue;

      this.FillPlotData(this.PlotArray[i], data_array);
    }
  }

  //////////////////////////////////////////////////////////////////////////////
  // Fills plot with data from the provided data array.
  // For increased performance of prefilling a chart with large quantities of
  // data, the data are pushed into the plot using low level API methods.
  //////////////////////////////////////////////////////////////////////////////
  FillPlotData(/* GlgObject */ plot, /* PlotDataPoint[] */ data_array) {
    var size = data_array.length;
    for (var i = 0; i < size; ++i) {
      var data_point = data_array[i]; /* PlotDataPoint */
      var datasample = this.GLG.CreateDataSample(
        data_point.value,
        data_point.time_stamp,
        data_point.value_valid,
        0
      );
      this.GLG.AddDataSample(plot, datasample);
    }
  }

  //////////////////////////////////////////////////////////////////////////////
  // A custom input handler for the page. If it returns false, the default
  // input handler of the SCADA Viewer will be used to process common
  // events and commands.
  //////////////////////////////////////////////////////////////////////////////
  InputCallback(
    /* GlgObject */ viewport,
    /* GlgObject */ message_obj
  ) /* boolean */ {
    var processed = false; /* boolean */

    var origin = message_obj.GetSResource('Origin');
    var format = message_obj.GetSResource('Format');
    var action = message_obj.GetSResource('Action');
    var subaction = message_obj.GetSResource('SubAction');

    // Process button events.
    if (format == 'Button') {
      if (
        action != 'Activate' /* Not a push button */ &&
        action != 'ValueChanged'
      )
        /* Not a toggle button */
        return false;

      this.AbortZoomTo();

      processed = true;
      if (origin == 'ToggleAutoScroll') {
        /* Set Chart AutoScroll based on the ToggleAutoScroll toggle button 
           setting.
        */
        this.ChangeAutoScroll(-1);
      } else if (origin == 'ZoomTo') {
        // Start ZoomTo operation.
        this.ChartVP.SetZoom(null, 't', 0.0);
      } else if (origin == 'ZoomReset') {
        // Set initial time span and reset initial Y ranges.
        this.SetChartSpan(this.SpanIndex);
        this.RestoreInitialYRanges();
      } else if (origin == 'ScrollBack' || origin == 'Left') {
        /* Handle both the buttons on the RTChart page, as well as the zoom
         controls on the left of the top level window.
      */
        this.ChangeAutoScroll(0);

        // Scroll left by 1/3 of the span.
        this.ChartVP.SetZoom(null, 'l', 0.33);
      } else if (origin == 'ScrollForward' || origin == 'Right') {
        this.ChangeAutoScroll(0);

        // Scroll right by 1/3 of the span.
        this.ChartVP.SetZoom(null, 'r', 0.33);
      } else if (origin == 'ScrollBack2') {
        this.ChangeAutoScroll(0);

        // Scroll left by a full span.
        this.ChartVP.SetZoom(null, 'l', 1.0);
      } else if (origin == 'ScrollForward2') {
        this.ChangeAutoScroll(0);

        // Scroll right by a full span.
        this.ChartVP.SetZoom(null, 'r', 1.0);
      } else if (origin == 'Up') {
        // Scroll up.
        this.ChartVP.SetZoom(null, 'u', 0.5);
      } else if (origin == 'Down') {
        // Scroll down.
        this.ChartVP.SetZoom(null, 'd', 0.5);
      } else if (origin == 'ZoomIn') {
        // Zoom in in Y direction.
        this.ChartVP.SetZoom(null, 'I', 1.5);
      } else if (origin == 'ZoomOut') {
        // Zoom out in Y direction.
        this.ChartVP.SetZoom(null, 'O', 1.5);
      } else if (origin == 'ScrollToRecent') {
        // Scroll to show most recent data.
        this.ScrollToDataEnd(this.MOST_RECENT, true);
      } else processed = false;

      if (processed) this.Viewport.Update();
    } else if (format == 'Menu') {
      if (action != 'Activate') return false;

      this.AbortZoomTo();

      if (origin == 'SpanSelector') {
        /* Span change */ processed = true;
        this.SpanIndex = Math.trunc(message_obj.GetDResource('SelectedIndex'));

        this.SetChartSpan(this.SpanIndex);
        this.RestoreInitialYRanges(); /* Restore in case the chart was zoomed.*/

        /* Scroll to show the recent data to avoid showing an empty chart
           if user scrolls too much into the future or into the past.
           
           Invoke ScrollToDataEnd() even if AutoScroll is True to 
           scroll ahead by a few extra seconds to show a few next updates
           without scrolling the chart.
        */
        var min_max /* GlgMinMax */ = this.Chart.GetDataExtent(
          null,
          /* x extent */ true
        );

        if (min_max != null) {
          var first_time_stamp = min_max.min; /* double */
          var last_time_stamp = min_max.max; /* double */
          var displayed_time_end /* double */ = this.Chart.GetDResource(
            'XAxis/EndValue'
          );

          if (this.AutoScroll != 0)
            this.ScrollToDataEnd(this.MOST_RECENT, true);
          else if (
            displayed_time_end >
            last_time_stamp + this.GetExtraSeconds()
          )
            this.ScrollToDataEnd(this.MOST_RECENT, true);
          else if (displayed_time_end - this.TimeSpan <= first_time_stamp)
            this.ScrollToDataEnd(this.LEAST_RECENT, true);

          this.Viewport.Update();
        }
      }
    } else if (format == 'Chart' && action == 'CrossHairUpdate') {
      /* To avoid slowing down real-time chart updates, invoke Update() 
         to redraw cross-hair only if the chart is not updated fast 
         enough by the timer.
      */
      if (this.GetUpdateInterval() > 100) this.Viewport.Update();

      processed = true;
    } else if (action == 'Zoom') {
      // Zoom events
      processed = true;
      if (subaction == 'ZoomRectangle') {
        // Store AutoSCroll state to restore it if ZoomTo is aborted.
        this.StoredScrollState = this.AutoScroll;

        // Stop scrolling when ZoomTo action is started.
        this.ChangeAutoScroll(0);
      } else if (subaction == 'End') {
        /* No additional actions on finishing ZoomTo. The Y scrollbar 
           appears automatically if needed: it is set to GLG_PAN_Y_AUTO. 
           Don't resume scrolling: it'll scroll too fast since we zoomed 
           in. Keep it still to allow inspecting zoomed data.
        */
      } else if (subaction == 'Abort') {
        // Resume scrolling if it was on.
        this.ChangeAutoScroll(this.StoredScrollState);
      }

      this.Viewport.Update();
    } else if (action == 'Pan') {
      // Pan events
      processed = true;

      /* This code may be used to perform custom action when dragging the 
         chart's data with the mouse. 
      */
      if (subaction == 'Start') {
        // Chart dragging start
      } else if (subaction == 'Drag') {
        // Dragging
      } else if (subaction == 'ValueChanged') {
        // Scrollbars
      }
      // Dragging ended or aborted.
      else if (subaction == 'End' || subaction == 'Abort') {
      }

      this.Viewport.Update();
    }

    // If event was processed here, return true not to process it outside.
    return processed;
  }

  //////////////////////////////////////////////////////////////////////////////
  // A custom trace callback for the page; is used to obtain coordinates
  // of the mouse click. If it returns false, the default trace callback
  // of the SCADA Viewer will be used to process events.
  // Used to obtain coordinates of the mouse click.
  //////////////////////////////////////////////////////////////////////////////
  TraceCallback(/* GlgObject */ viewport, /* GlgTraceData */ trace_info) {
    // Process only events that occur in ChartViewport.
    if (!trace_info.viewport.Equals(this.ChartVP)) return false;

    var x, y; /* double */

    var event_type = trace_info.event_type;
    switch (event_type) {
      case this.GLG.GlgEventType.TOUCH_START:
        this.GLG.SetTouchMode(); /* Start dragging via touch events. */
      /* Fall through */

      case this.GLG.GlgEventType.TOUCH_MOVED:
        if (!this.GLG.GetTouchMode()) return;
      case this.GLG.GlgEventType.MOUSE_PRESSED:
      case this.GLG.GlgEventType.MOUSE_MOVED:
        x = trace_info.mouse_x * this.CoordScale;
        y = trace_info.mouse_y * this.CoordScale;

        /* COORD_MAPPING_ADJ is added to the cursor coordinates for precise
           pixel mapping.
        */
        x += this.GLG.COORD_MAPPING_ADJ;
        y += this.GLG.COORD_MAPPING_ADJ;
        break;

      default:
        return false;
    }

    switch (event_type) {
      case this.GLG.GlgEventType.TOUCH_START:
      case this.GLG.GlgEventType.MOUSE_PRESSED:
        if (this.ZoomToMode()) return false; // ZoomTo or dragging mode in progress.

        /* Start dragging with the mouse on a mouse click. 
           If user clicked of an axis, the dragging will be activated in the
           direction of that axis. If the user clicked in the chart area,
           dragging in both the time and the Y direction will be activated. 
        */
        this.ChartVP.SetZoom(null, 's', 0.0);

        // Disable AutoScroll not to interfere with dragging.
        this.ChangeAutoScroll(0);
        return true;

      default:
        break;
    }
    return false;
  }

  //////////////////////////////////////////////////////////////////////////////
  // Returns true if tag sources need to be remapped for the page.
  //////////////////////////////////////////////////////////////////////////////
  NeedTagRemapping() /* boolean */ {
    // In demo mode, unset tags need to be remapped to enable animation.
    if (this.RandomData) return true;
    else return false; //  remap tags only if necessary.
  }

  //////////////////////////////////////////////////////////////////////////////
  // Reassign TagSource parameter for a given tag object to a new
  // TagSource value. tag_source and tag_name parameters are the current
  // TagSource and TagName of the tag_obj.
  //////////////////////////////////////////////////////////////////////////////
  RemapTagObject(
    /* GlgObject */ tag_obj,
    /* String */ tag_name,
    /* String */ tag_source
  ) {
    var new_tag_source; /* String */

    if (this.RandomData) {
      // Skip tags with undefined TagName.
      if (this.IsUndefined(tag_name)) return;

      /* In demo mode, assign unset tag sources to be the same as tag names
         to enable animation with demo data.
      */
      new_tag_source = tag_name;
      if (this.IsUndefined(tag_source))
        this.AssignTagSource(tag_obj, new_tag_source);
    } else {
      // Assign new TagSource as needed.
      // AssignTagSource( tag_obj, new_tag_source );
    }
  }

  //////////////////////////////////////////////////////////////////////////////
  AdjustForMobileDevices() {
    /* Adjust chart offsets to fit chart labels on mobile devices with 
       canvas scaling.
    */
    if (this.CoordScale == 1.0) return; // Desktop.

    // Increase toolbar size for mobile devices.
    this.Viewport.SetDResource('Toolbar/HeightScale', 2);
    this.Viewport.SetDResource('Toolbar/YScale', 2);
    this.Viewport.SetDResource('Toolbar/XScale', 1.2);

    /* Adjust chart offsets to fit chart labels on mobile devices with 
       canvas scaling.
    */
    var chart /* GlgObject */ = this.Viewport.GetResourceObject(
      'ChartViewport/Chart'
    );
    this.AdjustOffset(chart, 'OffsetTop', 10);
    this.AdjustOffset(chart, 'OffsetLeft', 10);
    this.AdjustOffset(chart, 'OffsetBottom', -5);
  }

  AdjustOffset(
    /* GlgObject */ object,
    /* String */ offset_name,
    /* double */ adjustment
  ) {
    var value = object.GetDResource(offset_name); /* double */
    value += adjustment;
    object.SetDResource(offset_name, value);
  }

  //////////////////////////////////////////////////////////////////////////////
  // Change chart's AutoScroll mode.
  //////////////////////////////////////////////////////////////////////////////
  ChangeAutoScroll(/* int */ new_value) {
    if (new_value == -1) {
      // Use the state of the ToggleAutoScroll button.
      this.AutoScroll = Math.trunc(
        this.Viewport.GetDResource('Toolbar/ToggleAutoScroll/OnState')
      );
    } // Set to the supplied value.
    else {
      this.AutoScroll = new_value;
      this.Viewport.SetDResource(
        'Toolbar/ToggleAutoScroll/OnState',
        this.AutoScroll
      );
    }

    // Set chart's auto-scroll.
    this.Chart.SetDResource('AutoScroll', this.AutoScroll);

    /* Activate time scrollbar if AutoScroll is Off. The Y value scrollbar 
       uses GLG_PAN_Y_AUTO and appears automatically as needed.
    */
    var pan_x /* int */ =
      this.AutoScroll != 0
        ? this.GLG.GlgPanType.NO_PAN
        : this.GLG.GlgPanType.PAN_X;
    this.ChartVP.SetDResource('Pan', pan_x | this.GLG.GlgPanType.PAN_Y_AUTO);
  }

  //////////////////////////////////////////////////////////////////////////////
  // Changes the time span shown in the graph, adjusts major and minor tick
  // intervals to match the time span.
  //////////////////////////////////////////////////////////////////////////////
  SetChartSpan(/* int */ span_index) {
    var span, major_interval, minor_interval; /* int */

    /* Change chart's time span, as well as major and minor tick intervals.*/
    switch (span_index) {
      default:
      case 0:
        span = this.ONE_MINUTE;
        major_interval = 10; /* major tick every 10 sec. */
        minor_interval = 1; /* minor tick every sec. */
        break;

      case 1:
        span = 10 * this.ONE_MINUTE;
        major_interval =
          this.ONE_MINUTE * 2; /* major tick every tow minutes. */
        minor_interval = 30; /* minor tick every 30 sec. */
        break;

      case 2:
        span = this.ONE_HOUR;
        major_interval = this.ONE_MINUTE * 10; /* major tick every 10 min. */
        minor_interval = this.ONE_MINUTE; /* minor tick every min. */
        break;

      case 3:
        span = this.ONE_HOUR * 8;
        major_interval = this.ONE_HOUR; /* major tick every hour. */
        minor_interval =
          this.ONE_MINUTE * 15; /* minor tick every 15 minutes. */
        break;
    }

    /* Update the menu in the drawing with the initial value if different. */
    this.Viewport.SetDResourceIf(
      'Toolbar/SpanSelector/SelectedIndex',
      span_index,
      true
    );

    /* Set intervals before SetZoom() below to avoid redrawing huge number 
       of labels. */
    this.Chart.SetDResource('XAxis/MajorInterval', major_interval);
    this.Chart.SetDResource('XAxis/MinorInterval', minor_interval);

    /* Set the X axis span which controls how much data is displayed in the 
       chart. */
    this.TimeSpan = span;
    this.Chart.SetDResource('XAxis/Span', this.TimeSpan);

    /* Turn on data filtering for large spans. FilterType and FilterPrecision
       attributes of all plots are constrained, so that they may be set in one
       place, on one plot.
    */
    if (span_index > 1) {
      /* Agregate multiple data samples to minimize a number of data points 
         drawn per each horizontal FilterPrecision interval.
         Show only one set of MIN/MAX values per each pixel interval. 
         An averaging data filter is also available.
      */
      this.PlotArray[0].SetDResource(
        'FilterType',
        this.GLG.GlgChartFilterType.MIN_MAX_FILTER
      );
      this.PlotArray[0].SetDResource('FilterPrecision', 1.0);
    } else
      this.PlotArray[0].SetDResource(
        'FilterType',
        this.GLG.GlgChartFilterType.NULL_FILTER
      );

    /* Change time and tooltip formatting to match the selected span. */
    this.SetTimeFormats();
  }

  //////////////////////////////////////////////////////////////////////////////
  // Sets the formats of time labels and tooltips depending on the selected
  // time span.
  //////////////////////////////////////////////////////////////////////////////
  SetTimeFormats() {
    var time_label_format,
      time_tooltip_format,
      chart_tooltip_format; /* String */

    /* No additional code is required to use the defaults defined in the 
       drawing. The code below illustrates advanced options for 
       customizing label and tooltip formatting when switching between 
       time spans and data display modes.
       
       For an even greater control over labels and tooltips, an application 
       can define custom Label and Tooltip formatters that will supply 
       custom strings for axis labels and tooltips.
    */

    /* Different time formats are used depending on the selected
       time span. See strftime() for all time format options.
    */
    switch (this.SpanIndex) {
      default:
        /* 1 minute and 10 minutes spans */
        /* Use the preferred time and date display format for the current 
           locale. */
        time_label_format = '%X%n%x';
        break;

      case 2 /* 1 hour span */:
        /* Use the 12 hour time display with no seconds, and the default 
           date display format for the current locale.
        */
        time_label_format = '%I:%M %p%n%x';
        break;

      case 3 /* 1 hour and 8 hour spans */:
        /* Use 24 hour notation and don't display seconds. */
        time_label_format = '%H:%M%n%x';
        break;
    }
    this.Chart.SetSResource('XAxis/TimeFormat', time_label_format);

    /* Specify axis and chart tooltip format, if different from default 
       formats defined in the drawing.
    */
    time_tooltip_format =
      'Time: <axis_time:%X> +0.<axis_time_ms:%03.0lf> sec.\nDate: <axis_time:%x>';

    /* <sample_time:%s> inherits time format from the X axis. */
    chart_tooltip_format =
      'Plot <plot_string:%s> value= <sample_y:%.2lf>\n<sample_time:%s>';

    /* Set time label and tooltip formats. */
    this.Chart.SetSResource('XAxis/TooltipFormat', time_tooltip_format);
    this.Chart.SetSResource('TooltipFormat', chart_tooltip_format);
  }

  //////////////////////////////////////////////////////////////////////////////
  // Scrolls the graph to the minimum or maximum time stamp to show the
  // most recent or the least recent data. If show_extra is True, adds a
  // few extra seconds in the real-time mode to show a few next updates
  // without scrolling the chart.
  //
  // Enabling AutoScroll automatically scrolls to show current data points
  // when the new time stamp is more recent then the EndValue of the axis,
  // but it is not the case when the chart is scrolled into the future
  // (to the right) - still need to invoke this method.
  //////////////////////////////////////////////////////////////////////////////
  ScrollToDataEnd(/* int */ data_end, /* boolean */ show_extra) {
    var end_value, extra_sec; /* double */

    if (data_end == this.DONT_CHANGE) return;

    /* Get the min and max time stamp. */
    var min_max /* GlgMinMax */ = this.Chart.GetDataExtent(
      null,
      /* x extent */ true
    );
    if (min_max == null) return;

    if (show_extra) extra_sec = this.GetExtraSeconds();
    else extra_sec = 0.0;

    if (data_end == this.MOST_RECENT) end_value = min_max.max + extra_sec;
    /* LEAST_RECENT */ else end_value = min_max.min - extra_sec + this.TimeSpan;

    this.Chart.SetDResource('XAxis/EndValue', end_value);
  }

  //////////////////////////////////////////////////////////////////////////////
  // Determines a good number of extra seconds to be added at the end in
  // the real-time mode to show a few next updates without scrolling the
  // chart.
  //////////////////////////////////////////////////////////////////////////////
  GetExtraSeconds() /* double */ {
    var extra_sec, max_extra_sec; /* double */

    extra_sec = this.TimeSpan * 0.1;
    max_extra_sec = this.TimeSpan > this.ONE_HOUR ? 5.0 : 3.0;

    if (extra_sec > max_extra_sec) extra_sec = max_extra_sec;

    return extra_sec;
  }

  //////////////////////////////////////////////////////////////////////////////
  // Restore Y axis range to the initial Low/High values.
  //////////////////////////////////////////////////////////////////////////////
  RestoreInitialYRanges() {
    var axis_array = this.Chart.GetResourceObject('YAxisGroup'); /* GlgObject */
    for (var i = 0; i < this.NumYAxes; ++i) {
      var axis = axis_array.GetElement(i); /* GlgObject */
      axis.SetDResource('Low', this.Low[i]);
      axis.SetDResource('High', this.High[i]);
    }
  }

  //////////////////////////////////////////////////////////////////////////////
  // Returns true if the chart's viewport is in ZoomToMode.
  // ZoomToMode is activated on Dragging and ZoomTo operations.
  //////////////////////////////////////////////////////////////////////////////
  ZoomToMode() /* boolean */ {
    var zoom_mode /* int */ = Math.trunc(
      this.ChartVP.GetDResource('ZoomToMode')
    );
    return zoom_mode != 0;
  }

  //////////////////////////////////////////////////////////////////////////////
  // Abort ZoomTo mode.
  //////////////////////////////////////////////////////////////////////////////
  AbortZoomTo() {
    if (this.ZoomToMode()) {
      // Abort zoom mode in progress.
      this.ChartVP.SetZoom(null, 'e', 0.0);
      this.Viewport.Update();
    }
  }

  IsUndefined(/* String */ str) /* boolean */ {
    if (str == null || str.length == 0 || str == 'unset' || str == '$unnamed')
      return true;

    return false;
  }
  AssignTagSource(/* GlgObject */ tag_obj, /* String */ new_tag_source) {
    tag_obj.SetSResource('TagSource', new_tag_source);
  }
}
