import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Wrapper } from '../../components';
import Paper from '@material-ui/core/Paper';
import { Card } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import TrendService from '../../services/trends/trends-service';
import { CustomizedSnackbars } from '../../shared/snackbar-service';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
// import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import ListItemText from '@material-ui/core/ListItemText';
import FormHelperText from '@material-ui/core/FormHelperText';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Typography from '@material-ui/core/Typography';
import AsyncSelect from 'react-select/lib/Async';
import VirtualizedSelect from 'react-virtualized-select';
// import Select from 'react-select';
import 'react-select/dist/react-select.css';
import 'react-virtualized/styles.css';
import 'react-virtualized-select/styles.css';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Pagination from 'react-js-pagination';
// import exportFromJSON from 'export-from-json';
import Select from '@material-ui/core/Select';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

import Highcharts from 'highcharts';
require('highcharts/modules/exporting')(Highcharts);

const styles = (theme) => ({
  root: {
    width: '100%',
    height: '100%',
    marginTop: theme.spacing.unit * 1,
  },
  table: {
    minWidth: 500,
    width: '100%',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  progress: {
    marginLeft: '50%',
    position: 'absolute',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingBottom: '5px',
  },
  textField: {
    marginLeft: theme.spacing.unit * 1,
    marginRight: theme.spacing.unit * 1,
    width: 218,
  },
  textField2: {
    marginLeft: theme.spacing.unit * 1,
    marginRight: theme.spacing.unit * 1,
    width: 350,
  },
  textField3: {
    marginLeft: theme.spacing.unit * 1,
    marginRight: theme.spacing.unit * 1,
    width: 150,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
});

var divStyle = {
  paddingLeft: '22px',
  paddingTop: '10px',
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const data = [{ foo: 'foo' }, { bar: 'bar' }];
const fileName = 'trend_report';
const exportType = 'xls';

class Trends extends Component {
  constructor(props) {
    super(props);
    this.trendService = new TrendService();
    this.intervalId = 0;
    let dt = new Date();
    let tm =
      ('0' + dt.getHours()).slice(-2) + ':' + ('0' + dt.getMinutes()).slice(-2);
    let dt1 = dt.toISOString().split('T')[0];
    dt.setHours(dt.getHours() - 1);
    // alert(dt);
    let tm1 =
      ('0' + dt.getHours()).slice(-2) + ':' + ('0' + dt.getMinutes()).slice(-2);
    let dt2 = dt.toISOString().split('T')[0];
    console.log(dt + '   ' + tm);
    this.allowChartUpdate = true;
    this.units = localStorage.getItem('User');
    this.units = JSON.parse(JSON.parse(this.units)['unit_id']);
    this.exportData = [];
    const unitIds = this.units.map((u) => {
      return Object.assign(
        {},
        {
          value: u,
          label: u,
        }
      );
    });
    this.state = {
      tagList: [],
      msgStatus: false,
      msgType: '',
      msgContent: '',
      isSpinner: false,
      selectedTags: [],
      selectedDigiTags: [],
      selectedFrom: dt2 + 'T' + tm1,
      selectedTo: dt1 + 'T' + tm,
      hasError: false,
      hasError1: false,
      errorStyle: { color: 'red' },
      errorStyleU: {},
      selectedInterval: 0,
      errMsg: '',
      errDigiMsg: '',
      series: [],
      yAxis: [],
      unitIDs: unitIds,
      selectedUnit: this.units,
      views: [],
      selectedView: {},
      openModal: false,
      vName: '',
      vTags: [],
      vSelectedTag: [],
      vSelectedDigiTag: [],
      vIntervals: [],
      vSelectedInterval: 0,
      vUnits: this.units,
      vSelectedUnit: [],
      page: 0,
      rowsPerPage: 5,
      isUpdate: false,
      selectedID: '',
      openConfirmModal: false,
      errors: {},
      openDeleteAllModal: false,
      hasError2: false,
      hasError3: false,
      inputValue1: '',
      inputValue2: '',
      inputValue3: '',
      inputValue4: '',
      hasError4: false,

      dayWiseChecked: true,
      recordPerPage: 1,
      totalRecords: 0,
      currentPage: 1,
      intervals: [],
      pagesArray: [],
      offsets: [
        { label: '1 Hour', value: 1 },
        { label: '4 Hour', value: 4 },
        { label: '8 Hour', value: 8 },
        { label: '12 Hour', value: 12 },
        { label: '24 Hour', value: 24 },
      ],
      selectedOffset: 1,
      unitSelected: this.units,
      unitList: this.units,
      isAllSelected: false,
    };
  }

  highChartsRender = () => {
    var chart = new Highcharts.Chart({
      chart: {
        zoomType: 'xy',
        renderTo: 'advaitTrends',
      },
      title: {
        align: 'center',
        text: this.state.selectedFrom + ' to ' + this.state.selectedTo,
      },
      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
          hour: '%H:%M',
          month: '%e. %b',
          year: '%b',
        },
        crosshair: true,
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [
              'printChart',
              'separator',
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadSVG',
            ],
          },
        },
      },
      yAxis: this.state.yAxis,
      tooltip: {
        shared: true,
      },
      legend: {
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'bottom',
        floating: false,
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || // theme
          'rgba(255,255,255,0.25)',
      },
      series: this.state.series,
    });
  };

  componentDidMount() {
    if (Highcharts.chart) {
      Highcharts.chart = null;
    }
    this.findAndCheckForDateDiff();
    this.getAllTrendViews();
  }

  componentWillUnmount() {
    if (Highcharts.chart) {
      Highcharts.chart = null;
    }
  }

  getAllTrendViews = () => {
    // this.setState({ isSpinner: true });
    this.trendService.getAllTrendView().then((res) => {
      // alert(JSON.stringify(res))
      if (res && res.length > 0) {
        this.setState({ views: res, isSpinner: false });
      } else {
        this.setState({ views: [], isSpinner: false });
      }
    });
  };

  changeTagList(event) {
    if (event.target.value.length > 0) {
      if (event.target.value.length > 6) {
        this.setState({
          selectedTags: event.target.value,
          hasError1: true,
          errMsg: 'Maximum 6 tags canbe selected!',
        });
      } else {
        this.setState({ selectedTags: event.target.value, hasError1: false });
      }
    } else {
      this.setState({
        selectedTags: event.target.value,
        hasError1: true,
        errMsg: 'Tag is required!',
      });
    }
  }

  getTrendChart = () => {
    this.state.currentPage = 1;
    if (!this.state.dayWiseChecked) {
      this.getTrend();
    } else {
      this.getTrendByPage(1);
    }
  };

  getTrend = () => {
    this.exportData = [];
    if (
      !this.state.hasError1 &&
      !this.state.hasError1 &&
      !this.state.hasError2 &&
      !this.state.hasError3 &&
      !this.state.hasError4
    ) {
      this.setState({ isSpinner: true });

      if (this.state.selectedTags.length > 0) {
        let fDT1 = this.state.selectedFrom.split('T');
        let fDT = fDT1[0] + ' ' + fDT1[1] + ':00';
        let tDT1 = this.state.selectedTo.split('T');
        let tDT = tDT1[0] + ' ' + tDT1[1] + ':59';
        const date1 = new Date(this.state.selectedFrom);
        const date2 = new Date(this.state.selectedTo);
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        // if (diffDays <= 7 && diffDays >= 0) {
        let reqParams = {
          tags: this.state.selectedTags,
          interval: this.state.selectedInterval,
          from: fDT,
          to: tDT,
          sorting: 1,
          function: 'avg',
        };
        console.log('TTT  ' + JSON.stringify(reqParams));
        this.trendService.getTrends(reqParams).then((res) => {
          console.log('Ana ' + JSON.stringify(res));
          var finalRes = [];
          // if (res && res.length > 0) {
          finalRes = res;
          // }
          // if (this.state.selectedDigiTags.length > 0) {
          if (this.state.selectedDigiTags.length == 0) {
            if (finalRes && finalRes.length > 0) {
              console.log('final ' + JSON.stringify(finalRes));
              let dynSeries = [];
              let dynAxes = [];
              let isNullArr = [];
              let allTags = this.state.selectedTags;
              allTags.map((t, tIndx) => {
                //*** creating yAxis */
                let tAxis = {};
                tAxis['labels'] = {
                  format: '{value}',
                  style: { color: Highcharts.getOptions().colors[tIndx] },
                };
                tAxis['title'] = {
                  text: t,
                  style: { color: Highcharts.getOptions().colors[tIndx] },
                };
                if (tIndx % 2 === 0) {
                  tAxis['opposite'] = false;
                } else {
                  tAxis['opposite'] = true;
                }
                // tAxis['softMax'] = 125;
                // tAxis['min'] = 0;
                // tAxis['plotLines'] = [{
                //   value: 10,
                //   width: 1,
                //   color: 'rgba(204,0,0,0.75)',
                //   dashStyle: 'ShortDash',
                // }, {
                //   value: 125,
                //   width: 1,
                //   color: '#008000',
                //   dashStyle: 'ShortDash',
                // }]
                dynAxes.push(tAxis);
                //*** creating series */
                let tagSeries = {};
                tagSeries['name'] = t;
                tagSeries['type'] = 'spline';
                tagSeries['yAxis'] = tIndx;
                tagSeries['data'] = [];
                tagSeries['color'] = Highcharts.getOptions().colors[tIndx];
                tagSeries['tooltip'] = { valueSuffix: '' }; //eng unit canbe append here
                finalRes.map((r) => {
                  // let cTime = new Date(r.date_time).getTime();
                  let cVal = r[t];
                  if (cVal !== null && cVal !== undefined) {
                    cVal = parseFloat(cVal).toFixed(2);
                    const TD = r.date_time.split(' ');
                    const dt = TD[0].split('-');
                    const tm = TD[1].split(':');
                    const year = dt[0];
                    const month = parseInt(dt[1]) - 1;
                    const day = dt[2];
                    const hour = tm[0];
                    const minute = tm[1];
                    const seconds = tm[2];
                    tagSeries['data'].push([
                      Date.UTC(year, month, day, hour, minute, seconds),
                      parseFloat(cVal),
                    ]);
                  }

                  // if(cVal === null){
                  //   isNullArr.push(0);
                  // }else{
                  //   isNullArr.push(1);
                  // }
                });
                dynSeries.push(tagSeries);
              });

              finalRes.map((r) => {
                let temp = {};
                temp['Time Date'] = r.date_time;
                let p = 0,
                  allTagsIndex;
                allTags.map((t, tIndx) => {
                  allTagsIndex = tIndx + 1;
                  if (r[t] !== undefined && r[t] !== null) temp[t] = r[t];
                  else {
                    temp[t] = '#';
                    p++;
                  }
                });

                console.log(allTagsIndex + ' index ' + p);
                if (allTagsIndex != p) {
                  this.exportData.push(temp);
                }
              });
              // console.log('this.exportData ' + JSON.stringify(this.exportData));
              this.setState({
                isSpinner: false,
                series: dynSeries,
                yAxis: dynAxes,
              });
              // if(isNullArr.includes(1)){
              this.highChartsRender();
              // }else{
              //   console.log('no data..')
              //   this.setState({ isSpinner: false });
              //   document.getElementById('advaitTrends').innerHTML = '<br/><p>&nbsp;&nbsp;&nbsp; No data found!</p><br/>';
              // }
            } else {
              this.setState({ isSpinner: false });
              document.getElementById('advaitTrends').innerHTML =
                '<br/><p>&nbsp;&nbsp;&nbsp; No data found!</p><br/>';
            }
          } else {
            let reqParams1 = {
              tags: this.state.selectedDigiTags,
              interval: 0,
              from: fDT,
              to: tDT,
              sorting: 1,
              function: 'avg',
            };
            // alert(JSON.stringify(reqParams));
            this.trendService.getTrends(reqParams1).then((res1) => {
              console.log('Dig ' + JSON.stringify(finalRes));
              // let finalRes = [];
              // if (res && res.length > 0) {
              // setTimeout(() =>{

              finalRes = finalRes.concat(res1);
              // }
              console.log('AA ' + JSON.stringify(finalRes));
              if (finalRes && finalRes.length > 0) {
                console.log('Dig ' + JSON.stringify(finalRes));
                let dynSeries = [];
                let dynAxes = [];
                let isNullArr = [];
                let allTags = this.state.selectedTags.concat(
                  this.state.selectedDigiTags
                );
                allTags.map((t, tIndx) => {
                  //*** creating yAxis */
                  let tAxis = {};
                  tAxis['labels'] = {
                    format: '{value}',
                    style: { color: Highcharts.getOptions().colors[tIndx] },
                  };
                  tAxis['title'] = {
                    text: t,
                    style: { color: Highcharts.getOptions().colors[tIndx] },
                  };
                  if (tIndx % 2 === 0) {
                    tAxis['opposite'] = false;
                  } else {
                    tAxis['opposite'] = true;
                  }
                //   tAxis['softMax'] = 125;
                // tAxis['min'] = 0;
                // tAxis['plotLines'] = [{
                //   value: 10,
                //   width: 1,
                //   color: 'rgba(204,0,0,0.75)',
                //   dashStyle: 'ShortDash',
                // }, {
                //   value: 125,
                //   width: 1,
                //   color: '#008000',
                //   dashStyle: 'ShortDash',
                // }]
                  dynAxes.push(tAxis);
                  //*** creating series */
                  let tagSeries = {};
                  tagSeries['name'] = t;
                  tagSeries['type'] = 'spline';
                  tagSeries['yAxis'] = tIndx;
                  tagSeries['data'] = [];
                  tagSeries['color'] = Highcharts.getOptions().colors[tIndx];
                  tagSeries['tooltip'] = { valueSuffix: '' }; //eng unit canbe append here
                  finalRes.map((r) => {
                    // let cTime = new Date(r.date_time).getTime();
                    let cVal = r[t];
                    if (cVal !== null && cVal !== undefined) {
                      cVal = parseFloat(cVal).toFixed(2);
                      const TD = r.date_time.split(' ');
                      const dt = TD[0].split('-');
                      const tm = TD[1].split(':');
                      const year = dt[0];
                      const month = parseInt(dt[1]) - 1;
                      const day = dt[2];
                      const hour = tm[0];
                      const minute = tm[1];
                      const seconds = tm[2];
                      tagSeries['data'].push([
                        Date.UTC(year, month, day, hour, minute, seconds),
                        parseFloat(cVal),
                      ]);
                    }

                    // if(cVal === null){
                    //   isNullArr.push(0);
                    // }else{
                    //   isNullArr.push(1);
                    // }
                  });
                  dynSeries.push(tagSeries);
                });

                finalRes.map((r) => {
                  let temp = {};
                  temp['Time Date'] = r.date_time;
                  let p = 0,
                    allTagsIndex;
                  allTags.map((t, tIndx) => {
                    allTagsIndex = tIndx + 1;
                    if (r[t] !== undefined && r[t] !== null) temp[t] = r[t];
                    else {
                      temp[t] = '#';
                      p++;
                    }
                  });

                  console.log(allTagsIndex + ' index ' + p);
                  if (allTagsIndex != p) {
                    this.exportData.push(temp);
                  }
                });
                // console.log(
                //   'this.exportData ' + JSON.stringify(this.exportData)
                // );
                this.setState({
                  isSpinner: false,
                  series: dynSeries,
                  yAxis: dynAxes,
                });
                // if(isNullArr.includes(1)){
                this.highChartsRender();
                // }else{
                //   console.log('no data..')
                //   this.setState({ isSpinner: false });
                //   document.getElementById('advaitTrends').innerHTML = '<br/><p>&nbsp;&nbsp;&nbsp; No data found!</p><br/>';
                // }
              } else {
                this.setState({ isSpinner: false });
                document.getElementById('advaitTrends').innerHTML =
                  '<br/><p>&nbsp;&nbsp;&nbsp; No data found!</p><br/>';
              }
              // }, 2000)
            });
          }
        });

        // } else {
        //   this.setState({ msgStatus: true, msgType: 'warning', msgContent: 'From date and to date difference should be greater then 0 & less then 7 !!!', isSpinner: false });
        // }
      } else if (this.state.selectedDigiTags.length > 0) {
        // alert("Called ")
        let fDT1 = this.state.selectedFrom.split('T');
        let fDT = fDT1[0] + ' ' + fDT1[1] + ':00';
        let tDT1 = this.state.selectedTo.split('T');
        let tDT = tDT1[0] + ' ' + tDT1[1] + ':00';
        const date1 = new Date(this.state.selectedFrom);
        const date2 = new Date(this.state.selectedTo);
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        // if (diffDays <= 7 && diffDays >= 0) {
        let reqParams = {
          tags: this.state.selectedDigiTags,
          interval: 0,
          from: fDT,
          to: tDT,
          sorting: 1,
          function: 'avg',
        };
        // alert(JSON.stringify(reqParams));
        this.trendService.getTrends(reqParams).then((res) => {
          // console.log('Ana ' + JSON.stringify(res));
          var finalRes = [];
          // if (res && res.length > 0) {
          finalRes = res;
          // }
          // if (this.state.selectedDigiTags.length > 0) {
          if (this.state.selectedTags.length == 0) {
            if (finalRes && finalRes.length > 0) {
              let dynSeries = [];
              let dynAxes = [];
              let isNullArr = [];
              let allTags = this.state.selectedDigiTags;
              allTags.map((t, tIndx) => {
                //*** creating yAxis */
                let tAxis = {};
                tAxis['labels'] = {
                  format: '{value}',
                  style: { color: Highcharts.getOptions().colors[tIndx] },
                };
                tAxis['title'] = {
                  text: t,
                  style: { color: Highcharts.getOptions().colors[tIndx] },
                };
                if (tIndx % 2 === 0) {
                  tAxis['opposite'] = false;
                } else {
                  tAxis['opposite'] = true;
                }
                // tAxis['softMax'] = 125;
                // tAxis['min'] = 0;
                // tAxis['plotLines'] = [{
                //   value: 10,
                //   width: 1,
                //   color: 'rgba(204,0,0,0.75)',
                //   dashStyle: 'ShortDash',
                // }, {
                //   value: 125,
                //   width: 1,
                //   color: '#008000',
                //   dashStyle: 'ShortDash',
                // }]
                dynAxes.push(tAxis);
                //*** creating series */
                let tagSeries = {};
                tagSeries['name'] = t;
                tagSeries['type'] = 'spline';
                tagSeries['yAxis'] = tIndx;
                tagSeries['data'] = [];
                tagSeries['color'] = Highcharts.getOptions().colors[tIndx];
                tagSeries['tooltip'] = { valueSuffix: '' }; //eng unit canbe append here
                finalRes.map((r) => {
                  // let cTime = new Date(r.date_time).getTime();
                  let cVal = r[t];
                  if (cVal !== null && cVal !== undefined) {
                    cVal = parseFloat(cVal).toFixed(2);
                    const TD = r.date_time.split(' ');
                    const dt = TD[0].split('-');
                    const tm = TD[1].split(':');
                    const year = dt[0];
                    const month = parseInt(dt[1]) - 1;
                    const day = dt[2];
                    const hour = tm[0];
                    const minute = tm[1];
                    const seconds = tm[2];
                    tagSeries['data'].push([
                      Date.UTC(year, month, day, hour, minute, seconds),
                      parseFloat(cVal),
                    ]);
                  }

                  // if(cVal === null){
                  //   isNullArr.push(0);
                  // }else{
                  //   isNullArr.push(1);
                  // }
                });
                dynSeries.push(tagSeries);
              });

              finalRes.map((r) => {
                let temp = {};
                temp['Time Date'] = r.date_time;
                let p = 0,
                  allTagsIndex;
                allTags.map((t, tIndx) => {
                  allTagsIndex = tIndx + 1;
                  if (r[t] !== undefined && r[t] !== null) temp[t] = r[t];
                  else {
                    temp[t] = '#';
                    p++;
                  }
                });

                // console.log(allTagsIndex + ' index ' + p);
                if (allTagsIndex != p) {
                  this.exportData.push(temp);
                }
              });

              // console.log(JSON.stringify(dynSeries));
              this.setState({
                isSpinner: false,
                series: dynSeries,
                yAxis: dynAxes,
              });
              // if(isNullArr.includes(1)){
              this.highChartsRender();
              // }else{
              //   console.log('no data..')
              //   this.setState({ isSpinner: false });
              //   document.getElementById('advaitTrends').innerHTML = '<br/><p>&nbsp;&nbsp;&nbsp; No data found!</p><br/>';
              // }
            } else {
              this.setState({ isSpinner: false });
              document.getElementById('advaitTrends').innerHTML =
                '<br/><p>&nbsp;&nbsp;&nbsp; No data found!</p><br/>';
            }
          } else {
            let reqParams1 = {
              tags: this.state.selectedTags,
              interval: this.state.selectedInterval,
              from: fDT,
              to: tDT,
              sorting: 1,
              function: 'avg',
            };
            // alert(JSON.stringify(reqParams));
            this.trendService.getTrends(reqParams1).then((res1) => {
              // console.log('Dig ' + JSON.stringify(res1));
              // let finalRes = [];
              // if (res && res.length > 0) {
              finalRes = finalRes.concat(res1);
              // }
              console.log('AA ' + JSON.stringify(finalRes));
              // setTimeout(() =>{
              if (finalRes && finalRes.length > 0) {
                let dynSeries = [];
                let dynAxes = [];
                let isNullArr = [];
                let allTags = this.state.selectedDigiTags.concat(
                  this.state.selectedTags
                );
                allTags.map((t, tIndx) => {
                  //*** creating yAxis */
                  let tAxis = {};
                  tAxis['labels'] = {
                    format: '{value}',
                    style: { color: Highcharts.getOptions().colors[tIndx] },
                  };
                  tAxis['title'] = {
                    text: t,
                    style: { color: Highcharts.getOptions().colors[tIndx] },
                  };
                  if (tIndx % 2 === 0) {
                    tAxis['opposite'] = false;
                  } else {
                    tAxis['opposite'] = true;
                  }
                //   tAxis['softMax'] = 125;
                // tAxis['min'] = 0;
                // tAxis['plotLines'] = [{
                //   value: 10,
                //   width: 1,
                //   color: 'rgba(204,0,0,0.75)',
                //   dashStyle: 'ShortDash',
                // }, {
                //   value: 125,
                //   width: 1,
                //   color: '#008000',
                //   dashStyle: 'ShortDash',
                // }]
                  dynAxes.push(tAxis);
                  //*** creating series */
                  let tagSeries = {};
                  tagSeries['name'] = t;
                  tagSeries['type'] = 'spline';
                  tagSeries['yAxis'] = tIndx;
                  tagSeries['data'] = [];
                  tagSeries['color'] = Highcharts.getOptions().colors[tIndx];
                  tagSeries['tooltip'] = { valueSuffix: '' }; //eng unit canbe append here
                  finalRes.map((r) => {
                    // let cTime = new Date(r.date_time).getTime();
                    let cVal = r[t];
                    if (cVal !== null && cVal !== undefined) {
                      cVal = parseFloat(cVal).toFixed(2);
                      const TD = r.date_time.split(' ');
                      const dt = TD[0].split('-');
                      const tm = TD[1].split(':');
                      const year = dt[0];
                      const month = parseInt(dt[1]) - 1;
                      const day = dt[2];
                      const hour = tm[0];
                      const minute = tm[1];
                      const seconds = tm[2];
                      tagSeries['data'].push([
                        Date.UTC(year, month, day, hour, minute, seconds),
                        parseFloat(cVal),
                      ]);
                    }

                    // if(cVal === null){
                    //   isNullArr.push(0);
                    // }else{
                    //   isNullArr.push(1);
                    // }
                  });
                  dynSeries.push(tagSeries);
                });

                finalRes.map((r) => {
                  let temp = {};
                  temp['Time Date'] = r.date_time;
                  let p = 0,
                    allTagsIndex;
                  allTags.map((t, tIndx) => {
                    allTagsIndex = tIndx + 1;
                    if (r[t] !== undefined && r[t] !== null) temp[t] = r[t];
                    else {
                      temp[t] = '#';
                      p++;
                    }
                  });

                  // console.log(allTagsIndex + ' index ' + p);
                  if (allTagsIndex != p) {
                    this.exportData.push(temp);
                  }
                });

                // console.log(JSON.stringify(dynSeries));
                this.setState({
                  isSpinner: false,
                  series: dynSeries,
                  yAxis: dynAxes,
                });
                // if(isNullArr.includes(1)){
                this.highChartsRender();
                // }else{
                //   console.log('no data..')
                //   this.setState({ isSpinner: false });
                //   document.getElementById('advaitTrends').innerHTML = '<br/><p>&nbsp;&nbsp;&nbsp; No data found!</p><br/>';
                // }
              } else {
                this.setState({ isSpinner: false });
                document.getElementById('advaitTrends').innerHTML =
                  '<br/><p>&nbsp;&nbsp;&nbsp; No data found!</p><br/>';
              }
              // }, 2000)
            });
          }
        });

        // } else {
        //   this.setState({ msgStatus: true, msgType: 'warning', msgContent: 'From date and to date difference should be greater then 0 & less then 7 !!!', isSpinner: false });
        // }
      } else {
        this.setState({
          msgStatus: true,
          msgType: 'warning',
          msgContent: 'Form is invalid!',
          hasError1: true,
          isSpinner: false,
        });
      }
    } else {
      this.setState({
        msgStatus: true,
        msgType: 'warning',
        msgContent: 'Form is invalid!',
        isSpinner: false,
      });
    }
  };

  getTrendByPage = (page) => {
    this.exportData = [];
    if (
      !this.state.hasError1 &&
      !this.state.hasError1 &&
      !this.state.hasError2 &&
      !this.state.hasError3 &&
      !this.state.hasError4
    ) {
      this.setState({ isSpinner: true });

      // alert(this.state.selectedFrom + '  -- ' + this.state.selectedTo);
      let fDT, tDT;
      if (this.state.selectedTags.length > 0) {
        if (page == 1) {
          let fDT1 = this.state.selectedFrom.split('T');
          fDT = fDT1[0] + ' ' + fDT1[1] + ':00';
          let tDT1 = this.state.selectedFrom.split('T');
          tDT = tDT1[0] + ' 23:59:59';
        } else if (page == this.state.totalRecords) {
          // alert("Test")
          let fDT1 = this.state.selectedTo.split('T');
          fDT = fDT1[0] + ' 00:00:00';
          let tDT1 = this.state.selectedTo.split('T');
          tDT = tDT1[0] + ' ' + tDT1[1] + ':59';
          // alert(fDT + "  -- " + tDT)
        } else {
          // alert(JSON.stringify(this.state.pagesArray))
          // alert(this.state.pagesArray[page-1])
          let tDate = this.state.pagesArray[page - 1];
          // alert(tDate)
          let fDT1 = tDate.split('T');
          fDT = fDT1[0] + ' 00:00:00';
          let tDT1 = tDate.split('T');
          tDT = tDT1[0] + ' 23:59:59';
        }
        const date1 = new Date(this.state.selectedFrom);
        const date2 = new Date(this.state.selectedTo);
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        // console.log(
        //   'this.state.selectedDigiTags ' +
        //     JSON.stringify(this.state.selectedDigiTags)
        // );
        // if (diffDays <= 7 && diffDays >= 0) {
        let reqParams = {
          tags: this.state.selectedTags,
          interval: this.state.selectedInterval,
          from: fDT,
          to: tDT,
          sorting: 1,
          function: 'avg',
        };
        console.log(JSON.stringify(reqParams));
        this.trendService.getTrends(reqParams).then((res) => {
          // console.log('Ana ' + JSON.stringify(res));
          var finalRes = [];
          // if (res && res.length > 0) {
          finalRes = res;
          // }
          // if (this.state.selectedDigiTags.length > 0) {
          let reqParams1 = {
            tags: this.state.selectedDigiTags,
            interval: 0,
            from: fDT,
            to: tDT,
            sorting: 1,
            function: 'avg',
          };
          // alert(JSON.stringify(reqParams));
          this.trendService.getTrends(reqParams1).then((res1) => {
            // console.log('Dig ' + JSON.stringify(finalRes));
            // let finalRes = [];
            // if (res && res.length > 0) {
            // setTimeout(() =>{

            finalRes = finalRes.concat(res1);
            // }
            console.log('AA ' + JSON.stringify(finalRes));
            if (finalRes && finalRes.length > 0) {
              // console.log('Dig ' + JSON.stringify(finalRes));
              let dynSeries = [];
              let dynAxes = [];
              let isNullArr = [];
              let allTags = this.state.selectedTags.concat(
                this.state.selectedDigiTags
              );
              allTags.map((t, tIndx) => {
                //*** creating yAxis */
                let tAxis = {};
                tAxis['labels'] = {
                  format: '{value}',
                  style: { color: Highcharts.getOptions().colors[tIndx] },
                };
                tAxis['title'] = {
                  text: t,
                  style: { color: Highcharts.getOptions().colors[tIndx] },
                };
                if (tIndx % 2 === 0) {
                  tAxis['opposite'] = false;
                } else {
                  tAxis['opposite'] = true;
                }
                // tAxis['softMax'] = 125;
                // tAxis['min'] = 0;
                // tAxis['plotLines'] = [{
                //   value: 10,
                //   width: 1,
                //   color: 'rgba(204,0,0,0.75)',
                //   dashStyle: 'ShortDash',
                // }, {
                //   value: 125,
                //   width: 1,
                //   color: '#008000',
                //   dashStyle: 'ShortDash',
                // }]
                dynAxes.push(tAxis);
                //*** creating series */
                let tagSeries = {};
                tagSeries['name'] = t;
                tagSeries['type'] = 'spline';
                tagSeries['yAxis'] = tIndx;
                tagSeries['data'] = [];
                tagSeries['color'] = Highcharts.getOptions().colors[tIndx];
                tagSeries['tooltip'] = { valueSuffix: '' }; //eng unit canbe append here
                finalRes.map((r) => {
                  // let cTime = new Date(r.date_time).getTime();
                  let cVal = r[t];
                  if (cVal !== null && cVal !== undefined) {
                    cVal = parseFloat(cVal).toFixed(2);
                    const TD = r.date_time.split(' ');
                    const dt = TD[0].split('-');
                    const tm = TD[1].split(':');
                    const year = dt[0];
                    const month = parseInt(dt[1]) - 1;
                    const day = dt[2];
                    const hour = tm[0];
                    const minute = tm[1];
                    const seconds = tm[2];
                    tagSeries['data'].push([
                      Date.UTC(year, month, day, hour, minute, seconds),
                      parseFloat(cVal),
                    ]);
                  }

                  // if(cVal === null){
                  //   isNullArr.push(0);
                  // }else{
                  //   isNullArr.push(1);
                  // }
                });
                dynSeries.push(tagSeries);
              });

              finalRes.map((r) => {
                let temp = {};
                temp['Time Date'] = r.date_time;
                let p = 0,
                  allTagsIndex;
                allTags.map((t, tIndx) => {
                  allTagsIndex = tIndx + 1;
                  if (r[t] !== undefined && r[t] !== null) temp[t] = r[t];
                  else {
                    temp[t] = '#';
                    p++;
                  }
                });

                // console.log(allTagsIndex + ' index ' + p);
                if (allTagsIndex != p) {
                  this.exportData.push(temp);
                }
              });
              // console.log('this.exportData ' + JSON.stringify(this.exportData));

              // console.log(JSON.stringify(dynSeries));
              this.setState({
                isSpinner: false,
                series: dynSeries,
                yAxis: dynAxes,
              });
              // if(isNullArr.includes(1)){
              // this.highChartsRender();
              var chart = new Highcharts.Chart({
                chart: {
                  zoomType: 'xy',
                  renderTo: 'advaitTrends',
                },
                title: {
                  align: 'center',
                  text: fDT + ' to ' + tDT,
                },
                xAxis: {
                  type: 'datetime',
                  dateTimeLabelFormats: {
                    hour: '%H:%M',
                    month: '%e. %b',
                    year: '%b',
                  },
                  crosshair: true,
                },
                exporting: {
                  buttons: {
                    contextButton: {
                      menuItems: [
                        'printChart',
                        'separator',
                        'downloadPNG',
                        'downloadJPEG',
                        'downloadPDF',
                        'downloadSVG',
                      ],
                    },
                  },
                },
                yAxis: this.state.yAxis,
                tooltip: {
                  shared: true,
                },
                legend: {
                  layout: 'horizontal',
                  align: 'center',
                  verticalAlign: 'bottom',
                  floating: false,
                  backgroundColor:
                    Highcharts.defaultOptions.legend.backgroundColor || // theme
                    'rgba(255,255,255,0.25)',
                },
                series: this.state.series,
              });
              // }else{
              //   console.log('no data..')
              //   this.setState({ isSpinner: false });
              //   document.getElementById('advaitTrends').innerHTML = '<br/><p>&nbsp;&nbsp;&nbsp; No data found!</p><br/>';
              // }
            } else {
              this.setState({ isSpinner: false });
              document.getElementById('advaitTrends').innerHTML =
                '<br/><p>&nbsp;&nbsp;&nbsp; No data found!</p><br/>';
            }
            // }, 2000)
          });
        });

        // } else {
        //   this.setState({ msgStatus: true, msgType: 'warning', msgContent: 'From date and to date difference should be greater then 0 & less then 7 !!!', isSpinner: false });
        // }
      } else if (this.state.selectedDigiTags.length > 0) {
        // alert("Called ")
        if (page == 1) {
          let fDT1 = this.state.selectedFrom.split('T');
          fDT = fDT1[0] + ' ' + fDT1[1] + ':00';
          let tDT1 = this.state.selectedFrom.split('T');
          tDT = tDT1[0] + ' 23:59:00';
        } else if (page == this.state.totalRecords) {
          let fDT1 = this.state.selectedTo.split('T');
          fDT = fDT1[0] + ' 00:00:00';
          let tDT1 = this.state.selectedTo.split('T');
          tDT = tDT1[0] + ' ' + tDT1[1] + ':00';
        } else {
          // alert(JSON.stringify(this.state.pagesArray))
          // alert(this.state.pagesArray[page-1])
          let tDate = this.state.pagesArray[page - 1];
          // alert(tDate)
          let fDT1 = tDate.split('T');
          fDT = fDT1[0] + ' 00:00:00';
          let tDT1 = tDate.split('T');
          tDT = tDT1[0] + ' 23:59:00';
        }
        const date1 = new Date(this.state.selectedFrom);
        const date2 = new Date(this.state.selectedTo);
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        // if (diffDays <= 7 && diffDays >= 0) {
        let reqParams = {
          tags: this.state.selectedDigiTags,
          interval: 0,
          from: fDT,
          to: tDT,
          sorting: 1,
          function: 'avg',
        };
        // alert(JSON.stringify(reqParams));
        this.trendService.getTrends(reqParams).then((res) => {
          // console.log('Ana ' + JSON.stringify(res));
          var finalRes = [];
          // if (res && res.length > 0) {
          finalRes = res;
          // }
          // if (this.state.selectedDigiTags.length > 0) {
          let reqParams1 = {
            tags: this.state.selectedTags,
            interval: this.state.selectedInterval,
            from: fDT,
            to: tDT,
            sorting: 1,
            function: 'avg',
          };
          // alert(JSON.stringify(reqParams));
          this.trendService.getTrends(reqParams1).then((res1) => {
            // console.log('Dig ' + JSON.stringify(res1));
            // let finalRes = [];
            // if (res && res.length > 0) {
            finalRes = finalRes.concat(res1);
            // }
            console.log('AA ' + JSON.stringify(finalRes));
            // setTimeout(() =>{
            if (finalRes && finalRes.length > 0) {
              let dynSeries = [];
              let dynAxes = [];
              let isNullArr = [];
              let allTags = this.state.selectedDigiTags.concat(
                this.state.selectedTags
              );
              allTags.map((t, tIndx) => {
                //*** creating yAxis */
                let tAxis = {};
                tAxis['labels'] = {
                  format: '{value}',
                  style: { color: Highcharts.getOptions().colors[tIndx] },
                };
                tAxis['title'] = {
                  text: t,
                  style: { color: Highcharts.getOptions().colors[tIndx] },
                };
                if (tIndx % 2 === 0) {
                  tAxis['opposite'] = false;
                } else {
                  tAxis['opposite'] = true;
                }
                // tAxis['softMax'] = 125;
                // tAxis['min'] = 0;
                // tAxis['plotLines'] = [{
                //   value: 10,
                //   width: 1,
                //   color: 'rgba(204,0,0,0.75)',
                //   dashStyle: 'ShortDash',
                // }, {
                //   value: 125,
                //   width: 1,
                //   color: '#008000',
                //   dashStyle: 'ShortDash',
                // }]
                dynAxes.push(tAxis);
                //*** creating series */
                let tagSeries = {};
                tagSeries['name'] = t;
                tagSeries['type'] = 'spline';
                tagSeries['yAxis'] = tIndx;
                tagSeries['data'] = [];
                tagSeries['color'] = Highcharts.getOptions().colors[tIndx];
                tagSeries['tooltip'] = { valueSuffix: '' }; //eng unit canbe append here
                finalRes.map((r) => {
                  // let cTime = new Date(r.date_time).getTime();
                  let cVal = r[t];
                  if (cVal !== null && cVal !== undefined) {
                    cVal = parseFloat(cVal).toFixed(2);
                    const TD = r.date_time.split(' ');
                    const dt = TD[0].split('-');
                    const tm = TD[1].split(':');
                    const year = dt[0];
                    const month = parseInt(dt[1]) - 1;
                    const day = dt[2];
                    const hour = tm[0];
                    const minute = tm[1];
                    const seconds = tm[2];
                    tagSeries['data'].push([
                      Date.UTC(year, month, day, hour, minute, seconds),
                      parseFloat(cVal),
                    ]);
                  }

                  // if(cVal === null){
                  //   isNullArr.push(0);
                  // }else{
                  //   isNullArr.push(1);
                  // }
                });
                dynSeries.push(tagSeries);
              });

              finalRes.map((r) => {
                let temp = {};
                temp['Time Date'] = r.date_time;
                let p = 0,
                  allTagsIndex;
                allTags.map((t, tIndx) => {
                  allTagsIndex = tIndx + 1;
                  if (r[t] !== undefined && r[t] !== null) temp[t] = r[t];
                  else {
                    temp[t] = '#';
                    p++;
                  }
                });

                // console.log(allTagsIndex + ' index ' + p);
                if (allTagsIndex != p) {
                  this.exportData.push(temp);
                }
              });

              // console.log(JSON.stringify(dynSeries));
              this.setState({
                isSpinner: false,
                series: dynSeries,
                yAxis: dynAxes,
              });
              // if(isNullArr.includes(1)){
              // this.highChartsRender();
              var chart = new Highcharts.Chart({
                chart: {
                  zoomType: 'xy',
                  renderTo: 'advaitTrends',
                },
                title: {
                  align: 'center',
                  text: fDT + ' to ' + tDT,
                },
                xAxis: {
                  type: 'datetime',
                  dateTimeLabelFormats: {
                    hour: '%H:%M',
                    month: '%e. %b',
                    year: '%b',
                  },
                  crosshair: true,
                },
                exporting: {
                  buttons: {
                    contextButton: {
                      menuItems: [
                        'printChart',
                        'separator',
                        'downloadPNG',
                        'downloadJPEG',
                        'downloadPDF',
                        'downloadSVG',
                      ],
                    },
                  },
                },
                yAxis: this.state.yAxis,
                tooltip: {
                  shared: true,
                },
                legend: {
                  layout: 'horizontal',
                  align: 'center',
                  verticalAlign: 'bottom',
                  floating: false,
                  backgroundColor:
                    Highcharts.defaultOptions.legend.backgroundColor || // theme
                    'rgba(255,255,255,0.25)',
                },
                series: this.state.series,
              });
              // }else{
              //   console.log('no data..')
              //   this.setState({ isSpinner: false });
              //   document.getElementById('advaitTrends').innerHTML = '<br/><p>&nbsp;&nbsp;&nbsp; No data found!</p><br/>';
              // }
            } else {
              this.setState({ isSpinner: false });
              document.getElementById('advaitTrends').innerHTML =
                '<br/><p>&nbsp;&nbsp;&nbsp; No data found!</p><br/>';
            }
            // }, 2000)
          });
        });

        // } else {
        //   this.setState({ msgStatus: true, msgType: 'warning', msgContent: 'From date and to date difference should be greater then 0 & less then 7 !!!', isSpinner: false });
        // }
      } else {
        this.setState({
          msgStatus: true,
          msgType: 'warning',
          msgContent: 'Form is invalid!',
          hasError1: true,
          isSpinner: false,
        });
      }
    } else {
      this.setState({
        msgStatus: true,
        msgType: 'warning',
        msgContent: 'Form is invalid!',
        isSpinner: false,
      });
    }
  };

  // getTrend = () => {
  //   if (
  //     !this.state.hasError1 &&
  //     !this.state.hasError1 &&
  //     !this.state.hasError2 &&
  //     !this.state.hasError3 &&
  //     !this.state.hasError4
  //   ) {
  //     this.setState({ isSpinner: true });

  //     if (this.state.selectedTags.length > 0) {
  //       let fDT1 = this.state.selectedFrom.split('T');
  //       let fDT = fDT1[0] + ' ' + fDT1[1] + ':00';
  //       let tDT1 = this.state.selectedTo.split('T');
  //       let tDT = tDT1[0] + ' ' + tDT1[1] + ':59';
  //       const date1 = new Date(this.state.selectedFrom);
  //       const date2 = new Date(this.state.selectedTo);
  //       const diffTime = Math.abs(date2 - date1);
  //       const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  //       // if (diffDays <= 7 && diffDays >= 0) {
  //       let reqParams = {
  //         tags: this.state.selectedTags,
  //         interval: this.state.selectedInterval,
  //         from: fDT,
  //         to: tDT,
  //         sorting: 1,
  //       };
  //       console.log('TTT  ' + JSON.stringify(reqParams));
  //       this.trendService.getTrends(reqParams).then((res) => {
  //         console.log('Ana ' + JSON.stringify(res));
  //         var finalRes = [];
  //         // if (res && res.length > 0) {
  //         finalRes = res;
  //         // }
  //         // if (this.state.selectedDigiTags.length > 0) {
  //         let reqParams1 = {
  //           tags: this.state.selectedDigiTags,
  //           interval: 0,
  //           from: fDT,
  //           to: tDT,
  //           sorting: 1,
  //         };
  //         // alert(JSON.stringify(reqParams));
  //         this.trendService.getTrends(reqParams1).then((res1) => {
  //           console.log('Dig ' + JSON.stringify(finalRes));
  //           // let finalRes = [];
  //           // if (res && res.length > 0) {
  //           // setTimeout(() =>{

  //           finalRes = finalRes.concat(res1);
  //           // }
  //           console.log('AA ' + JSON.stringify(finalRes));
  //           if (finalRes && finalRes.length > 0) {
  //             console.log('Dig ' + JSON.stringify(finalRes));
  //             let dynSeries = [];
  //             let dynAxes = [];
  //             let isNullArr = [];
  //             let allTags = this.state.selectedTags.concat(
  //               this.state.selectedDigiTags
  //             );
  //             allTags.map((t, tIndx) => {
  //               //*** creating yAxis */
  //               let tAxis = {};
  //               tAxis['labels'] = {
  //                 format: '{value}',
  //                 style: { color: Highcharts.getOptions().colors[tIndx] },
  //               };
  //               tAxis['title'] = {
  //                 text: t,
  //                 style: { color: Highcharts.getOptions().colors[tIndx] },
  //               };
  //               if (tIndx % 2 === 0) {
  //                 tAxis['opposite'] = false;
  //               } else {
  //                 tAxis['opposite'] = true;
  //               }
  //               dynAxes.push(tAxis);
  //               //*** creating series */
  //               let tagSeries = {};
  //               tagSeries['name'] = t;
  //               tagSeries['type'] = 'spline';
  //               tagSeries['yAxis'] = tIndx;
  //               tagSeries['data'] = [];
  //               tagSeries['color'] = Highcharts.getOptions().colors[tIndx];
  //               tagSeries['tooltip'] = { valueSuffix: '' }; //eng unit canbe append here
  //               finalRes.map((r) => {
  //                 // let cTime = new Date(r.date_time).getTime();
  //                 let cVal = r[t];
  //                 if (cVal !== null && cVal !== undefined) {
  //                   const TD = r.date_time.split(' ');
  //                   const dt = TD[0].split('-');
  //                   const tm = TD[1].split(':');
  //                   const year = dt[0];
  //                   const month = parseInt(dt[1]) - 1;
  //                   const day = dt[2];
  //                   const hour = tm[0];
  //                   const minute = tm[1];
  //                   const seconds = tm[2];
  //                   tagSeries['data'].push([
  //                     Date.UTC(year, month, day, hour, minute, seconds),
  //                     parseFloat(cVal),
  //                   ]);
  //                 }

  //                 // if(cVal === null){
  //                 //   isNullArr.push(0);
  //                 // }else{
  //                 //   isNullArr.push(1);
  //                 // }
  //               });
  //               dynSeries.push(tagSeries);
  //             });
  //             console.log(JSON.stringify(dynSeries));
  //             this.setState({
  //               isSpinner: false,
  //               series: dynSeries,
  //               yAxis: dynAxes,
  //             });
  //             // if(isNullArr.includes(1)){
  //             this.highChartsRender();
  //             // }else{
  //             //   console.log('no data..')
  //             //   this.setState({ isSpinner: false });
  //             //   document.getElementById('advaitTrends').innerHTML = '<br/><p>&nbsp;&nbsp;&nbsp; No data found!</p><br/>';
  //             // }
  //           } else {
  //             this.setState({ isSpinner: false });
  //             document.getElementById('advaitTrends').innerHTML =
  //               '<br/><p>&nbsp;&nbsp;&nbsp; No data found!</p><br/>';
  //           }
  //           // }, 2000)
  //         });
  //       });

  //       // } else {
  //       //   this.setState({ msgStatus: true, msgType: 'warning', msgContent: 'From date and to date difference should be greater then 0 & less then 7 !!!', isSpinner: false });
  //       // }
  //     } else if (this.state.selectedDigiTags.length > 0) {
  //       // alert("Called ")
  //       let fDT1 = this.state.selectedFrom.split('T');
  //       let fDT = fDT1[0] + ' ' + fDT1[1] + ':00';
  //       let tDT1 = this.state.selectedTo.split('T');
  //       let tDT = tDT1[0] + ' ' + tDT1[1] + ':00';
  //       const date1 = new Date(this.state.selectedFrom);
  //       const date2 = new Date(this.state.selectedTo);
  //       const diffTime = Math.abs(date2 - date1);
  //       const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  //       // if (diffDays <= 7 && diffDays >= 0) {
  //       let reqParams = {
  //         tags: this.state.selectedDigiTags,
  //         interval: 0,
  //         from: fDT,
  //         to: tDT,
  //         sorting: 1,
  //       };
  //       // alert(JSON.stringify(reqParams));
  //       this.trendService.getTrends(reqParams).then((res) => {
  //         console.log('Ana ' + JSON.stringify(res));
  //         var finalRes = [];
  //         // if (res && res.length > 0) {
  //         finalRes = res;
  //         // }
  //         // if (this.state.selectedDigiTags.length > 0) {
  //         let reqParams1 = {
  //           tags: this.state.selectedTags,
  //           interval: this.state.selectedInterval,
  //           from: fDT,
  //           to: tDT,
  //           sorting: 1,
  //         };
  //         // alert(JSON.stringify(reqParams));
  //         this.trendService.getTrends(reqParams1).then((res1) => {
  //           console.log('Dig ' + JSON.stringify(res1));
  //           // let finalRes = [];
  //           // if (res && res.length > 0) {
  //           finalRes = finalRes.concat(res1);
  //           // }
  //           console.log('AA ' + JSON.stringify(finalRes));
  //           // setTimeout(() =>{
  //           if (finalRes && finalRes.length > 0) {
  //             let dynSeries = [];
  //             let dynAxes = [];
  //             let isNullArr = [];
  //             let allTags = this.state.selectedDigiTags.concat(
  //               this.state.selectedTags
  //             );
  //             allTags.map((t, tIndx) => {
  //               //*** creating yAxis */
  //               let tAxis = {};
  //               tAxis['labels'] = {
  //                 format: '{value}',
  //                 style: { color: Highcharts.getOptions().colors[tIndx] },
  //               };
  //               tAxis['title'] = {
  //                 text: t,
  //                 style: { color: Highcharts.getOptions().colors[tIndx] },
  //               };
  //               if (tIndx % 2 === 0) {
  //                 tAxis['opposite'] = false;
  //               } else {
  //                 tAxis['opposite'] = true;
  //               }
  //               dynAxes.push(tAxis);
  //               //*** creating series */
  //               let tagSeries = {};
  //               tagSeries['name'] = t;
  //               tagSeries['type'] = 'spline';
  //               tagSeries['yAxis'] = tIndx;
  //               tagSeries['data'] = [];
  //               tagSeries['color'] = Highcharts.getOptions().colors[tIndx];
  //               tagSeries['tooltip'] = { valueSuffix: '' }; //eng unit canbe append here
  //               finalRes.map((r) => {
  //                 // let cTime = new Date(r.date_time).getTime();
  //                 let cVal = r[t];
  //                 if (cVal !== null && cVal !== undefined) {
  //                   const TD = r.date_time.split(' ');
  //                   const dt = TD[0].split('-');
  //                   const tm = TD[1].split(':');
  //                   const year = dt[0];
  //                   const month = parseInt(dt[1]) - 1;
  //                   const day = dt[2];
  //                   const hour = tm[0];
  //                   const minute = tm[1];
  //                   const seconds = tm[2];
  //                   tagSeries['data'].push([
  //                     Date.UTC(year, month, day, hour, minute, seconds),
  //                     parseFloat(cVal),
  //                   ]);
  //                 }

  //                 // if(cVal === null){
  //                 //   isNullArr.push(0);
  //                 // }else{
  //                 //   isNullArr.push(1);
  //                 // }
  //               });
  //               dynSeries.push(tagSeries);
  //             });
  //             console.log(JSON.stringify(dynSeries));
  //             this.setState({
  //               isSpinner: false,
  //               series: dynSeries,
  //               yAxis: dynAxes,
  //             });
  //             // if(isNullArr.includes(1)){
  //             this.highChartsRender();
  //             // }else{
  //             //   console.log('no data..')
  //             //   this.setState({ isSpinner: false });
  //             //   document.getElementById('advaitTrends').innerHTML = '<br/><p>&nbsp;&nbsp;&nbsp; No data found!</p><br/>';
  //             // }
  //           } else {
  //             this.setState({ isSpinner: false });
  //             document.getElementById('advaitTrends').innerHTML =
  //               '<br/><p>&nbsp;&nbsp;&nbsp; No data found!</p><br/>';
  //           }
  //           // }, 2000)
  //         });
  //       });

  //       // } else {
  //       //   this.setState({ msgStatus: true, msgType: 'warning', msgContent: 'From date and to date difference should be greater then 0 & less then 7 !!!', isSpinner: false });
  //       // }
  //     } else {
  //       this.setState({
  //         msgStatus: true,
  //         msgType: 'warning',
  //         msgContent: 'Form is invalid!',
  //         hasError1: true,
  //         isSpinner: false,
  //       });
  //     }
  //   } else {
  //     this.setState({
  //       msgStatus: true,
  //       msgType: 'warning',
  //       msgContent: 'Form is invalid!',
  //       isSpinner: false,
  //     });
  //   }
  // };

  // getTrendByPage = (page) => {
  //   if (
  //     !this.state.hasError1 &&
  //     !this.state.hasError1 &&
  //     !this.state.hasError2 &&
  //     !this.state.hasError3 &&
  //     !this.state.hasError4
  //   ) {
  //     this.setState({ isSpinner: true });

  //     // alert(JSON.stringify(tDate) +"  -- "+page)
  //     let fDT, tDT;
  //     if (this.state.selectedTags.length > 0) {
  //       if (page == 1) {
  //         let fDT1 = this.state.selectedFrom.split('T');
  //         fDT = fDT1[0] + ' ' + fDT1[1] + ':00';
  //         let tDT1 = this.state.selectedFrom.split('T');
  //         tDT = tDT1[0] + ' 23:59:59';
  //       } else if (page == this.state.totalRecords) {
  //         // alert("Test")
  //         let fDT1 = this.state.selectedTo.split('T');
  //         fDT = fDT1[0] + ' 00:00:00';
  //         let tDT1 = this.state.selectedTo.split('T');
  //         tDT = tDT1[0] + ' ' + tDT1[1] + ':59';
  //         // alert(fDT + "  -- " + tDT)
  //       } else {
  //         // alert(JSON.stringify(this.state.pagesArray))
  //         // alert(this.state.pagesArray[page-1])
  //         let tDate = this.state.pagesArray[page - 1];
  //         // alert(tDate)
  //         let fDT1 = tDate.split('T');
  //         fDT = fDT1[0] + ' 00:00:00';
  //         let tDT1 = tDate.split('T');
  //         tDT = tDT1[0] + ' 23:59:59';
  //       }
  //       const date1 = new Date(this.state.selectedFrom);
  //       const date2 = new Date(this.state.selectedTo);
  //       const diffTime = Math.abs(date2 - date1);
  //       const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  //       console.log(
  //         'this.state.selectedDigiTags ' +
  //           JSON.stringify(this.state.selectedDigiTags)
  //       );
  //       // if (diffDays <= 7 && diffDays >= 0) {
  //       let reqParams = {
  //         tags: this.state.selectedTags,
  //         interval: this.state.selectedInterval,
  //         from: fDT,
  //         to: tDT,
  //         sorting: 1,
  //       };
  //       console.log(JSON.stringify(reqParams));
  //       this.trendService.getTrends(reqParams).then((res) => {
  //         console.log('Ana ' + JSON.stringify(res));
  //         var finalRes = [];
  //         // if (res && res.length > 0) {
  //         finalRes = res;
  //         // }
  //         // if (this.state.selectedDigiTags.length > 0) {
  //         let reqParams1 = {
  //           tags: this.state.selectedDigiTags,
  //           interval: 0,
  //           from: fDT,
  //           to: tDT,
  //           sorting: 1,
  //         };
  //         // alert(JSON.stringify(reqParams));
  //         this.trendService.getTrends(reqParams1).then((res1) => {
  //           console.log('Dig ' + JSON.stringify(finalRes));
  //           // let finalRes = [];
  //           // if (res && res.length > 0) {
  //           // setTimeout(() =>{

  //           finalRes = finalRes.concat(res1);
  //           // }
  //           console.log('AA ' + JSON.stringify(finalRes));
  //           if (finalRes && finalRes.length > 0) {
  //             console.log('Dig ' + JSON.stringify(finalRes));
  //             let dynSeries = [];
  //             let dynAxes = [];
  //             let isNullArr = [];
  //             let allTags = this.state.selectedTags.concat(
  //               this.state.selectedDigiTags
  //             );
  //             allTags.map((t, tIndx) => {
  //               //*** creating yAxis */
  //               let tAxis = {};
  //               tAxis['labels'] = {
  //                 format: '{value}',
  //                 style: { color: Highcharts.getOptions().colors[tIndx] },
  //               };
  //               tAxis['title'] = {
  //                 text: t,
  //                 style: { color: Highcharts.getOptions().colors[tIndx] },
  //               };
  //               if (tIndx % 2 === 0) {
  //                 tAxis['opposite'] = false;
  //               } else {
  //                 tAxis['opposite'] = true;
  //               }
  //               dynAxes.push(tAxis);
  //               //*** creating series */
  //               let tagSeries = {};
  //               tagSeries['name'] = t;
  //               tagSeries['type'] = 'spline';
  //               tagSeries['yAxis'] = tIndx;
  //               tagSeries['data'] = [];
  //               tagSeries['color'] = Highcharts.getOptions().colors[tIndx];
  //               tagSeries['tooltip'] = { valueSuffix: '' }; //eng unit canbe append here
  //               finalRes.map((r) => {
  //                 // let cTime = new Date(r.date_time).getTime();
  //                 let cVal = r[t];
  //                 if (cVal !== null && cVal !== undefined) {
  //                   const TD = r.date_time.split(' ');
  //                   const dt = TD[0].split('-');
  //                   const tm = TD[1].split(':');
  //                   const year = dt[0];
  //                   const month = parseInt(dt[1]) - 1;
  //                   const day = dt[2];
  //                   const hour = tm[0];
  //                   const minute = tm[1];
  //                   const seconds = tm[2];
  //                   tagSeries['data'].push([
  //                     Date.UTC(year, month, day, hour, minute, seconds),
  //                     parseFloat(cVal),
  //                   ]);
  //                 }

  //                 // if(cVal === null){
  //                 //   isNullArr.push(0);
  //                 // }else{
  //                 //   isNullArr.push(1);
  //                 // }
  //               });
  //               dynSeries.push(tagSeries);
  //             });
  //             console.log(JSON.stringify(dynSeries));
  //             this.setState({
  //               isSpinner: false,
  //               series: dynSeries,
  //               yAxis: dynAxes,
  //             });
  //             // if(isNullArr.includes(1)){
  //             // this.highChartsRender();
  //             var chart = new Highcharts.Chart({
  //               chart: {
  //                 zoomType: 'xy',
  //                 renderTo: 'advaitTrends',
  //                 height: 600,
  //               },
  //               title: {
  //                 align: 'center',
  //                 text: fDT + ' to ' + tDT,
  //               },
  //               xAxis: {
  //                 type: 'datetime',
  //                 dateTimeLabelFormats: {
  //                   hour: '%H:%M',
  //                   month: '%e. %b',
  //                   year: '%b',
  //                 },
  //                 crosshair: true,
  //               },
  //               exporting: {
  //                 buttons: {
  //                   contextButton: {
  //                     menuItems: [
  //                       'printChart',
  //                       'separator',
  //                       'downloadPNG',
  //                       'downloadJPEG',
  //                       'downloadPDF',
  //                       'downloadSVG',
  //                     ],
  //                   },
  //                 },
  //               },
  //               yAxis: this.state.yAxis,
  //               tooltip: {
  //                 shared: true,
  //               },
  //               legend: {
  //                 layout: 'horizontal',
  //                 align: 'center',
  //                 verticalAlign: 'bottom',
  //                 floating: false,
  //                 backgroundColor:
  //                   Highcharts.defaultOptions.legend.backgroundColor || // theme
  //                   'rgba(255,255,255,0.25)',
  //               },
  //               series: this.state.series,
  //             });
  //             // }else{
  //             //   console.log('no data..')
  //             //   this.setState({ isSpinner: false });
  //             //   document.getElementById('advaitTrends').innerHTML = '<br/><p>&nbsp;&nbsp;&nbsp; No data found!</p><br/>';
  //             // }
  //           } else {
  //             this.setState({ isSpinner: false });
  //             document.getElementById('advaitTrends').innerHTML =
  //               '<br/><p>&nbsp;&nbsp;&nbsp; No data found!</p><br/>';
  //           }
  //           // }, 2000)
  //         });
  //       });

  //       // } else {
  //       //   this.setState({ msgStatus: true, msgType: 'warning', msgContent: 'From date and to date difference should be greater then 0 & less then 7 !!!', isSpinner: false });
  //       // }
  //     } else if (this.state.selectedDigiTags.length > 0) {
  //       // alert("Called ")
  //       if (page == 1) {
  //         let fDT1 = this.state.selectedFrom.split('T');
  //         fDT = fDT1[0] + ' ' + fDT1[1] + ':00';
  //         let tDT1 = this.state.selectedFrom.split('T');
  //         tDT = tDT1[0] + ' 23:59:00';
  //       } else if (page == this.state.totalRecords) {
  //         let fDT1 = this.state.selectedTo.split('T');
  //         fDT = fDT1[0] + ' 00:00:00';
  //         let tDT1 = this.state.selectedTo.split('T');
  //         tDT = tDT1[0] + ' ' + tDT1[1] + ':00';
  //       } else {
  //         // alert(JSON.stringify(this.state.pagesArray))
  //         // alert(this.state.pagesArray[page-1])
  //         let tDate = this.state.pagesArray[page - 1];
  //         // alert(tDate)
  //         let fDT1 = tDate.split('T');
  //         fDT = fDT1[0] + ' 00:00:00';
  //         let tDT1 = tDate.split('T');
  //         tDT = tDT1[0] + ' 23:59:00';
  //       }
  //       const date1 = new Date(this.state.selectedFrom);
  //       const date2 = new Date(this.state.selectedTo);
  //       const diffTime = Math.abs(date2 - date1);
  //       const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  //       // if (diffDays <= 7 && diffDays >= 0) {
  //       let reqParams = {
  //         tags: this.state.selectedDigiTags,
  //         interval: 0,
  //         from: fDT,
  //         to: tDT,
  //         sorting: 1,
  //       };
  //       // alert(JSON.stringify(reqParams));
  //       this.trendService.getTrends(reqParams).then((res) => {
  //         console.log('Ana ' + JSON.stringify(res));
  //         var finalRes = [];
  //         // if (res && res.length > 0) {
  //         finalRes = res;
  //         // }
  //         // if (this.state.selectedDigiTags.length > 0) {
  //         let reqParams1 = {
  //           tags: this.state.selectedTags,
  //           interval: this.state.selectedInterval,
  //           from: fDT,
  //           to: tDT,
  //           sorting: 1,
  //         };
  //         // alert(JSON.stringify(reqParams));
  //         this.trendService.getTrends(reqParams1).then((res1) => {
  //           console.log('Dig ' + JSON.stringify(res1));
  //           // let finalRes = [];
  //           // if (res && res.length > 0) {
  //           finalRes = finalRes.concat(res1);
  //           // }
  //           console.log('AA ' + JSON.stringify(finalRes));
  //           // setTimeout(() =>{
  //           if (finalRes && finalRes.length > 0) {
  //             let dynSeries = [];
  //             let dynAxes = [];
  //             let isNullArr = [];
  //             let allTags = this.state.selectedDigiTags.concat(
  //               this.state.selectedTags
  //             );
  //             allTags.map((t, tIndx) => {
  //               //*** creating yAxis */
  //               let tAxis = {};
  //               tAxis['labels'] = {
  //                 format: '{value}',
  //                 style: { color: Highcharts.getOptions().colors[tIndx] },
  //               };
  //               tAxis['title'] = {
  //                 text: t,
  //                 style: { color: Highcharts.getOptions().colors[tIndx] },
  //               };
  //               if (tIndx % 2 === 0) {
  //                 tAxis['opposite'] = false;
  //               } else {
  //                 tAxis['opposite'] = true;
  //               }
  //               dynAxes.push(tAxis);
  //               //*** creating series */
  //               let tagSeries = {};
  //               tagSeries['name'] = t;
  //               tagSeries['type'] = 'spline';
  //               tagSeries['yAxis'] = tIndx;
  //               tagSeries['data'] = [];
  //               tagSeries['color'] = Highcharts.getOptions().colors[tIndx];
  //               tagSeries['tooltip'] = { valueSuffix: '' }; //eng unit canbe append here
  //               finalRes.map((r) => {
  //                 // let cTime = new Date(r.date_time).getTime();
  //                 let cVal = r[t];
  //                 if (cVal !== null && cVal !== undefined) {
  //                   const TD = r.date_time.split(' ');
  //                   const dt = TD[0].split('-');
  //                   const tm = TD[1].split(':');
  //                   const year = dt[0];
  //                   const month = parseInt(dt[1]) - 1;
  //                   const day = dt[2];
  //                   const hour = tm[0];
  //                   const minute = tm[1];
  //                   const seconds = tm[2];
  //                   tagSeries['data'].push([
  //                     Date.UTC(year, month, day, hour, minute, seconds),
  //                     parseFloat(cVal),
  //                   ]);
  //                 }

  //                 // if(cVal === null){
  //                 //   isNullArr.push(0);
  //                 // }else{
  //                 //   isNullArr.push(1);
  //                 // }
  //               });
  //               dynSeries.push(tagSeries);
  //             });
  //             console.log(JSON.stringify(dynSeries));
  //             this.setState({
  //               isSpinner: false,
  //               series: dynSeries,
  //               yAxis: dynAxes,
  //             });
  //             // if(isNullArr.includes(1)){
  //             // this.highChartsRender();
  //             var chart = new Highcharts.Chart({
  //               chart: {
  //                 zoomType: 'xy',
  //                 renderTo: 'advaitTrends',
  //               },
  //               title: {
  //                 align: 'center',
  //                 text: fDT + ' to ' + tDT,
  //               },
  //               xAxis: {
  //                 type: 'datetime',
  //                 dateTimeLabelFormats: {
  //                   hour: '%H:%M',
  //                   month: '%e. %b',
  //                   year: '%b',
  //                 },
  //                 crosshair: true,
  //               },
  //               exporting: {
  //                 buttons: {
  //                   contextButton: {
  //                     menuItems: [
  //                       'printChart',
  //                       'separator',
  //                       'downloadPNG',
  //                       'downloadJPEG',
  //                       'downloadPDF',
  //                       'downloadSVG',
  //                     ],
  //                   },
  //                 },
  //               },
  //               yAxis: this.state.yAxis,
  //               tooltip: {
  //                 shared: true,
  //               },
  //               legend: {
  //                 layout: 'horizontal',
  //                 align: 'center',
  //                 verticalAlign: 'bottom',
  //                 floating: false,
  //                 backgroundColor:
  //                   Highcharts.defaultOptions.legend.backgroundColor || // theme
  //                   'rgba(255,255,255,0.25)',
  //               },
  //               series: this.state.series,
  //             });
  //             // }else{
  //             //   console.log('no data..')
  //             //   this.setState({ isSpinner: false });
  //             //   document.getElementById('advaitTrends').innerHTML = '<br/><p>&nbsp;&nbsp;&nbsp; No data found!</p><br/>';
  //             // }
  //           } else {
  //             this.setState({ isSpinner: false });
  //             document.getElementById('advaitTrends').innerHTML =
  //               '<br/><p>&nbsp;&nbsp;&nbsp; No data found!</p><br/>';
  //           }
  //           // }, 2000)
  //         });
  //       });

  //       // } else {
  //       //   this.setState({ msgStatus: true, msgType: 'warning', msgContent: 'From date and to date difference should be greater then 0 & less then 7 !!!', isSpinner: false });
  //       // }
  //     } else {
  //       this.setState({
  //         msgStatus: true,
  //         msgType: 'warning',
  //         msgContent: 'Form is invalid!',
  //         hasError1: true,
  //         isSpinner: false,
  //       });
  //     }
  //   } else {
  //     this.setState({
  //       msgStatus: true,
  //       msgType: 'warning',
  //       msgContent: 'Form is invalid!',
  //       isSpinner: false,
  //     });
  //   }
  // };

  getTrend1 = () => {
    if (
      !this.state.hasError1 &&
      !this.state.hasError1 &&
      !this.state.hasError2 &&
      !this.state.hasError3 &&
      !this.state.hasError4
    ) {
      this.setState({ isSpinner: true });

      if (this.state.selectedTags.length > 0) {
        let fDT1 = this.state.selectedFrom.split('T');
        let fDT = fDT1[0] + ' ' + fDT1[1] + ':00';
        let tDT1 = this.state.selectedTo.split('T');
        let tDT = tDT1[0] + ' ' + tDT1[1] + ':00';
        const date1 = new Date(this.state.selectedFrom);
        const date2 = new Date(this.state.selectedTo);
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        console.log(
          'this.state.selectedDigiTags ' +
            JSON.stringify(this.state.selectedDigiTags)
        );
        if (diffDays <= 7 && diffDays >= 0) {
          let reqParams = {
            tags: this.state.selectedTags,
            interval: this.state.selectedInterval,
            from: fDT,
            to: tDT,
            sorting: 1,
          };
          // alert(JSON.stringify(reqParams));
          this.trendService.getTrends(reqParams).then((res) => {
            console.log('Ana ' + JSON.stringify(res));
            var finalRes = [];
            // if (res && res.length > 0) {
            finalRes = res;
            // }
            // if (this.state.selectedDigiTags.length > 0) {
            let reqParams1 = {
              tags: this.state.selectedDigiTags,
              interval: 0,
              from: fDT,
              to: tDT,
              sorting: 1,
            };
            // alert(JSON.stringify(reqParams));
            this.trendService.getTrends(reqParams1).then((res1) => {
              console.log('Dig ' + JSON.stringify(finalRes));
              // let finalRes = [];
              // if (res && res.length > 0) {
              // setTimeout(() =>{

              finalRes = finalRes.concat(res1);
              // }
              console.log('AA ' + JSON.stringify(finalRes));
              if (finalRes && finalRes.length > 0) {
                console.log('Dig ' + JSON.stringify(finalRes));
                let dynSeries = [];
                let dynAxes = [];
                let isNullArr = [];
                let allTags = this.state.selectedTags.concat(
                  this.state.selectedDigiTags
                );
                allTags.map((t, tIndx) => {
                  //*** creating yAxis */
                  let tAxis = {};
                  tAxis['labels'] = {
                    format: '{value}',
                    style: { color: Highcharts.getOptions().colors[tIndx] },
                  };
                  tAxis['title'] = {
                    text: t,
                    style: { color: Highcharts.getOptions().colors[tIndx] },
                  };
                  if (tIndx % 2 === 0) {
                    tAxis['opposite'] = false;
                  } else {
                    tAxis['opposite'] = true;
                  }
                //   tAxis['softMax'] = 125;
                // tAxis['min'] = 0;
                // tAxis['plotLines'] = [{
                //   value: 10,
                //   width: 1,
                //   color: 'rgba(204,0,0,0.75)',
                //   dashStyle: 'ShortDash',
                // }, {
                //   value: 125,
                //   width: 1,
                //   color: '#008000',
                //   dashStyle: 'ShortDash',
                // }]
                  dynAxes.push(tAxis);
                  //*** creating series */
                  let tagSeries = {};
                  tagSeries['name'] = t;
                  tagSeries['type'] = 'spline';
                  tagSeries['yAxis'] = tIndx;
                  tagSeries['data'] = [];
                  tagSeries['color'] = Highcharts.getOptions().colors[tIndx];
                  tagSeries['tooltip'] = { valueSuffix: '' }; //eng unit canbe append here
                  finalRes.map((r) => {
                    // let cTime = new Date(r.date_time).getTime();
                    let cVal = r[t];
                    if (cVal !== null && cVal !== undefined) {
                      const TD = r.date_time.split(' ');
                      const dt = TD[0].split('-');
                      const tm = TD[1].split(':');
                      const year = dt[0];
                      const month = parseInt(dt[1]) - 1;
                      const day = dt[2];
                      const hour = tm[0];
                      const minute = tm[1];
                      const seconds = tm[2];
                      tagSeries['data'].push([
                        Date.UTC(year, month, day, hour, minute, seconds),
                        parseFloat(cVal),
                      ]);
                    }

                    // if(cVal === null){
                    //   isNullArr.push(0);
                    // }else{
                    //   isNullArr.push(1);
                    // }
                  });
                  dynSeries.push(tagSeries);
                });
                console.log(JSON.stringify(dynSeries));
                this.setState({
                  isSpinner: false,
                  series: dynSeries,
                  yAxis: dynAxes,
                });
                // if(isNullArr.includes(1)){
                this.highChartsRender();
                // }else{
                //   console.log('no data..')
                //   this.setState({ isSpinner: false });
                //   document.getElementById('advaitTrends').innerHTML = '<br/><p>&nbsp;&nbsp;&nbsp; No data found!</p><br/>';
                // }
              } else {
                this.setState({ isSpinner: false });
                document.getElementById('advaitTrends').innerHTML =
                  '<br/><p>&nbsp;&nbsp;&nbsp; No data found!</p><br/>';
              }
              // }, 2000)
            });
          });
        } else {
          this.setState({
            msgStatus: true,
            msgType: 'warning',
            msgContent:
              'From date and to date difference should be greater then 0 & less then 7 !!!',
            isSpinner: false,
          });
        }
      } else if (this.state.selectedDigiTags.length > 0) {
        // alert("Called ")
        let fDT1 = this.state.selectedFrom.split('T');
        let fDT = fDT1[0] + ' ' + fDT1[1] + ':00';
        let tDT1 = this.state.selectedTo.split('T');
        let tDT = tDT1[0] + ' ' + tDT1[1] + ':00';
        const date1 = new Date(this.state.selectedFrom);
        const date2 = new Date(this.state.selectedTo);
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        if (diffDays <= 7 && diffDays >= 0) {
          let reqParams = {
            tags: this.state.selectedDigiTags,
            interval: 0,
            from: fDT,
            to: tDT,
            sorting: 1,
          };
          // alert(JSON.stringify(reqParams));
          this.trendService.getTrends(reqParams).then((res) => {
            console.log('Ana ' + JSON.stringify(res));
            var finalRes = [];
            // if (res && res.length > 0) {
            finalRes = res;
            // }
            // if (this.state.selectedDigiTags.length > 0) {
            let reqParams1 = {
              tags: this.state.selectedTags,
              interval: this.state.selectedInterval,
              from: fDT,
              to: tDT,
              sorting: 1,
            };
            // alert(JSON.stringify(reqParams));
            this.trendService.getTrends(reqParams1).then((res1) => {
              console.log('Dig ' + JSON.stringify(res1));
              // let finalRes = [];
              // if (res && res.length > 0) {
              finalRes = finalRes.concat(res1);
              // }
              console.log('AA ' + JSON.stringify(finalRes));
              // setTimeout(() =>{
              if (finalRes && finalRes.length > 0) {
                let dynSeries = [];
                let dynAxes = [];
                let isNullArr = [];
                let allTags = this.state.selectedDigiTags.concat(
                  this.state.selectedTags
                );
                allTags.map((t, tIndx) => {
                  //*** creating yAxis */
                  let tAxis = {};
                  tAxis['labels'] = {
                    format: '{value}',
                    style: { color: Highcharts.getOptions().colors[tIndx] },
                  };
                  tAxis['title'] = {
                    text: t,
                    style: { color: Highcharts.getOptions().colors[tIndx] },
                  };
                  if (tIndx % 2 === 0) {
                    tAxis['opposite'] = false;
                  } else {
                    tAxis['opposite'] = true;
                  }
                //   tAxis['softMax'] = 125;
                // tAxis['min'] = 0;
                // tAxis['plotLines'] = [{
                //   value: 10,
                //   width: 1,
                //   color: 'rgba(204,0,0,0.75)',
                //   dashStyle: 'ShortDash',
                // }, {
                //   value: 125,
                //   width: 1,
                //   color: '#008000',
                //   dashStyle: 'ShortDash',
                // }]
                  dynAxes.push(tAxis);
                  //*** creating series */
                  let tagSeries = {};
                  tagSeries['name'] = t;
                  tagSeries['type'] = 'spline';
                  tagSeries['yAxis'] = tIndx;
                  tagSeries['data'] = [];
                  tagSeries['color'] = Highcharts.getOptions().colors[tIndx];
                  tagSeries['tooltip'] = { valueSuffix: '' }; //eng unit canbe append here
                  finalRes.map((r) => {
                    // let cTime = new Date(r.date_time).getTime();
                    let cVal = r[t];
                    if (cVal !== null && cVal !== undefined) {
                      const TD = r.date_time.split(' ');
                      const dt = TD[0].split('-');
                      const tm = TD[1].split(':');
                      const year = dt[0];
                      const month = parseInt(dt[1]) - 1;
                      const day = dt[2];
                      const hour = tm[0];
                      const minute = tm[1];
                      const seconds = tm[2];
                      tagSeries['data'].push([
                        Date.UTC(year, month, day, hour, minute, seconds),
                        parseFloat(cVal),
                      ]);
                    }

                    // if(cVal === null){
                    //   isNullArr.push(0);
                    // }else{
                    //   isNullArr.push(1);
                    // }
                  });
                  dynSeries.push(tagSeries);
                });
                console.log(JSON.stringify(dynSeries));
                this.setState({
                  isSpinner: false,
                  series: dynSeries,
                  yAxis: dynAxes,
                });
                // if(isNullArr.includes(1)){
                this.highChartsRender();
                // }else{
                //   console.log('no data..')
                //   this.setState({ isSpinner: false });
                //   document.getElementById('advaitTrends').innerHTML = '<br/><p>&nbsp;&nbsp;&nbsp; No data found!</p><br/>';
                // }
              } else {
                this.setState({ isSpinner: false });
                document.getElementById('advaitTrends').innerHTML =
                  '<br/><p>&nbsp;&nbsp;&nbsp; No data found!</p><br/>';
              }
              // }, 2000)
            });
          });
        } else {
          this.setState({
            msgStatus: true,
            msgType: 'warning',
            msgContent:
              'From date and to date difference should be greater then 0 & less then 7 !!!',
            isSpinner: false,
          });
        }
      } else {
        this.setState({
          msgStatus: true,
          msgType: 'warning',
          msgContent: 'Form is invalid!',
          hasError1: true,
          isSpinner: false,
        });
      }

      // if (this.state.selectedTags.length > 0) {
      //   let fDT1 = this.state.selectedFrom.split('T');
      //   let fDT = fDT1[0] + ' ' + fDT1[1] + ':00';
      //   let tDT1 = this.state.selectedTo.split('T');
      //   let tDT = tDT1[0] + ' ' + tDT1[1] + ':00';
      //   const date1 = new Date(this.state.selectedFrom);
      //   const date2 = new Date(this.state.selectedTo);
      //   const diffTime = Math.abs(date2 - date1);
      //   const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      //   if (diffDays <= 7 && diffDays >= 0) {
      //     let reqParams = { "tags": this.state.selectedTags, "interval": this.state.selectedInterval, "from": fDT, "to": tDT, "sorting": 1 };
      //     // alert(JSON.stringify(reqParams));
      //     this.trendService.getTrends(reqParams).then(res => {
      //       console.log(JSON.stringify(res));
      //       if (res && res.length > 0) {
      //         let dynSeries = [];
      //         let dynAxes = [];
      //         let isNullArr = [];
      //         this.state.selectedTags.map((t, tIndx) => {
      //           //*** creating yAxis */
      //           let tAxis = {};
      //           tAxis['labels'] = { 'format': '{value}', style: { color: Highcharts.getOptions().colors[tIndx] } };
      //           tAxis['title'] = { 'text': t, style: { color: Highcharts.getOptions().colors[tIndx] } };
      //           if (tIndx % 2 === 0) {
      //             tAxis['opposite'] = false;
      //           } else {
      //             tAxis['opposite'] = true;
      //           }
      //           dynAxes.push(tAxis);
      //           //*** creating series */
      //           let tagSeries = {};
      //           tagSeries['name'] = t;
      //           tagSeries['type'] = 'spline';
      //           tagSeries['yAxis'] = tIndx;
      //           tagSeries['data'] = [];
      //           tagSeries['color'] = Highcharts.getOptions().colors[tIndx];
      //           tagSeries['tooltip'] = { 'valueSuffix': '' }; //eng unit canbe append here
      //           res.map(r => {
      //             // let cTime = new Date(r.date_time).getTime();
      //             let cVal = r[t];
      //             if (cVal !== null && cVal !== undefined) {
      //               const TD = r.date_time.split(' ');
      //               const dt = TD[0].split('-');
      //               const tm = TD[1].split(':');
      //               const year = dt[0];
      //               const month = parseInt(dt[1]) - 1;
      //               const day = dt[2];
      //               const hour = tm[0];
      //               const minute = tm[1];
      //               const seconds = tm[2];
      //               tagSeries['data'].push([Date.UTC(year, month, day, hour, minute, seconds), parseFloat(cVal)]);
      //             }

      //             // if(cVal === null){
      //             //   isNullArr.push(0);
      //             // }else{
      //             //   isNullArr.push(1);
      //             // }
      //           });
      //           dynSeries.push(tagSeries);
      //         });
      //         console.log(JSON.stringify(dynSeries));
      //         this.setState({ isSpinner: false, series: dynSeries, yAxis: dynAxes });
      //         // if(isNullArr.includes(1)){
      //         this.highChartsRender();
      //         // }else{
      //         //   console.log('no data..')
      //         //   this.setState({ isSpinner: false });
      //         //   document.getElementById('advaitTrends').innerHTML = '<br/><p>&nbsp;&nbsp;&nbsp; No data found!</p><br/>';
      //         // }
      //       } else {
      //         this.setState({ isSpinner: false });
      //         document.getElementById('advaitTrends').innerHTML = '<br/><p>&nbsp;&nbsp;&nbsp; No data found!</p><br/>';
      //       }
      //     });
      //   } else {
      //     this.setState({ msgStatus: true, msgType: 'warning', msgContent: 'From date and to date difference should be greater then 0 & less then 7 !!!', isSpinner: false });
      //   }
      // } else {
      //   if (this.state.selectedDigiTags.length > 0) {
      //     let fDT1 = this.state.selectedFrom.split('T');
      //     let fDT = fDT1[0] + ' ' + fDT1[1] + ':00';
      //     let tDT1 = this.state.selectedTo.split('T');
      //     let tDT = tDT1[0] + ' ' + tDT1[1] + ':00';
      //     const date1 = new Date(this.state.selectedFrom);
      //     const date2 = new Date(this.state.selectedTo);
      //     const diffTime = Math.abs(date2 - date1);
      //     const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      //     if (diffDays <= 7 && diffDays >= 0) {
      //       let reqParams = { "tags": this.state.selectedTags, "interval": this.state.selectedInterval, "from": fDT, "to": tDT, "sorting": 1 };
      //       // alert(JSON.stringify(reqParams));
      //       this.trendService.getTrends(reqParams).then(res => {
      //         console.log(JSON.stringify(res));
      //         if (res && res.length > 0) {
      //           let dynSeries = [];
      //           let dynAxes = [];
      //           let isNullArr = [];
      //           this.state.selectedTags.map((t, tIndx) => {
      //             //*** creating yAxis */
      //             let tAxis = {};
      //             tAxis['labels'] = { 'format': '{value}', style: { color: Highcharts.getOptions().colors[tIndx] } };
      //             tAxis['title'] = { 'text': t, style: { color: Highcharts.getOptions().colors[tIndx] } };
      //             if (tIndx % 2 === 0) {
      //               tAxis['opposite'] = false;
      //             } else {
      //               tAxis['opposite'] = true;
      //             }
      //             dynAxes.push(tAxis);
      //             //*** creating series */
      //             let tagSeries = {};
      //             tagSeries['name'] = t;
      //             tagSeries['type'] = 'spline';
      //             tagSeries['yAxis'] = tIndx;
      //             tagSeries['data'] = [];
      //             tagSeries['color'] = Highcharts.getOptions().colors[tIndx];
      //             tagSeries['tooltip'] = { 'valueSuffix': '' }; //eng unit canbe append here
      //             res.map(r => {
      //               // let cTime = new Date(r.date_time).getTime();
      //               let cVal = r[t];
      //               if (cVal !== null && cVal !== undefined) {
      //                 const TD = r.date_time.split(' ');
      //                 const dt = TD[0].split('-');
      //                 const tm = TD[1].split(':');
      //                 const year = dt[0];
      //                 const month = parseInt(dt[1]) - 1;
      //                 const day = dt[2];
      //                 const hour = tm[0];
      //                 const minute = tm[1];
      //                 const seconds = tm[2];
      //                 tagSeries['data'].push([Date.UTC(year, month, day, hour, minute, seconds), parseFloat(cVal)]);
      //               }

      //               // if(cVal === null){
      //               //   isNullArr.push(0);
      //               // }else{
      //               //   isNullArr.push(1);
      //               // }
      //             });
      //             dynSeries.push(tagSeries);
      //           });
      //           console.log(JSON.stringify(dynSeries));
      //           this.setState({ isSpinner: false, series: dynSeries, yAxis: dynAxes });
      //           // if(isNullArr.includes(1)){
      //           this.highChartsRender();
      //           // }else{
      //           //   console.log('no data..')
      //           //   this.setState({ isSpinner: false });
      //           //   document.getElementById('advaitTrends').innerHTML = '<br/><p>&nbsp;&nbsp;&nbsp; No data found!</p><br/>';
      //           // }
      //         } else {
      //           this.setState({ isSpinner: false });
      //           document.getElementById('advaitTrends').innerHTML = '<br/><p>&nbsp;&nbsp;&nbsp; No data found!</p><br/>';
      //         }
      //       });
      //     } else {
      //       this.setState({ msgStatus: true, msgType: 'warning', msgContent: 'From date and to date difference should be greater then 0 & less then 7 !!!', isSpinner: false });
      //     }
      //   } else {
      //     this.setState({ msgStatus: true, msgType: 'warning', msgContent: 'Form is invalid!', hasError1: true, errMsg: 'Analog Tag is required!', errDigiMsg: 'Digital Tag is required!', isSpinner: false });
      //   }
      // }
    } else {
      this.setState({
        msgStatus: true,
        msgType: 'warning',
        msgContent: 'Form is invalid!',
        isSpinner: false,
      });
    }
  };

  handlePageChange = (pageNumber) => {
    // alert(pageNumber)
    this.setState({ currentPage: pageNumber });
    this.getTrendByPage(pageNumber);
    // call API to get data based on pageNumber
  };

  handleChange = (event) => {
    // alert(event.target.checked)
    this.setState({ dayWiseChecked: event.target.checked });
    this.state.dayWiseChecked = event.target.checked;
    this.findAndCheckForDateDiff();
    // alert(this.state.dayWiseChecked);
  };

  changeFromDate = (event) => {
    if (event.target.value > this.state.selectedTo) {
      this.setState({ selectedFrom: event.target.value, hasError2: true });
    } else {
      this.setState({ selectedFrom: event.target.value, hasError2: false });
    }
    setTimeout(() => {
      this.findAndCheckForDateDiff();
    }, 10);
  };

  changeToDate = (event) => {
    if (event.target.value < this.state.selectedFrom) {
      this.setState({ selectedTo: event.target.value, hasError3: true });
    } else {
      this.setState({ selectedTo: event.target.value, hasError3: false });
    }
    setTimeout(() => {
      this.findAndCheckForDateDiff();
    }, 10);
  };

  findAndCheckForDateDiff = () => {
    this.state.pagesArray = [];
    let fDT1 = this.state.selectedFrom.split('T');
    let fDT = fDT1[0] + ' ' + fDT1[1] + ':00';
    let tDT1 = this.state.selectedTo.split('T');
    let tDT = tDT1[0] + ' ' + tDT1[1] + ':00';
    const date1 = new Date(this.state.selectedFrom);
    const date2 = new Date(this.state.selectedTo);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    // alert(diffDays)
    this.setState({ totalRecords: diffDays });
    this.state.totalRecords = diffDays;
    // alert(this.state.totalRecords)

    if (!this.state.dayWiseChecked) {
      if (diffDays < 7) {
        this.state.intervals = [
          { label: '5 min', value: 300 },
          { label: '10 min', value: 600 },
          { label: '15 min', value: 900 },
          { label: '30 min', value: 1800 },
          { label: '1 hour', value: 3600 },
        ];
        this.state.selectedInterval = 300;
      } else if (diffDays >= 7 && diffDays < 15) {
        this.state.intervals = [
          { label: '10 min', value: 600 },
          { label: '15 min', value: 900 },
          { label: '30 min', value: 1800 },
          { label: '1 hour', value: 3600 },
          { label: '2 hours', value: 7200 },
          { label: '4 hours', value: 14400 },
          { label: '8 hours', value: 28800 },
          { label: '12 hours', value: 43200 },
          { label: '16 hours', value: 57600 },
          { label: '24 hours', value: 86400 },
        ];
        this.state.selectedInterval = 600;
      } else if (diffDays >= 15 && diffDays < 30) {
        this.state.intervals = [
          { label: '15 min', value: 900 },
          { label: '30 min', value: 1800 },
          { label: '1 hour', value: 3600 },
          { label: '2 hours', value: 7200 },
          { label: '4 hours', value: 14400 },
          { label: '8 hours', value: 28800 },
          { label: '12 hours', value: 43200 },
          { label: '16 hours', value: 57600 },
          { label: '24 hours', value: 86400 },
        ];
        this.state.selectedInterval = 900;
      } else if (diffDays >= 30) {
        this.state.intervals = [
          { label: '30 min', value: 1800 },
          { label: '1 hour', value: 3600 },
          { label: '2 hours', value: 7200 },
          { label: '4 hours', value: 14400 },
          { label: '8 hours', value: 28800 },
          { label: '12 hours', value: 43200 },
          { label: '16 hours', value: 57600 },
          { label: '24 hours', value: 86400 },
        ];
        this.state.selectedInterval = 1800;
      }
    } else if (this.state.dayWiseChecked) {
      let d = this.state.selectedFrom;
      for (let i = 0; i < diffDays; i++) {
        // d = new Date(new Date(this.state.selectedFrom).setDate(new Date(this.state.selectedFrom).getDate() + i));
        // let temp = {}
        // temp[i] = d;
        var date = new Date(this.state.selectedFrom);
        d = new Date(date.setDate(date.getDate() + i));
        var m = d.getMonth() + 1;
        if (m.toString().length < 2) m = '0' + m;
        d = d.getFullYear() + '-' + m + '-' + d.getDate() + 'T00:00';
        this.state.pagesArray.push(d);
      }

      // alert(JSON.stringify(pagesArray))

      this.state.intervals = [
        { label: 'No Interval', value: 0 },
        { label: '1 min', value: 60 },
        { label: '5 min', value: 300 },
        { label: '10 min', value: 600 },
        { label: '15 min', value: 900 },
        { label: '30 min', value: 1800 },
        { label: '1 hour', value: 3600 },
      ];
      this.state.selectedInterval = 300;
    }

    // if (intervals) {
    //   this.state.intervals = intervals.map((el) => <option key={el}>{el}</option>);
    // }

    // alert(JSON.stringify(intervals))
  };

  changeInterval = (newInterval) => {
    if (newInterval === null) {
      this.setState({
        selectedInterval: newInterval,
        hasError4: true,
        errMsg: 'Interval is required!',
      });
    } else {
      this.setState({ selectedInterval: newInterval, hasError4: false });
    }
  };

  changeView = (vID) => {
    this.trendService.getTrendView(vID).then((res) => {
      // alert(JSON.stringify(res))
      if (res && res.analog_tags.length > 0) {
        const resTags = res.analog_tags.map((r) => {
          return Object.assign(
            {},
            {
              value: r,
              label: r,
            }
          );
        });

        var resDigiTags = [];
        if (res.digital_tags.length > 0) {
          resDigiTags = res.digital_tags.map((r) => {
            return Object.assign(
              {},
              {
                value: r,
                label: r,
              }
            );
          });
        }
        // alert(JSON.stringify(resTags))
        this.setState({
          selectedView: res,
          selectedInterval: res.interval,
          selectedTags: res.analog_tags,
          inputValue1: resTags,
          selectedDigiTags: res.digital_tags,
          inputValue3: resDigiTags,
          hasError1: false,
        });
        setTimeout(() => {
          // this.getTrend();
          this.findAndCheckForDateDiff();
          this.getTrendChart();
        }, 10);
      } else if (res && res.digital_tags.length > 0) {
        // alert(JSON.stringify(res))
        var resTags = [];
        if (res.analog_tags.length > 0) {
          resTags = res.analog_tags.map((r) => {
            return Object.assign(
              {},
              {
                value: r,
                label: r,
              }
            );
          });
        }
        const resDigiTags = res.digital_tags.map((r) => {
          return Object.assign(
            {},
            {
              value: r,
              label: r,
            }
          );
        });
        // alert(JSON.stringify(resTags))
        this.setState({
          selectedView: res,
          selectedInterval: res.interval,
          selectedTags: res.analog_tags,
          inputValue1: resTags,
          selectedDigiTags: res.digital_tags,
          inputValue3: resDigiTags,
          hasError1: false,
        });
        setTimeout(() => {
          // this.getTrend();
          this.findAndCheckForDateDiff();
          this.getTrendChart();
        }, 10);
      } else {
        this.setState({
          msgStatus: true,
          msgType: 'warning',
          msgContent:
            'No tags found in this view, please try with another trend view!',
          isSpinner: false,
        });
      }
    });
  };

  changeUnitID = (newUnitObj) => {
    // alert(JSON.stringify(newUnitObj))
    let newUnit = [];
    newUnitObj.map((u) => {
      newUnit.push(u);
    });
    if (newUnit.length > 0) {
      this.setState({
        selectedUnit: newUnit,
        hasError: false,
        errorStyleU: {},
        selectedDigiTags: [],
        selectedTags: [],
        inputValue1: [],
        inputValue3: [],
        hasError1: true,
        errMsg: 'Analog Tag is required!',
      });
      this.state.selectedUnit = newUnit;
      // this.loadAnalogOptions();
      // this.loadDigitalOptions();
    } else {
      this.state.selectedUnit = newUnit;
      this.setState({
        selectedUnit: newUnit,
        selectedDigiTags: [],
        selectedTags: [],
        inputValue1: [],
        inputValue3: [],
        hasError: true,
        hasError1: true,
        errorStyleU: { color: 'red' },
        errMsg: 'Analog Tag is required!',
      });

      // this.loadAnalogOptions();
      // this.loadDigitalOptions();
      // this.getEquipments();
    }
  };

  handleChangeUnit = (event) => {
    const value = event.target.value;
    // alert(JSON.stringify(value));
    if (value[value.length - 1] === 'all') {
      // setSelected(selected.length === options.length ? [] : options);
      let allOpt =
        this.state.unitSelected.length === this.units.length
          ? []
          : this.units;
      this.setState({
        unitSelected: allOpt,
      });

      this.state.unitSelected = allOpt;

      return;
    }
    this.state.unitSelected = value;
    this.setState({ unitSelected: value });
    this.changeUnitID(this.state.unitSelected);
    // alert(JSON.stringify(this.state.selected));
  };

  // changeUnitID = (event) => {
  //   this.setState({ selectedUnit: event.target.value, tagList: [] });
  //   //*** Get tags of selected unit */
  //   setTimeout(() => {
  //     this.trendService.getUnitTags(this.state.selectedUnit).then(res => {
  //       if (res && res.length > 0) {
  //         this.setState({ tagList: res, selectedTags: [], selectedDigiTags: [] });
  //       } else {
  //         this.setState({ tagList: [], selectedTags: [], selectedDigiTags: [], msgStatus: true, msgType: 'error', msgContent: 'No tag found for the selected unit!' });
  //       }
  //     }, 10);
  //   });
  // }

  closeSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ msgStatus: false });
  };

  openDialog = () => {
    if (this.state.selectedTags.length > 0) {
      this.setState({
        openModal: true,
        vName: this.state.selectedView.view_name,
        vSelectedInterval: this.state.selectedInterval,
        vSelectedUnit: this.state.selectedUnit,
        vSelectedTag: this.state.selectedTags,
        vSelectedDigiTag: this.state.selectedDigiTags,
        inputValue2: this.state.inputValue1,
        inputValue4: this.state.inputValue3,
      });
    } else if (this.state.selectedDigiTags.length > 0) {
      this.setState({
        openModal: true,
        vName: this.state.selectedView.view_name,
        vSelectedInterval: this.state.selectedInterval,
        vSelectedUnit: this.state.selectedUnit,
        vSelectedTag: this.state.selectedTags,
        vSelectedDigiTag: this.state.selectedDigiTags,
        inputValue2: this.state.inputValue1,
        inputValue4: this.state.inputValue3,
      });
    } else if (this.state.selectedTags.length <= 0) {
      this.setState({
        openModal: true,
        vName: this.state.selectedView.view_name,
        vSelectedInterval: this.state.selectedInterval,
        vSelectedUnit: this.state.selectedUnit,
        vSelectedTag: this.state.selectedTags,
        vSelectedDigiTag: this.state.selectedDigiTags,
        inputValue4: this.state.inputValue3,
        hasError: true,
        errMsg: 'Analog Tag is required!',
        errDigiMsg: 'Digital Tag is required!',
      });
    } else if (this.state.selectedDigiTags.length <= 0) {
      this.setState({
        openModal: true,
        vName: this.state.selectedView.view_name,
        vSelectedInterval: this.state.selectedInterval,
        vSelectedUnit: this.state.selectedUnit,
        vSelectedTag: this.state.selectedTags,
        vSelectedDigiTag: this.state.selectedDigiTags,
        inputValue2: this.state.inputValue1,
        hasError: true,
        errMsg: 'Analog Tag is required!',
        errDigiMsg: 'Digital Tag is required!',
      });
    }
  };

  closeDialog = () => {
    this.setState({
      openModal: false,
      hasError: false,
      errMsg: '',
      errDigiMsg: '',
      errors: {},
    });
  };

  vNameChange = (event) => {
    this.setState({ vName: event.target.value });
  };

  changeVUnitID = (event) => {
    this.setState({
      vSelectedUnit: event.target.value,
      vTags: [],
      hasError: false,
      errorStyle: {},
    });
    //*** Get tags of selected unit */
    setTimeout(() => {
      this.trendService.getUnitTags(this.state.vSelectedUnit).then((res) => {
        if (res && res.length > 0) {
          this.setState({ vTags: res, vSelectedTag: [], vSelectedDigiTag: [] });
        } else {
          this.setState({
            vTags: [],
            vSelectedTag: [],
            vSelectedDigiTag: [],
            msgStatus: true,
            msgType: 'error',
            msgContent: 'No tag found for the selected unit!',
          });
        }
      }, 10);
    });
  };

  changeVTagList = (event) => {
    this.setState({ vSelectedTag: event.target.value, hasError: false });
  };

  changeVDigiTagList = (event) => {
    this.setState({ vSelectedDigiTag: event.target.value, hasError: false });
  };

  changeVInterval = (newInterval) => {
    this.setState({ vSelectedInterval: newInterval });
  };

  addView = (event) => {
    if (this.handleValidation()) {
      if (this.state.isUpdate) {
        //*** update record */
        let updVparams = {
          _id: this.state.selectedID,
          view_name: this.state.vName,
          analog_tags: this.state.vSelectedTag,
          digital_tags: this.state.vSelectedDigiTag,
          interval: this.state.vSelectedInterval,
        };
        this.trendService.updateTrendView(updVparams).then((res) => {
          console.log(res);
          if (res && res.status === 'success') {
            this.getAllTrendViews();
            this.setState({
              msgStatus: true,
              msgType: 'success',
              msgContent: res.response,
              isUpdate: false,
            });
          } else {
            this.setState({
              msgStatus: true,
              msgType: 'error',
              msgContent: res.response,
            });
          }
        });
      } else {
        //*** add record */
        let addVparams = {
          view_name: this.state.vName,
          analog_tags: this.state.vSelectedTag,
          digital_tags: this.state.vSelectedDigiTag,
          interval: this.state.vSelectedInterval,
        };
        this.trendService.addTrendView(addVparams).then((res) => {
          console.log(res);
          if (res && res.status === 'success') {
            this.getAllTrendViews();
            this.setState({
              msgStatus: true,
              msgType: 'success',
              msgContent: res.response,
            });
          } else {
            this.setState({
              msgStatus: true,
              msgType: 'error',
              msgContent: res.response,
            });
          }
        });
      }
    } else {
      // alert('form is invalid!');
      this.setState({ hasError: true, errorStyle: { color: 'red' } });
    }
  };

  handleValidation = () => {
    let formIsValid = true;
    let errors = {};
    if (!this.state.vName) {
      formIsValid = false;
      errors['name'] = 'Name is required!';
    }
    if (!this.state.vSelectedInterval) {
      if (this.state.vSelectedInterval !== 0) {
        formIsValid = false;
        errors['interval'] = 'Interval is required!';
      }
    }
    if (this.state.vSelectedTag.length === 0) {
      formIsValid = false;
      errors['tags'] = 'Analog Tag is required!';
      if (this.state.vSelectedDigiTag.length === 0) {
        formIsValid = false;
        errors['digiTags'] = 'Digital Tag is required!';
      } else {
        formIsValid = true;
        if (this.state.vSelectedDigiTag.length > 6) {
          formIsValid = false;
          errors['tags'] = '';
          errors['digiTags'] = 'Maximum 6 tags canbe selected!';
        }
      }
    } else {
      if (this.state.vSelectedTag.length > 6) {
        formIsValid = false;
        errors['digiTags'] = '';
        errors['tags'] = 'Maximum 6 tags canbe selected!';
      }
    }

    // if (this.state.vSelectedDigiTag.length === 0) {
    //   formIsValid = false;
    //   errors['digiTags'] = 'Digital Tag is required!';
    // } else {
    //   if (this.state.vSelectedDigiTag.length > 6) {
    //     formIsValid = false;
    //     errors['digiTags'] = 'Maximum 6 tags canbe selected!';
    //   }
    // }
    this.setState({ errors: errors });
    return formIsValid;
  };

  getViewSingleRecord = (id) => {
    this.trendService.getTrendView(id).then((res) => {
      if (res) {
        const resvTags = res.analog_tags.map((r) => {
          return Object.assign(
            {},
            {
              value: r,
              label: r,
            }
          );
        });

        const resvDigiTags = res.digital_tags.map((r) => {
          return Object.assign(
            {},
            {
              value: r,
              label: r,
            }
          );
        });
        this.setState({
          selectedID: id,
          vName: res.view_name,
          vSelectedTag: res.analog_tags,
          vSelectedDigiTag: res.digital_tags,
          vSelectedInterval: res.interval,
          isUpdate: true,
          inputValue2: resvTags,
          inputValue4: resvDigiTags,
        });
      }
    });
  };

  clearView = (event) => {
    this.setState({
      vName: '',
      vSelectedTag: [],
      vSelectedDigiTag: [],
      vSelectedUnit: [],
      vSelectedInterval: '',
      inputValue2: [],
      inputValue4: [],
      isUpdate: false,
      hasError: false,
      errMsg: false,
      errDigiMsg: false,
    });
  };

  handleClose = (event) => {
    this.setState({ openConfirmModal: false });
  };

  openConfirmDialog = (id) => {
    this.setState({ openConfirmModal: true, selectedID: id });
  };

  deleteView = (event) => {
    this.trendService.deleteTrendView(this.state.selectedID).then((res) => {
      if (res && res.status === 'success') {
        this.getAllTrendViews();
        this.setState({
          msgStatus: true,
          msgType: 'success',
          msgContent: res.response,
          openConfirmModal: false,
        });
      } else {
        this.setState({
          msgStatus: true,
          msgType: 'error',
          msgContent: res.response,
          openConfirmModal: false,
        });
      }
    });
  };

  handleChangePage = (event, page) => {
    this.setState({ page: page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  openDeleteAll = (event) => {
    this.setState({ openDeleteAllModal: true });
  };

  handleCloseDeleteAll = (event) => {
    this.setState({ openDeleteAllModal: false });
  };

  deleteAllViews = (event) => {
    this.trendService.deleteAllTrendViews().then((res) => {
      if (res && res.status === 'success') {
        this.getAllTrendViews();
        this.setState({
          msgStatus: true,
          msgType: 'success',
          msgContent: res.response,
          openDeleteAllModal: false,
        });
      } else {
        this.setState({
          msgStatus: true,
          msgType: 'error',
          msgContent: res.response,
          openDeleteAllModal: false,
        });
      }
    });
  };

  loadOptions = (inputValue, callback) => {
    if (!inputValue) {
      callback([]);
    }
    console.log(inputValue);
    setTimeout(() => {
      this.trendService.searchTag({ tag_name: inputValue }).then((res) => {
        console.log('res: ' + JSON.stringify(res));
        if (res && res.length > 0) {
          const result = res.map((r) => {
            return Object.assign(
              {},
              {
                value: r,
                label: r,
              }
            );
          });
          console.log(JSON.stringify(result));
          callback(result);
        } else {
          callback([]);
        }
      });
    }, 100);
  };

  loadAnalogOptions = (inputValue, callback) => {
    if (!inputValue && !this.state.selectedUnit) {
      callback([]);
    }
    // alert(inputValue, JSON.stringify(this.state.selectedUnit));
    setTimeout(() => {
      let result = [];
      this.trendService
        .searchTagUnit({
          tag_name: inputValue,
          unitid: JSON.stringify(this.state.selectedUnit),
          type: 'Analog',
        })
        .then((res) => {
          console.log('res: ' + JSON.stringify(res));
          if (res && res.length > 0) {
            result = res.map((r) => {
              return Object.assign(
                {},
                {
                  value: r,
                  label: r,
                }
              );
            });
            console.log(JSON.stringify(result));
            callback(result);
          } else {
            callback([]);
          }
        });
    }, 100);
  };

  loadDigitalOptions = (inputValue, callback) => {
    if (!inputValue) {
      callback([]);
    }
    console.log(inputValue, JSON.stringify(this.state.selectedUnit));
    setTimeout(() => {
      let result = [];
      this.trendService
        .searchTagUnit({
          tag_name: inputValue,
          unitid: JSON.stringify(this.state.selectedUnit),
          type: 'Digital',
        })
        .then((res) => {
          console.log('res: ' + JSON.stringify(res));
          if (res && res.length > 0) {
            result = res.map((r) => {
              return Object.assign(
                {},
                {
                  value: r,
                  label: r,
                }
              );
            });
            console.log(JSON.stringify(result));
            callback(result);
          } else {
            callback([]);
          }
        });
    }, 100);
  };

  handleTagChange = (inputValue1) => {
    let sTags = [];
    // alert(JSON.stringify(inputValue1))
    let len = inputValue1.length + this.state.selectedDigiTags.length;
    if (inputValue1.length > 0) {
      inputValue1.map((tag, idx) => {
        sTags.push(tag.value);
      });
      if (len > 6) {
        this.setState({
          selectedTags: sTags,
          hasError1: true,
          errMsg: 'Maximum 6 tags canbe selected!',
        });
      } else {
        this.setState({ selectedTags: sTags, hasError1: false });
      }
    } else {
      if (this.state.selectedDigiTags.length > 0) {
        this.setState({ selectedTags: sTags });
      } else {
        this.setState({
          selectedTags: sTags,
          hasError1: true,
          errMsg: 'Analog Tag is required!',
        });
      }
    }
    // const inputValue = newValue.replace(/\W/g, '');
    this.setState({ inputValue1 });
    return inputValue1;
  };

  handleDigiTagChange = (inputValue3) => {
    let sTags = [];
    // alert(JSON.stringify(inputValue3))
    let len = inputValue3.length + this.state.selectedTags.length;
    if (inputValue3.length > 0) {
      inputValue3.map((tag, idx) => {
        sTags.push(tag.value);
      });
      if (len > 6) {
        this.setState({
          selectedDigiTags: sTags,
          hasError1: true,
          errDigiMsg: 'Maximum 6 tags canbe selected!',
        });
      } else {
        this.setState({ selectedDigiTags: sTags, hasError1: false });
      }
    } else {
      if (this.state.selectedTags.length > 0) {
        this.setState({ selectedDigiTags: sTags });
      } else {
        this.setState({
          selectedDigiTags: sTags,
          hasError1: true,
          errDigiMsg: 'Digital Tag is required!',
        });
      }
    }
    // const inputValue = newValue.replace(/\W/g, '');
    this.setState({ inputValue3 });
    return inputValue3;
  };

  handleVtagChange = (inputValue2) => {
    let sTags = [];
    let len = inputValue2.length + this.state.vSelectedDigiTag.length;
    if (inputValue2.length > 0) {
      inputValue2.map((tag, idx) => {
        sTags.push(tag.value);
      });
      if (len > 6) {
        this.setState({
          vSelectedTag: sTags,
          hasError: true,
          errMsg: 'Maximum 6 tags canbe selected!',
        });
      } else {
        this.setState({ vSelectedTag: sTags, hasError: false });
      }
    } else {
      if (this.state.vSelectedDigiTag.length > 0) {
        this.setState({ vSelectedTag: sTags });
      } else {
        this.setState({
          vSelectedTag: sTags,
          hasError: true,
          errMsg: 'Analog Tag is required!',
        });
      }
    }
    // const inputValue = newValue.replace(/\W/g, '');
    this.setState({ inputValue2 });
    return inputValue2;
  };

  handleVDigitagChange = (inputValue4) => {
    let sTags = [];
    let len = inputValue4.length + this.state.vSelectedTag.length;
    if (inputValue4.length > 0) {
      inputValue4.map((tag, idx) => {
        sTags.push(tag.value);
      });
      if (len > 6) {
        this.setState({
          vSelectedDigiTag: sTags,
          hasError: true,
          errDigiMsg: 'Maximum 6 tags canbe selected!',
        });
      } else {
        this.setState({ vSelectedDigiTag: sTags, hasError: false });
      }
    } else {
      if (this.state.vSelectedTag.length > 0) {
        this.setState({ vSelectedDigiTag: sTags });
      } else {
        this.setState({
          vSelectedDigiTag: sTags,
          hasError: true,
          errDigiMsg: 'Digital Tag is required!',
        });
      }
    }
    // const inputValue = newValue.replace(/\W/g, '');
    this.setState({ inputValue4 });
    return inputValue4;
  };

  changeOffset = (selectedOffset) => {
    // alert(selectedOffset);
    this.setState({ selectedOffset: selectedOffset });
    let dt = new Date();
    dt.setHours(dt.getHours() - selectedOffset);
    let tm =
      ('0' + dt.getHours()).slice(-2) + ':' + ('0' + dt.getMinutes()).slice(-2);
    dt = dt.toISOString().split('T')[0];
    let newDate = dt + 'T' + tm;
    // alert(newDate);
    // this.state.selectedFrom = dt + 'T' + tm;

    this.setState({ selectedFrom: newDate });
    this.state.selectedFrom = newDate;
    // alert(this.state.selectedFrom);
    setTimeout(() => {
      this.findAndCheckForDateDiff();
    }, 10);

    // this.setState({ selectedFrom: dt + 'T' + tm });
  };

  ExportToExcel = () => {
    let data = this.exportData;
    if (this.exportData.length > 0) {
      // exportFromJSON({ data, fileName, exportType });

      const ws = XLSX.utils.json_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, fileName);

      // Generate binary string representation of the workbook
      const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

      // Define the filename with .xlsx extension
      const filename = `${fileName}.xlsx`;

      // Save the file using FileSaver
      saveAs(new Blob([wbout], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), filename);
    } else {
      this.setState({
        msgStatus: true,
        msgType: 'warning',
        msgContent: 'No data found!',
        hasError1: true,
        isSpinner: false,
      });
    }
  };

  render() {
    console.log('.. in render..');
    const { classes, defaultOptions, placeholder, inputId } = this.props;
    const {
      dayWiseChecked,
      isSpinner,
      tagList,
      selectedTags,
      selectedDigiTags,
      selectedFrom,
      selectedTo,
      errorStyle,
      errorStyleU,
      hasError,
      selectedInterval,
      errMsg,
      errDigiMsg,
      unitIDs,
      selectedUnit,
      views,
      selectedView,
      vName,
      vTags,
      vSelectedTag,
      vSelectedDigiTag,
      vIntervals,
      vSelectedInterval,
      vUnits,
      vSelectedUnit,
      openModal,
      page,
      rowsPerPage,
      openConfirmModal,
      errors,
      openDeleteAllModal,
      hasError1,
      hasError2,
      hasError3,
      hasError4,
      currentPage,
      recordPerPage,
      totalRecords,
      pageRange,
      intervals,
      offsets,
      selectedOffset,
      unitSelected, unitList, isAllSelected
    } = this.state;
    // const intervals = [{ 'label': 'No Interval', value: 0 }, { 'label': '1 min', value: 60 }, { 'label': '5 min', 'value': 300 }, { 'label': '10 min', 'value': 600 }, { 'label': '15 min', 'value': 900 }, { 'label': '30 min', 'value': 1800 }, { 'label': '1 hour', 'value': 3600 }, { 'label': '2 hours', 'value': 7200 }, { 'label': '4 hours', 'value': 14400 }, { 'label': '8 hours', 'value': 28800 }, { 'label': '12 hours', 'value': 43200 }, { 'label': '16 hours', 'value': 57600 }, { 'label': '24 hours', 'value': 86400 }];
    const intervals1 = [{ label: 'No Interval', value: 0 }];
    return (
      <Wrapper>
        <Dialog
          open={openConfirmModal}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Delete Record?'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure to delete this record?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleClose}
              color="secondary"
              variant="outlined"
            >
              No
            </Button>
            <Button
              onClick={this.deleteView}
              color="primary"
              variant="outlined"
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openDeleteAllModal}
          onClose={this.handleCloseDeleteAll}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {'Delete All Records?'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure to delete all thess records?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleCloseDeleteAll}
              color="secondary"
              variant="outlined"
            >
              No
            </Button>
            <Button
              onClick={this.deleteAllViews}
              color="primary"
              variant="outlined"
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openModal}
          onClose={this.closeDialog}
          aria-labelledby="form-dialog-title"
          maxWidth="lg"
        >
          <DialogTitle id="form-dialog-title">Trend View Settings</DialogTitle>
          <DialogContent>
            <form>
              <FormControl className={classes.textField}>
                <Typography></Typography>
                <TextField
                  id="vName"
                  label="View Name"
                  type="text"
                  required={true}
                  value={vName}
                  style={errorStyle}
                  onChange={(event) => this.vNameChange(event)}
                />
                {hasError && (
                  <FormHelperText style={errorStyle}>
                    {errors['name']}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl className={classes.textField}>
                <Typography>
                  Interval <sup>*</sup>
                </Typography>
                <VirtualizedSelect
                  ref="vInterval"
                  options={intervals1}
                  simpleValue
                  clearable={false}
                  name="select-vInterval"
                  value={vSelectedInterval}
                  onChange={this.changeVInterval}
                  searchable
                  required={true}
                />
                {/* <InputLabel id="vInterval">Interval <sup>*</sup></InputLabel>
                      <Select
                        labelId="vInterval"
                        id="vInterval-select"
                        value={vSelectedInterval}
                        required={true}
                        onChange={this.changeVInterval}
                      >
                        <MenuItem value={0}>No Interval</MenuItem>
                        <MenuItem value={60}>1 min</MenuItem>
                        <MenuItem value={300}>5 min</MenuItem>
                        <MenuItem value={600}>10 min</MenuItem>
                        <MenuItem value={900}>15 min</MenuItem>
                        <MenuItem value={1800}>30 min</MenuItem>
                        <MenuItem value={3600}>1 hour</MenuItem>
                        <MenuItem value={7200}>2 hour</MenuItem>
                        <MenuItem value={14400}>4 hour</MenuItem>
                        <MenuItem value={28800}>8 hour</MenuItem>
                        <MenuItem value={43200}>12 hour</MenuItem>
                        <MenuItem value={57600}>16 hour</MenuItem>
                        <MenuItem value={86400}>24 hour</MenuItem>
                      </Select> */}
                {hasError && (
                  <FormHelperText style={errorStyle}>
                    {errors['interval']}
                  </FormHelperText>
                )}
              </FormControl>
              {/* <FormControl className={classes.textField}>
                    <InputLabel id="vUnitID">Unit Id</InputLabel>
                      <Select
                        multiple
                        labelId="vUnitID"
                        id="vUnitID-select"
                        value={vSelectedUnit}
                        onChange={ (event) => this.changeVUnitID(event)}
                        input={<Input />}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                        title={vSelectedUnit}
                      >
                        {vUnits.map( (u, i) => (
                          <MenuItem key={i} value={u}>
                            <Checkbox checked={vSelectedUnit.indexOf(u) > -1} color="primary" />
                            <ListItemText primary={u} />
                          </MenuItem>
                        ))}
                      </Select>
                  </FormControl> */}
              <FormControl className={classes.textField2}>
                <Typography>
                  Analog Tags <sup>*</sup>
                </Typography>
                <AsyncSelect
                  isMulti
                  cacheOptions
                  defaultOptions={this.state.vSelectedTag}
                  loadOptions={this.loadAnalogOptions}
                  value={this.state.inputValue2}
                  onChange={this.handleVtagChange}
                />
                {/* <InputLabel id="vTag">Tags <sup>*</sup> </InputLabel>
                      <Select
                        multiple
                        labelId="vTag"
                        id="vTag-select"
                        value={vSelectedTag}
                        onChange={ (event) => this.changeVTagList(event)}
                        input={<Input />}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                        title={vSelectedTag}
                      >
                        {vTags.map( (u, i) => (
                          <MenuItem key={i} value={u}>
                            <Checkbox checked={vSelectedTag.indexOf(u) > -1} color="primary" />
                            <ListItemText primary={u} />
                          </MenuItem>
                        ))}
                      </Select> */}
                {hasError && (
                  <FormHelperText style={errorStyle}>
                    {errors['tags']}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl className={classes.textField2}>
                <Typography>
                  Digital Tags <sup>*</sup>
                </Typography>
                <AsyncSelect
                  isMulti
                  cacheOptions
                  defaultOptions={this.state.vSelectedDigiTag}
                  loadOptions={this.loadDigitalOptions}
                  value={this.state.inputValue4}
                  onChange={this.handleVDigitagChange}
                />
                {hasError && (
                  <FormHelperText style={errorStyle}>
                    {errors['digiTags']}
                  </FormHelperText>
                )}
              </FormControl>
            </form>
            {/* </DialogContent>
          <DialogActions> */}
            <br></br>
            <div style={{ float: 'right', margin: '10px' }}>
              <Button
                style={{ margin: '5px', backgroundColor: 'rgb(0, 153, 204)' }}
                onClick={this.addView}
                variant="contained"
                color="primary"
              >
                Submit
              </Button>
              <Button
                style={{ margin: '5px', backgroundColor: 'rgb(0, 153, 204)' }}
                onClick={this.clearView}
                variant="contained"
                color="primary"
              >
                Clear
              </Button>
              <Button
                style={{ margin: '5px' }}
                onClick={this.openDeleteAll}
                variant="contained"
                color="secondary"
              >
                Delete All
              </Button>
              <Button
                style={{ margin: '5px' }}
                onClick={this.closeDialog}
                variant="contained"
                color="secondary"
              >
                Cancel
              </Button>
            </div>
            {/* </DialogActions> */}
            {/* <DialogContent> */}
            <Paper className={classes.root}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>S/N</TableCell>
                    <TableCell>View Name</TableCell>
                    {/* <TableCell>Tags</TableCell> */}
                    <TableCell>Interval (in seconds)</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                {isSpinner ? (
                  <CircularProgress
                    className={classes.progress}
                    color="primary"
                    size={50}
                    thickness={3}
                  />
                ) : (
                  <TableBody>
                    {views.length > 0 ? (
                      views
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, idx) => {
                          return (
                            <TableRow key={idx}>
                              <TableCell>{idx + 1}</TableCell>
                              <TableCell>{row.view_name}</TableCell>
                              {/* <TableCell>{row.tags.join(',')}</TableCell> */}
                              <TableCell>{row.interval}</TableCell>
                              <TableCell>
                                <EditIcon
                                  title="Edit Record"
                                  color="primary"
                                  style={{
                                    cursor: 'pointer',
                                    color: 'rgb(0, 153, 204)',
                                  }}
                                  onClick={() =>
                                    this.getViewSingleRecord(row._id)
                                  }
                                />
                                &nbsp;&nbsp;
                                <DeleteIcon
                                  title="Delete Record"
                                  color="secondary"
                                  style={{ cursor: 'pointer' }}
                                  onClick={() =>
                                    this.openConfirmDialog(row._id)
                                  }
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })
                    ) : (
                      <p>No data found!</p>
                    )}
                  </TableBody>
                )}
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      colSpan={5}
                      count={views.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActionsWrapped}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </Paper>
          </DialogContent>
        </Dialog>
        {/* <Card>
          <CardContent style={{ padding:'10px' }}> */}
        <Paper className={classes.root}>
          {this.state.msgStatus ? (
            <CustomizedSnackbars
              opened={this.state.msgStatus}
              snackType={this.state.msgType}
              snackMessage={this.state.msgContent}
              closed={this.closeSnackBar}
            ></CustomizedSnackbars>
          ) : null}
          <form className={classes.container}>
            <div className="row" style={divStyle}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={dayWiseChecked}
                    onChange={(event) => this.handleChange(event)}
                    name="dayWiseChecked"
                    color="primary"
                  />
                }
                label="Day-wise"
              />
              <FormControl className={classes.textField}>
                <Typography>Offset</Typography>
                <VirtualizedSelect
                  ref="offsets"
                  options={offsets}
                  simpleValue
                  clearable={false}
                  name="select-offset"
                  value={selectedOffset}
                  onChange={this.changeOffset}
                  searchable
                />
                {/* <InputLabel id="view">Select View</InputLabel>
                <Select
                  labelId="view"
                  id="view-select"
                  value={selectedView}
                  onChange={this.changeView}
                >
                  {views.map((v, idx) => {
                    return <MenuItem value={v} key={idx}>{v.view_name}</MenuItem>
                  })}
                </Select> */}
              </FormControl>

              <FormControl className={classes.textField}>
                <Typography>&nbsp;&nbsp;From</Typography>
                <TextField
                  id="date1"
                  label=""
                  type="datetime-local"
                  error={hasError2}
                  value={selectedFrom}
                  className={classes.textField}
                  isRequired={true}
                  onChange={(event) => this.changeFromDate(event)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {hasError2 && (
                  <FormHelperText style={errorStyle}>
                    This can not be greater than to date!
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl className={classes.textField}>
                <Typography>&nbsp;&nbsp;To</Typography>
                <TextField
                  id="date2"
                  label=""
                  type="datetime-local"
                  error={hasError3}
                  defaultValue={selectedTo}
                  value={selectedTo}
                  className={classes.textField}
                  isRequired={true}
                  onChange={(event) => this.changeToDate(event)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {hasError3 && (
                  <FormHelperText style={errorStyle}>
                    This can not be less than from date!
                  </FormHelperText>
                )}
              </FormControl>


              {/* <FormControl className={classes.textField2}>
                <Typography style={errorStyleU}>
                  Unit Id <sup>*</sup>
                </Typography>
                <VirtualizedSelect
                  ref="unitIDs"
                  options={unitIDs}
                  clearable={true}
                  multi={true}
                  name="select-unitIDs"
                  value={selectedUnit}
                  onChange={(event) => this.changeUnitID(event)}
                  searchable
                  required={true}
                  placeholder="Search/Select Units"
                />
                {hasError && (
                  <FormHelperText style={errorStyleU}>
                    Unit Id is required!
                  </FormHelperText>
                )}
              </FormControl> */}

              <FormControl className={classes.textField2}>
              {/* <InputLabel style={errorStyle} id="mutiple-select-label">
                  Unit Id<sup>*</sup>
                </InputLabel> */}
              <Typography style={errorStyleU}>
                Unit Id <sup>*</sup>
              </Typography>
              <Select
                labelId="mutiple-select-label"
                multiple
                value={unitSelected}
                onChange={this.handleChangeUnit}
                renderValue={(unitSelected) => unitSelected.join(', ')}
                MenuProps={MenuProps}
              >
                <MenuItem
                  value="all"
                  classes={{
                    root: isAllSelected ? classes.selectedAll : '',
                  }}
                >
                  <ListItemIcon>
                    <Checkbox
                      classes={{ indeterminate: classes.indeterminateColor }}
                      checked={isAllSelected}
                      indeterminate={
                        unitSelected.length > 0 &&
                        unitSelected.length < unitList.length
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    classes={{ primary: classes.selectAllText }}
                    primary="Select All"
                  />
                </MenuItem>
                {unitList.map((option) => (
                  <MenuItem key={option} value={option}>
                    <ListItemIcon>
                      <Checkbox checked={unitSelected.indexOf(option) > -1} />
                    </ListItemIcon>
                    <ListItemText primary={option} />
                  </MenuItem>
                ))}
              </Select>
              {hasError && (
                <FormHelperText style={errorStyleU}>
                  Unit Id is required!
                </FormHelperText>
              )}
            </FormControl>




              <FormControl className={classes.textField2}>
                <Typography>
                  Analog Tags <sup>*</sup>
                </Typography>
                <AsyncSelect
                  isMulti
                  cacheOptions
                  loadOptions={this.loadAnalogOptions}
                  value={this.state.inputValue1}
                  onChange={this.handleTagChange}
                />
                {hasError1 && (
                  <FormHelperText style={errorStyle}>{errMsg}</FormHelperText>
                )}
              </FormControl>
              <FormControl className={classes.textField2}>
                <Typography>
                  Digital Tags <sup>*</sup>
                </Typography>
                <AsyncSelect
                  isMulti
                  cacheOptions
                  loadOptions={this.loadDigitalOptions}
                  value={this.state.inputValue3}
                  onChange={this.handleDigiTagChange}
                />
                {/* <InputLabel id="tags">Tags <sup>*</sup> </InputLabel>
                <Select
                  multiple
                  labelId="tags"
                  id="tags-select"
                  value={selectedTags}
                  onChange={ (event) => this.changeTagList(event)}
                  input={<Input />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                  title={selectedTags}
                >
                  {tagList.map( (u, i) => (
                    <MenuItem key={i} value={u}>
                      <Checkbox checked={selectedTags.indexOf(u) > -1} color="primary" />
                      <ListItemText primary={u} />
                    </MenuItem>
                  ))}
                </Select> */}
                {hasError1 && (
                  <FormHelperText style={errorStyle}>
                    {errDigiMsg}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl className={classes.textField3}>
                <Typography>
                  Interval <sup>*</sup>
                </Typography>
                <VirtualizedSelect
                  ref="interval"
                  options={intervals}
                  simpleValue
                  clearable={false}
                  name="select-interval"
                  value={selectedInterval}
                  onChange={this.changeInterval}
                  searchable
                />
                {hasError4 && (
                  <FormHelperText style={errorStyle}>{errMsg}</FormHelperText>
                )}
                {/* <InputLabel id="prio">Interval</InputLabel>
                <Select
                  labelId="interval"
                  id="interval-select"
                  value={selectedInterval}
                  onChange={this.changeInterval}
                >
                  <MenuItem value={0}>No Interval</MenuItem>
                  <MenuItem value={60}>1 min</MenuItem>
                  <MenuItem value={300}>5 min</MenuItem>
                  <MenuItem value={600}>10 min</MenuItem>
                  <MenuItem value={900}>15 min</MenuItem>
                  <MenuItem value={1800}>30 min</MenuItem>
                  <MenuItem value={3600}>1 hour</MenuItem>
                  <MenuItem value={7200}>2 hour</MenuItem>
                  <MenuItem value={14400}>4 hour</MenuItem>
                  <MenuItem value={28800}>8 hour</MenuItem>
                  <MenuItem value={43200}>12 hour</MenuItem>
                  <MenuItem value={57600}>16 hour</MenuItem>
                  <MenuItem value={86400}>24 hour</MenuItem>
                </Select> */}
              </FormControl>
              <FormControl>
                <Typography>&nbsp;</Typography>
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  title="Get Trend"
                  onClick={this.getTrendChart}
                  style={{ backgroundColor: 'rgb(0, 153, 204' }}
                >
                  Get Trend
                </Button>
              </FormControl>

              {/* <Icon className={classes.rightIcon}>timeline</Icon> */}
              {/* </div> */}
              {/* <br></br> */}
              {/* <div className="row"> */}
              <FormControl className={classes.textField}>
                <Typography>View</Typography>
                <VirtualizedSelect
                  ref="views"
                  options={views}
                  simpleValue
                  clearable={false}
                  name="select-view"
                  value={selectedView}
                  onChange={this.changeView}
                  searchable
                  labelKey="view_name"
                  valueKey="_id"
                />
                {/* <InputLabel id="view">Select View</InputLabel>
                <Select
                  labelId="view"
                  id="view-select"
                  value={selectedView}
                  onChange={this.changeView}
                >
                  {views.map((v, idx) => {
                    return <MenuItem value={v} key={idx}>{v.view_name}</MenuItem>
                  })}
                </Select> */}
              </FormControl>
              <FormControl>
                <Typography>&nbsp;</Typography>
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  title="Set Trend View"
                  onClick={this.openDialog}
                  style={{ backgroundColor: 'rgb(0, 153, 204' }}
                >
                  Trend View Setting
                </Button>
              </FormControl>

              <FormControl style={{ marginLeft: '10px' }}>
                <Typography>&nbsp;</Typography>
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  title="Export"
                  onClick={this.ExportToExcel}
                  style={{ backgroundColor: 'rgb(0, 153, 204' }}
                >
                  Export To Excel
                </Button>
              </FormControl>
            </div>
          </form>
        </Paper>
        {/* </CardContent>
        </Card> */}
        <Paper className={classes.root}>
          {isSpinner ? (
            <CircularProgress
              className={classes.progress}
              color="primary"
              size={50}
              thickness={3}
            />
          ) : (
            <div id="advaitTrends"></div>
          )}
        </Paper>
        <br></br>
        {dayWiseChecked ? (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Pagination
              itemClass="page-item" // add it for bootstrap 4
              linkClass="page-link" // add it for bootstrap 4
              activePage={currentPage}
              itemsCountPerPage={recordPerPage}
              totalItemsCount={totalRecords}
              // pageRangeDisplayed={pageRange}
              onChange={this.handlePageChange}
            />
          </div>
        ) : null}
      </Wrapper>
    );
  }
}

Trends.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Trends);

/******** Pagination for Table */
const actionsStyles = (theme) => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 2.5,
  },
});
class TablePaginationActions extends React.Component {
  handleFirstPageButtonClick = (event) => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = (event) => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1)
    );
  };

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
};

const TablePaginationActionsWrapped = withStyles(actionsStyles, {
  withTheme: true,
})(TablePaginationActions);
