import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Wrapper } from '../../components';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Card } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import AlarmService from '../../services/alarms/alarm-service';
import { CustomizedSnackbars } from '../../shared/snackbar-service';
import './Alarms.css';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import CircularProgress from '@material-ui/core/CircularProgress';
// import ConfirmDialog from '../../shared/confirmationBox';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import VirtualizedSelect from 'react-virtualized-select';
import 'react-select/dist/react-select.css';
import 'react-virtualized/styles.css';
import 'react-virtualized-select/styles.css';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Icon from '@material-ui/core/Icon';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import FilterListIcon from '@material-ui/icons/FilterList';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import FormHelperText from '@material-ui/core/FormHelperText';
import exportFromJSON from 'export-from-json';
import ListItemIcon from '@material-ui/core/ListItemIcon';

//*** Enhanced table: start */
function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  {
    id: 'date_time',
    numeric: false,
    disablePadding: false,
    label: 'Date Time',
  },
  { id: 'tag_name', numeric: false, disablePadding: false, label: 'Tag' },
  {
    id: 'alarm_desc',
    numeric: false,
    disablePadding: false,
    label: 'Description',
  },
  { id: 'value', numeric: false, disablePadding: false, label: 'Value' },
  { id: 'priority', numeric: false, disablePadding: false, label: 'Priority' },
  { id: 'unit_id', numeric: false, disablePadding: false, label: 'Unit' },
  {
    id: 'equipment_id',
    numeric: false,
    disablePadding: false,
    label: 'Equipment',
  },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
  { id: 'is_ack', numeric: false, disablePadding: false, label: 'Is Ack' },
  {
    id: 'acknowledged_by',
    numeric: false,
    disablePadding: false,
    label: 'Ack By',
  },
  {
    id: 'acknowledged_at',
    numeric: false,
    disablePadding: false,
    label: 'Ack At',
  },
];

class EnhancedTableHead extends React.Component {
  createSortHandler = (property) => (event) => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
    } = this.props;

    return (
      <TableHead>
        <TableRow style={{ backgroundColor: '#BBB', fontSize: '18px' }}>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
              color="default"
            />
          </TableCell>
          {rows.map((row) => {
            return (
              <TableCell
                key={row.id}
                numeric={row.numeric}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false}
                style={{ color: '#333' }}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = (theme) => ({
  root: {
    paddingRight: theme.spacing.unit,
    minHeight: '30px',
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.primary.main,
        backgroundColor: lighten(theme.palette.primary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.primary.dark,
      },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.primary,
  },
  title: {
    flex: '0 0 auto',
  },
});

let EnhancedTableToolbar = (props) => {
  const { numSelected, classes, onAck } = props;

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subheading">
            {numSelected} selected
          </Typography>
        ) : (
            <Typography variant="title" id="tableTitle">
              Alarms
          </Typography>
          )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {/*{numSelected > 0 ? (
          <Tooltip title="Acknowledge Alarm">
            <IconButton aria-label="Acknowledge">
              <CheckCircleIcon onClick={onAck} color="primary"/>
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton aria-label="Filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}*/}
        <Tooltip title="Filter list">
          <IconButton aria-label="Filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onAck: PropTypes.func,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

//*** Enhanced table: end */

const actionsStyles = (theme) => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 2.5,
  },
});

class TablePaginationActions extends React.Component {
  handleFirstPageButtonClick = (event) => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = (event) => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1)
    );
  };

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowRight />
          ) : (
              <KeyboardArrowLeft />
            )}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowLeft />
          ) : (
              <KeyboardArrowRight />
            )}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
};

const TablePaginationActionsWrapped = withStyles(actionsStyles, {
  withTheme: true,
})(TablePaginationActions);

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 1,
  },
  table: {
    minWidth: 500,
    color: '#FFF',
    width: '100%',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  progress: {
    marginLeft: '50%',
    position: 'absolute',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: theme.spacing.unit * 1,
    paddingBottom: '5px',
  },
  textField1: {
    marginLeft: theme.spacing.unit * 1,
    marginRight: theme.spacing.unit * 1,
    width: 220,
  },
  textField2: {
    marginTop: theme.spacing.unit * 2,
    marginLeft: theme.spacing.unit * 1,
    marginRight: theme.spacing.unit * 1,
    width: 550,
  },
  textField3: {
    marginLeft: theme.spacing.unit * 1,
    marginRight: theme.spacing.unit * 1,
    width: 130,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  variant: 'menu',
};

const fileName = 'historical_alarms';
const exportType = 'xls';

class Alarms extends Component {
  constructor(props) {
    super(props);
    this.alarmService = new AlarmService();
    this.intervalId = 0;
    let dt = new Date();
    let tm =
      ('0' + dt.getHours()).slice(-2) + ':' + ('0' + dt.getMinutes()).slice(-2);
    dt = dt.toISOString().split('T')[0];
    this.units = localStorage.getItem('User');
    this.units = JSON.parse(JSON.parse(this.units)['unit_id']);
    this.exportData = [];
    const unitIds = this.units.map((u) => {
      return Object.assign(
        {},
        {
          value: u,
          label: u,
        }
      );
    });
    this.state = {
      rows: [],
      msgStatus: false,
      msgType: '',
      msgContent: '',
      unitIDs: unitIds,
      equipIDs: [{ label: 'ALL', value: 'ALL' }],
      page: 0,
      rowsPerPage: 50,
      isSpinner: false,
      open: false,
      selectedUnit: this.units,
      selectedUnitObj: unitIds,
      selectedEquip: 'ALL',
      selectedPriority: 'ALL',
      selectedAck: 'ALL',
      selectedFrom: dt + 'T00:00',
      selectedTo: dt + 'T' + tm,
      order: 'desc',
      orderBy: 'date_time',
      selected: [],
      hasError: false,
      errorStyle: {},
      hasError1: false,
      errorStyle1: { color: 'red' },
      hasError2: false,
      inputValue: '',
      hasError3: false,
      hasError4: false,
      hasError5: false,
      errorStyle3: {},
      errorStyle4: {},
      errorStyle5: {},
      totalRowCount: 0,
      unitSelected: this.units,
      isAllSelected: false,
      unitList: this.units
    };
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }
    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState((state) => ({ selected: state.rows.map((n) => n._id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({ selected: newSelected });
  };

  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  handleChangePage = (event, page) => {
    // alert(page)
    this.setState({ page: page, selected: [] });
    this.getAlarmsByPagination(page);
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  componentDidMount() {
    this.getLatestAlarms();
    this.getEquipments();
    // this.getFilteredAlarms();
    // this.getInitialTotalCount();
    // this.intervalId = setInterval(this.getLatestAlarms, 20000); // 5sec = 5000
  }

  componentWillUnmount() {
    console.log('interval end: ' + this.intervalId);
    clearInterval(this.intervalId);
  }

  getEquipments() {
    this.state.selectedEquip = [];
    const eqpObj = [{ value: 'ALL', label: 'ALL' }];
    this.alarmService
      .getEquipmentsByUnit(this.state.selectedUnit)
      .then((res) => {
        if (res && res.length > 0) {
          // const eqpObj = res.map(e => {
          //   return Object.assign({}, {
          //     value: e,
          //     label: e
          //   });
          // });
          res.map((e) => {
            eqpObj.push({
              label: e,
              value: e,
            });
          });
          this.setState({ equipIDs: eqpObj });
          this.state.selectedEquip = 'ALL';
          // this.getFilteredAlarms();
          this.getInitialTotalCount();
        } else {
          this.setState({ equipIDs: eqpObj });
          setTimeout(() => {
            this.state.selectedEquip = 'ALL';
            // this.getFilteredAlarms();
            this.getInitialTotalCount();
          }, 100);
        }
      });
  }

  getLatestAlarms = () => {
    // this.exportData = [];
    this.setState({ isSpinner: true });
    let reqParams = { unit_id: this.state.selectedUnit, no_of_records: 50 };
    // alert(JSON.stringify(reqParams));
    this.alarmService.last5alarms(reqParams).then((res) => {
      if (res && res.length > 0) {
        this.setState({ totalRowCount: res.length });
        this.setState({ rows: res, isSpinner: false });

        // res.map((p) => {
        //   let temp = {};
        //   temp['Date Time'] = p.date_time;
        //   temp['Tag'] = p.tag_name;
        //   temp['Description'] = p.alarm_desc;
        //   temp['Value'] = p.value;
        //   temp['Priority'] = p.priority;
        //   temp['Unit'] = p.unit_id;
        //   temp['Equipment'] = p.equipment_id;
        //   temp['Status'] = p.status;
        //   temp['Is Ack'] = p.is_ack;
        //   if (p.acknowledged_by != undefined) {
        //     temp['Ack By'] = p.acknowledged_by;
        //   } else temp['Ack By'] = '';
        //   if (p.acknowledged_at != undefined) {
        //     temp['Ack At'] = p.acknowledged_at;
        //   } else temp['Ack At'] = '';
        //   // temp['Port Number'] = p.port;

        //   this.exportData.push(temp);
        // });
      } else {
        this.setState({ totalRowCount: 0 });
        // this.setState({ msgStatus: true, msgType:'error', msgContent:'No data found!', rows: [], isSpinner:false });
        this.setState({ rows: [], isSpinner: false });
      }
    });
  };

  getBackgoundColor(r) {
    if (r.status === 'RTN') {
      return '#49CC90'; //Green #49CC90
    } else if (r.status === 'ALM') {
      if (r.priority === 'High') {
        return '#fc5a23'; //Orange #FF4400
      } else if (r.priority === 'Low') {
        return '#FCA130'; //Yellow #FFBB00 #FC9403 #FCA130
      } else {
        return '#F44336'; //Red  #D50000  #FF1100 #F93E3E
      }
    }
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  openDialog = () => {
    this.setState({ open: true });
  };

  acknowledgeAlarm = () => {
    let ackReq = [];
    this.state.selected.map((s) => {
      ackReq.push({ _id: s, app_type: 'ADVAIT_HMI' });
    });
    // alert(JSON.stringify(ackReq));
    this.alarmService.ackAlarms(ackReq).then((res) => {
      if (res.length > 0) {
        // this.getFilteredAlarms();
        this.getTotalCount();
        this.setState({
          open: false,
          msgStatus: true,
          msgType: 'success',
          msgContent: 'Alarm acknowledged successfully!',
        });
      } else {
        this.setState({
          open: false,
          msgStatus: true,
          msgType: 'error',
          msgContent: JSON.stringify(res),
        });
      }
    });
  };

  changeFromDate = (event) => {
    if (event.target.value > this.state.selectedTo) {
      this.setState({ selectedFrom: event.target.value, hasError1: true });
    } else {
      this.setState({ selectedFrom: event.target.value, hasError1: false });
    }
  };

  changeToDate = (event) => {
    if (event.target.value < this.state.selectedFrom) {
      this.setState({ selectedTo: event.target.value, hasError2: true });
    } else {
      this.setState({ selectedTo: event.target.value, hasError2: false });
    }
  };

  changeUnitID = (newUnitObj) => {
    // alert(JSON.stringify(newUnitObj))
    let newUnit = [];
    newUnitObj.map((u) => {
      newUnit.push(u);
    });
    if (newUnit.length > 0) {
      this.setState({
        selectedUnit: newUnit,
        selectedUnitObj: newUnitObj,
        hasError: false,
        errorStyle: {},
      });
      this.state.selectedUnit = newUnit;
      this.getEquipments();
    } else {
      this.setState({
        selectedUnit: newUnit,
        selectedUnitObj: newUnitObj,
        hasError: true,
        errorStyle: { color: 'red' },
      });
      this.state.selectedUnit = newUnit;
      this.getEquipments();
    }
  };


  handleChangeUnit = (event) => {
    const value = event.target.value;
    // alert(JSON.stringify(value));
    if (value[value.length - 1] === 'all') {
      // setSelected(selected.length === options.length ? [] : options);
      let allOpt =
        this.state.unitSelected.length === this.units.length
          ? []
          : this.units;
      this.state.unitSelected = allOpt;
      this.setState({
        unitSelected: allOpt,
      });


      return;
    }
    this.state.unitSelected = value;
    this.setState({ unitSelected: value });
    this.changeUnitID(this.state.unitSelected);
    // alert(JSON.stringify(this.state.selected));
  };

  changeEquipID = (newEqp) => {
    if (newEqp) {
      this.setState({
        selectedEquip: newEqp,
        hasError3: false,
        errorStyle3: {},
      });
    } else {
      this.setState({
        selectedEquip: newEqp,
        hasError3: true,
        errorStyle3: { color: 'red' },
      });
    }
  };

  changePriority = (newPrio) => {
    if (newPrio) {
      this.setState({
        selectedPriority: newPrio,
        hasError4: false,
        errorStyle4: {},
      });
    } else {
      this.setState({
        selectedPriority: newPrio,
        hasError4: true,
        errorStyle4: { color: 'red' },
      });
    }
    // this.setState( { selectedPriority:newPrio });
  };

  changeAck = (newAck) => {
    if (newAck) {
      this.setState({ selectedAck: newAck, hasError5: false, errorStyle5: {} });
    } else {
      this.setState({
        selectedAck: newAck,
        hasError5: true,
        errorStyle5: { color: 'red' },
      });
    }
    // this.setState( { selectedAck:newAck });
  };

  getTotalCount = () => {
    // this.getFilteredAlarms();
    this.getInitialTotalCount();
    this.setState({ totalRowCount: 0, page: 0, rowsPerPage: 50 });
    if (
      !this.state.hasError &&
      !this.state.hasError1 &&
      !this.state.hasError2 &&
      !this.state.hasError3 &&
      !this.state.hasError4 &&
      !this.state.hasError5
    ) {
      this.setState({ isSpinner: true });
      let fDT1 = this.state.selectedFrom.split('T');
      let fDT = fDT1[0] + ' ' + fDT1[1] + ':00';
      let tDT1 = this.state.selectedTo.split('T');
      let tDT = tDT1[0] + ' ' + tDT1[1] + ':00';
      //*** Getting difference between two dates */
      const d1 = new Date(fDT1[0]);
      const d2 = new Date(tDT1[0]);
      let diffInTime = d2.getTime() - d1.getTime();
      let diffInDays = diffInTime / (1000 * 3600 * 24);
      if (diffInDays > 31) {
        this.setState({
          msgStatus: true,
          msgType: 'error',
          msgContent:
            'Difference between two dates should not more than 1 month !!!',
          rows: [],
          isSpinner: false,
        });
      } else {
        let reqParams = {
          unit_id: this.state.selectedUnit,
          equipment_id: this.state.selectedEquip,
          priority: this.state.selectedPriority,
          is_ack: this.state.selectedAck,
          from: fDT,
          to: tDT,
        };
        this.alarmService.getAlarmCount(reqParams).then((res) => {
          // alert("history alarms: " + JSON.stringify(res));
          this.setState({ totalRowCount: res.count });
          this.getAlarmsByPagination(0);
          // if(res.length > 0){
          //   this.setState({ rows: res, isSpinner:false });
          // }else{
          //   this.setState({ msgStatus: true, msgType:'error', msgContent:'No data found!', rows: [], isSpinner:false });
          //   // this.setState({ rows: [], isSpinner:false });
          // }
        });
      }
    } else {
      this.setState({
        msgStatus: true,
        msgType: 'warning',
        msgContent: 'Form is invalid!',
        rows: [],
        isSpinner: false,
      });
    }
    // this.getInitialTotalCount();
  };

  getAlarmsByPagination = (page) => {
    // alert(page)
    // this.exportData = [];
    let fDT1 = this.state.selectedFrom.split('T');
    let fDT = fDT1[0] + ' ' + fDT1[1] + ':00';
    let tDT1 = this.state.selectedTo.split('T');
    let tDT = tDT1[0] + ' ' + tDT1[1] + ':00';
    let reqParams = {
      unit_id: this.state.selectedUnit,
      equipment_id: this.state.selectedEquip,
      priority: this.state.selectedPriority,
      is_ack: this.state.selectedAck,
      from: fDT,
      to: tDT,
      page_no: page,
      no_of_records: this.state.rowsPerPage,
    };
    // alert(JSON.stringify(reqParams));
    this.alarmService.getAlarmsByPage(reqParams).then((res) => {
      // alert("history alarms: " + JSON.stringify(res));
      if (res.length > 0) {
        this.setState({ rows: res, isSpinner: false });
        // res.map((p) => {
        //   let temp = {};
        //   temp['Date Time'] = p.date_time;
        //   temp['Tag'] = p.tag_name;
        //   temp['Description'] = p.alarm_desc;
        //   temp['Value'] = p.value;
        //   temp['Priority'] = p.priority;
        //   temp['Unit'] = p.unit_id;
        //   temp['Equipment'] = p.equipment_id;
        //   temp['Status'] = p.status;
        //   temp['Is Ack'] = p.is_ack;
        //   if (p.acknowledged_by != undefined) {
        //     temp['Ack By'] = p.acknowledged_by;
        //   } else temp['Ack By'] = '';
        //   if (p.acknowledged_at != undefined) {
        //     temp['Ack At'] = p.acknowledged_at;
        //   } else temp['Ack At'] = '';
        //   // temp['Port Number'] = p.port;

        //   this.exportData.push(temp);
        // });
      } else {
        this.setState({
          msgStatus: true,
          msgType: 'error',
          msgContent: 'No data found!',
          rows: [],
          isSpinner: false,
        });
        // this.setState({ rows: [], isSpinner:false });
      }
    });
  };

  getInitialTotalCount = () => {
    this.exportData = [];
    // this.setState({ totalRowCount: 0, page: 0, rowsPerPage: 50 });
    // this.exportData = [];
    // if (
    //   !this.state.hasError &&
    //   !this.state.hasError1 &&
    //   !this.state.hasError2 &&
    //   !this.state.hasError3 &&
    //   !this.state.hasError4 &&
    //   !this.state.hasError5
    // ) {
    // this.setState({ isSpinner: true });
    let fDT1 = this.state.selectedFrom.split('T');
    let fDT = fDT1[0] + ' ' + fDT1[1] + ':00';
    let tDT1 = this.state.selectedTo.split('T');
    let tDT = tDT1[0] + ' ' + tDT1[1] + ':00';
    //*** Getting difference between two dates */
    const d1 = new Date(fDT1[0]);
    const d2 = new Date(tDT1[0]);
    let diffInTime = d2.getTime() - d1.getTime();
    let diffInDays = diffInTime / (1000 * 3600 * 24);
    if (diffInDays > 31) {
      // this.setState({
      //   msgStatus: true,
      //   msgType: 'error',
      //   msgContent:
      //     'Difference between two dates should not more than 30 days',
      //   rows: [],
      //   isSpinner: false,
      // });
    } else {
      let reqParams = {
        unit_id: this.state.selectedUnit,
        equipment_id: this.state.selectedEquip,
        priority: this.state.selectedPriority,
        is_ack: this.state.selectedAck,
        from: fDT,
        to: tDT,
      };
      this.alarmService.getAlarmCount(reqParams).then((res) => {
        // this.initialCount = res.count;
        let promise = new Promise((resolve, reject) => resolve());
        for (let i = 0; i < res.count / 9000; i++) {
          // alert(i);
          promise = promise.then(() => {
            this.getAlarmsByPaginationToExport(i);
          });
        }
      });
    }
    // } else {
    //   // this.setState({
    //   //   msgStatus: true,
    //   //   msgType: 'warning',
    //   //   msgContent: 'Form is invalid!',
    //   //   rows: [],
    //   //   isSpinner: false,
    //   // });
    // }
  };

  getAlarmsByPaginationToExport = (page) => {
    // alert(page)
    let fDT1 = this.state.selectedFrom.split('T');
    let fDT = fDT1[0] + ' ' + fDT1[1] + ':00';
    let tDT1 = this.state.selectedTo.split('T');
    let tDT = tDT1[0] + ' ' + tDT1[1] + ':00';
    let reqParams = {
      unit_id: this.state.selectedUnit,
      equipment_id: this.state.selectedEquip,
      priority: this.state.selectedPriority,
      is_ack: this.state.selectedAck,
      from: fDT,
      to: tDT,
      page_no: page,
      no_of_records: '9000',
    };
    // alert(JSON.stringify(reqParams));
    this.alarmService.getAlarmsByPage(reqParams).then((res) => {
      // alert("history alarms: " + JSON.stringify(res));
      if (res.length > 0) {
        // this.setState({ rows: res, isSpinner: false });
        res.map((p) => {
          let temp = {};
          temp['Date Time'] = p.date_time;
          temp['Tag'] = p.tag_name;
          temp['Description'] = p.alarm_desc;
          temp['Value'] = p.value;
          temp['Priority'] = p.priority;
          temp['Unit'] = p.unit_id;
          temp['Equipment'] = p.equipment_id;
          temp['Status'] = p.status;
          temp['Is Ack'] = p.is_ack;
          if (p.acknowledged_by != undefined) {
            temp['Ack By'] = p.acknowledged_by;
          } else temp['Ack By'] = '';
          if (p.acknowledged_at != undefined) {
            temp['Ack At'] = p.acknowledged_at;
          } else temp['Ack At'] = '';
          // temp['Port Number'] = p.port;

          this.exportData.push(temp);
        });
      } else {
        // this.setState({
        //   msgStatus: true,
        //   msgType: 'error',
        //   msgContent: 'No data found!',
        //   rows: [],
        //   isSpinner: false,
        // });
        // this.setState({ rows: [], isSpinner:false });
      }
    });
  };

  getFilteredAlarms = () => {
    this.exportData = [];
    // if (
    //   !this.state.hasError &&
    //   !this.state.hasError1 &&
    //   !this.state.hasError2 &&
    //   !this.state.hasError3 &&
    //   !this.state.hasError4 &&
    //   !this.state.hasError5
    // ) {
    // this.setState({ isSpinner: true });
    let fDT1 = this.state.selectedFrom.split('T');
    let fDT = fDT1[0] + ' ' + fDT1[1] + ':00';
    let tDT1 = this.state.selectedTo.split('T');
    let tDT = tDT1[0] + ' ' + tDT1[1] + ':00';
    //*** Getting difference between two dates */
    const d1 = new Date(fDT1[0]);
    const d2 = new Date(tDT1[0]);
    let diffInTime = d2.getTime() - d1.getTime();
    let diffInDays = diffInTime / (1000 * 3600 * 24);
    if (diffInDays > 32) {
      // this.setState({
      //   msgStatus: true,
      //   msgType: 'error',
      //   msgContent:
      //     'Difference between two dates should not more than 30 days',
      //   rows: [],
      //   isSpinner: false,
      // });
    } else {
      let reqParams = {
        unit_id: this.state.selectedUnit,
        equipment_id: this.state.selectedEquip,
        priority: this.state.selectedPriority,
        is_ack: this.state.selectedAck,
        from: fDT,
        to: tDT,
      };
      // alert(JSON.stringify(reqParams));
      this.alarmService.getAlarmHistory(reqParams).then((res) => {
        if (res.length > 0) {
          res.map((p) => {
            let temp = {};
            temp['Date Time'] = p.date_time;
            temp['Tag'] = p.tag_name;
            temp['Description'] = p.alarm_desc;
            temp['Value'] = p.value;
            temp['Priority'] = p.priority;
            temp['Unit'] = p.unit_id;
            temp['Equipment'] = p.equipment_id;
            temp['Status'] = p.status;
            temp['Is Ack'] = p.is_ack;
            if (p.acknowledged_by != undefined) {
              temp['Ack By'] = p.acknowledged_by;
            } else temp['Ack By'] = '';
            if (p.acknowledged_at != undefined) {
              temp['Ack At'] = p.acknowledged_at;
            } else temp['Ack At'] = '';
            // temp['Port Number'] = p.port;

            this.exportData.push(temp);
          });
          // this.setState({ rows: res, isSpinner: false });
        } else {
          // this.setState({
          //   msgStatus: true,
          //   msgType: 'error',
          //   msgContent: 'No data found!',
          //   rows: [],
          //   isSpinner: false,
          // });
          // this.setState({ rows: [], isSpinner:false });
        }
      });
    }
    // } else {
    //   // this.setState({
    //   //   msgStatus: true,
    //   //   msgType: 'warning',
    //   //   msgContent: 'Form is invalid!',
    //   //   rows: [],
    //   //   isSpinner: false,
    //   // });
    // }
  };

  closeSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ msgStatus: false });
  };

  ExportToExcel = () => {
    let data = this.exportData;
    // alert(this.state.hasError1);
    if (data.length > 0) {
      exportFromJSON({ data, fileName, exportType });
    } else {
      this.setState({
        msgStatus: true,
        msgType: 'warning',
        msgContent: 'No data found!',
        isSpinner: false,
      });
    }
  };

  render() {
    const { classes } = this.props;
    const {
      unitIDs,
      equipIDs,
      rowsPerPage,
      page,
      rows,
      isSpinner,
      open,
      selectedUnit,
      selectedUnitObj,
      selectedEquip,
      selectedPriority,
      selectedAck,
      selectedFrom,
      selectedTo,
      order,
      orderBy,
      selected,
      hasError,
      errorStyle,
      hasError1,
      errorStyle1,
      hasError2,
      hasError3,
      hasError4,
      hasError5,
      errorStyle3,
      errorStyle4,
      errorStyle5,
      totalRowCount,
      unitSelected, isAllSelected, unitList
    } = this.state;
    const priority = [
      { label: 'All', value: 'ALL' },
      { label: 'Emergency', value: 'Emergency' },
      { label: 'High', value: 'High' },
      { label: 'Low', value: 'Low' },
      { label: 'Normal', value: 'Normal' },
    ];
    const acknowledged = [
      { label: 'All', value: 'ALL' },
      { label: 'Acknowledge', value: 'Y' },
      { label: 'UnAcknowledge', value: 'N' },
    ];
    // const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
    return (
      <Wrapper>
        {/* <Card>
          <CardContent style={{ padding:'10px' }}> */}
        <Paper className={classes.root}>
          {this.state.msgStatus ? (
            <CustomizedSnackbars
              opened={this.state.msgStatus}
              snackType={this.state.msgType}
              snackMessage={this.state.msgContent}
              closed={this.closeSnackBar}
            ></CustomizedSnackbars>
          ) : null}
          <form className={classes.container}>
            <FormControl
              className={classes.textField1}
              style={{ marginTop: '15px' }}
            >
              <Typography>&nbsp;&nbsp;From</Typography>
              <TextField
                id="date1"
                label=""
                type="datetime-local"
                error={hasError1}
                defaultValue={selectedFrom}
                className={classes.textField1}
                onChange={(event) => this.changeFromDate(event)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {hasError1 && (
                <FormHelperText style={errorStyle1}>
                  This can not be greater than to date!
                </FormHelperText>
              )}
            </FormControl>
            <FormControl
              className={classes.textField1}
              style={{ marginTop: '15px' }}
            >
              <Typography>&nbsp;&nbsp;To</Typography>
              <TextField
                id="date2"
                label=""
                type="datetime-local"
                error={hasError2}
                defaultValue={selectedTo}
                value={selectedTo}
                onChange={(event) => this.changeToDate(event)}
                className={classes.textField1}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {hasError2 && (
                <FormHelperText style={errorStyle1}>
                  This can not be less than from date!
                </FormHelperText>
              )}
            </FormControl>
            {/* <FormControl
              className={classes.textField2}
              style={{ marginTop: '10px' }}
            >
              <Typography style={errorStyle}>
                Unit Id <sup>*</sup>
              </Typography>
              <VirtualizedSelect
                ref="unitIDs"
                options={unitIDs}
                clearable={true}
                multi={true}
                name="select-unitIDs"
                value={selectedUnitObj}
                onChange={this.changeUnitID}
                searchable
                required={true}
                placeholder="Search/Select Units"
              />
              {hasError && (
                <FormHelperText style={errorStyle}>
                  Unit Id is required!
                </FormHelperText>
              )}
            </FormControl> */}
            <FormControl className={classes.textField2}>
              {/* <InputLabel style={errorStyle} id="mutiple-select-label">
                  Unit Id<sup>*</sup>
                </InputLabel> */}
              <Typography style={errorStyle}>
                Unit Id <sup>*</sup>
              </Typography>
              <Select
                labelId="mutiple-select-label"
                multiple
                value={unitSelected}
                onChange={this.handleChangeUnit}
                renderValue={(unitSelected) => unitSelected.join(', ')}
                MenuProps={MenuProps}
              >
                <MenuItem
                  value="all"
                  classes={{
                    root: isAllSelected ? classes.selectedAll : '',
                  }}
                >
                  <ListItemIcon>
                    <Checkbox
                      classes={{ indeterminate: classes.indeterminateColor }}
                      checked={isAllSelected}
                      indeterminate={
                        unitSelected.length > 0 &&
                        unitSelected.length < unitList.length
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    classes={{ primary: classes.selectAllText }}
                    primary="Select All"
                  />
                </MenuItem>
                {unitList.map((option) => (
                  <MenuItem key={option} value={option}>
                    <ListItemIcon>
                      <Checkbox checked={unitSelected.indexOf(option) > -1} />
                    </ListItemIcon>
                    <ListItemText primary={option} />
                  </MenuItem>
                ))}
              </Select>
              {hasError && (
                <FormHelperText style={errorStyle}>
                  Unit Id is required!
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              className={classes.textField3}
              style={{ marginTop: '10px' }}
            >
              <Typography style={errorStyle3}>
                Equipment Id <sup>*</sup>
              </Typography>
              <VirtualizedSelect
                ref="equipIDs"
                options={equipIDs}
                simpleValue
                clearable={false}
                name="select-equipIDs"
                value={selectedEquip}
                onChange={this.changeEquipID}
                searchable
                isRequired={true}
              />
              {hasError3 && (
                <FormHelperText style={errorStyle3}>
                  Equipment is required!
                </FormHelperText>
              )}
              {/* <InputLabel id="eqpID">Equipment Id</InputLabel>
                <Select
                  labelId="eqpID"
                  id="eqpID-select"
                  value={selectedEquip}
                  onChange={this.changeEquipID}
                >
                  
                  {equipIDs.map( (e, i) => {
                      return <MenuItem key={i} value={e}>{e}</MenuItem>
                  })}
                </Select> */}
            </FormControl>
            <FormControl
              className={classes.textField3}
              style={{ marginTop: '10px' }}
            >
              <Typography style={errorStyle4}>
                Priority <sup>*</sup>
              </Typography>
              <VirtualizedSelect
                ref="priority"
                options={priority}
                simpleValue
                clearable={false}
                name="select-priority"
                value={selectedPriority}
                onChange={this.changePriority}
                searchable
                isRequired={true}
              />
              {hasError4 && (
                <FormHelperText style={errorStyle4}>
                  Priority is required!
                </FormHelperText>
              )}
              {/* <InputLabel id="prio">Priority</InputLabel>
                <Select
                  labelId="prio"
                  id="prio-select"
                  value={selectedPriority}
                  onChange={this.changePriority}
                >
                  <MenuItem value={'ALL'}>All</MenuItem>
                  <MenuItem value={'Emergency'}>Emergency</MenuItem>
                  <MenuItem value={'High'}>High</MenuItem>
                  <MenuItem value={'Low'}>Low</MenuItem>
                </Select> */}
            </FormControl>
            <FormControl
              className={classes.textField3}
              style={{ marginTop: '10px' }}
            >
              <Typography style={errorStyle5}>
                Acknowledged <sup>*</sup>
              </Typography>
              <VirtualizedSelect
                ref="acknowledged"
                options={acknowledged}
                simpleValue
                clearable={false}
                name="select-acknowledged"
                value={selectedAck}
                onChange={this.changeAck}
                searchable
                isRequired={true}
              />
              {hasError5 && (
                <FormHelperText style={errorStyle5}>
                  Ack is required!
                </FormHelperText>
              )}
              {/* <InputLabel id="ack">Acknowledged</InputLabel>
                <Select
                  labelId="ack"
                  id="ack-select"
                  value={selectedAck}
                  onChange={this.changeAck}
                >
                  <MenuItem value={'ALL'}>All</MenuItem>
                  <MenuItem value={'Y'}>Acknowledged</MenuItem>
                  <MenuItem value={'N'}>UnAcknowledged</MenuItem>
                </Select> */}
            </FormControl>
            <FormControl style={{ marginLeft: '10px' }}>
              <Typography style={{ marginTop: '10px' }}>&nbsp;</Typography>
              <Button
                color="primary"
                variant="contained"
                size="small"
                title="Get Alarms"
                onClick={this.getTotalCount}
                style={{ backgroundColor: 'rgb(0, 153, 204' }}
              >
                Get <Icon className={classes.rightIcon}>alarm</Icon>
              </Button>
            </FormControl>

            <FormControl style={{ marginLeft: '10px', marginTop: '10px' }}>
              <Typography>&nbsp;</Typography>
              <Button
                color="primary"
                variant="contained"
                size="medium"
                title="Export"
                onClick={this.ExportToExcel}
                style={{ backgroundColor: 'rgb(0, 153, 204' }}
              >
                Export To Excel
              </Button>
            </FormControl>
          </form>
          {/* </CardContent>
        </Card> */}
        </Paper>
        <Paper className={classes.root}>
          <Dialog
            open={open}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {'Acknowledge Alarm?'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure to acknowledge this alarm?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.handleClose}
                color="secondary"
                variant="outlined"
              >
                No
              </Button>
              <Button
                onClick={this.acknowledgeAlarm}
                color="primary"
                variant="outlined"
                autoFocus
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          <EnhancedTableToolbar
            numSelected={selected.length}
            onAck={this.openDialog}
          />
          <div className={classes.tableWrapper}>
            <Table className="alarmTable">
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={rows.length}
              />
              {isSpinner ? (
                <CircularProgress
                  className={classes.progress}
                  color="primary"
                  size={50}
                  thickness={3}
                />
              ) : (
                  <TableBody>
                    {rows.length > 0 ? (
                      rows.sort(getSorting(order, orderBy)).map((row) => {
                        const isSelected = this.isSelected(row._id);
                        return (
                          // 'backgroundColor': this.getBackgoundColor(row),
                          <TableRow
                            key={row._id}
                            style={{ cursor: 'pointer' }}
                            role="checkbox"
                            tabIndex={-1}
                            selected={isSelected}
                            onClick={(event) => this.handleClick(event, row._id)}
                          >
                            <TableCell
                              padding="checkbox"
                              style={{ 'padding-left': '6px' }}
                            >
                              <Checkbox checked={isSelected} color="default" />
                            </TableCell>
                            <TableCell
                              style={{ 'padding-left': '6px' }}
                              className={
                                row.status === 'RTN'
                                  ? 'rtn'
                                  : row.status === 'ALM' &&
                                    row.priority === 'High'
                                    ? 'almhigh'
                                    : row.status === 'ALM' && row.priority === 'Low'
                                      ? 'almLow'
                                      : 'almEmg'
                              }
                            >
                              {row.date_time}
                            </TableCell>
                            <TableCell
                              style={{ 'padding-left': '6px' }}
                              className={
                                row.status === 'RTN'
                                  ? 'rtn'
                                  : row.status === 'ALM' &&
                                    row.priority === 'High'
                                    ? 'almhigh'
                                    : row.status === 'ALM' && row.priority === 'Low'
                                      ? 'almLow'
                                      : 'almEmg'
                              }
                            >
                              {row.tag_name}
                            </TableCell>
                            <TableCell
                              style={{ 'padding-left': '6px' }}
                              className={
                                row.status === 'RTN'
                                  ? 'rtn'
                                  : row.status === 'ALM' &&
                                    row.priority === 'High'
                                    ? 'almhigh'
                                    : row.status === 'ALM' && row.priority === 'Low'
                                      ? 'almLow'
                                      : 'almEmg'
                              }
                            >
                              {row.alarm_desc}
                            </TableCell>
                            <TableCell
                              style={{ 'padding-left': '6px' }}
                              className={
                                row.status === 'RTN'
                                  ? 'rtn'
                                  : row.status === 'ALM' &&
                                    row.priority === 'High'
                                    ? 'almhigh'
                                    : row.status === 'ALM' && row.priority === 'Low'
                                      ? 'almLow'
                                      : 'almEmg'
                              }
                            >
                              {row.value}
                            </TableCell>
                            <TableCell
                              style={{ 'padding-left': '6px' }}
                              className={
                                row.status === 'RTN'
                                  ? 'rtn'
                                  : row.status === 'ALM' &&
                                    row.priority === 'High'
                                    ? 'almhigh'
                                    : row.status === 'ALM' && row.priority === 'Low'
                                      ? 'almLow'
                                      : 'almEmg'
                              }
                            >
                              {row.priority}
                            </TableCell>
                            <TableCell
                              style={{ 'padding-left': '6px' }}
                              className={
                                row.status === 'RTN'
                                  ? 'rtn'
                                  : row.status === 'ALM' &&
                                    row.priority === 'High'
                                    ? 'almhigh'
                                    : row.status === 'ALM' && row.priority === 'Low'
                                      ? 'almLow'
                                      : 'almEmg'
                              }
                            >
                              {row.unit_id}
                            </TableCell>
                            <TableCell
                              style={{ 'padding-left': '6px' }}
                              className={
                                row.status === 'RTN'
                                  ? 'rtn'
                                  : row.status === 'ALM' &&
                                    row.priority === 'High'
                                    ? 'almhigh'
                                    : row.status === 'ALM' && row.priority === 'Low'
                                      ? 'almLow'
                                      : 'almEmg'
                              }
                            >
                              {row.equipment_id}
                            </TableCell>
                            <TableCell
                              style={{ 'padding-left': '6px' }}
                              className={
                                row.status === 'RTN'
                                  ? 'rtn'
                                  : row.status === 'ALM' &&
                                    row.priority === 'High'
                                    ? 'almhigh'
                                    : row.status === 'ALM' && row.priority === 'Low'
                                      ? 'almLow'
                                      : 'almEmg'
                              }
                            >
                              {row.status}
                            </TableCell>
                            {/*<TableCell className={row.is_ack === 'N' && row.status === 'RTN' ? 'rtnBlink' : row.is_ack === 'N' && row.status === 'ALM' && row.priority === 'High' ? 'almHighBlink' : row.is_ack === 'N' && row.status === 'ALM' && row.priority === 'Low' ? 'almLowBlink' : row.is_ack === 'N' && row.status === 'ALM' && row.priority === 'Emergency' ? 'almEmgBlink' : row.is_ack === 'Y' && row.status === 'RTN' ? 'rtn' : row.is_ack === 'Y' && row.status === 'ALM' && row.priority === 'High' ? 'almhigh' : row.is_ack === 'Y' && row.status === 'ALM' && row.priority === 'Low' ? 'almLow' : 'almEmg'}>{row.is_ack}</TableCell>*/}
                            <TableCell
                              style={{ 'padding-left': '6px' }}
                              className={
                                row.status === 'RTN'
                                  ? 'rtn'
                                  : row.status === 'ALM' &&
                                    row.priority === 'High'
                                    ? 'almhigh'
                                    : row.status === 'ALM' && row.priority === 'Low'
                                      ? 'almLow'
                                      : 'almEmg'
                              }
                            >
                              {row.is_ack}
                            </TableCell>
                            <TableCell
                              style={{ 'padding-left': '6px' }}
                              className={
                                row.status === 'RTN'
                                  ? 'rtn'
                                  : row.status === 'ALM' &&
                                    row.priority === 'High'
                                    ? 'almhigh'
                                    : row.status === 'ALM' && row.priority === 'Low'
                                      ? 'almLow'
                                      : 'almEmg'
                              }
                            >
                              {row.acknowledged_by}
                            </TableCell>
                            <TableCell
                              style={{ 'padding-left': '6px' }}
                              className={
                                row.status === 'RTN'
                                  ? 'rtn'
                                  : row.status === 'ALM' &&
                                    row.priority === 'High'
                                    ? 'almhigh'
                                    : row.status === 'ALM' && row.priority === 'Low'
                                      ? 'almLow'
                                      : 'almEmg'
                              }
                            >
                              {row.acknowledged_at}
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                        <p>
                          <center>No data found!</center>
                        </p>
                      )}
                    {/* {emptyRows > 0 && (
                  <TableRow style={{ height: 48 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
                  </TableBody>
                )}
              <TableFooter>
                <TableRow>
                  <TablePagination
                    colSpan={11}
                    count={totalRowCount}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[]}
                    page={page}
                    onChangePage={this.handleChangePage}
                    // onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActionsWrapped}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </div>
        </Paper>
      </Wrapper>
    );
  }
}

Alarms.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Alarms);

// export default Alarms;
