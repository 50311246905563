import React, { Component } from "react";
import { Wrapper } from "../../components";
import PropTypes from "prop-types";
import { Card } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import { CustomizedSnackbars } from "../../shared/snackbar-service";
import "./assetHome.css";
import TextField from "@material-ui/core/TextField";
import AssetHomeService from "../../services/assetHome/assetHome-service";
import NearMeIcon from "@material-ui/icons/NearMe";
import Icon from "@material-ui/core/Icon";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "react-modern-drawer";
import InputLabel from "@material-ui/core/InputLabel";
import FilterListIcon from "@material-ui/icons/FilterList";
import CircularProgress from "@material-ui/core/CircularProgress";
import BarChartIcon from "@material-ui/icons/BarChart";
// import Select from '@material-ui/core/Select';

// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import Checkbox from '@material-ui/core/Checkbox';
// import ListItemText from '@material-ui/core/ListItemText';

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 1,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    paddingTop: theme.spacing.unit * 1,
    paddingBottom: "5px",
  },
  textField2: {
    marginTop: theme.spacing.unit * 2,
    marginLeft: theme.spacing.unit * 1,
    marginRight: theme.spacing.unit * 1,
    width: 550,
  },
  formControl: {
    width: 420,
  },
  indeterminateColor: {
    color: "#f50057",
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
});

const containerStyle = {
  width: "400px",
  height: "400px",
};

var divStyle = {
  paddingLeft: "20px",
  paddingTop: "15px",
  fontSize: "11px",
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};

class assetHome extends Component {
  constructor(props) {
    super(props);
    this.homeService = new AssetHomeService();
    this.units = localStorage.getItem("User");
    this.units = JSON.parse(JSON.parse(this.units)["unit_id"]);
    this.intervalId = 0;
    this.assetsList = [];
    this.state = {
      msgContent: "",
      msgStatus: "",
      msgType: "",
      assetData: [],
      unitSelected: this.units,
      // isAllSelected: false,
      unitList: this.units,
      hasError: false,
      errorStyle: {},
      alarmCount: 0,
      serNoList: [],
      selectedSerNo: [],
      custList: [],
      selectedCust: [],
      // stateList: [{ label: 'ALL', value: 'ALL' }],
      selectedState: [],
      regionList: [],
      selectedRegion: [],
      engineerList: [],
      selectedEngineer: [],
      typeList: [],
      selectedType: [],
      articleNoList: [],
      selectedAcrticleNo: [],
      selectedRegion1: ["WEST", "NORTH"],
      stateSelected: [],
      isAllSelected: false,
      stateList: [],
      customerSelected: [],
      citySelected: [],
      engineerSelected: [],
      typeSelected: [],
      articleNoSelected: [],
      serialNoSelected: [],
      region1List: ["WEST", "NORTH"],
      regionSelected: ["WEST", "NORTH"],
      isOpen: false,
      isSpinner: false,
    };
  }

  componentDidMount() {
    this.getAssetFields("MACHINE_SR_NO");
    this.getAssetFields("CUSTOMER_NAME");
    this.getAssetFields("STATE");
    this.getAssetFields("CITY");
    this.getAssetFields("FSE");
    this.getAssetFields("ARTICLE_NO");
    this.getAssetFields("DRIVE_TYPE");

    // this.getAssets();
    setTimeout(this.getMapAsset, 700);
    this.intervalId = setInterval(this.getMapAsset, 10000);
  }
  getAssets = () => {
    this.assetsList = [];
    // this.state.unitSelected.map((unit, idx) => {

    let req = {
      unit_id: JSON.stringify(this.state.unitSelected),
      type: "COMP",
    };
    this.homeService.getAssetByUnit(req).then((res) => {
      // alert('Template Data: ' + res);
      if (res.length != 0) {
        this.assetsList = res;
        // this.assetsList.push(res[0]);
        this.getAssetDetails();
      }
      // if (idx == this.units.length - 1) {

      // }
    });
    // })
  };
  getAssetDetails = () => {
    // this.assetsList.map((asset, idx) => {
    // this.state.assetData = [];
    
    // let req = { "operation": "get_asset_status", "asset_list": this.assetsList }
    // this.homeService.tcpRequest2(req).then((tagres) => {
    //   statusRes = tagres;
    // })

    this.state.isSpinner = true;
    let req = { operation: "get_asset_status", asset_list: this.assetsList };
    this.homeService.getAssetsLiveData({asset_list: this.assetsList }).then((res) => {
      let tempAssetData = [];
    let statusRes = [];
    this.state.alarmCount = 0;
      this.state.isSpinner = false;
      if (res) {
        const tagres = res.data;
        this.assetsList.map((asset, idx) => {
          let showAlm;
          let borderCol = "";
          let showOffline = false;

          let temp = {};
          let data = tagres[asset];
          if (data.length != 0) {
            if (data.tag_data['COMM_STS'] == -2) {
              this.state.alarmCount++;
              showAlm = true;
              borderCol = "5px solid red";
            } else if(data.tag_data['COMM_STS'] === -1 || data.tag_data['COMM_STS'] === undefined) {
              borderCol = "5px solid #A52A2A";
              showAlm = false;
              showOffline = true;
            } else {
              borderCol = "5px solid #5ea35e";
              showAlm = false;
            }
            // if (data["COMM_STS"] === 0 || data["COMM_STS"] === undefined) {
            //   borderCol = "5px solid #A52A2A";
            // }
            temp["asset_id"] = asset;
            if (data.tag_data["PT01"] != undefined)
              temp["PT01"] = parseFloat(data.tag_data["PT01"]).toFixed(1);
            else temp["PT01"] = "-";
            if (data.tag_data["PT04"] != undefined)
              temp["PT04"] = parseFloat(data.tag_data["PT04"]).toFixed(1);
            else temp["PT04"] = "-";
            if (data.tag_data["PT05"] != undefined)
              temp["PT05"] = parseFloat(data.tag_data["PT05"]).toFixed(1);
            else temp["PT05"] = "-";
            if (data.tag_data["KW"] != undefined)
              temp["KW"] = parseFloat(data.tag_data["KW"]).toFixed(1);
            else temp["KW"] = "-";
            if (data.tag_data["DISCHARGE_FLOW"] != undefined)
              temp["FM_02"] = parseFloat(data.tag_data["DISCHARGE_FLOW"]).toFixed(1);
            else temp["FM_02"] = "-";
            if (data["field_data"]["CAPACITY"] != undefined)
              temp["CAPACITY"] = data["field_data"]["CAPACITY"];
            else temp["CAPACITY"] = "-";
            temp["SITE"] = data["field_data"]["STATION"];
            if (data.tag_data["VBR"] != undefined)
              temp["VT01"] = parseFloat(data.tag_data["VBR"]).toFixed(1);
            else temp["VT01"] = "-";
            temp["showAlm"] = showAlm;
            temp["showOffline"] = showOffline;
            temp["BORDER"] = borderCol;
            temp["ass_disp"] = data.ass_disp;
            if (data["field_data"]["ANALYTICS_URL"] != undefined)
              temp["redirect_url"] = data["field_data"]["ANALYTICS_URL"];
            else temp["redirect_url"] = "-";
            temp["unit_id"] = data["field_data"]["UNIT_ID"];
            tempAssetData.push(temp);
            // tempAssetData.sort((a, b) => (a.SITE > b.SITE) ? 1 : -1);
            // tempAssetData.sort((a, b) => (b.showAlm > a.showAlm) ? 1 : -1);
            
           this.setState({ assetData: [] });
            tempAssetData.sort((a, b) => {
              if (a.showOffline !== b.showOffline) {
                return a.showOffline < b.showOffline ? 1 : -1;
              } else if (a.showAlm !== b.showAlm) {
                return a.showAlm < b.showAlm ? 1 : -1;
              }else {
                return a.SITE > b.SITE ? 1 : -1;
              }
            });

            // if (idx == this.assetsList.length - 1) {
            this.setState({ assetData: tempAssetData });
            // tempAssetData.sort(this.sortByProperty('showAlm'));
            this.state.assetData = tempAssetData;
            // }
          }
        });
      } else {
        this.state.isSpinner = false;
      }
    });
    // this.homeService.tcpRequest2(req).then((tagres) => {
    //   let regAdd = {
    //     operation: "get_asset_live_value",
    //     asset_list: this.assetsList,
    //   };
    //   this.homeService.tcpRequest(regAdd).then((regRes) => {
    //     // this.state.assetData = [];
    //     this.setState({ assetData: [] });
    //     this.state.isSpinner = false;
    //     if (regRes) {
    //       this.assetsList.map((asset, idx) => {
    //         let showAlm;
    //         let borderCol = "";
    //         let showOffline = false;
    //         if (tagres[asset] == -2) {
    //           this.state.alarmCount++;
    //           showAlm = true;
    //           borderCol = "5px solid red";
    //         } else if(tagres[asset] === -1 || tagres[asset] === undefined) {
    //           borderCol = "5px solid #A52A2A";
    //           showAlm = false;
    //           showOffline = true;
    //         } else {
    //           borderCol = "5px solid #5ea35e";
    //           showAlm = false;
    //         }

    //         let temp = {};
    //         let data = regRes[asset];
    //         // console.log("Data d " + JSON.stringify(data) +" - "+data.length)
    //         if (data.length != 0) {
             
    //           // if (data["COMM_STS"] === 0 || data["COMM_STS"] === undefined) {
    //           //   borderCol = "5px solid #A52A2A";
    //           // }
    //           temp["asset_id"] = asset;
    //           if (data["PT01"] != undefined)
    //             temp["PT01"] = parseFloat(data["PT01"]).toFixed(1);
    //           else temp["PT01"] = "-";
    //           if (data["PT04"] != undefined)
    //             temp["PT04"] = parseFloat(data["PT04"]).toFixed(1);
    //           else temp["PT04"] = "-";
    //           if (data["PT05"] != undefined)
    //             temp["PT05"] = parseFloat(data["PT05"]).toFixed(1);
    //           else temp["PT05"] = "-";
    //           if (data["KW"] != undefined)
    //             temp["KW"] = parseFloat(data["KW"]).toFixed(1);
    //           else temp["KW"] = "-";
    //           if (data["DISCHARGE_FLOW"] != undefined)
    //             temp["FM_02"] = parseFloat(data["DISCHARGE_FLOW"]).toFixed(1);
    //           else temp["FM_02"] = "-";
    //           if (data["field_data"]["CAPACITY"] != undefined)
    //             temp["CAPACITY"] = data["field_data"]["CAPACITY"];
    //           else temp["CAPACITY"] = "-";
    //           temp["SITE"] = data["field_data"]["STATION"];
    //           if (data["VBR"] != undefined)
    //             temp["VT01"] = parseFloat(data["VBR"]).toFixed(1);
    //           else temp["VT01"] = "-";
    //           temp["showAlm"] = showAlm;
    //           temp["showOffline"] = showOffline;
    //           temp["BORDER"] = borderCol;
    //           temp["ass_disp"] = data.ass_disp;
    //           if (data["field_data"]["ANALYTICS_URL"] != undefined)
    //             temp["redirect_url"] = data["field_data"]["ANALYTICS_URL"];
    //           else temp["redirect_url"] = "-";
    //           temp["unit_id"] = data["field_data"]["UNIT_ID"];
    //           tempAssetData.push(temp);
    //           // tempAssetData.sort((a, b) => (a.SITE > b.SITE) ? 1 : -1);
    //           // tempAssetData.sort((a, b) => (b.showAlm > a.showAlm) ? 1 : -1);
    //           tempAssetData.sort((a, b) => {
    //             if (a.showOffline !== b.showOffline) {
    //               return a.showOffline < b.showOffline ? 1 : -1;
    //             } else if (a.showAlm !== b.showAlm) {
    //               return a.showAlm < b.showAlm ? 1 : -1;
    //             }else {
    //               return a.SITE > b.SITE ? 1 : -1;
    //             }
    //           });

    //           // if (idx == this.assetsList.length - 1) {

    //           this.setState({ assetData: tempAssetData });
    //           // tempAssetData.sort(this.sortByProperty('showAlm'));
    //           this.state.assetData = tempAssetData;
    //           // }
    //         }
    //       });
    //     } else {
    //       this.state.isSpinner = false;
    //     }
    //   });
    // });

    // })
  };

  sortByProperty(property) {
    return (a, b) => {
      var sortStatus = 0;
      if (a[property] < b[property]) {
        sortStatus = -1;
      } else if (a[property] > b[property]) {
        sortStatus = 1;
      }
      return sortStatus;
    };
  }

  closeSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ msgStatus: false });
  };

  changeUnitID = (newUnitObj) => {
    // alert(JSON.stringify(newUnitObj))
    let newUnit = [];
    newUnitObj.map((u) => {
      newUnit.push(u);
    });
    if (newUnit.length > 0) {
      this.setState({
        selectedUnit: newUnit,
        selectedUnitObj: newUnitObj,
        hasError: false,
        errorStyle: {},
      });
      this.state.selectedUnit = newUnit;
      // this.getEquipments();
    } else {
      this.setState({
        selectedUnit: newUnit,
        selectedUnitObj: newUnitObj,
        hasError: true,
        errorStyle: { color: "red" },
      });
      this.state.selectedUnit = newUnit;
      // this.getEquipments();
    }
  };

  handleChangeUnit = (event) => {
    const value = event.target.value;
    // alert(JSON.stringify(value));
    if (value[value.length - 1] === "all") {
      // setSelected(selected.length === options.length ? [] : options);
      let allOpt =
        this.state.unitSelected.length === this.units.length ? [] : this.units;
      this.setState({
        unitSelected: allOpt,
      });

      this.state.unitSelected = allOpt;

      return;
    }
    this.state.unitSelected = value;
    this.setState({ unitSelected: value });
    this.changeUnitID(this.state.unitSelected);
    // alert(JSON.stringify(this.state.selected));
  };

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  openDialog = () => {
    this.setState({ isOpen: true });
  };

  toggleDrawer = (prevState) => {
    this.setState({
      isOpen: !prevState,
    });
  };

  getAssetFields = (type) => {
    // alert(JSON.stringify(this.units));
    let req = {
      key: type,
      unit_id: JSON.stringify(this.units),
    };
    this.homeService.getAssetFields(req).then((res) => {
      if (res.length > 0) {
        if (type == "MACHINE_SR_NO") {
          this.state.serNoList = res;
          this.state.serialNoSelected = res;
          this.state.selectedSerNo = res;
          // this.getMapAsset();
          // res.map((t) => {
          //   this.state.serNoList.push({ label: t, value: t });
          // });
        } else if (type == "CUSTOMER_NAME") {
          this.state.custList = res;
          this.state.customerSelected = res;
          this.state.selectedCust = res;
          // this.getMapAsset();
          // res.map((t) => {
          //   this.state.custList.push({ label: t, value: t });
          // });
        } else if (type == "STATE") {
          this.state.stateList = res;
          this.state.stateSelected = res;
          this.state.selectedState = res;
          // this.getMapAsset();
          // res.map((t) => {

          //   this.state.stateList.push({ label: t, value: t });
          // });
        } else if (type == "CITY") {
          this.state.regionList = res;
          this.state.citySelected = res;
          this.state.selectedRegion = res;
          // res.map((t) => {
          //   this.state.regionList.push({ label: t, value: t });
          // });
        } else if (type == "FSE") {
          this.state.engineerList = res;
          this.state.engineerSelected = res;
          this.state.selectedEngineer = res;
          // this.getMapAsset();
          // res.map((t) => {
          //   this.state.engineerList.push({ label: t, value: t });
          // });
        } else if (type == "ARTICLE_NO") {
          this.state.articleNoList = res;
          this.state.articleNoSelected = res;
          this.state.selectedAcrticleNo = res;
          // this.getMapAsset();
          // console.log(JSON.stringify(res));
          // res.map((t) => {
          //   this.state.articleNoList.push({ label: t, value: t });
          // });
        } else if (type == "DRIVE_TYPE") {
          this.state.typeList = res;
          this.state.typeSelected = res;
          this.state.selectedType = res;

          // this.getMapAsset();
          // console.log(JSON.stringify(res));
          // res.map((t) => {
          //   this.state.articleNoList.push({ label: t, value: t });
          // });
        }
      }
    });
  };

  getMapAsset = () => {
    this.assetsList = [];
    // this.getEngineerAssets();
    let req = {
      filter: {
        MACHINE_SR_NO: this.state.selectedSerNo,
        CUSTOMER_NAME: this.state.selectedCust,
        STATE: this.state.selectedState,
        CITY: this.state.selectedRegion,
        FSE: this.state.selectedEngineer,
        ARTICLE_NO: this.state.selectedAcrticleNo,
        ZONE: this.state.selectedRegion1,
      },
      unit_id: JSON.stringify(this.units),
    };
    this.homeService.getMapAsset(req).then((res) => {

      // this.ModelsMap(this.map, this.maps);
      if(res) {
        res.map((t, idx) => {
          this.assetsList.push(t.asset_id);
          // this.assetsList.push(res[0]);
          if (idx === res.length - 1) this.getAssetDetails();
        });
      }
    });
  };

  changeSerNo = (event) => {
    this.setState({
      selectedSerNo: event,
    });
  };

  changeCustomer = (event) => {
    this.setState({
      selectedCust: event,
    });
  };
  changeState = (event) => {
    this.setState({
      selectedState: event,
    });
    // alert(JSON.stringify(this.state.selectedState))
  };
  changeCity = (event) => {
    this.setState({
      selectedRegion: event,
    });
  };
  changeRegion = (event) => {
    this.setState({
      selectedRegion1: event,
    });
  };
  changeEngineer = (event) => {
    this.setState({
      selectedEngineer: event,
    });
  };
  changeType = (event) => {
    this.setState({
      selectedType: event,
    });
  };
  changeArticle = (event) => {
    this.setState({
      selectedAcrticleNo: event,
    });
  };
  ModelsMap = (map, maps) => {
    // maps.Marker().clear();
    this.map = map;
    this.maps = maps;
    // let marker = new maps.Marker({
    //   position: { lat: 18.5204, lng: 73.8567 },
    //   map,
    //   // label: 'Pune',
    // });

    //Loop through the dataArray to create a marker per data using the coordinates in the json
    for (let i = 0; i < this.state.mapData.length; i++) {
      let marker = new maps.Marker({
        position: {
          lat: parseInt(this.state.mapData[i].field_data.LATITUDE),
          lng: parseInt(this.state.mapData[i].field_data.LONGITUDE),
        },
        map,
        // label: this.state.mapData[i]._id,
      });
      // alert(marker);
    }
  };

  // handleChange = (event) => {
  //   alert(event);
  //   // this.setState({ value });
  //   // console.log(value);
  // };

  handleChange = (selected) => {
    this.node.blur();
    this.setState({
      optionSelected: selected,
    });
    // alert(JSON.stringify(this.state.optionSelected));
    this.node.focus();
  };

  handleChangeState = (event) => {
    const value = event.target.value;
    // alert(JSON.stringify(value));
    if (value[value.length - 1] === "all") {
      // setSelected(selected.length === options.length ? [] : options);
      let allOpt =
        this.state.stateSelected.length === this.state.stateList.length
          ? []
          : this.state.stateList;
      this.setState({
        stateSelected: allOpt,
      });

      this.state.stateSelected = allOpt;

      this.changeState(this.state.stateSelected);
      return;
    }
    this.state.stateSelected = value;
    this.setState({ stateSelected: value });
    this.changeState(this.state.stateSelected);
    // alert(JSON.stringify(this.state.selected));
  };

  handleChangeCustomer = (event) => {
    const value = event.target.value;
    // alert(JSON.stringify(value));
    if (value[value.length - 1] === "all") {
      // setSelected(selected.length === options.length ? [] : options);
      let allOpt =
        this.state.customerSelected.length === this.state.custList.length
          ? []
          : this.state.custList;
      this.setState({
        customerSelected: allOpt,
      });

      this.state.customerSelected = allOpt;

      this.changeCustomer(this.state.customerSelected);
      return;
    }
    this.state.customerSelected = value;
    this.setState({ customerSelected: value });
    this.changeCustomer(this.state.customerSelected);
    // alert(JSON.stringify(this.state.selected));
  };

  handleChangeRegion = (event) => {
    const value = event.target.value;
    // alert(JSON.stringify(value));
    if (value[value.length - 1] === "all") {
      // setSelected(selected.length === options.length ? [] : options);
      let allOpt =
        this.state.regionSelected.length === this.state.region1List.length
          ? []
          : this.state.region1List;
      this.setState({
        regionSelected: allOpt,
      });

      this.state.regionSelected = allOpt;

      this.changeRegion(this.state.regionSelected);
      return;
    }
    this.state.regionSelected = value;
    this.setState({ regionSelected: value });
    this.changeRegion(this.state.regionSelected);
    // alert(JSON.stringify(this.state.selected));
  };

  handleChangeEngineer = (event) => {
    const value = event.target.value;
    // alert(JSON.stringify(value));
    if (value[value.length - 1] === "all") {
      // setSelected(selected.length === options.length ? [] : options);
      let allOpt =
        this.state.engineerSelected.length === this.state.engineerList.length
          ? []
          : this.state.engineerList;
      this.setState({
        engineerSelected: allOpt,
      });

      this.state.engineerSelected = allOpt;

      this.changeEngineer(this.state.engineerSelected);
      return;
    }
    this.state.engineerSelected = value;
    this.setState({ engineerSelected: value });
    this.changeEngineer(this.state.engineerSelected);
    // alert(JSON.stringify(this.state.selected));
  };

  handleChangeType = (event) => {
    const value = event.target.value;
    // alert(JSON.stringify(value));
    if (value[value.length - 1] === "all") {
      // setSelected(selected.length === options.length ? [] : options);
      let allOpt =
        this.state.typeSelected.length === this.state.typeList.length
          ? []
          : this.state.typeList;
      this.setState({
        typeSelected: allOpt,
      });

      this.state.typeSelected = allOpt;

      this.changeType(this.state.typeSelected);
      return;
    }
    this.state.typeSelected = value;
    this.setState({ typeSelected: value });
    this.changeType(this.state.typeSelected);
    // alert(JSON.stringify(this.state.selected));
  };

  handleChangeArticle = (event) => {
    const value = event.target.value;
    // alert(JSON.stringify(value));
    if (value[value.length - 1] === "all") {
      // setSelected(selected.length === options.length ? [] : options);
      let allOpt =
        this.state.articleNoSelected.length === this.state.articleNoList.length
          ? []
          : this.state.articleNoList;
      this.setState({
        articleNoSelected: allOpt,
      });

      this.state.articleNoSelected = allOpt;

      this.changeArticle(this.state.articleNoSelected);
      return;
    }
    this.state.articleNoSelected = value;
    this.setState({ articleNoSelected: value });
    this.changeArticle(this.state.articleNoSelected);
    // alert(JSON.stringify(this.state.selected));
  };

  handleChangeCity = (event) => {
    const value = event.target.value;
    // alert(JSON.stringify(value));
    if (value[value.length - 1] === "all") {
      // setSelected(selected.length === options.length ? [] : options);
      let allOpt =
        this.state.citySelected.length === this.state.regionList.length
          ? []
          : this.state.regionList;
      this.setState({
        citySelected: allOpt,
      });

      this.state.citySelected = allOpt;

      this.changeCity(this.state.citySelected);
      return;
    }
    this.state.citySelected = value;
    this.setState({ citySelected: value });
    this.changeCity(this.state.citySelected);
    // alert(JSON.stringify(this.state.selected));
  };

  handleChangeSerial = (event) => {
    const value = event.target.value;
    // alert(JSON.stringify(value));
    if (value[value.length - 1] === "all") {
      // setSelected(selected.length === options.length ? [] : options);
      let allOpt =
        this.state.serialNoSelected.length === this.state.serNoList.length
          ? []
          : this.state.serNoList;
      this.setState({
        serialNoSelected: allOpt,
      });

      this.state.serialNoSelected = allOpt;

      this.changeSerNo(this.state.serialNoSelected);
      return;
    }
    this.state.serialNoSelected = value;
    this.setState({ serialNoSelected: value });
    this.changeSerNo(this.state.serialNoSelected);
    // alert(JSON.stringify(this.state.selected));
  };

  setUnit = (e, unit) => {
    // e.preventDefault();
    // alert("Button")
    localStorage.setItem("select_unit", unit);
  };

  render() {
    const { classes, theme } = this.props;
    const {
      msgContent,
      msgStatus,
      msgType,
      assetData,
      unitSelected,
      // isAllSelected,
      unitList,
      hasError,
      errorStyle,
      alarmCount,
      selectedRegion1,
      typeList,
      selectedType,
      articleNoList,
      selectedAcrticleNo,
      isOpen,
      stateSelected,
      isAllSelected,
      customerSelected,
      citySelected,
      engineerSelected,
      typeSelected,
      articleNoSelected,
      serialNoSelected,
      region1List,
      regionSelected,
      serNoList,
      selectedSerNo,
      custList,
      selectedCust,
      stateList,
      selectedState,
      regionList,
      selectedRegion,
      engineerList,
      selectedEngineer,
      isSpinner,
    } = this.state;

    // alert(JSON.stringify(assetData))
    return (
      <Wrapper>
        {msgStatus ? (
          <CustomizedSnackbars
            opened={msgStatus}
            snackType={msgType}
            snackMessage={msgContent}
            closed={this.closeSnackBar}
          ></CustomizedSnackbars>
        ) : null}
        {!isOpen ? (
          <div className="searchBox">
            <br></br>

            <Button
              title="Filter"
              variant="fab"
              mini
              color="primary"
              aria-label="Settings"
              onClick={this.openDialog}
              style={{
                backgroundColor: "rgb(0, 153, 204",
                margin: "1%",
                float: "left",
                zIndex: 9999,
                // marginLeft: '182px',
                marginTop: "-14px",
                fontSize: "10px",
              }}
            >
              <FilterListIcon />{" "}
            </Button>
          </div>
        ) : null}
        <div style={{ height: "auto" }}>
          <Drawer
            open={isOpen}
            onClose={this.toggleDrawer}
            direction="left"
            size={270}
            style={{ padding: 20, paddingTop: "70px" }}
          >
            <form
              style={{
                // display: 'flex',
                flexWrap: "wrap",
                // paddingBottom: '5px',
              }}
            >
              <div className="row" style={divStyle}>
                <FormControl
                  style={{
                    width: 180,
                    marginLeft: "1%",
                  }}
                >
                  <InputLabel id="mutiple-select-label">
                    Region<sup>*</sup>
                  </InputLabel>
                  <Select
                    labelId="mutiple-select-label"
                    multiple
                    value={regionSelected}
                    onChange={this.handleChangeRegion}
                    renderValue={(regionSelected) => regionSelected.join(", ")}
                    MenuProps={MenuProps}
                  >
                    <MenuItem
                      value="all"
                      classes={{
                        root: isAllSelected ? classes.selectedAll : "",
                      }}
                    >
                      <ListItemIcon>
                        <Checkbox
                          classes={{
                            indeterminate: this.props.indeterminateColor,
                          }}
                          checked={isAllSelected}
                          indeterminate={
                            regionSelected.length > 0 &&
                            regionSelected.length < region1List.length
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        classes={{ primary: this.props.selectAllText }}
                        primary="Select All"
                      />
                    </MenuItem>
                    {region1List.map((option) => (
                      <MenuItem key={option} value={option}>
                        <ListItemIcon>
                          <Checkbox
                            checked={regionSelected.indexOf(option) > -1}
                          />
                        </ListItemIcon>
                        <ListItemText primary={option} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              {/* <div className="row" style={divStyle}>
                    <FormControl
                      style={{
                        minWidth: 180,
                        marginLeft: '1%',
                      }}
                    >
                      <Typography style={{ fontSize: '13px' }}>
                        Region
                      </Typography>
                      <VirtualizedSelect
                        ref="region"
                        options={[
                          { label: 'West', value: 'WEST' },
                          { label: 'North', value: 'NORTH' },
                        ]}
                        simpleValue
                        clearable={false}
                        name="select-region"
                        value={selectedRegion1}
                        onChange={(event) => this.changeRegion(event)}
                        searchable
                      />
                    </FormControl>
                  </div> */}

              <div className="row" style={divStyle}>
                <FormControl
                  style={{
                    width: 180,
                    marginLeft: "1%",
                  }}
                >
                  <InputLabel id="mutiple-select-label">
                    Customer<sup>*</sup>
                  </InputLabel>
                  <Select
                    labelId="mutiple-select-label"
                    multiple
                    value={customerSelected}
                    onChange={this.handleChangeCustomer}
                    renderValue={(customerSelected) =>
                      customerSelected.join(", ")
                    }
                    MenuProps={MenuProps}
                  >
                    <MenuItem
                      value="all"
                      classes={{
                        root: isAllSelected ? classes.selectedAll : "",
                      }}
                    >
                      <ListItemIcon>
                        <Checkbox
                          classes={{
                            indeterminate: this.props.indeterminateColor,
                          }}
                          checked={isAllSelected}
                          indeterminate={
                            customerSelected.length > 0 &&
                            customerSelected.length < custList.length
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        classes={{ primary: this.props.selectAllText }}
                        primary="Select All"
                      />
                    </MenuItem>
                    {custList.map((option) => (
                      <MenuItem key={option} value={option}>
                        <ListItemIcon>
                          <Checkbox
                            checked={customerSelected.indexOf(option) > -1}
                          />
                        </ListItemIcon>
                        <ListItemText primary={option} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              {/* <div className="row" style={divStyle}>
                    <FormControl
                      style={{
                        minWidth: 180,
                        marginLeft: '1%',
                      }}
                    >
                      <Typography style={{ fontSize: '13px' }}>
                        Customer
                      </Typography>
                      <VirtualizedSelect
                        ref="customer"
                        options={custList}
                        simpleValue
                        clearable={false}
                        name="select-customer"
                        value={selectedCust}
                        onChange={(event) => this.changeCustomer(event)}
                        searchable
                      />
                    </FormControl>
                  </div> */}

              <div className="row" style={divStyle}>
                <FormControl
                  style={{
                    width: 180,
                    marginLeft: "1%",
                  }}
                >
                  <InputLabel id="mutiple-select-label">
                    State<sup>*</sup>
                  </InputLabel>
                  <Select
                    labelId="mutiple-select-label"
                    multiple
                    value={stateSelected}
                    onChange={this.handleChangeState}
                    renderValue={(stateSelected) => stateSelected.join(", ")}
                    MenuProps={MenuProps}
                  >
                    <MenuItem
                      value="all"
                      classes={{
                        root: isAllSelected ? classes.selectedAll : "",
                      }}
                    >
                      <ListItemIcon>
                        <Checkbox
                          classes={{
                            indeterminate: this.props.indeterminateColor,
                          }}
                          checked={isAllSelected}
                          indeterminate={
                            stateSelected.length > 0 &&
                            stateSelected.length < stateList.length
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        classes={{ primary: this.props.selectAllText }}
                        primary="Select All"
                      />
                    </MenuItem>
                    {stateList.map((option) => (
                      <MenuItem key={option} value={option}>
                        <ListItemIcon>
                          <Checkbox
                            checked={stateSelected.indexOf(option) > -1}
                          />
                        </ListItemIcon>
                        <ListItemText primary={option} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              {/* <div className="row" style={divStyle}>
                    <FormControl
                      style={{
                        minWidth: 180,
                        marginLeft: '1%',
                      }}
                    >
                      <Typography style={{ fontSize: '13px' }}>
                        State
                      </Typography>
                      <VirtualizedSelect
                        ref="state"
                        options={stateList}
                        simpleValue
                        clearable={false}
                        name="select-state"
                        value={selectedState}
                        onChange={(event) => this.changeState(event)}
                        searchable
                      />
                    </FormControl>
                  </div> */}

              <div className="row" style={divStyle}>
                <FormControl
                  style={{
                    width: 180,
                    marginLeft: "1%",
                  }}
                >
                  <InputLabel id="mutiple-select-label">
                    City<sup>*</sup>
                  </InputLabel>
                  <Select
                    labelId="mutiple-select-label"
                    multiple
                    value={citySelected}
                    onChange={this.handleChangeCity}
                    renderValue={(citySelected) => citySelected.join(", ")}
                    MenuProps={MenuProps}
                  >
                    <MenuItem
                      value="all"
                      classes={{
                        root: isAllSelected ? classes.selectedAll : "",
                      }}
                    >
                      <ListItemIcon>
                        <Checkbox
                          classes={{
                            indeterminate: this.props.indeterminateColor,
                          }}
                          checked={isAllSelected}
                          indeterminate={
                            citySelected.length > 0 &&
                            citySelected.length < regionList.length
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        classes={{ primary: this.props.selectAllText }}
                        primary="Select All"
                      />
                    </MenuItem>
                    {regionList.map((option) => (
                      <MenuItem key={option} value={option}>
                        <ListItemIcon>
                          <Checkbox
                            checked={citySelected.indexOf(option) > -1}
                          />
                        </ListItemIcon>
                        <ListItemText primary={option} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              {/* <div className="row" style={divStyle}>
                    <FormControl
                      style={{
                        minWidth: 180,
                        marginLeft: '1%',
                      }}
                    >
                      <Typography style={{ fontSize: '13px' }}>City</Typography>
                      <VirtualizedSelect
                        ref="region"
                        options={regionList}
                        simpleValue
                        clearable={false}
                        name="select-region"
                        value={selectedRegion}
                        onChange={(event) => this.changeCity(event)}
                        searchable
                      />
                    </FormControl>
                  </div> */}

              <div className="row" style={divStyle}>
                <FormControl
                  style={{
                    width: 180,
                    marginLeft: "1%",
                  }}
                >
                  <InputLabel id="mutiple-select-label">
                    Service Engineer<sup>*</sup>
                  </InputLabel>
                  <Select
                    labelId="mutiple-select-label"
                    multiple
                    value={engineerSelected}
                    onChange={this.handleChangeEngineer}
                    renderValue={(engineerSelected) =>
                      engineerSelected.join(", ")
                    }
                    MenuProps={MenuProps}
                  >
                    <MenuItem
                      value="all"
                      classes={{
                        root: isAllSelected ? classes.selectedAll : "",
                      }}
                    >
                      <ListItemIcon>
                        <Checkbox
                          classes={{
                            indeterminate: this.props.indeterminateColor,
                          }}
                          checked={isAllSelected}
                          indeterminate={
                            engineerSelected.length > 0 &&
                            engineerSelected.length < engineerList.length
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        classes={{ primary: this.props.selectAllText }}
                        primary="Select All"
                      />
                    </MenuItem>
                    {engineerList.map((option) => (
                      <MenuItem key={option} value={option}>
                        <ListItemIcon>
                          <Checkbox
                            checked={engineerSelected.indexOf(option) > -1}
                          />
                        </ListItemIcon>
                        <ListItemText primary={option} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              {/* <div className="row" style={divStyle}>
                    <FormControl
                      style={{
                        minWidth: 180,
                        marginLeft: '1%',
                      }}
                    >
                      <Typography style={{ fontSize: '13px' }}>
                        Service Engineer
                      </Typography>
                      <VirtualizedSelect
                        ref="engineer"
                        options={engineerList}
                        simpleValue
                        clearable={false}
                        name="select-engineer"
                        value={selectedEngineer}
                        onChange={(event) => this.changeEngineer(event)}
                        searchable
                      />
                    </FormControl>
                  </div> */}
              <div className="row" style={divStyle}>
                <FormControl
                  style={{
                    width: 180,
                    marginLeft: "1%",
                  }}
                >
                  <InputLabel id="mutiple-select-label">
                    Type<sup>*</sup>
                  </InputLabel>
                  <Select
                    labelId="mutiple-select-label"
                    multiple
                    value={typeSelected}
                    onChange={this.handleChangeType}
                    renderValue={(typeSelected) => typeSelected.join(", ")}
                    MenuProps={MenuProps}
                  >
                    <MenuItem
                      value="all"
                      classes={{
                        root: isAllSelected ? classes.selectedAll : "",
                      }}
                    >
                      <ListItemIcon>
                        <Checkbox
                          classes={{
                            indeterminate: this.props.indeterminateColor,
                          }}
                          checked={isAllSelected}
                          indeterminate={
                            typeSelected.length > 0 &&
                            typeSelected.length < typeList.length
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        classes={{ primary: this.props.selectAllText }}
                        primary="Select All"
                      />
                    </MenuItem>
                    {typeList.map((option) => (
                      <MenuItem key={option} value={option}>
                        <ListItemIcon>
                          <Checkbox
                            checked={typeSelected.indexOf(option) > -1}
                          />
                        </ListItemIcon>
                        <ListItemText primary={option} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              {/* <div className="row" style={divStyle}>
                    <FormControl
                      style={{
                        minWidth: 180,
                        marginLeft: '1%',
                      }}
                    >
                      <Typography style={{ fontSize: '13px' }}>Type</Typography>
                      <VirtualizedSelect
                        ref="type"
                        options={typeList}
                        simpleValue
                        clearable={false}
                        name="select-type"
                        value={selectedType}
                        onChange={(event) => this.changeType(event)}
                        searchable
                      />
                    </FormControl>
                  </div> */}

              <div className="row" style={divStyle}>
                <FormControl
                  style={{
                    width: 180,
                    marginLeft: "1%",
                  }}
                >
                  <InputLabel id="mutiple-select-label">
                    Article Number<sup>*</sup>
                  </InputLabel>
                  <Select
                    labelId="mutiple-select-label"
                    multiple
                    value={articleNoSelected}
                    onChange={this.handleChangeArticle}
                    renderValue={(articleNoSelected) =>
                      articleNoSelected.join(", ")
                    }
                    MenuProps={MenuProps}
                  >
                    <MenuItem
                      value="all"
                      classes={{
                        root: isAllSelected ? classes.selectedAll : "",
                      }}
                    >
                      <ListItemIcon>
                        <Checkbox
                          classes={{
                            indeterminate: this.props.indeterminateColor,
                          }}
                          checked={isAllSelected}
                          indeterminate={
                            articleNoSelected.length > 0 &&
                            articleNoSelected.length < articleNoList.length
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        classes={{ primary: this.props.selectAllText }}
                        primary="Select All"
                      />
                    </MenuItem>
                    {articleNoList.map((option) => (
                      <MenuItem key={option} value={option}>
                        <ListItemIcon>
                          <Checkbox
                            checked={articleNoSelected.indexOf(option) > -1}
                          />
                        </ListItemIcon>
                        <ListItemText primary={option} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              {/* <div className="row" style={divStyle}>
                    <FormControl
                      style={{
                        width: 180,
                        marginLeft: '1%',
                      }}
                    >
                      <Typography style={{ fontSize: '13px' }}>
                        Article Number
                      </Typography>
                      <VirtualizedSelect
                        ref="article"
                        options={articleNoList}
                        simpleValue
                        clearable={false}
                        name="select-articleNo"
                        value={selectedAcrticleNo}
                        onChange={(event) => this.changeArticle(event)}
                        searchable
                      />
                    </FormControl>
                  </div> */}
              <div className="row" style={divStyle}>
                <FormControl
                  style={{
                    width: 180,
                    marginLeft: "1%",
                  }}
                >
                  <InputLabel id="mutiple-select-label">
                    Serial No.<sup>*</sup>
                  </InputLabel>
                  <Select
                    labelId="mutiple-select-label"
                    multiple
                    value={serialNoSelected}
                    onChange={this.handleChangeSerial}
                    renderValue={(serialNoSelected) =>
                      serialNoSelected.join(", ")
                    }
                    MenuProps={MenuProps}
                  >
                    <MenuItem
                      value="all"
                      classes={{
                        root: isAllSelected ? classes.selectedAll : "",
                      }}
                    >
                      <ListItemIcon>
                        <Checkbox
                          classes={{
                            indeterminate: this.props.indeterminateColor,
                          }}
                          checked={isAllSelected}
                          indeterminate={
                            serialNoSelected.length > 0 &&
                            serialNoSelected.length < serNoList.length
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        classes={{ primary: this.props.selectAllText }}
                        primary="Select All"
                      />
                    </MenuItem>
                    {serNoList.map((option) => (
                      <MenuItem key={option} value={option}>
                        <ListItemIcon>
                          <Checkbox
                            checked={serialNoSelected.indexOf(option) > -1}
                          />
                        </ListItemIcon>
                        <ListItemText primary={option} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              {/* <div className="row" style={divStyle}>
                    <FormControl
                      style={{
                        minWidth: 180,
                        marginLeft: '1%',
                      }}
                    >
                      <Typography style={{ fontSize: '13px' }}>
                        Serial No.
                      </Typography>
                      <VirtualizedSelect
                        ref="serial_no"
                        options={serNoList}
                        simpleValue
                        clearable={false}
                        name="select-ser-no"
                        value={selectedSerNo}
                        onChange={(event) => this.changeSerNo(event)}
                        searchable
                      />
                    </FormControl>
                  </div> */}
              <div className="row" style={divStyle}>
                <FormControl style={{ marginLeft: "1%" }}>
                  <Typography>&nbsp;</Typography>
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    title="Get Asset"
                    onClick={this.getMapAsset}
                    style={{ backgroundColor: "rgb(0, 153, 204" }}
                    ref={(input) => (this.inputElement = input)}
                  >
                    Apply Filter
                  </Button>
                </FormControl>
              </div>
            </form>
          </Drawer>
        </div>
        <Paper className={classes.root}>
          {isSpinner ? (
            <CircularProgress
              className={classes.progress}
              color="primary"
              size={50}
              thickness={3}
            />
          ) : null}
          <Grid
            container
            class="row"
            spacing={12}
            style={{ marginLeft: "10px" }}
          >
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
              <form className={classes.container}>
                {/* 
                <FormControl className={classes.textField2}>
                  <Typography style={errorStyle}>
                    Unit Id <sup>*</sup>
                  </Typography>
                  <Select
                    labelId="mutiple-select-label"
                    multiple
                    value={unitSelected}
                    onChange={this.handleChangeUnit}
                    renderValue={(unitSelected) => unitSelected.join(', ')}
                    MenuProps={MenuProps}
                  >
                    <MenuItem
                      value="all"
                      classes={{
                        root: isAllSelected ? classes.selectedAll : '',
                      }}
                    >
                      <ListItemIcon>
                        <Checkbox
                          classes={{ indeterminate: classes.indeterminateColor }}
                          checked={isAllSelected}
                          indeterminate={
                            unitSelected.length > 0 &&
                            unitSelected.length < unitList.length
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        classes={{ primary: classes.selectAllText }}
                        primary="Select All"
                      />
                    </MenuItem>
                    {unitList.map((option) => (
                      <MenuItem key={option} value={option}>
                        <ListItemIcon>
                          <Checkbox checked={unitSelected.indexOf(option) > -1} />
                        </ListItemIcon>
                        <ListItemText primary={option} />
                      </MenuItem>
                    ))}
                  </Select>
                  {hasError && (
                    <FormHelperText style={errorStyle}>
                      Unit Id is required!
                </FormHelperText>
                  )}
                </FormControl>

                <FormControl style={{ marginLeft: '10px' }}>
                  <Typography style={{ marginTop: '10px' }}>&nbsp;</Typography>
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    title="Get Assets"
                    onClick={this.getAssets}
                    style={{ backgroundColor: 'rgb(0, 153, 204' }}
                  >
                    Get
              </Button>
                </FormControl>
 */}
              </form>
            </Grid>
            <Grid
              item
              xs={2}
              sm={2}
              md={2}
              lg={2}
              xl={2}
              style={{ marginTop: "2%", textAlign: "center", color: "red" }}
            >
              <span>
                <b> Asset With Alarm: {alarmCount}</b>
              </span>
              {/* <FormControl style={{ float: 'right' }}>
            <Typography style={{ marginTop: '20px' }}>Asset With Alarm: {alarmCount}</Typography>

          </FormControl> */}
            </Grid>
          </Grid>
          {/* </CardContent>
        </Card> */}
        </Paper>

        <Grid container class="row" spacing={12} style={{ margin: "0.3em" }}>
          {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ backgroundColor: 'black', minHeight: '800px' }}> */}
          {/* <Card style={{ padding: '15px' }}>
          
          </Card> */}
          {/* <div style={{ marginTop: '1%', marginLeft: '2%' }}> */}
          {assetData.length > 0
            ? assetData.map((a, idx) => {
                return (
                  <Grid
                    item
                    xs={2}
                    sm={2}
                    md={2}
                    lg={2}
                    xl={2}
                    title={a.asset_id}
                    style={{
                      marginLeft: "35px",
                      marginTop: "1%",
                      color: "white",
                      backgroundColor: "#b3ced9",
                      height: "fit-content",
                      border: a.BORDER,
                      borderRadius: "20px",
                    }}
                  >
                    {/* <Card>
                <CardContent style={{ color: 'white', backgroundColor: '#b3ced9' }}> */}
                    {/* <Typography variant="h2" component="h6"> */}
                    {/* <div style={{ border: a.BORDER }}> */}
                    {/* <a href={'graphics/' + a.asset_id+'_Run1.g'} onClick={localStorage.setItem('asset_id', a.asset_id)}> */}
                    <table
                      style={{
                        fontWeight: "bold",
                        marginLeft: "5px",
                        width: "100%",
                      }}
                    >
                      <tr>
                        <td
                          style={{
                            fontSize: "11px",
                            color: "black",
                            width: "31%",
                          }}
                        >
                          Capacity:{" "}
                        </td>
                        <td style={{ fontSize: "15px", color: "blue" }}>
                          {a["CAPACITY"]}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontSize: "11px",
                            color: "black",
                            width: "31%",
                          }}
                        >
                          Site Name:{" "}
                        </td>
                        <td style={{ fontSize: "14px", color: "blue" }}>
                          {a["SITE"]}
                        </td>
                      </tr>
                    </table>

                    <table
                      style={{
                        marginLeft: "5px",
                        marginBottom: "5px",
                        marginRight: "5px",
                        marginTop: "5px",
                        width: "95%",
                      }}
                    >
                      <tr>
                        <td
                          style={{
                            fontSize: "11px",
                            color: "black",
                            width: "35%",
                          }}
                        >
                          PT 01 (bar){" "}
                        </td>
                        <td
                          style={{
                            fontSize: "14px",
                            fontWeight: "bold",
                            color: "blue",
                            width: "20%",
                          }}
                        >
                          {" "}
                          {a["PT01"]}
                        </td>
                        <td
                          style={{
                            fontSize: "11px",
                            color: "black",
                            width: "35%",
                          }}
                        >
                          POWER (kW){" "}
                        </td>
                        <td
                          style={{
                            fontSize: "14px",
                            fontWeight: "bold",
                            color: "blue",
                            width: "20%",
                          }}
                        >
                          {a["KW"]}
                        </td>
                      </tr>
                      <tr style={{ marginTop: "2px" }}>
                        <td
                          style={{
                            fontSize: "11px",
                            color: "black",
                            width: "35%",
                          }}
                        >
                          PT 04 (bar){" "}
                        </td>
                        <td
                          style={{
                            fontSize: "14px",
                            fontWeight: "bold",
                            color: "blue",
                            width: "20%",
                          }}
                        >
                          {" "}
                          {a["PT04"]}
                        </td>
                        <td
                          style={{
                            fontSize: "11px",
                            color: "black",
                            width: "35%",
                          }}
                        >
                          FM-02 (kg/hr){" "}
                        </td>
                        <td
                          style={{
                            fontSize: "14px",
                            fontWeight: "bold",
                            color: "blue",
                            width: "20%",
                          }}
                        >
                          {a["FM_02"]}
                        </td>
                      </tr>

                      <tr style={{ marginTop: "2px" }}>
                        <td
                          style={{
                            fontSize: "11px",
                            color: "black",
                            width: "35%",
                          }}
                        >
                          PT 05 (bar){" "}
                        </td>
                        <td
                          style={{
                            fontSize: "14px",
                            fontWeight: "bold",
                            color: "blue",
                            width: "20%",
                          }}
                        >
                          {" "}
                          {a["PT05"]}
                        </td>
                        <td
                          style={{
                            fontSize: "11px",
                            color: "black",
                            width: "35%",
                          }}
                        >
                          VBR (mm/s){" "}
                        </td>
                        <td
                          style={{
                            fontSize: "14px",
                            fontWeight: "bold",
                            color: "blue",
                            width: "20%",
                          }}
                        >
                          {a["VT01"]}
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                      </tr>
                    </table>
                    {/* </a> */}

                    <div
                      style={{
                        paddingTop: "0%",
                        fontSize: "0px",
                      }}
                    >
                      {a.showAlm ? (
                        // <div>
                        <a
                          href="/alarmActive"
                          class="buttonA js-buttonA"
                          style={{ backgroundColor: "red", float: "left" }}
                          title="Alarm"
                          onClick={(e) => this.setUnit(e, a.unit_id)}
                          role="button"
                        >
                          <AccessAlarmIcon
                            style={{ color: "white" }}
                          ></AccessAlarmIcon>{" "}
                        </a>
                      ) : // </div>
                      null}
                      {a.redirect_url != "-" ? (
                        <a
                          href={a.redirect_url}
                          target="_blank"
                          title="Analytics"
                          class="buttonA js-buttonA"
                          style={{
                            backgroundColor: "gray",
                            float: "right",
                            marginLeft: "2%",
                          }}
                          role="button"
                        >
                          <BarChartIcon
                            style={{ color: "blue" }}
                          ></BarChartIcon>{" "}
                        </a>
                      ) : null}
                      <a
                        href={"graphics/" + a.asset_id + "_" + a.ass_disp}
                        title="More Info"
                        class="buttonA js-buttonA"
                        style={{ backgroundColor: "gray", float: "right" }}
                        onClick={localStorage.setItem("asset_id", a.asset_id)}
                        role="button"
                      >
                        <NearMeIcon style={{ color: "blue" }}></NearMeIcon>{" "}
                      </a>
                    </div>

                    {/* </div> */}

                    {/* </Typography> */}
                    {/* </CardContent>
              </Card> */}
                  </Grid>
                );
              })
            : null}
          {/* </div> */}
          {/* </Grid> */}
        </Grid>
      </Wrapper>
    );
  }
}

// export default assetHome;

assetHome.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(assetHome);
