import { axiosInstance1 } from '../authorization/auth-intercept-service';
class EventService {

    async getLatestEvents(){
        return axiosInstance1.post('user_events/events/50', {app_type: JSON.parse(localStorage.getItem('User'))['app_type']}).then(res => {
            console.log(res);
            return res.data;
        }).catch(err => {
            console.log(err);
            return err;
        });
    }

    async getFilteredEvents(reqParams){
        return axiosInstance1.post('user_events/byDate', reqParams).then(res => {
            console.log(res);
            return res.data;
        }).catch(err => {
            console.log(err);
            return err;
        });
    }

    async getEmails(){
        return axiosInstance1.get('user/getAllEmails').then(res => {
            console.log(res);
            return res.data;
        }).catch(err => {
            console.log(err);
            return err;
        });
    }
}
export default EventService;