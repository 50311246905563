import {
  axiosInstance1,
  axiosInstance6,
} from '../authorization/auth-intercept-service';

class ReportService {
  userEmail = localStorage.getItem('User');
  userEmail = JSON.parse(this.userEmail)['email'];
  async getAllTemplates() {
    return axiosInstance6
      .get('/template/getAll')
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async getUnitTags(id) {
    return axiosInstance1
      .post('tag/getAllLoggingTagNamesByUnitIds', id)
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async addAlarmReport(data) {
    return axiosInstance6
      .post('/report/alarmOneTimeReportWebsite?email=' + this.userEmail, data, {
        responseType: 'arraybuffer',
      })
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async addLiveReport(data) {
    return axiosInstance6
      .post('/report/liveOneTimeReportWebsite?email=' + this.userEmail, data, {
        responseType: 'arraybuffer',
      })
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async getCPReport(data) {
    return axiosInstance6
      .post(
        '/cp_report?email=' + this.userEmail,
        data,
        { responseType: 'arraybuffer' }, {timeout: 600000}
      )
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async getUnits() {
    return axiosInstance1
      .get('/general_setting')
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async getTemplateByUnit(req) {
    return axiosInstance6
      .post('template/getAllByUnitId', req)
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async getAssetByUnit(req) {
    return axiosInstance1
      .post('assets/getAssetIdByUnit', req)
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  async getFilePath(req) {
    return axiosInstance6
      .post('report/getFilePath', req)
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }
  async downloadReport(req) {
    return axiosInstance6
      .post(
        'report/download',
        req,
        { responseType: 'arraybuffer' }
      )
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }
}
export default ReportService;
