import axios from 'axios';
import GlobalMessage from '../../shared/snackbar-service';

// const REACT_APP_API_URL =
//    window.location.protocol + '//' + window.location.hostname;
// const REACT_APP_API_URL = window.location.protocol + '//114.143.186.54';
const REACT_APP_API_URL = 'https://itracklink.advait.io';
// const REACT_APP_API_URL = 'http://192.168.1.151';
console.log(REACT_APP_API_URL + ' -- ' + process.env.REACT_APP_API_URL3);
// let url2 = window.location.hostname;
// console.log(url2);
// let pro = window.location.href;
// console.log(pro)

const axiosInstance1 = axios.create({
  // baseURL: process.env.REACT_APP_API_URL1,
  baseURL: REACT_APP_API_URL + '/config',
});

axiosInstance1.interceptors.request.use(
  (config) => {
    var uToken = localStorage.getItem('User');
    uToken = JSON.parse(uToken)['token'];
    //*** checking of we have token */
    if (uToken) {
      config.headers.common['Authorization'] = 'Bearer ' + uToken;
    }
    return config;
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  }
);

const axiosInstance2 = axios.create({
  // baseURL: process.env.REACT_APP_API_URL2,
  baseURL: REACT_APP_API_URL + '/analytics',
});

axiosInstance2.interceptors.request.use(
  (config) => {
    var uToken = localStorage.getItem('User');
    uToken = JSON.parse(uToken)['token'];
    //*** checking of we have token */
    if (uToken) {
      config.headers.common['Authorization'] = 'Bearer ' + uToken;
    }
    return config;
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  }
);

const axiosInstance3 = axios.create({
  // baseURL: process.env.REACT_APP_API_URL1,
  baseURL: REACT_APP_API_URL + '/config',
});

axiosInstance3.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  }
);

const axiosInstance4 = axios.create({
  // baseURL: process.env.REACT_APP_API_URL3,
  baseURL: REACT_APP_API_URL + '/license',
});

axiosInstance4.interceptors.request.use(
  (config) => {
    config.headers['Content-Type'] = 'application/json;charset=utf-8';
    var uToken = localStorage.getItem('User');
    uToken = JSON.parse(uToken)['token'];
    //*** checking of we have token */
    if (uToken) {
      config.headers.common['Authorization'] = 'Bearer ' + uToken;
    }
    return config;
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  }
);

const axiosInstance5 = axios.create({
  // baseURL: process.env.REACT_APP_API_URL1,
  baseURL: REACT_APP_API_URL + '/config',
});

axiosInstance5.interceptors.request.use(
  (config) => {
    config.headers['Content-Type'] = 'application/json;charset=utf-8';
    var uToken = localStorage.getItem('User');
    uToken = JSON.parse(uToken)['token'];
    //*** checking of we have token */
    if (uToken) {
      config.headers.common['Authorization'] = 'Bearer ' + uToken;
    }
    return config;
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  }
);

const axiosInstance6 = axios.create({
  // baseURL: process.env.REACT_APP_API_URL4,
  baseURL: REACT_APP_API_URL + '/report',
});

axiosInstance6.interceptors.request.use(
  (config) => {
    config.headers['Content-Type'] = 'application/json;charset=utf-8';
    var uToken = localStorage.getItem('User');
    uToken = JSON.parse(uToken)['token'];
    //*** checking of we have token */
    if (uToken) {
      config.headers.common['Authorization'] = 'Bearer ' + uToken;
    }
    return config;
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  }
);

const axiosInstance7 = axios.create({
  // baseURL: process.env.REACT_APP_API_URL2,
  baseURL: REACT_APP_API_URL + '/scada',
});

axiosInstance7.interceptors.request.use(
  (config) => {
    var uToken = localStorage.getItem('User');
    uToken = JSON.parse(uToken)['token'];
    //*** checking of we have token */
    if (uToken) {
      config.headers.common['Authorization'] = 'Bearer ' + uToken;
    }
    return config;
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  }
);

const handleClose = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  GlobalMessage({ open: false });
};

axiosInstance1.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    if (err.message === 'Network Error' && !err.response) {
      // GlobalMessage({'open': true, 'snackType': 'error', 'snackMessage':'Network error!', 'close':handleClose });
      console.log('Network Error');
    } else {
      switch (err.response.status) {
        case 400:
        case 403:
        case 409:
          // GlobalMessage({'open': true, 'snackType': 'error', 'snackMessage':'Bad request!', 'close':handleClose });
          console.log('Bad request!');
          break;
        case 401:
        case 405:
          // this.relogin(); //open dialg box of login
          localStorage.clear();
          // localStorage.clear();
          GlobalMessage({
            open: true,
            snackType: 'warning',
            snackMessage: 'Your session is expired!',
            close: handleClose,
          });
          return (window.location.href = '/signin');
          break;
        case 500:
          // GlobalMessage({'open': true, 'snackType': 'error', 'snackMessage':'Internal server error!', 'close':handleClose });
          console.log('internal server error.');
          break;
          return Promise.reject(err);
      }
    }
  }
);

axiosInstance2.interceptors.response.use(
  (res) => {
    // alert(JSON.stringify(res));
    return res;
  },
  (err) => {
    if (err.message === 'Network Error' && !err.response) {
      // GlobalMessage({'open': true, 'snackType': 'error', 'snackMessage':'Network error!', 'close':handleClose });
      console.log('Network Error');
    } else {
      switch (err.response.status) {
        case 400:
        case 403:
        case 409:
          // GlobalMessage({'open': true, 'snackType': 'error', 'snackMessage':'Bad request!', 'close':handleClose });
          console.log('Bad request!');
          break;
        case 401:
        case 405:
        case 404:
          // this.relogin(); //open dialg box of login
          localStorage.clear();
          GlobalMessage({
            open: true,
            snackType: 'warning',
            snackMessage: 'Your session is expired!',
            close: handleClose,
          });
          return (window.location.href = '/signin');
          break;
        case 500:
          // GlobalMessage({'open': true, 'snackType': 'error', 'snackMessage':'Internal server error!', 'close':handleClose });
          console.log('internal server error.');
          break;
          return Promise.reject(err);
      }
    }
  }
);

axiosInstance3.interceptors.response.use(
  (res) => {
    // alert(JSON.stringify(res));
    return res;
  },
  (err) => {
    if (err.message === 'Network Error' && !err.response) {
      // GlobalMessage({'open': true, 'snackType': 'error', 'snackMessage':'Network error!', 'close':handleClose });
      console.log('Network Error');
    } else {
      switch (err.response.status) {
        case 400:
        case 403:
        case 409:
          // GlobalMessage({'open': true, 'snackType': 'error', 'snackMessage':'Bad request!', 'close':handleClose });
          console.log('Bad request!');
          break;
        case 401:
        case 405:
          // this.relogin(); //open dialg box of login
          localStorage.clear();
          GlobalMessage({
            open: true,
            snackType: 'warning',
            snackMessage: 'Your session is expired!',
            close: handleClose,
          });
          return (window.location.href = '/signin');
          break;
        case 500:
          // GlobalMessage({'open': true, 'snackType': 'error', 'snackMessage':'Internal server error!', 'close':handleClose });
          console.log('internal server error.');
          break;
          return Promise.reject(err);
      }
    }
  }
);

axiosInstance4.interceptors.response.use(
  (res) => {
    // alert(JSON.stringify(res));
    return res;
  },
  (err) => {
    if (err.message === 'Network Error' && !err.response) {
      // GlobalMessage({'open': true, 'snackType': 'error', 'snackMessage':'Network error!', 'close':handleClose });
      console.log('Network Error');
    } else {
      switch (err.response.status) {
        case 400:
        case 403:
        case 409:
          // GlobalMessage({'open': true, 'snackType': 'error', 'snackMessage':'Bad request!', 'close':handleClose });
          console.log('Bad request!');
          break;
        case 401:
        case 405:
          // this.relogin(); //open dialg box of login
          localStorage.clear();
          GlobalMessage({
            open: true,
            snackType: 'warning',
            snackMessage: 'Your session is expired!',
            close: handleClose,
          });
          return (window.location.href = '/signin');
          break;
        case 500:
          // GlobalMessage({'open': true, 'snackType': 'error', 'snackMessage':'Internal server error!', 'close':handleClose });
          console.log('internal server error.');
          break;
          return Promise.reject(err);
      }
    }
  }
);

axiosInstance5.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    if (err.message === 'Network Error' && !err.response) {
      // GlobalMessage({'open': true, 'snackType': 'error', 'snackMessage':'Network error!', 'close':handleClose });
      console.log('Network Error');
    } else {
      switch (err.response.status) {
        case 400:
        case 403:
        case 409:
          // GlobalMessage({'open': true, 'snackType': 'error', 'snackMessage':'Bad request!', 'close':handleClose });
          console.log('Bad request!');
          break;
        case 401:
        case 405:
          // this.relogin(); //open dialg box of login
          localStorage.clear();
          GlobalMessage({
            open: true,
            snackType: 'warning',
            snackMessage: 'Your session is expired!',
            close: handleClose,
          });
          return (window.location.href = '/signin');
          break;
        case 500:
          // GlobalMessage({'open': true, 'snackType': 'error', 'snackMessage':'Internal server error!', 'close':handleClose });
          console.log('internal server error.');
          break;
          return Promise.reject(err);
      }
    }
  }
);

axiosInstance6.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    if (err.message === 'Network Error' && !err.response) {
      // GlobalMessage({'open': true, 'snackType': 'error', 'snackMessage':'Network error!', 'close':handleClose });
      console.log('Network Error');
    } else {
      switch (err.response.status) {
        case 400:
        case 403:
        case 409:
          // GlobalMessage({'open': true, 'snackType': 'error', 'snackMessage':'Bad request!', 'close':handleClose });
          console.log('Bad request!');
          break;
        case 401:
        case 405:
          // this.relogin(); //open dialg box of login
          localStorage.clear();
          GlobalMessage({
            open: true,
            snackType: 'warning',
            snackMessage: 'Your session is expired!',
            close: handleClose,
          });
          return (window.location.href = '/signin');
          break;
        case 500:
          // GlobalMessage({'open': true, 'snackType': 'error', 'snackMessage':'Internal server error!', 'close':handleClose });
          console.log('internal server error.');
          break;
          return Promise.reject(err);
      }
    }
  }
);


axiosInstance7.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    if (err.message === 'Network Error' && !err.response) {
      // GlobalMessage({'open': true, 'snackType': 'error', 'snackMessage':'Network error!', 'close':handleClose });
      console.log('Network Error');
    } else {
      switch (err.response.status) {
        case 400:
        case 403:
        case 409:
          // GlobalMessage({'open': true, 'snackType': 'error', 'snackMessage':'Bad request!', 'close':handleClose });
          console.log('Bad request!');
          break;
        case 401:
        case 405:
          // this.relogin(); //open dialg box of login
          localStorage.clear();
          GlobalMessage({
            open: true,
            snackType: 'warning',
            snackMessage: 'Your session is expired!',
            close: handleClose,
          });
          return (window.location.href = '/signin');
          break;
        case 500:
          // GlobalMessage({'open': true, 'snackType': 'error', 'snackMessage':'Internal server error!', 'close':handleClose });
          console.log('internal server error.');
          break;
          return Promise.reject(err);
      }
    }
  }
);

export {
  axiosInstance1,
  axiosInstance2,
  axiosInstance3,
  axiosInstance4,
  axiosInstance5,
  axiosInstance6,
  axiosInstance7
};
