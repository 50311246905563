import React, { Component } from 'react';
import { Wrapper } from '../../components';
import { Card } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import HomeService from '../../services/home/home-service';
import { CustomizedSnackbars } from '../../shared/snackbar-service';

class advaitHome extends Component {
  constructor(props) {
    super(props);
    this.homeService = new HomeService();
    this.state = {
      desc: '',
      msgStatus: false,
      msgType: '',
      msgContent: '',
      // logo: JSON.parse(localStorage.getItem('hLogo'))
      logo: '',
    };
  }

  componentDidMount() {
    this.getDescription();
    this.getLOGO();
    // if(!this.state.logo){
    //   this.getLOGO();
    // }
  }

  getDescription() {
    this.homeService.getHomeDesc().then((res) => {
      if (res) {
        this.setState({ desc: res });
      } else {
        this.setState({
          msgStatus: true,
          msgType: 'error',
          msgContent: 'No data found!',
        });
      }
    });
  }

  getLOGO = () => {
    this.homeService.getLogo().then((res) => {
      if (res && res.data) {
        const logoURL = window.URL.createObjectURL(new Blob([res.data]));
        this.setState({ logo: logoURL });
        // }else{
        //   this.setState({ logo: '/static/images/advaitLOGO.png' });
      }
    });
  };

  closeSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ msgStatus: false });
  };

  render() {
    const { desc, logo, msgContent, msgStatus, msgType } = this.state;
    // alert(logo)
    const withLogo = (
      <Grid container spacing={8}>
        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
          <Card>
            <CardContent style={{ padding: '14px 1px' }}>
              <center>
                <img src={logo} width="100%" />
              </center>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
          <Card style={{ padding: '15px' }}>
            <div dangerouslySetInnerHTML={{ __html: desc }} />
          </Card>
        </Grid>
      </Grid>
    );

    const withoutLogo = (
      <Grid container spacing={8}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Card style={{ padding: '15px' }}>
            <div dangerouslySetInnerHTML={{ __html: desc }} />
          </Card>
        </Grid>
      </Grid>
    );
    return (
      <Wrapper>
        {msgStatus ? (
          <CustomizedSnackbars
            opened={msgStatus}
            snackType={msgType}
            snackMessage={msgContent}
            closed={this.closeSnackBar}
          ></CustomizedSnackbars>
        ) : null}
        <br></br>
        {logo ? withLogo : withoutLogo}
      </Wrapper>
    );
  }
}

export default advaitHome;
