import React, { Component } from 'react';
import { Wrapper } from '../../components';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ManualEntryService from '../../services/manualEntry/manualEntry';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { Card } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { RadioGroup } from '@material-ui/core';
import UploadIcon from '@material-ui/icons/CloudUpload';
import 'font-awesome/css/font-awesome.css';
import ColumnResizer from 'react-column-resizer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { CustomizedSnackbars } from '../../shared/snackbar-service';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import * as FileSaver from 'file-saver';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 1,
  },
  progress: {
    marginLeft: '50%',
    position: 'absolute',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 180,
  },
  formControl1: {
    // margin: theme.spacing.unit,
    minWidth: 180,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit * 2,
    minWidth: 200,
  },
  errorText: {
    marginLeft: theme.spacing.unit * 2,
    color: 'red',
  },
  columnResizer: {
    backgroundColor: '#333',
    width: '1px',
  },
  table: {
    width: '100%',
  },
});

class ManualEntry extends Component {
  constructor(props) {
    super(props);
    this.meService = new ManualEntryService();
    this.state = {
      formList: [],
      selectedForm: { id: '', name: '' },
      formData: {},
      fields: {},
      errors: {},
      selectedCB: '',
      selectedRB: '',
      selectedDD: '',
      manualData: [],
      columnList: [],
      msgStatus: false,
      msgType: '',
      msgContent: '',
      open: false,
      selectedRowID: '',
      page: 0,
      rowsPerPage: 5,
      isSpinner: false,
      isUpdate: false,
      searchValue: '',
      sManualData: [],
      openForm: false,
      title: '',
      permission: [],
    };
  }

  componentDidMount() {
    const user = localStorage.getItem('User');
    const uPrms = JSON.parse(user)['permissions'];
    this.state.permission = JSON.parse(uPrms);
    this.getFormList();
  }

  getFormList = () => {
    this.meService.getAllForms().then((res) => {
      // alert(JSON.stringify(res));
      if (res && res.length > 0) {
        this.setState({ formList: res });
      } else {
        // alert('no forms are available...')
        this.setState({
          msgStatus: true,
          msgType: 'error',
          msgContent: 'No form is available!',
        });
      }
    });
  };

  getForm = (id) => {
    this.meService.getForm(id).then((res) => {
      // alert(JSON.stringify(res));
      if (res) {
        //*** checking permissions */
        this.state.title = res.form_menu_name;
        // alert(this.state.title)
        const user = localStorage.getItem('User');
        const uPrms = JSON.parse(user)['permissions'];
        // this.state.permission = uPrms;
        if (res.privilege === 0 || uPrms.includes(res.privilege)) {
          this.getFormAllData(res.form_name);
          this.setState({ formData: JSON.parse(res.form_data) });
        } else {
          this.setState({
            msgStatus: true,
            msgType: 'error',
            msgContent: 'You not have permission for this form!',
          });
        }
      } else {
        this.setState({
          msgStatus: true,
          msgType: 'error',
          msgContent: 'Form is not available!',
        });
      }
    });
  };

  getFormAllData = (formName) => {
    // alert(formName);
    this.setState({ isSpinner: true });
    var paramReq = { form_name: formName, sort_order: -1 };
    this.meService.getAllData(paramReq).then((res) => {
      console.log(formName + ' -- ' + JSON.stringify(res));
      if (res && res.length > 0) {
        let cols1 = [];
        let cols = [];
        let rows = [];
        res.map((r) => {
          // cols = Object.keys(r.form_data);
          cols1 = cols1.concat(Object.keys(r.form_data));
          r.form_data['_id'] = r._id;
          rows.push(r.form_data);
        });
        cols1.forEach((c) => {
          if (!cols.includes(c)) {
            cols.push(c);
          }
        });
        console.log(JSON.stringify(rows) + '   ' + cols);
        this.setState({
          manualData: rows,
          sManualData: rows,
          columnList: cols,
          isSpinner: false,
        });
      } else {
        this.setState({
          msgStatus: true,
          msgType: 'error',
          msgContent: 'No data found!',
          manualData: [],
          sManualData: [],
          columnList: [],
          isSpinner: false,
        });
      }
    });
  };

  changeForms = (event) => {
    // alert(JSON.stringify(event.target.value));
    this.getForm(event.target.value._id);
    // this.getFormAllData(event.target.value.form_name);
    this.setState({
      selectedForm: event.target.value,
      columnList: [],
      manualData: [],
    });
  };

  handleSubmit = (event) => {
    const { formData, ...inputData } = this.state;
    console.log(inputData);
    console.log(JSON.stringify(formData));
    event.preventDefault();
    let temp = inputData;
    delete temp.errors;
    delete temp.fields;
    delete temp.formList;
    // delete temp.selectedCB;
    // delete temp.selectedDD;
    delete temp.selectedRB;
    delete temp.selectedForm;
    delete temp.manualData;
    delete temp.columnList;
    delete temp.msgContent;
    delete temp.msgStatus;
    delete temp.msgType;
    delete temp.open;
    delete temp.selectedRowID;
    delete temp.page;
    delete temp.rowsPerPage;
    delete temp.isSpinner;
    delete temp.isUpdate;
    delete temp.searchValue;
    delete temp.sManualData;
    delete temp.openForm;
    let reqParams = {};
    formData.attributes.map((f) => {
      if (f.type == 'checkbox') {
        temp[f.label] = temp.selectedCB;
        delete temp.selectedCB;
      }
      if (f.type == 'autocomplete') {
        temp[f.label] = temp.selectedDD;
        delete temp.selectedDD;
      }
    });
    reqParams['form_data'] = temp;
    reqParams['form_name'] = this.state.selectedForm.form_name;

    console.log(JSON.stringify(reqParams));
    // if(this.handleValidation()){
    //     alert('form is valid!');
    this.meService.saveData(reqParams).then((res) => {
      console.log(res);
      if (res.status === 'success') {
        this.getFormAllData(this.state.selectedForm.form_name);
        this.setState({
          msgStatus: true,
          msgType: 'success',
          msgContent: 'Form submitted successfully!',
          openForm: false,
        });
      } else {
        this.setState({ msgStatus: true, msgType: 'error', msgContent: res });
      }
    });
    // }else{
    //     alert('form is Invlaid!')
    // this.setState({ msgStatus: true, msgType:'error', msgContent:'Form is invalid!' });
    // }
  };

  handleChange = (event) => {
    console.log(event.currentTarget);
    this.setState({ [event.currentTarget.name]: event.currentTarget.value });
    // this.setState({ [event.currentTarget.name]: event.currentTarget.checked});
  };

  handleRadioButton = (event) => {
    this.setState({ selectedRB: event.target.value });
  };

  handleCheckBox = (event) => {
    console.log(event.target);
    this.setState({ selectedCB: event.target.value });
  };

  handleDropDown = (event) => {
    console.log(event.target);
    this.setState({ selectedDD: event.target.value });
  };

  handleValidation = () => {
    const { ...inputData } = this.state;
    console.log(inputData);
    const errors = {};
    let formIsValid = true;
    let fields = this.state.formData.attributes;
    if (fields && fields.length > 0) {
      fields.map((f) => {
        if (f.required === true) {
          if (!inputData[f.name]) {
            formIsValid = false;
            errors[f.name] = f.label + ' is required.';
          }
        }
        if (f.regex) {
          if (
            inputData[f.name] !== undefined &&
            !inputData[f.name].match(f.regex)
          ) {
            formIsValid = false;
            errors[f.name] = f.errorText;
          }
        }
      });
    }
    this.setState({ errors: errors });
    return formIsValid;
  };

  closeSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ msgStatus: false });
  };

  // getDataSingleRecord = (rowID) => {
  //     alert('edit: '+rowID+'   '+JSON.stringify(this.state.formData));
  //     this.meService.getData(rowID).then(res => {
  //         console.log(res);
  //         if(res){
  //             const fields = Object.keys(res.form_data);
  //             alert('fields: '+JSON.stringify(fields));
  //             this.state.formData.attributes.map((a, i) => {
  //                 fields.map((f, j) => {
  //                     if(f === a.label){
  //                         alert(f+'  '+a.label)
  //                         a.value = res.form_data[f];
  //                     }
  //                 });
  //                 alert('data: '+JSON.stringify(a))
  //             });
  //             alert('>>>: '+JSON.stringify(this.state.formData));
  //             this.setState({ formData: this.state.formData, isUpdate: true });
  //         }
  //     });
  // }

  // handleEdit = (rowID) => {
  //     alert('edit: '+rowID+'   '+JSON.stringify(this.state.formData));

  // }

  handleClose = () => {
    this.setState({ open: false });
  };

  openDialog = (rowID) => {
    this.setState({ open: true, selectedRowID: rowID });
  };

  handleDelete = () => {
    // alert('delete: '+this.state.selectedRowID);
    this.meService.deleteData(this.state.selectedRowID).then((res) => {
      console.log(res);
      if (res.status === 'success') {
        this.getFormAllData(this.state.selectedForm.form_name);
        this.setState({
          msgStatus: true,
          msgType: 'success',
          msgContent: 'Record deleted successfully!',
          open: false,
        });
      } else {
        this.setState({
          msgStatus: true,
          msgType: 'error',
          msgContent: res.response,
          open: false,
        });
      }
    });
  };

  handleCloseForm = () => {
    this.setState({ openForm: false });
  };

  openDialogForm = () => {
    this.setState({ openForm: true });
  };

  handleChangePage = (event, page) => {
    this.setState({ page: page, selected: [] });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleSearch = (event) => {
    const searchInput = event.target.value;
    const { sManualData, columnList } = this.state;
    alert(JSON.stringify(sManualData));
    let filteredDatas = [];
    filteredDatas = sManualData.filter((e) => {
      columnList.map((c) => {
        alert(c);
        return e[c].toLowerCase().includes(searchInput.toLowerCase());
        // e.date_time.toString().includes(searchInput.toLowerCase()) ||
        // e.user_email.toLowerCase().includes(searchInput.toLowerCase()) ||
        // e.event.toLowerCase().includes(searchInput.toLowerCase()) ||
        // e.ip_address.toLowerCase().includes(searchInput.toLowerCase()) ||
        // e.port.toString().includes(searchInput.toLowerCase())
      });
    });
    alert(JSON.stringify(filteredDatas));
    this.setState({
      manualData: filteredDatas,
      searchValue: event.target.value,
    });
  };

  exportToExcel = () => {
    let manualData = this.state.manualData;
    let columnList = this.state.columnList;
    let exportData = [];
    manualData.map((r) => {
      let temp = {};
      columnList.map((c) => {
        // alert(r[c]);
        temp[c] = r[c];
      });
      exportData.push(temp);
      console.log('exportData  ' + JSON.stringify(exportData));
    });
    FileSaver.saveAs(exportData, this.state.selectedForm + '_List');
  };

  render() {
    const { classes } = this.props;
    const {
      formList,
      selectedForm,
      formData,
      errors,
      selectedCB,
      selectedRB,
      selectedDD,
      manualData,
      columnList,
      msgStatus,
      msgType,
      msgContent,
      open,
      rowsPerPage,
      page,
      isSpinner,
      searchValue,
      openForm,
      title,
      permission,
    } = this.state;
    return (
      <Wrapper>
        {msgStatus ? (
          <CustomizedSnackbars
            opened={msgStatus}
            snackType={msgType}
            snackMessage={msgContent}
            closed={this.closeSnackBar}
          ></CustomizedSnackbars>
        ) : null}
        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Delete Record?'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure to delete this record?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleClose}
              color="secondary"
              variant="outlined"
            >
              No
            </Button>
            <Button
              onClick={this.handleDelete}
              color="primary"
              variant="outlined"
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openForm}
          onClose={this.handleCloseForm}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xl"
        >
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
          <DialogContent>
            {formData.attributes ? (
              <form
                noValidate
                onSubmit={this.handleSubmit}
                style={{
                  backgroundColor:
                    formData.theme.bgColor !== undefined
                      ? formData.theme.bgColor
                      : '#FFF',
                }}
              >
                {/* <form noValidate onSubmit={this.handleSubmit} style={{ backgroundColor:formData.theme.bgColor, color:formData.theme.textColor }}> */}
                {formData.attributes.map((f) => {
                  if (f.type === 'text' || f.type === 'phone') {
                    return (
                      <FormControl className={classes.formControl}>
                        <TextField
                          id={f.name}
                          key={f}
                          name={f.label}
                          label={f.label}
                          placeholder={f.placeholder}
                          required={f.required}
                          pattern={f.regex}
                          className={classes.textField}
                          onChange={this.handleChange}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <i className={f.icon}></i>
                              </InputAdornment>
                            ),
                          }}
                        />
                        {errors[f.name] ? (
                          <FormHelperText className={classes.errorText}>
                            {errors[f.name]}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    );
                  }
                  if (f.type === 'email') {
                    return (
                      <FormControl className={classes.formControl}>
                        <TextField
                          id={f.name}
                          key={f.name}
                          name={f.label}
                          label={f.label}
                          type="email"
                          placeholder={f.placeholder}
                          required={f.required}
                          className={classes.textField}
                          onChange={this.handleChange}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <i className={f.icon}></i>
                              </InputAdornment>
                            ),
                          }}
                        />
                        {errors[f.name] ? (
                          <FormHelperText className={classes.errorText}>
                            {errors[f.name]}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    );
                  }
                  if (f.type === 'number') {
                    return (
                      <FormControl className={classes.formControl}>
                        <TextField
                          id={f.name}
                          key={f.name}
                          name={f.label}
                          label={f.label}
                          type="number"
                          placeholder={f.placeholder}
                          required={f.required}
                          className={classes.textField}
                          onChange={this.handleChange}
                          InputProps={{
                            inputProps: { max: f.max, min: f.min },
                            startAdornment: (
                              <InputAdornment position="start">
                                <i className={f.icon}></i>
                              </InputAdornment>
                            ),
                          }}
                        />
                        {errors[f.name] ? (
                          <FormHelperText className={classes.errorText}>
                            {errors[f.name]}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    );
                  }
                  if (f.type === 'date') {
                    return (
                      <FormControl className={classes.formControl}>
                        <TextField
                          id={f.name}
                          key={f.name}
                          name={f.label}
                          label={f.label}
                          type="date"
                          defaultValue=""
                          placeholder={f.placeholder}
                          required={f.required}
                          className={classes.textField}
                          onChange={this.handleChange}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <i className={f.icon}></i>
                              </InputAdornment>
                            ),
                          }}
                        />
                        {errors[f.name] ? (
                          <FormHelperText className={classes.errorText}>
                            {errors[f.name]}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    );
                  }
                  if (f.type === 'time') {
                    return (
                      <FormControl className={classes.formControl}>
                        <TextField
                          id={f.name}
                          key={f.name}
                          name={f.label}
                          label={f.label}
                          type="time"
                          defaultValue=""
                          placeholder={f.placeholder}
                          required={f.required}
                          className={classes.textField}
                          onChange={this.handleChange}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <i className={f.icon}></i>
                              </InputAdornment>
                            ),
                          }}
                        />
                        {errors[f.name] ? (
                          <FormHelperText className={classes.errorText}>
                            {errors[f.name]}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    );
                  }
                  if (f.type === 'datetime-local') {
                    return (
                      <FormControl className={classes.formControl}>
                        <TextField
                          id={f.name}
                          key={f.name}
                          name={f.label}
                          label={f.label}
                          type="datetime-local"
                          defaultValue=""
                          placeholder={f.placeholder}
                          required={f.required}
                          className={classes.textField}
                          onChange={this.handleChange}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <i className={f.icon}></i>
                              </InputAdornment>
                            ),
                          }}
                        />
                        {errors[f.name] ? (
                          <FormHelperText className={classes.errorText}>
                            {errors[f.name]}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    );
                  }
                  if (f.type === 'textarea' || f.type === 'paragraph') {
                    return (
                      <FormControl className={classes.formControl}>
                        <TextField
                          id={f.name}
                          key={f.name}
                          name={f.label}
                          label={f.label}
                          placeholder={f.placeholder}
                          required={f.required}
                          pattern={f.regex}
                          className={classes.textField}
                          onChange={this.handleChange}
                          multiline
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <i className={f.icon}></i>
                              </InputAdornment>
                            ),
                          }}
                        />
                        {errors[f.name] ? (
                          <FormHelperText className={classes.errorText}>
                            {errors[f.name]}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    );
                  }
                  if (f.type === 'checkbox') {
                    return (
                      <FormControl className={classes.formControl}>
                        <FormLabel required={f.required}>{f.label}</FormLabel>
                        <FormGroup
                          name={f.label}
                          value={selectedCB}
                          row={f.inline}
                          onChange={(this.handleChange, this.handleCheckBox)}
                        >
                          {f.values.map((c) => {
                            return (
                              <FormControlLabel
                                name={f.label}
                                control={<Checkbox color="primary" />}
                                label={c.label}
                                value={c.value}
                                onChange={this.handleCheckBox}
                              />
                            );
                          })}
                        </FormGroup>
                        {errors[f.name] ? (
                          <FormHelperText className={classes.errorText}>
                            {errors[f.name]}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    );
                  }
                  if (f.type === 'radio') {
                    return (
                      <FormControl className={classes.formControl1}>
                        <FormLabel required={f.required}>{f.label}</FormLabel>
                        <RadioGroup
                          name={f.label}
                          value={selectedRB}
                          onChange={this.handleChange}
                          row
                        >
                          {f.values.map((r) => {
                            return (
                              <FormControlLabel
                                name={f.label}
                                control={<Radio color="primary" />}
                                label={r.label}
                                value={r.value}
                                key={r.label}
                                onChange={this.handleRadioButton}
                              />
                            );
                          })}
                        </RadioGroup>
                        {errors[f.name] ? (
                          <FormHelperText className={classes.errorText}>
                            {errors[f.name]}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    );
                  }
                  if (f.type === 'autocomplete') {
                    return (
                      <FormControl
                        className={classes.formControl}
                        required={f.required}
                      >
                        <InputLabel id={f.name}>{f.label}</InputLabel>
                        <Select
                          labelid={f.name}
                          id={f.name}
                          name={f.label}
                          value={selectedDD}
                          style={{ minWidth: 200 }}
                          onChange={(this.handleChange, this.handleDropDown)}
                        >
                          {f.values.map((d) => {
                            return (
                              <MenuItem value={d.value} key={d.label}>
                                {d.label}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {errors[f.name] ? (
                          <FormHelperText className={classes.errorText}>
                            {errors[f.name]}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    );
                  }
                  if (f.type === 'file') {
                    return (
                      <FormControl
                        className={classes.formControl}
                        required={f.required}
                      >
                        <FormLabel
                          className={classes.textField}
                          required={f.required}
                          htmlFor={f.name}
                        >
                          {f.label}:&nbsp;&nbsp;&nbsp;
                          <input
                            color="primary"
                            // accept="image/*"
                            type="file"
                            onChange={this.handleChange}
                            id={f.name}
                            style={{ display: 'none' }}
                            name={f.label}
                          />
                          <Button
                            variant="contained"
                            component="span"
                            className={classes.button}
                            size="medium"
                            color="primary"
                            title={f.label}
                          >
                            <UploadIcon />
                          </Button>
                        </FormLabel>
                      </FormControl>
                    );
                  }
                  if (f.type === 'button') {
                    return (
                      <Button variant="raised" color="primary" type={f.subtype}>
                        {f.label}&nbsp;&nbsp;<i className={f.icon}></i>
                      </Button>
                    );
                  }
                })}
              </form>
            ) : (
              <p>No data found!</p>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleCloseForm}
              color="secondary"
              variant="outlined"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <FormControl className={classes.textField}>
          <InputLabel id="formsID">Select Form</InputLabel>
          <Select
            labelid="formsID"
            id="formsID-select"
            value={selectedForm}
            onChange={this.changeForms}
          >
            {formList.map((f, i) => {
              console.log('Prei  ' + JSON.stringify(f));
              if (f.privilege === 0) {
                return (
                  <MenuItem key={i} value={f}>
                    {f.form_name}
                  </MenuItem>
                );
              } else if (permission.includes(f.privilege)) {
                return (
                  <MenuItem key={i} value={f}>
                    {f.form_name}
                  </MenuItem>
                );
              }
            })}
          </Select>
        </FormControl>
        <Button
          variant="raised"
          color="primary"
          type="button"
          onClick={this.openDialogForm}
        >
          Open Manual Entry Form
        </Button>
        <br></br>
        <Paper
          className={classes.root}
          style={{ visibility: columnList.length > 0 ? 'visible' : 'hidden' }}
        >
          {/* <EnhancedTableToolbar handleSearch={event => this.handleSearch(event)} value={searchValue} /> */}
          {/* <Button
            variant="raised"
            color="primary"
            type="button"
            onClick={this.exportToExcel}
            style={{ float: 'right' }}
          >
            Export
          </Button> */}
          <div style={{ float: 'right' }}>
            <ReactHTMLTableToExcel
              id="excel-button"
              className="btn-success"
              table="table-to-xls"
              filename="formdata"
              sheet="tablexls"
              buttonText="Export"
            />
          </div>
          <Table id="table-to-xls">
            <TableHead>
              <TableRow>
                <TableCell>S/N</TableCell>
                {columnList.map((c) => {
                  return <TableCell key={c}>{c}</TableCell>;
                })}
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            {isSpinner ? (
              <CircularProgress
                className={classes.progress}
                color="primary"
                size={50}
                thickness={3}
              />
            ) : (
              <TableBody>
                {manualData.length > 0 ? (
                  manualData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, idx) => {
                      return (
                        <TableRow>
                          <TableCell>{idx + 1}</TableCell>
                          {columnList.map((c, i) => {
                            return <TableCell key={i}>{row[c]}</TableCell>;
                          })}
                          <TableCell>
                            {/* <EditIcon title='Edit Record' color='primary' style={{ cursor: 'pointer'}} onClick={() => this.getDataSingleRecord(row._id)} />&nbsp;&nbsp; */}
                            <DeleteIcon
                              title="Delete Record"
                              color="secondary"
                              style={{ cursor: 'pointer' }}
                              onClick={() => this.openDialog(row._id)}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })
                ) : (
                  <p>No data found!</p>
                )}
              </TableBody>
            )}
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={columnList.length + 2}
                  count={manualData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActionsWrapped}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </Paper>
      </Wrapper>
    );
  }
}

// export default ManualEntry;
ManualEntry.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ManualEntry);

/********* Enhanced toolbar for table (search) */
const toolbarStyles = (theme) => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
});

let EnhancedTableToolbar = (props) => {
  const { classes, value, handleSearch } = props;

  return (
    <Toolbar className={classes.root}>
      <div className={classes.title}>
        <Typography variant="title" id="tableTitle">
          Manual Entry Records
        </Typography>
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        <TextField
          placeholder="Search"
          onChange={handleSearch}
          value={value}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon>search</Icon>
              </InputAdornment>
            ),
          }}
          style={{ float: 'right' }}
        />
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

/******** Pagination for Table */
const actionsStyles = (theme) => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 2.5,
  },
});
class TablePaginationActions extends React.Component {
  handleFirstPageButtonClick = (event) => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = (event) => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1)
    );
  };

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
};

const TablePaginationActionsWrapped = withStyles(actionsStyles, {
  withTheme: true,
})(TablePaginationActions);
